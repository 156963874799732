import { formatDate } from "./date";

export const getDashboardSchedulesToList = (schedules) => {
  return schedules.map((s) => ({
    name: s.nameEvent,
    id: s._id.toString(),
    info: formatDate(s.date),
  }));
};

export const getDashboardPatientFormList = (patientForms) => {
  return patientForms.map((pF) => ({
    name: pF.name,
    id: pF._id.toString(),
    info: formatDate(pF.createdAt),
  }));
};

export const getDashboardWorkoutList = (workouts) => {
  return workouts.map((pS) => ({
    name: pS.patient?.name ?? "-1",
    id: pS._id.toString() ?? "-1",
    info: formatDate(pS.initialDate),
  }));
};

export const getDashboardRoutineList = (patientRoutines) => {
  return patientRoutines.map((pR) => ({
    name: pR?.patient?.name ?? "-1",
    id: pR._id.toString() ?? "-1",
    info: formatDate(pR.createdAt),
  }));
};

export const getDashboardFinanceList = (finances) => {
  return finances.map((f) => ({
    name: !!f?.name ? f?.name : f.pacient?.name ?? "-1",
    id: f._id.toString() ?? "-1",
    info: formatDate(f.date),
  }));
};
