import React, { useState } from "react";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import { Button, Card, Form, Image } from "react-bootstrap";
import penSvg from "../../../../assets/v2/icons/pen.svg";
import { SpinnerButton } from "../../../../components/v2/SpinnerButton";
import { useQueryClient } from "@tanstack/react-query";
import { usePlanMutation } from "../../../../mutations";
import { useReadUserDetailsQuery } from "../../../../queries";
import {
  formatToMoneyString,
  onMoneyInput,
} from "../../../../utils/mask.utils";

export function MyPlans() {
  const { userDetails } = useUserInfo();

  const myPlans = userDetails?.myPlans ?? [];

  const [selectedPlan, setPlan] = useState(undefined);

  const [value, setValue] = useState(0);

  const queryClient = useQueryClient();

  const { editPlanMutation } = usePlanMutation();

  const isLoading = editPlanMutation.isPending;

  const onValueInput = (e) => {
    const realValue = onMoneyInput(e);
    setValue(realValue);
  };

  const handleSubmit = async () => {
    const response = await editPlanMutation.mutateAsync({
      id: selectedPlan?._id,
      value: value,
    });

    await queryClient.refetchQueries({
      queryKey: [useReadUserDetailsQuery.key],
    });

    setPlan(undefined);
    setValue(0);
  };

  return (
    <section>
      {myPlans.map((plan, i) => {
        const isPlan = selectedPlan?._id === plan?._id;
        return (
          <Card.Body key={i} className="flex-between  mb-4">
            <div className="d-flex flex-column flex-grow-1">
              <div className="flex-between flex-grow-1 align-items-center">
                <p className="m-0">{plan?.plansPatient?.name_type}</p>

                {isPlan && (
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={formatToMoneyString(value)}
                      onChange={onValueInput}
                    ></Form.Control>
                  </Form.Group>
                )}
                {!isPlan && (
                  <p className="m-0">
                    R$ {plan?.value?.toFixed(2).replace(".", ",")}
                  </p>
                )}
              </div>
            </div>

            {!isPlan && (
              <Image
                className="ms-4 pointer"
                src={penSvg}
                onClick={() => {
                  setPlan(plan);
                  setValue(plan?.value);
                }}
              ></Image>
            )}

            {isPlan && (
              <Button
                onClick={() => handleSubmit()}
                className="ms-2"
                disabled={isLoading}
              >
                <SpinnerButton isLoading={isLoading}></SpinnerButton>
              </Button>
            )}
          </Card.Body>
        );
      })}
    </section>
  );
}
