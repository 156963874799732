import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, CloseButton } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export function TimerModal({ timeSeconds = undefined, onFinish = () => {} }) {
  const [currentTime, setCurrentTime] = useState(timeSeconds);

  const [pause, dispatch] = useState(false);
  const pauseRef = useRef(pause);

  const decreaseTime = () => {
    setTimeout(() => {
      if (pauseRef.current) return;

      setCurrentTime((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (!Number.isInteger(currentTime)) return;
    if (pause) return;

    if (currentTime > 0) {
      decreaseTime();
    } else {
      onFinish();
    }
  }, [currentTime]);

  const setPause = (value) => {
    pauseRef.current = value;
    dispatch(value);

    if (!value) {
      decreaseTime();
    }
  };

  const percentage = 100 - (currentTime / timeSeconds) * 100;

  return (
    <Modal show={true} centered={true} size="sm">
      <div className="flex-between px-4 align-items-center mt-1">
        <p className="m-0"></p>

        <CloseButton
          onClick={() => onFinish()}
          className="btn text-danger fs-4"
        ></CloseButton>
      </div>
      <p className="h4 text-primary m-0 align-self-center mb-4">
        Tempo de Descanço
      </p>
      <div className="p-5 pt-0 pb-4" style={{}}>
        <CircularProgressbar
          value={percentage}
          text={currentTime + "s"}
          strokeWidth={5}
          styles={buildStyles({
            textColor: "#20295d",
            pathColor: "#20295d",
          })}
        />
      </div>

      <Button
        onClick={() => setPause(!pause)}
        className="align-self-center mb-3"
      >
        {pause ? "Continuar" : "Pausar"}
      </Button>
    </Modal>
  );
}
