import { React, useEffect, useState } from "react";
import { Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import PacientCardV2 from "../../../components/v2/PacientCardV2";
import RegisterCostumerV2 from "../../../components/v2/RegisterCostumerV2";
import { useDispatch, useSelector } from "react-redux";
import { listPacients } from "../../../actions/pacientActions";
import PaginationV2 from "../../../components/v2/PaginationV2";
import { Link, useRouteMatch } from "react-router-dom";

function PatientsListV2() {
  const pacientList = useSelector((state) => state.pacientList);
  const { loading, pacients, page, pages } = pacientList;
  const [currentPage, setCurrentPage] = useState(1);

  const pacientsCreateSelector = useSelector((state) => state.pacientCreate);
  // const { loadingWorkoutCreate, workoutsCreate } = workoutsCreateSelector;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listPacients(currentPage));
  }, [dispatch, currentPage]);

  return (
    <>
      {!pacients && "Nenhum aluno cadastrado."}
      {pacients?.map((pacient, i) => (
        <div className="mb-2 w-100" key={i}>
          <PacientCardV2
            key={i}
            user={pacient}
            loading={loading}
            hasDate={false}
            hasDelete={false}
            hasMsg={true}
            fullName={true}
            link={`/home/personal/pacients/${pacient.id}`}
          />
        </div>
      ))}
      <PaginationV2
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default PatientsListV2;
