import { useMediaQueryValues } from "../hooks/useMediaQueryValues";

export function respStyle(
  { base = "", xs = "", md = "", lg = "", xl = "" },
  mediaQuerieValues
) {
  const { isMD, isLG, isXL, isXS } = mediaQuerieValues;

  let classStyles = "";

  classStyles += isXS && xs !== "" ? ` ${xs} ` : "";
  classStyles += isMD && md !== "" ? ` ${md} ` : "";
  classStyles += isLG && lg !== "" ? ` ${lg} ` : "";
  classStyles += isXL && xl !== "" ? ` ${xl} ` : "";

  if (classStyles === "") classStyles = ` ${base} `;

  return classStyles;
}
