import { useMutation } from "@tanstack/react-query";
import api from "../services/axios";
import { useApiConfig } from "../hooks/useApiConfig";

export function useFeedbackMutation() {
  const config = useApiConfig();

  const createFeedback = useMutation({
    mutationFn: async (params) => {
      const { exerciseId, ...feedback } = params;

      return api.post(
        `/api/exercises/feedback/create/${exerciseId}/`,
        feedback,
        config
      );
    },
  });

  const commentFeedbackItem = useMutation({
    mutationFn: async (params) => {
      const { exercise_done_id, ...rest } = params;

      return api.put(
        `/api/exercises_done/comments/update/${exercise_done_id}/`,
        rest,
        config
      );
    },
  });

  return { createFeedback, commentFeedbackItem };
}
