import {
    PACIENT_LIST_REQUEST,
    PACIENT_LIST_SUCCESS,
    PACIENT_LIST_FAIL,

    PACIENT_DETAILS_REQUEST,
    PACIENT_DETAILS_SUCCESS,
    PACIENT_DETAILS_FAIL,

    PACIENT_DELETE_REQUEST,
    PACIENT_DELETE_SUCCESS,
    PACIENT_DELETE_FAIL,

    PACIENT_CREATE_REQUEST,
    PACIENT_CREATE_SUCCESS,
    PACIENT_CREATE_FAIL,
    PACIENT_CREATE_RESET,

    PACIENT_UPDATE_REQUEST,
    PACIENT_UPDATE_SUCCESS,
    PACIENT_UPDATE_FAIL,
    PACIENT_UPDATE_RESET,

    PACIENT_SAVE_DATA,
    PACIENT_SAVE_INDICATIONS,
    PACIENT_SAVE_RESULTS,

    PACIENT_IMAGE_CREATE_REQUEST,
    PACIENT_IMAGE_CREATE_SUCCESS,
    PACIENT_IMAGE_CREATE_FAIL,

    PACIENT_VIDEO_CREATE_REQUEST,
    PACIENT_VIDEO_CREATE_SUCCESS,
    PACIENT_VIDEO_CREATE_FAIL,

    PACIENT_PATTER_LIST_REQUEST,
    PACIENT_PATTER_SUCCESS,
    PACIENT_PATTER_FAIL,

    PACIENT_RESET
} from '../constants/pacientConstants'


export const pacientListReducer = (state = { pacients: [] }, action) => {
    switch (action.type) {
        case PACIENT_LIST_REQUEST:
            return { loading: true, pacients: [] }

        case PACIENT_LIST_SUCCESS:
           return {
                loading: false,
                pacients: action.payload.data,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case PACIENT_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const pacientDetailsReducer = (state = { pacient: null }, action) => {
    switch (action.type) {
        case PACIENT_DETAILS_REQUEST:
            return { loading: true, pacient: null }

        case PACIENT_DETAILS_SUCCESS:
           return {
                loading: false,
                pacient: action.payload,
            }

        case PACIENT_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const pacientCreateReducer = (state = { pacientCreate: [] }, action) => {
    switch (action.type) {
        case PACIENT_CREATE_REQUEST:
            return { loadingPacientCreate: true }

        case PACIENT_CREATE_SUCCESS:
            return { loadingPacientCreate: false, pacientCreate: action.payload }

        case PACIENT_CREATE_FAIL:
            return { loadingPacientCreate: false, errorPacientCreate: action.payload }

        case PACIENT_CREATE_RESET:
            return { pacientCreate: [] }

        default:
            return state
    }
}

export const pacientImageCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PACIENT_IMAGE_CREATE_REQUEST:
            return { loadingImage: true }

        case PACIENT_IMAGE_CREATE_SUCCESS:
            return { loadingImage: false, successimage: true, image: action.payload }

        case PACIENT_IMAGE_CREATE_FAIL:
            return { loadingImage: false, error: action.payload }

        default:
            return state
    }
}