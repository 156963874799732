import React, { useEffect, useState } from "react";
import { useReadBillsQuery } from "../queries";
import { Badge } from "antd";
import { useMediaQueryValues } from "../hooks/useMediaQueryValues";
import { Card } from "react-bootstrap";
import PaginationV2 from "./v2/PaginationV2";
import { usePrintPage } from "../hooks/usePrintPage";
import { getPurchasePrint } from "./print/getPurchasePrint";
import { PrintButton } from "./PrintButton";
import { useUserInfo } from "../hooks/useUserInfo";
import downloadPng from "../assets/v2/icons/download.png";

export function BillsHistory() {
  const mediaValues = useMediaQueryValues();
  const { isMD, isLG } = mediaValues;

  const [printItem, setPrintItem] = useState();

  const [page, setPage] = useState(1);

  const { bills, pages } = useReadBillsQuery({
    params: { page },
  });

  const [currentBill, setCurrentBill] = useState();

  const { userDetails } = useUserInfo();

  const { document, printDocument } = usePrintPage({
    title: "Fatura",
    filename: "fatura",
    withProfiles: false,
    bodyContent: getPurchasePrint({ purchase: printItem, userDetails }),
    professional: userDetails,
  });

  const handlePrintPurchage = (item) => {
    setPrintItem(item);
  };

  const getStatusData = (status) => {
    switch (status) {
      case "paid":
        return { text: "Pago", color: "success" };
      case "pending":
        return { text: "Pedente", color: "warning" };
      case "cancelled":
        return { text: "Cancelado", color: "error" };
      default:
        return { text: "Pedente", color: "warning" };
    }
  };

  useEffect(() => {
    if (printItem) {
      printDocument();
    }
  }, [printItem]);

  return (
    <>
      <div className="col-12 nav-tabs mb-2">
        <p className="fs-3 fw-bold ">Histórico</p>
        <div className="row">
          <p className="col-4 col-lg-2 text-center fw-bold text-primary">
            Plano
          </p>
          <p className="col-4 col-lg-3 text-center fw-bold text-primary">
            Data
          </p>
          <p className="col-4 col-lg-3 text-center fw-bold text-primary">
            Status
          </p>
          <p className="d-none d-lg-flex justify-content-center col-lg-2 text-center fw-bold text-primary">
            Valor
          </p>

          <p className="d-none d-lg-flex justify-content-center col-lg-2 text-center fw-bold text-primary">
            Arquivo
          </p>
        </div>
      </div>

      {bills &&
        bills.map((b, i) => {
          const value = b?.pagueMe?.amount
            ? Number(b?.pagueMe?.amount).toFixed(2)
            : b?.pagueMe?.items?.price?.toFixed(2);

          const status = b?.bill?.is_pendent
            ? "pending"
            : b?.bill?.status
            ? "paid"
            : "cancelled";

          return (
            <div key={i}>
              <div
                className="row pointer"
                key={b?.bill?._id}
                onClick={() => setCurrentBill(b?.bill?._id)}
              >
                <p className="col-4 col-lg-2 text-center">
                  {b?.bill?.plan?.name}
                </p>
                <p className="col-4 col-lg-3 text-center">
                  {b?.bill?.createdAt &&
                    new Date(b.bill.createdAt)
                      .toISOString()
                      .slice(0, 10)
                      .split("-")
                      .reverse()
                      .join("/")}
                </p>
                <div className="col-4 col-lg-3 flex-center">
                  <div className="col-12 col-md-4 col-lg-5 col-xl-3 col-xxl-3">
                    <p className="text-nowrap text-center">
                      <Badge
                        className="me-2"
                        status={getStatusData(status).color}
                      ></Badge>
                      {getStatusData(status).text}
                    </p>
                  </div>
                </div>
                <p className="d-none d-lg-flex justify-content-center col-lg-2 col-3 text-center">
                  R$ {value}
                </p>

                <div className="col-lg-2 d-none d-lg-flex d-flex justify-content-center">
                  <PrintButton
                    onClick={() => handlePrintPurchage(b)}
                    icon={downloadPng}
                  ></PrintButton>
                </div>
              </div>
              {!isLG && currentBill === b?.bill?._id && (
                <div className="row mb-2">
                  <Card>
                    <Card.Body>
                      <p className="d-flex">
                        <span className="me-2">Valor: </span>
                        <span className="text-primary fw-bold">R$ {value}</span>
                        <div className="position-absolute end-0 me-5 d-flex">
                          <div className="me-2">Arquivo:</div>

                          <PrintButton
                            onClick={() => handlePrintPurchage(b)}
                            icon={downloadPng}
                          ></PrintButton>
                        </div>
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </div>
          );
        })}

      <PaginationV2
        currentPage={page}
        page={page}
        pages={pages}
        setCurrentPage={(p) => setPage(p)}
      ></PaginationV2>

      {document}
    </>
  );
}
