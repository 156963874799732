import React, { useMemo } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import { PremiumView } from "../screens/v2/PremiumView";

export const PrivateRoute = (params) => {
  const {
    userRole,
    routeRoles,
    routePlans,
    userPlan,
    routePlanFallback,
    renderPremiumView,

    allowedViews,
    userViews = [],

    withoutRoute,

    ...props
  } = params;

  const Component = useMemo(() => {
    if (routeRoles && !routeRoles.includes(userRole))
      return () =>
        renderPremiumView ? (
          <PremiumView></PremiumView>
        ) : (
          <Redirect to={"/login"}></Redirect>
        );

    if (routePlans && !routePlans.includes(userPlan)) {
      return () =>
        renderPremiumView ? (
          <PremiumView></PremiumView>
        ) : (
          <Redirect to={routePlanFallback ?? "/paywall"}></Redirect>
        );
    }

    if (allowedViews && !allowedViews.every((el) => userViews.includes(el))) {
      return () =>
        renderPremiumView ? (
          <PremiumView></PremiumView>
        ) : (
          <Redirect to={routePlanFallback ?? "/paywall"}></Redirect>
        );
    }

    return props.component;
  }, [params]);

  if (withoutRoute) return <Component></Component>;

  return <Route {...props} component={Component}></Route>;
};
