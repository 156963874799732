import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import {
  useReadAllAnswersPatientFormQuery,
  useReadAllFilledPatientFormQuery,
} from "../../../../queries/patient-form.query";
import { SpinnerButton } from "../../../../components/v2/SpinnerButton";
import { usePatientFormMutation } from "../../../../mutations/patient-form.mutation";
import { useQueryClient } from "@tanstack/react-query";
import { getQuestionsAnswers } from "../../../../utils/question.utils";
import { QuestionInput } from "../Evaluations/QuestionInput";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import { usePrintPage } from "../../../../hooks/usePrintPage";
import { getEvaluationPrint } from "../../../../components/print/getEvaluationPrint";
import download from "../../../../assets/v2/icons/download.png";
import { PrintButton } from "../../../../components/PrintButton";

export function ViewFilledPatientForm({
  filledPatientForm,
  onClose = () => {},
  patient,
}) {
  const { allQuestionAnswers, readAllAnswerPatientFormQuery } =
    useReadAllAnswersPatientFormQuery({
      enabled: true,
      params: { filledPatientFormId: filledPatientForm._id },
    });

  const queryClient = useQueryClient();

  const {
    editAnswerPatientFormMutation,
    editFilledPatientFormMutation,
    uploadPatientFormImage,
    createAnswerPatientFormMutation,
  } = usePatientFormMutation();
  const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const [formData, setFormData] = useState({ name: filledPatientForm.name });

  const { isProfessional, isPatient, userDetails } = useUserInfo();

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnChange = (e, value) => {
    const question = e.question;

    setQuestionsAnswers(
      questionsAnswers.map((i) => {
        if (i.question.id === question.id) {
          i.newValue = value;
        }

        return i;
      })
    );
  };

  useEffect(() => {
    setQuestionsAnswers(allQuestionAnswers);
  }, [allQuestionAnswers]);

  const getAnswerValue = (answer, type) => {
    if (type === "0") {
      return answer.data?.answerText;
    }

    if (type === "1") {
      return answer.data?.answerChoice;
    }

    if (type === "2") {
      return answer?.images?.map((i) => i.image) ?? [];
    }

    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await editFilledPatientFormMutation.mutateAsync({
      patientPatientFormId: filledPatientForm._id,
      name: formData.name,
    });

    const answerToCreate = questionsAnswers
      .filter((qA) => qA.answer.id === undefined && qA.newValue !== undefined)
      .map((qA) => ({
        id: qA.question.id,
        value: qA.newValue,
        type: qA.question.type,
      }));

    const answerToEdit = questionsAnswers
      .filter((qA) => qA.answer.id !== undefined)
      .map((qA) => ({
        id: qA.answer.id,
        value: qA.newValue ?? getAnswerValue(qA.answer, qA.question.type),
        type: qA.question.type,
      }));

    const createAnswerData = await getQuestionsAnswers({
      questions: answerToCreate,
      uploadImageMutation: uploadPatientFormImage,
      isPatientForm: true,
    });

    const editAnswerData = await getQuestionsAnswers({
      questions: answerToEdit,
      uploadImageMutation: uploadPatientFormImage,
      key: "answer_id",
      isPatientForm: true,
    });

    if (editAnswerData.length > 0) {
      await editAnswerPatientFormMutation.mutateAsync({
        questionsAnswers: editAnswerData,
        patientPatientFormId: filledPatientForm._id,
      });
    }

    if (createAnswerData.length > 0) {
      await createAnswerPatientFormMutation.mutateAsync({
        patientPatientFormId: filledPatientForm._id,
        patientId: filledPatientForm.patient.id,
        name: filledPatientForm.name,
        questionsAnswers: createAnswerData,
      });
    }

    await queryClient.refetchQueries({
      queryKey: [useReadAllFilledPatientFormQuery.key],
    });

    onClose();
  };

  const isLoading =
    readAllAnswerPatientFormQuery.isFetching ||
    editFilledPatientFormMutation.isPending ||
    editAnswerPatientFormMutation.isPending ||
    uploadPatientFormImage.isPending ||
    createAnswerPatientFormMutation.isPending;

  const { document, printDocument } = usePrintPage({
    title: "Formulário",
    filename: "formulario",
    withProfiles: true,
    patient: patient,
    bodyContent: getEvaluationPrint({
      name: filledPatientForm.name,
      questionsAnswers,
      isForm: true,
    }),
    professional: userDetails,
  });

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <ModalHeader
        title={"Formulário: " + (filledPatientForm.name ?? "-")}
        onClose={onClose}
      >
        <div className="me-2">
          <PrintButton
            icon={download}
            onClick={() => printDocument()}
          ></PrintButton>
        </div>
      </ModalHeader>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {isProfessional && (
            <>
              <Form.Group>
                <Form.Label>Título</Form.Label>
                <Form.Control
                  name="name"
                  onChange={updateFormData}
                  type="text"
                  placeholder="Digite o título"
                  required
                  value={formData["name"]}
                  disabled={isProfessional || isLoading}
                />
              </Form.Group>
              <div
                className="w-100 bg-black bg-opacity-10 mt-3 mb-3"
                style={{ height: 2 }}
              ></div>
            </>
          )}

          <h6>Questões</h6>

          {questionsAnswers.map((qA) => {
            const { question, answer, choices, newValue } = qA;

            question.choices = choices;

            return (
              <QuestionInput
                key={question.id}
                name={question.question_name}
                question={question}
                value={newValue}
                className="my-4"
                answer={answer}
                onChange={handleOnChange}
                readOnly={isProfessional || isLoading}
              ></QuestionInput>
            );
          })}
        </Modal.Body>

        {isPatient && (
          <Modal.Footer>
            <Button type="submit" disabled={isLoading}>
              <SpinnerButton isLoading={isLoading}></SpinnerButton>
            </Button>
          </Modal.Footer>
        )}
      </Form>
      {document}
    </Modal>
  );
}
