import { useMediaQuery } from "react-responsive";

export function useMediaQueryValues() {
  const isXS = useMediaQuery({
    query: "(min-width: 375px)",
  });

  const isSM = useMediaQuery({
    query: "(min-width: 576px)",
  });

  const isMD = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const isLG = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const isXL = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const isXXL = useMediaQuery({
    query: "(min-width: 1400px)",
  });

  const isLowerHeight = useMediaQuery({
    query: "(max-height: 700px)",
  });

  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });

  return { isSM, isMD, isLG, isXL, isXXL, isMobile, isXS, isLowerHeight };
}
