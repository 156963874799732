import React, { useState } from "react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import {
  useReadAllPatientFormModelQuery,
  useReadAllQuestionsPatientFormQuery,
} from "../../../../queries/patient-form.query";
import trash from "../../../../assets/v2/icons/trash.svg";
import { usePatientFormMutation } from "../../../../mutations/patient-form.mutation";
import { useQueryClient } from "@tanstack/react-query";
import { useQuestionFormController } from "../../../../controllers/question-form-controller";
import { SpinnerButton } from "../../../../components/v2/SpinnerButton";
import { QuestionInput } from "../Evaluations/QuestionInput";
import { useUserInfo } from "../../../../hooks/useUserInfo";

export function ViewPatientFormModel({ formModel, onClose = () => {} }) {
  const { allQuestions, readAllQuestionsPatientFormQuery } =
    useReadAllQuestionsPatientFormQuery({
      enabled: true,
      params: { formModelId: formModel.data._id },
    });
  const { editPatientFormModelMutation } = usePatientFormMutation();
  const isExercitium = formModel.data.isExercitium;
  const {
    questionFormRender,
    addQuestionButtonRender,
    questionsRender,
    sendCreateQuestions,
  } = useQuestionFormController();

  const {
    deleteQuestionPatientFormMutation,
    createQuestionPatientFormMutation,
    createChoiceQuestionMutation,
  } = usePatientFormMutation();
  const [formData, setFormData] = useState({ name: formModel.data.name });
  const queryClient = useQueryClient();

  const { isProfessional } = useUserInfo();

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDeleteClick = async (e, question) => {
    await deleteQuestionPatientFormMutation.mutateAsync({
      questionId: question.id,
    });

    readAllQuestionsPatientFormQuery.refetch();
  };

  const handleSaveClick = async (e) => {
    await editPatientFormModelMutation.mutateAsync({
      formModelId: formModel.data._id,
      name_form: formData.name,
    });

    await sendCreateQuestions(
      { modelId: formModel.data._id },
      createQuestionPatientFormMutation,
      createChoiceQuestionMutation
    );

    await queryClient.refetchQueries({
      queryKey: [useReadAllPatientFormModelQuery.key],
    });

    onClose();
  };

  const isLoading =
    readAllQuestionsPatientFormQuery.isFetching ||
    deleteQuestionPatientFormMutation.isPending ||
    editPatientFormModelMutation.isPending;

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <ModalHeader
        title={"Modelo: " + formModel.data.name}
        onClose={onClose}
      ></ModalHeader>

      <Modal.Body>
        {!isExercitium && (
          <>
            <Form.Group>
              <Form.Label>Título</Form.Label>
              <Form.Control
                name="name"
                onChange={updateFormData}
                type="text"
                placeholder="Digite o título"
                required
                value={formData["name"]}
              />
            </Form.Group>
            <div
              className="w-100 bg-black bg-opacity-10 mt-3 mb-3"
              style={{ height: 2 }}
            ></div>
          </>
        )}

        <h6>Questões</h6>

        {allQuestions.map((q) => (
          <div key={q.id} className="flex-between align-items-end my-4">
            <QuestionInput
              key={q.id}
              name={q.question_name}
              question={q}
              value={undefined}
              readOnly={true}
              className="flex-grow-1"
            ></QuestionInput>

            {!isExercitium && isProfessional && (
              <Image
                onClick={(e) => handleDeleteClick(e, q)}
                width={35}
                className="p-1 pointer ms-3"
                src={trash}
              ></Image>
            )}
          </div>
        ))}

        {questionsRender}

        {questionFormRender}

        <div className="mt-5"></div>

        {!isExercitium && isProfessional && addQuestionButtonRender}
      </Modal.Body>

      {!isExercitium && isProfessional && (
        <Modal.Footer>
          <Button disabled={isLoading} onClick={handleSaveClick}>
            <SpinnerButton isLoading={isLoading}></SpinnerButton>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
