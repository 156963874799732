import {
  Form,
  Button,
  Col,
  Row,
  Image,
  Modal,
  ModalBody,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  deleteWorkout,
  resetDeleteWorkout,
  listMyWorkouts,
  listMyWorkoutsDetails,
} from "../../../actions/workoutActions";
import WorkoutSheetV2 from "../../../components/v2/WorkoutSheetV2";
import { useParams, useHistory } from "react-router-dom";
import PacientCardV2 from "../../../components/v2/PacientCardV2";
import TrainingCardV2 from "../../../components/v2/Cards/TrainingCardV2";
import RegisterSheetRowV2 from "../../../components/v2/RegisterSheetRowV2";
import RegisterPacientWorkoutV2 from "../../../components/v2/RegisterPacientWorkoutV2";
import RegisterWorkoutV2 from "../../../components/v2/RegisterWorkoutV2";
import { createSelector } from "reselect";
import trash from "../../../assets/v2/icons/trash2.svg";

import penIcon from "../../../assets/v2/icons/pen2.svg";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { PrintButton } from "../../../components/PrintButton";
import printer from "../../../assets/v2/icons/printer3.svg";
import { usePrintPage } from "../../../hooks/usePrintPage";
import { getWorkoutSheetPrint } from "../../../components/print/getWorkoutSheetPrint";
import download from "../../../assets/v2/icons/download.png";

import { ModalHeader } from "../../../components/ModalHeader";
import { useUserInfo } from "../../../hooks/useUserInfo";

function WorkoutDetailsV2({ handleModal, unsetModalBody }) {
  const { id: workoutID } = useParams();

  const mediaValues = useMediaQueryValues();
  const { isMD } = mediaValues;

  const dispatch = useDispatch();
  const history = useHistory();

  const workoutsListDetails = useSelector((state) => state.workoutsListDetails);
  const workoutUpdate = useSelector((state) => state.workoutUpdate);

  const { userDetails } = useUserInfo();

  const [initialPatient, setInitialPatient] = useState(null);
  const [showRegisterPatientModal, setShowRegisterPatientModal] =
    useState(false);

  const {
    errorWorkoutListDetails,
    loadingWorkoutListDetails,
    workoutListDetails,
  } = workoutsListDetails;

  const getWorkoutDetails = (state) => state.workoutsListDetails;

  const workoutsDeleteDetails = useSelector((state) => state.workoutsDelete);
  const workoutSheetDeleteItemReducer = useSelector(
    (state) => state.workoutSheetDeleteItemReducer
  );

  const { deleteWorkoutSuccess } = workoutsDeleteDetails;

  //@todo move selector to a module
  const getPacientsFromSheets = createSelector(
    [getWorkoutDetails],
    (workoutsListDetails) => {
      const sheets = workoutsListDetails?.workoutListDetails?.PacientesFicha;

      return sheets?.map((sheet) => {
        const pacient = sheet?.patient;
        pacient.initialDate = new Date(sheet?.initialDate);
        pacient.finalDate = new Date(sheet?.finalDate);

        pacient.sheetId = sheet?._id;
        pacient.sheet = sheet;

        return pacient;
      });
    }
  );

  const deleteHandler = () => {
    dispatch(deleteWorkout(workoutID));
  };

  useEffect(() => {
    if (deleteWorkoutSuccess) {
      history.push("/home/personal/programs/");
      dispatch(resetDeleteWorkout());
    }
  }, [dispatch, deleteWorkoutSuccess]);

  const pacientList = useSelector(getPacientsFromSheets);

  const [sheetRows, setSheetRows] = useState([]);

  useEffect(() => {
    setSheetRows(workoutListDetails?.ExerciciosFicha ?? []);
  }, [workoutListDetails?.ExerciciosFicha]);

  useEffect(() => {
    dispatch(listMyWorkoutsDetails(workoutID));
  }, [
    pacientList?.length,
    workoutSheetDeleteItemReducer?.payload,
    workoutUpdate?.payload,
  ]);

  useEffect(() => {
    dispatch(listMyWorkoutsDetails(workoutID));
  }, []);

  const handleEditWorkoutClick = (e) => {
    e.preventDefault();

    const defaultWorkout = workoutListDetails?.treino;

    defaultWorkout.exercises =
      workoutListDetails?.ExerciciosFicha?.map((e) => e._id) ?? [];

    handleModal(
      <RegisterWorkoutV2
        unsetModalBody={unsetModalBody}
        defaultWorkout={workoutListDetails.treino}
      />,
      "Editar Treino"
    );
  };

  const { printDocument, document } = usePrintPage({
    title: "Treino - " + (sheetRows ?? [])[0]?.workoutSheet?.workout?.name,
    filename: "treino",
    bodyContent: getWorkoutSheetPrint({ workoutSheet: sheetRows ?? [] }),
    professional: userDetails,
  });

  const handlePrintClick = () => {
    printDocument();
  };

  const renderActions = (
    <Col className="col-12 col-md-2 d-flex justify-content-end ps-2 align-items-center">
      <p
        className="btn btn-primary fw-bold d-flex justify-content-center align-items-center h-75 me-2"
        onClick={(e) => handlePrintClick(e)}
      >
        <PrintButton></PrintButton>
      </p>

      <p
        className="btn btn-primary me-2 fw-bold d-flex justify-content-center align-items-center"
        onClick={(e) => handleEditWorkoutClick(e)}
      >
        <img className="p-1" src={penIcon} />
      </p>

      <p
        className="btn btn-primary fw-bold d-flex justify-content-center align-items-center"
        onClick={(e) => deleteHandler(e)}
      >
        <img className="p-1" src={trash} />
      </p>
    </Col>
  );

  const hideRegisterModal = () => {
    setShowRegisterPatientModal(false);
    setInitialPatient(null);
  };

  return (
    <>
      <Row className="m-0">
        {!isMD && renderActions}
        <Col className="col-12 col-md-10">
          <h2 className="text-primary text-capitalize h1">
            <button onClick={() => history.goBack()}>{"<"}</button>{" "}
            {workoutListDetails?.treino?.name}
          </h2>
        </Col>

        {isMD && renderActions}
      </Row>

      <Row className="m-0">
        <p className="fw-bold m-0">Informações</p>
        <p>{workoutListDetails?.treino?.description}</p>
      </Row>
      <Row className="m-0">
        <Col className="col-12">
          {sheetRows && (
            <WorkoutSheetV2
              sheetRows={sheetRows}
              handleModal={handleModal}
              unsetModalBody={unsetModalBody}
              onUpdate={() => dispatch(listMyWorkoutsDetails(workoutID))}
            />
          )}
        </Col>
        <Row className="m-0 mt-4">
          <Col className="col-6 p-1">
            <h3 className="mt-2">Alunos</h3>
          </Col>
          <Col className="col-6 p-1 d-flex justify-content-end align-items-center">
            <Button
              className="btn btn-primary"
              onClick={(e) => setShowRegisterPatientModal(true)}
            >
              Novo aluno
            </Button>
          </Col>
        </Row>
        <Row className="m-0">
          {!pacientList && (
            <p className="text-center col-12">Não há alunos cadastrados.</p>
          )}

          {pacientList?.map((pacient, i) => (
            <div
              key={i}
              className="col-12 col-md-6 col-xl-4 col-xxl-3 p-0 px-2 py-2 m-0"
            >
              <PacientCardV2
                user={pacient}
                hasDate
                hasDelete
                onDelete={() => dispatch(listMyWorkoutsDetails(workoutID))}
                onClick={(e) => setInitialPatient(pacient)}
                variant="secondary"
              />
            </div>
          ))}
        </Row>
        <Row className="m-0 mt-2">
          <Col className="col-12">
            <h3 className="mt-4">Exercícios</h3>
          </Col>

          {workoutListDetails?.ExerciciosFicha?.map(
            (sheet, i) =>
              sheet.exercise && (
                <div className="p-0 mb-2 col-md-6 col-xl-3 ps-md-2">
                  <TrainingCardV2
                    exercise={sheet.exercise}
                    img={"assets/v2/img/programs/card-image.jpg"}
                    checkbox={false}
                    withFavorite={false}
                    // handleSelectExercise={ handleExercisesList }
                    // clear={ clearSelected }
                  />
                </div>
              )
          )}
        </Row>
      </Row>
      {document}

      {(showRegisterPatientModal || initialPatient) && (
        <RegisterPacientWorkoutV2
          onDone={() => dispatch(listMyWorkoutsDetails(workoutID))}
          workoutID={workoutID}
          unsetModalBody={() => hideRegisterModal()}
          sheetRows={sheetRows}
          registeredPacientes={pacientList}
          defaultPacient={initialPatient}
        />
      )}
    </>
  );
}

export default WorkoutDetailsV2;
