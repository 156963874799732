import { useSocketController } from "./socket-controller";
import { useUserInfo } from "../hooks/useUserInfo";
import { FEEDBACK_SOCKET_SOURCES } from "../constants/socket.consts";
import { useEffect, useState, useRef } from "react";

export function useFeedbackStreamController({ forceNewSocket = false }) {
  const INITIAL_LOADING_STATE = {
    resultFinal: false,
    socketConnect: false,
  };

  const [status, setStatus] = useState();
  const [resultFinal, setResultFinal] = useState();
  const [loadingState, setLoadingState] = useState(INITIAL_LOADING_STATE);

  const [resultPart, setResultPart] = useState(undefined);

  const onResultFinalCallbackRef = useRef(() => {});

  const onMessage = (message) => {
    // console.log({ message });

    const socketData = JSON.parse(message.data);

    switch (socketData.source) {
      case FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_START:
      case FEEDBACK_SOCKET_SOURCES.GYMMENBER_START:
        setStatus(socketData.status);
        break;
      case FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_STOP:
        break;
      case FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_BODYPOINTS:
      case FEEDBACK_SOCKET_SOURCES.GYMMENBER_BODYPOINTS:
        console.log(socketData);
        break;
      case FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_RESULTPART:
      case FEEDBACK_SOCKET_SOURCES.GYMMENBER_RESULTPART:
        setResultPart(socketData);
        break;
      case FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_RESULTFINAL:
      case FEEDBACK_SOCKET_SOURCES.GYMMENBER_RESULTFINAL:
        setResultFinal(socketData.resultPointsHuman);
        setLoadingState((prev) => ({ ...prev, resultFinal: false }));
        onResultFinalCallbackRef.current(socketData.resultPointsHuman);
        onResultFinalCallbackRef.current = () => {};
        break;
      default:
        console.log("default");
        break;
    }
  };

  const socketController = useSocketController({ onMessage, forceNewSocket });
  const streamSocketController = useSocketController({
    onMessage,
    forceNewSocket,
  });

  const isSocketOpen =
    socketController.isSocketOpen && streamSocketController.isSocketOpen;

  const userInfo = useUserInfo();

  const connect = () => {
    streamSocketController.connect(
      process.env.REACT_APP_SOCKET_API + "/stream/?token=" + userInfo.token
    );

    socketController.connect(
      process.env.REACT_APP_SOCKET_API + "/feedback/?token=" + userInfo.token
    );

    setLoadingState((prev) => ({ ...prev, socketConnect: true }));
  };

  const startFeedback = ({ muscle, feedback = {}, isPatient }) => {
    if (!isSocketOpen) return;

    const source = isPatient
      ? FEEDBACK_SOCKET_SOURCES.GYMMENBER_START
      : FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_START;

    socketController.wsRef.current.send(
      JSON.stringify({
        source,
        muscle,
        ...feedback,
      })
    );
  };

  const stopFeedback = ({ isPatient }) => {
    if (!isSocketOpen) return;

    const source = isPatient
      ? FEEDBACK_SOCKET_SOURCES.GYMMEMBER_STOP
      : FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_STOP;

    setLoadingState((prev) => ({ ...prev, resultFinal: true }));

    console.log("stopFeedback");

    streamSocketController.wsRef.current.send(
      JSON.stringify({
        source: source,
        stop: true,
      })
    );
  };

  const uploadVideo = ({ link, muscle }, callback = () => {}) => {
    if (!isSocketOpen) return;

    console.log("uploadVideo");

    setLoadingState((prev) => ({ ...prev, resultFinal: true }));

    socketController.wsRef.current.send(
      JSON.stringify({
        source: FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_UPLOAD,
        link,
        muscle,
      })
    );

    onResultFinalCallbackRef.current = callback;
  };

  const resetResult = () => {
    setResultFinal(undefined);
    setLoadingState((prev) => ({ ...prev, resultFinal: false }));
  };

  const uploadPatientVideo = (
    { link, muscle, ...rest },
    callback = () => {}
  ) => {
    const data = {
      link,
      muscle,
      ...rest,
    };

    setLoadingState((prev) => ({ ...prev, resultFinal: true }));

    socketController.wsRef.current.send(
      JSON.stringify({
        source: FEEDBACK_SOCKET_SOURCES.GYMMEMBER_UPLOAD,
        ...data,
      })
    );

    onResultFinalCallbackRef.current = callback;
  };

  const sendBodypoints = ({ imageWidth, imageHeight, results, isPatient }) => {
    if (!isSocketOpen) return;

    console.log({ results });

    const source = isPatient
      ? FEEDBACK_SOCKET_SOURCES.GYMMENBER_BODYPOINTS
      : FEEDBACK_SOCKET_SOURCES.PROFESSIONAL_BODYPOINTS;

    const sendData = {
      source,
      imageWidth,
      imageHeight,
      results,
    };

    streamSocketController.wsRef.current.send(JSON.stringify(sendData));

    console.log("enviou", new Date().getSeconds());
  };

  useEffect(() => {
    if (!isSocketOpen) {
      setLoadingState(INITIAL_LOADING_STATE);
    } else {
      setLoadingState((prev) => ({ ...prev, socketConnect: false }));
    }
  }, [isSocketOpen]);

  return {
    isSocketOpen,
    connect,
    startFeedback,
    stopFeedback,
    sendBodypoints,
    status,
    resultFinal,
    loadingState,
    uploadVideo,
    uploadPatientVideo,
    resetResult,
    resultPart,
  };
}
