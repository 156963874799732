import { useMemo } from "react";
import api from "../services/axios";
import { useApiConfig } from "../hooks/useApiConfig";
import { useQuery } from "@tanstack/react-query";

export const useReadAllVinculedFinanceQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readAllVinculedFinanceQuery = useQuery({
    queryKey: ["read-all-vinculed-finance-query", enabled],
    queryFn: async () => {
      return api.get(`/api/management/finance/readall/`, config);
    },
    enabled,
  });

  const allVinculedFinances = useMemo(() => {
    return readAllVinculedFinanceQuery.data?.data ?? [];
  }, [readAllVinculedFinanceQuery.data?.data]);

  return { allVinculedFinances, readAllVinculedFinanceQuery };
};

export const useReadPatientFinancesQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readPatientFinancesQuery = useQuery({
    queryKey: ["read-patient-finances-details-query", enabled, params],
    queryFn: async () => {
      const { id } = params;
      return api.get(`/api/management/finance/read/${id}/`, config);
    },
    enabled,
  });

  const finances = useMemo(
    () => readPatientFinancesQuery.data?.data ?? [],
    [readPatientFinancesQuery.data?.data]
  );

  return { finances, readPatientFinancesQuery };
};

export const useReadAllFinancesQuery = ({ enabled = true, params = {} }) => {
  const config = useApiConfig();

  const readAllFinanceQuery = useQuery({
    queryKey: ["read-all-finances-query", enabled, params],
    queryFn: async () => {
      const searchParams = new URLSearchParams();

      if (params?.month) searchParams.set("month", params.month);

      return api.get(
        `/api/management/finance/norelate/readall/?${searchParams.toString()}`,
        config
      );
    },
    enabled,
  });

  const allFinances = useMemo(
    () => readAllFinanceQuery.data?.data ?? { total: 0, data: [] },
    [readAllFinanceQuery.data?.data]
  );

  return { allFinances, readAllFinanceQuery };
};

export const useReadNoRelateFinanceQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readNoRelateFinanceQuery = useQuery({
    queryKey: ["read-no-relate-finance-query", enabled, params],
    queryFn: async () => {
      const { id } = params;

      return api.get(`/api/management/finance/norelate/read/${id}/`, config);
    },
    enabled,
  });

  const finance = useMemo(
    () => readNoRelateFinanceQuery.data?.data,
    [readNoRelateFinanceQuery.data?.data]
  );

  return { finance, readNoRelateFinanceQuery };
};
