import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SocketService } from "../services/socket.service";

export function useSocketController({
  onMessage = () => {},
  forceNewSocket = false,
}) {
  const wsRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const onopen = useCallback(() => {
    console.log("open");
    setIsOpen(true);
  }, []);

  const onclose = useCallback(() => {
    console.log("close");
    setIsOpen(false);
  }, []);

  const onmessage = useCallback(onMessage, [onMessage]);

  const connect = (url) => {
    if (wsRef.current) return;

    console.log("connect");

    wsRef.current = SocketService.getInstance().createSocket(
      url,
      forceNewSocket
    );

    wsRef.current?.addEventListener("open", onopen);
    wsRef.current?.addEventListener("close", onclose);
    wsRef.current?.addEventListener("message", onmessage);

    wsRef.current?.readyState === WebSocket.OPEN && setIsOpen(true);
  };

  const disconnect = () => {
    console.log("disconenct");

    wsRef.current?.close();

    wsRef.current = undefined;
  };

  useEffect(() => {
    // destructor
    return () => {
      console.log("-- SOCKET DESTRUCTOR --");
      wsRef.current?.close();
      wsRef.current?.removeEventListener("open", onopen);
      wsRef.current?.removeEventListener("close", onclose);
      wsRef.current?.removeEventListener("message", onmessage);
    };
  }, []);

  return {
    connect,
    disconnect,
    wsRef,
    isSocketOpen: isOpen,
  };
}
