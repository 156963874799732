import { React, useState, useEffect, useMemo } from "react";
import { Badge, Calendar } from "antd";
import { useReadAllScheduleQuery } from "../../queries";
import RegisterEventV2 from "./RegisterEventV2";
import { Modal, ListGroup, CloseButton } from "react-bootstrap";
import {
  dateToStringApiFormat,
  formatDate,
  stringToDate,
} from "../../utils/date";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";

function Schedule() {
  const { schedules, readScheduleQuery } = useReadAllScheduleQuery({});

  const [selectedDate, setDate] = useState(null);
  const [selectedEvent, setEvent] = useState();

  const { isMobile } = useMediaQueryValues();

  const [currentScheduleList, setScheduleList] = useState();

  const schedulesByDateGroup = useMemo(() => {
    const result =
      schedules?.reduce((acc, event) => {
        if (Object.keys(acc).includes(event.date)) {
          acc[event.date].push(event);
          return acc;
        }

        acc[event.date] = [event];
        acc[event.date].date = event.date;

        return acc;
      }, {}) ?? {};

    return result;
  }, [schedules]);

  const hideRegisterModal = () => {
    setEvent(undefined);
    setScheduleList(undefined);
    readScheduleQuery.refetch();
  };

  const renderEventItem = (item) => {
    const hourInitial = item?.hourInitial;

    return (
      <Badge
        status={"success"}
        text={
          item?.nameEvent?.substring(0, 10) +
          (hourInitial ? " - " + hourInitial?.substring(0, 5) : "")
        }
      />
    );
  };

  const dateCellRender = (value) => {
    const date = dateToStringApiFormat(value.toDate());
    const schedulesList = schedulesByDateGroup[date] ?? [];

    if (schedulesList.length === 0) return <></>;

    return (
      <>
        <ul className="d-none events d-lg-flex flex-column align-items-start justify-content-start m-0 p-0">
          {schedulesList.slice(0, 3).map((item) => (
            <li key={item._id} onClick={(e) => setEvent(item)}>
              {renderEventItem(item)}
            </li>
          ))}
          {schedulesList.length > 3 && (
            <div
              className="text-primary fw-500 align-self-end me-2"
              onClick={() => setScheduleList(schedulesList)}
            >
              Ver todos {">>>"}
            </div>
          )}
        </ul>

        <div
          className="d-lg-none w-100 d-flex justify-content-center"
          onClick={() => setScheduleList(schedulesList)}
        >
          <Badge color="#198754" count={schedulesList.length} />
        </div>
      </>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    // if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  return (
    <>
      {selectedEvent && (
        <Modal show={!!selectedEvent} onHide={hideRegisterModal} size="lg">
          <Modal.Header className="text-primary fw-bold">
            Editar Evento
            <CloseButton
              className="btn text-danger fs-4 my-0 py-0"
              onClick={() => hideRegisterModal()}
            />
          </Modal.Header>
          <Modal.Body>
            <RegisterEventV2
              date={new Date()}
              schedule={selectedEvent}
              onFinish={hideRegisterModal}
            />
          </Modal.Body>
        </Modal>
      )}

      {currentScheduleList && (
        <Modal show={!!currentScheduleList} onHide={() => setScheduleList()}>
          <Modal.Header>
            <Modal.Title className="h5 text-primary">
              Eventos - {formatDate(currentScheduleList?.date)}
            </Modal.Title>

            <CloseButton
              className="btn text-danger fs-4 my-0 py-0"
              onClick={() => setScheduleList()}
            />
          </Modal.Header>
          <Modal.Body>
            <ListGroup className="d-flex flex-column">
              {currentScheduleList?.map((item) => (
                <ListGroup.Item
                  className="pointer"
                  key={item._id}
                  onClick={() => setEvent(item)}
                >
                  {item?.nameEvent +
                    (item?.hourInitial
                      ? " - " + item?.hourInitial?.substring(0, 5)
                      : "")}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Modal.Body>
        </Modal>
      )}

      <Calendar
        value={selectedDate}
        onSelect={(date) => setDate(date)}
        cellRender={cellRender}
      />
    </>
  );
}

export default Schedule;
