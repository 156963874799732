import React from "react";
import { FinanceCardItem } from "../Finance/FinanceCardItem";
import "../../../assets/v2/financePlanSection.css";

export function FinancePlanSection({
  id,
  name,
  monthCount,
  finances = { patientsFinances: [], plan: {} },
}) {
  const { patientsFinances } = finances;

  return (
    <div
      className="col-11 h-100 p-1 col-lg-6 col-xl-4 col-xxl-4 custom-scrollbar "
      style={{ scrollSnapAlign: "start", overflowY: "auto" }}
      onWheel={(e) => e.stopPropagation()}
    >
      <div className="bg-body-secondary h-100 rounded p-2">
        <p className="mb-0 p-2 pt-0 h5 text-primary">{name}</p>

        {patientsFinances.map((patientFinance, i) => (
          <div className="mb-1" key={i}>
            <FinanceCardItem patientFinance={patientFinance}></FinanceCardItem>
          </div>
        ))}
      </div>
    </div>
  );
}
