import React, { useState } from "react";
import pixPng from "../../../assets/v2/icons/pix.png";
import cardSvg from "../../../assets/v2/icons/card.svg";
import barcodePng from "../../../assets/v2/icons/barcode.png";
import { usePaymentMutation } from "../../../mutations";
import { PixPurchase } from "./PixPurchase";
import { parseBrazilianPhoneNumber } from "../../../utils/phone.utils";
import { cpf } from "cpf-cnpj-validator";
import { BoletoPurchase } from "./BoletoPurchase";
import { useReadPaymentCoupomQuery } from "../../../queries";
import { CreditCardPurchase } from "./CreditCardPurchase";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { Form, Image, Button } from "react-bootstrap";

export function PaymentMethod({
  formData,
  paymentMethod,
  paymentCheckMethod,
  setPaymentCheckMethod,
  planId,
  newPrice,
  planPrice,
  plan,
  goBack: goBackParam,
  onPayFinish,
  isMonthly,
}) {
  const userInfo = useUserInfo();

  const { createPayment } = usePaymentMutation();

  const goBack = () => {
    setPaymentCheckMethod(undefined);
    goBackParam();
  };

  const getPhoneData = () => {
    try {
      const phoneData = parseBrazilianPhoneNumber(
        "55" + userInfo.userDetails?.phone
      );
      return phoneData;
    } catch (error) {
      return null;
    }
  };
  const getCustomerData = () => {
    const phoneData = getPhoneData();

    const data = {
      cpf: formData.cpf.replace(/\D/g, ""),
      address: {
        street_number: formData.street_number,
        zip_code: formData.zip_code.replace(/\D/g, ""),
        city: formData.city,
        state: formData.state,
      },
      email: userInfo.email,
      name: userInfo.name,
    };

    if (phoneData) {
      data.phones = {
        mobile_phone: phoneData,
      };
    }

    return data;
  };

  const renderPaymentMethod = ({ value, img, name }) => {
    return (
      <Form.Check
        type="radio"
        className="d-flex align-items-center ms-1 me-2 mb-3"
        onClick={() => setPaymentCheckMethod(value)}
        value={value}
        checked={paymentCheckMethod === value}
        label={
          <div className="d-flex">
            <Image width={30} className="ms-2" src={img}></Image>
            <p className="fw-500 mb-0 mt-1 ms-2">{name}</p>
          </div>
        }
      />
    );
  };

  return (
    <div>
      {paymentMethod === undefined && (
        <div className="d-flex w-100 bg-body-secondary p-3 p-md-3 rounded flex-column my-3">
          <div className="flex-between">
            <h5 className="h5 text-primary">Escolher Forma de Pagamento</h5>
          </div>

          <Form.Group className="mt-3">
            {renderPaymentMethod({ img: pixPng, name: "PIX", value: 1 })}
            {renderPaymentMethod({
              img: cardSvg,
              name: "Cartão de Crédito",
              value: 2,
            })}
            {renderPaymentMethod({
              img: barcodePng,
              name: "Boleto",
              value: 3,
            })}
          </Form.Group>
        </div>
      )}
      {paymentMethod === 1 && (
        <PixPurchase
          planId={planId}
          price={newPrice ?? planPrice}
          description={plan.title}
          isMonthly={isMonthly}
          customer={getCustomerData()}
          onError={() => goBack()}
          goBack={goBack}
        ></PixPurchase>
      )}
      {paymentMethod === 3 && (
        <BoletoPurchase
          planId={planId}
          price={newPrice ?? planPrice}
          description={plan.title}
          isMonthly={isMonthly}
          customer={getCustomerData()}
          onError={() => goBack()}
          goBack={goBack}
        ></BoletoPurchase>
      )}
      {paymentMethod === 2 && (
        <CreditCardPurchase
          isMonthly={isMonthly}
          description={plan.title}
          planId={planId}
          planPrice={planPrice}
          onPayFinish={() => {
            goBack();
            onPayFinish();
          }}
          goBack={goBack}
          customer={getCustomerData()}
        ></CreditCardPurchase>
      )}
    </div>
  );
}
