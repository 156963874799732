export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_UPDATE = 'USER_LOGIN_UPDATE'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_LIST_BODYBUILDERS_REQUEST = 'USER_LIST_BODYBUILDERS_REQUEST'
export const USER_LIST_BODYBUILDERS_SUCCESS = 'USER_LIST_BODYBUILDERS_SUCCESS'
export const USER_LIST_BODYBUILDERS_FAIL = 'USER_LIST_BODYBUILDERS_FAIL'
export const USER_LIST_BODYBUILDERS_RESET = 'USER_LIST_BODYBUILDERS_RESET'

export const USER_LIST_BODYBUILDERS_AVAILABLE_REQUEST = 'USER_LIST_BODYBUILDERS_AVAILABLE_REQUEST'
export const USER_LIST_BODYBUILDERS_AVAILABLE_SUCCESS = 'USER_LIST_BODYBUILDERS_AVAILABLE_SUCCESS'
export const USER_LIST_BODYBUILDERS_AVAILABLE_FAIL = 'USER_LIST_BODYBUILDERS_AVAILABLE_FAIL'
export const USER_LIST_BODYBUILDERS_AVAILABLE_RESET = 'USER_LIST_BODYBUILDERS_AVAILABLE_RESET'

export const USER_LIST_PERSONAL_REQUEST = 'USER_LIST_PERSONAL_REQUEST'
export const USER_LIST_PERSONAL_SUCCESS = 'USER_LIST_PERSONAL_SUCCESS'
export const USER_LIST_PERSONAL_FAIL = 'USER_LIST_PERSONAL_FAIL'
export const USER_LIST_PERSONAL_RESET = 'USER_LIST_PERSONAL_RESET'

export const USER_INVITATION_LIST_REQUEST = 'USER_INVITATION_LIST_REQUEST'
export const USER_INVITATION_LIST_SUCCESS = 'USER_INVITATION_LIST_SUCCESS'
export const USER_INVITATION_LIST_FAIL = 'USER_INVITATION_LIST_FAIL'
export const USER_INVITATION_LIST_RESET = 'USER_INVITATION_LIST_RESET'

export const USER_INVITATION_SEND_REQUEST = 'USER_INVITATION_SEND_REQUEST'
export const USER_INVITATION_SEND_SUCCESS = 'USER_INVITATION_SEND_SUCCESS'
export const USER_INVITATION_SEND_FAIL = 'USER_INVITATION_SEND_FAIL'
export const USER_INVITATION_SEND_RESET = 'USER_INVITATION_SEND_RESET'

export const USER_INVITATION_REPLY_REQUEST = 'USER_INVITATION_REPLY_REQUEST'
export const USER_INVITATION_REPLY_SUCCESS = 'USER_INVITATION_REPLY_SUCCESS'
export const USER_INVITATION_REPLY_FAIL = 'USER_INVITATION_REPLY_FAIL'
export const USER_INVITATION_REPLY_RESET = 'USER_INVITATION_REPLY_RESET'

export const USER_FORGET_PASS_REQUEST = 'USER_FORGET_PASS_REQUEST'
export const USER_FORGET_PASS_SUCCESS = 'USER_FORGET_PASS_SUCCESS'
export const USER_FORGET_PASS_FAIL = 'USER_FORGET_PASS_FAIL'
export const USER_FORGET_PASS_RESET = 'USER_FORGET_PASS_RESET'

export const USER_RESET_PASS_REQUEST = 'USER_RESET_PASS_REQUEST'
export const USER_RESET_PASS_SUCCESS = 'USER_RESET_PASS_SUCCESS'
export const USER_RESET_PASS_FAIL = 'USER_RESET_PASS_FAIL'
export const USER_RESET_PASS_RESET = 'USER_RESET_PASS_RESET'

export const USER_UPDATE_PASS_REQUEST = 'USER_UPDATE_PASS_REQUEST'
export const USER_UPDATE_PASS_SUCCESS = 'USER_UPDATE_PASS_SUCCESS'
export const USER_UPDATE_PASS_FAIL = 'USER_UPDATE_PASS_FAIL'
export const USER_UPDATE_PASS = 'USER_UPDATE_PASS'

export const USER_IMAGE_CREATE_REQUEST = 'USER_IMAGE_CREATE_REQUEST'
export const USER_IMAGE_CREATE_SUCCESS = 'USER_IMAGE_CREATE_SUCCESS'
export const USER_IMAGE_CREATE_FAIL = 'USER_IMAGE_CREATE_FAIL'