import React, { useState } from "react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import {
  useReadAllEvaluationModelQuery,
  useReadAllQuestionsEvaluationQuery,
} from "../../../../queries/evaluation.query";
import { QuestionInput } from "./QuestionInput";
import trash from "../../../../assets/v2/icons/trash.svg";
import { useEvaluationMutation } from "../../../../mutations/evaluation.mutation";
import { useQueryClient } from "@tanstack/react-query";
import { useQuestionFormController } from "../../../../controllers/question-form-controller";
import { SpinnerButton } from "../../../../components/v2/SpinnerButton";
import { useUserInfo } from "../../../../hooks/useUserInfo";

export function ViewEvaluationModel({ evaluationModel, onClose = () => {} }) {
  const { allQuestions, readAllQuestionsEvaluationQuery } =
    useReadAllQuestionsEvaluationQuery({
      enabled: true,
      params: { evaluationModelId: evaluationModel.data._id },
    });
  const { editEvaluationModelMutation } = useEvaluationMutation();
  const isExercitium = evaluationModel.data.isExercitium;
  const {
    questionFormRender,
    addQuestionButtonRender,
    questionsRender,
    sendCreateQuestions,
  } = useQuestionFormController();

  const {
    deleteQuestionEvaluationMutation,
    createQuestionEvaluationMutation,
    createChoiceQuestionMutation,
  } = useEvaluationMutation();
  const [formData, setFormData] = useState({ name: evaluationModel.data.name });
  const queryClient = useQueryClient();

  const { isProfessional } = useUserInfo();

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDeleteClick = async (e, question) => {
    await deleteQuestionEvaluationMutation.mutateAsync({
      questionId: question.id,
    });

    readAllQuestionsEvaluationQuery.refetch();
  };

  const handleSaveClick = async (e) => {
    await editEvaluationModelMutation.mutateAsync({
      evaluationModelId: evaluationModel.data._id,
      name_evaluation: formData.name,
    });

    await sendCreateQuestions(
      { modelId: evaluationModel.data._id },
      createQuestionEvaluationMutation,
      createChoiceQuestionMutation
    );

    queryClient.refetchQueries({
      queryKey: [useReadAllEvaluationModelQuery.key, true],
    });

    onClose();
  };

  const isLoading =
    readAllQuestionsEvaluationQuery.isFetching ||
    deleteQuestionEvaluationMutation.isPending ||
    editEvaluationModelMutation.isPending ||
    createQuestionEvaluationMutation.isPending ||
    createChoiceQuestionMutation.isPending;

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <ModalHeader
        title={"Modelo: " + evaluationModel.data.name}
        onClose={onClose}
      ></ModalHeader>

      <Modal.Body>
        {!isExercitium && isProfessional && (
          <>
            <Form.Group>
              <Form.Label>Título</Form.Label>
              <Form.Control
                name="name"
                onChange={updateFormData}
                type="text"
                placeholder="Digite o título"
                required
                value={formData["name"]}
              />
            </Form.Group>
            <div
              className="w-100 bg-black bg-opacity-10 mt-3 mb-3"
              style={{ height: 2 }}
            ></div>
          </>
        )}

        <h6>Questões</h6>

        {allQuestions.map((q) => (
          <div key={q.id} className="flex-between align-items-end my-4">
            <QuestionInput
              key={q.id}
              name={q.question_name}
              question={q}
              value={undefined}
              readOnly={true}
              className="flex-grow-1"
            ></QuestionInput>

            {!isExercitium && isProfessional && (
              <Image
                onClick={(e) => handleDeleteClick(e, q)}
                width={35}
                className="p-1 pointer ms-3"
                src={trash}
              ></Image>
            )}
          </div>
        ))}

        {questionsRender}

        {questionFormRender}

        <div className="mt-5"></div>

        {!isExercitium && isProfessional && addQuestionButtonRender}
      </Modal.Body>

      {!isExercitium && isProfessional && (
        <Modal.Footer>
          <Button disabled={isLoading} onClick={handleSaveClick}>
            <SpinnerButton isLoading={isLoading}></SpinnerButton>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
