import { useMemo } from "react";
import api from "../services/axios";
import { useApiConfig } from "../hooks/useApiConfig";
import { useQuery } from "@tanstack/react-query";

const key1 = "readUserDetails";
export const useReadUserDetailsQuery = ({
  enabled = true,
  options = {
    refetchInterval: false,
  },
}) => {
  const config = useApiConfig();

  const readUserDetailsQuery = useQuery({
    queryKey: [key1, enabled],
    queryFn: async () => {
      return api.get("/api/users/details/read/", config);
    },
    enabled,
    ...options,
  });

  const userDetails = useMemo(() => {
    const details = readUserDetailsQuery.data?.data;

    if (details)
      return {
        ...details?.data,
        myPlans: details.plans,
      };

    return undefined;
  }, [readUserDetailsQuery.data?.data]);

  return { userDetails, readUserDetailsQuery };
};
useReadUserDetailsQuery.key = key1;

const key2 = "user-access-query";
export const useReadUserAccessQuery = ({
  enabled,
  params,
  refetchInterval = false,
}) => {
  const config = useApiConfig();

  // Queries
  const userAccessQuery = useQuery({
    queryKey: [key2],
    queryFn: async () => {
      const response = await api.get("/api/users/pages/acess/", config);

      return response;
    },
    enabled,
    refetchInterval,
  });

  // Memos
  const userAccess = useMemo(() => {
    return userAccessQuery.data?.data ?? [];
  }, [userAccessQuery.data?.data]);

  return { userAccess, userAccessQuery };
};
useReadUserAccessQuery.key = key2;
