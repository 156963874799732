import React, { useState } from "react";
import { NavBar } from "./v2/Navbar";
import { NavBarSpacer } from "./v2/NavbarSpacer";
import { respStyle } from "../utils/respStyle";
import { useMediaQueryValues } from "../hooks/useMediaQueryValues";
import { Col } from "react-bootstrap";

// import { Container } from './styles';

export function PageRouteContainer({
  children,
  changeVisbility,
  getModalBody,
  unsetModalBody,
  withNavbar = true,
  className = "",
}) {
  const mediaValues = useMediaQueryValues();

  const [navbarIsOpen, setIsOpen] = useState(false);

  return (
    <div className={"col-12 position-relative m-0 p-0 " + className}>
      {withNavbar && (
        <NavBar
          changeVisbility={changeVisbility}
          getModalBody={getModalBody}
          unsetModalBody={unsetModalBody}
          onChanged={(isOpen) => setIsOpen(isOpen)}
        ></NavBar>
      )}

      <div style={{ width: "100%" }} className="row h-100 p-0 m-0">
        <NavBarSpacer.Left isOpen={navbarIsOpen}></NavBarSpacer.Left>

        <Col
          className={
            "col-12  p-2 " + (navbarIsOpen ? "col-md-10" : "col-md-11")
          }
        >
          <NavBarSpacer.Top></NavBarSpacer.Top>

          <div
            className={
              "" + respStyle({ lg: "col-11", xl: "col-11" }, mediaValues)
            }
          >
            {children}
          </div>
        </Col>
      </div>
    </div>
  );
}
