import React, { useEffect } from "react";
import { usePaymentMutation } from "../../../mutations";
import barcodePng from "../../../assets/v2/icons/barcode.png";
import { Button, Image, Spinner } from "react-bootstrap";
import { useRef } from "react";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { toast } from "react-toastify";

export function BoletoPurchase({
  planId,
  price,
  description,
  customer,
  onError,
  goBack,
  isMonthly,
}) {
  const { createBoletoPayment } = usePaymentMutation();

  const isLoading = createBoletoPayment.isPending;

  const { isMobile } = useMediaQueryValues();

  const sectionRef = useRef();

  const boletoPDFUrl =
    createBoletoPayment.data?.data?.charges[0]?.last_transaction?.pdf;

  const boletoCode =
    createBoletoPayment.data?.data?.charges[0]?.last_transaction?.line;

  //     (3, 'Boleto - Pagamento mensal'),
  // (4, 'Boleto - Pagamento anual'),
  const createBoleto = async () => {
    try {
      await createBoletoPayment.mutateAsync({
        plan_id: planId,
        paymentsTypes_id: isMonthly ? 3 : 4,
        customer: customer,
        items: {
          price,
          description,
          quantity: 1,
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    createBoleto();

    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleDownloadClick = () => {
    window.open(boletoPDFUrl, "_blank");
  };

  return (
    <section
      ref={sectionRef}
      className="bg-body-secondary p-3 rounded d-flex flex-column"
    >
      <div className="flex-center justify-content-between mb-2">
        <div className="d-flex">
          <Image
            width={30}
            style={{ objectFit: "contain" }}
            src={barcodePng}
          ></Image>
          <p className="fw-500 m-0 ms-3">Pagamento Via Boleto</p>
        </div>

        <Button className="d-none d-md-flex" onClick={goBack}>
          Cancelar
        </Button>
      </div>

      {isLoading && (
        <div className="flex-center flex-column">
          <p className="m-0">Gerando Boleto</p>
          <Spinner
            className="ms-2 text-primary"
            animation="border"
            role="status"
            style={{
              height: 30,
              width: 30,
            }}
          ></Spinner>
        </div>
      )}

      {boletoPDFUrl && !isMobile && (
        <object
          data={boletoPDFUrl}
          type="application/pdf"
          className="my-3"
          style={{ height: 600 }}
        >
          <p>
            <a href={boletoPDFUrl}>Link do Boleto</a>
          </p>
        </object>
      )}

      {boletoPDFUrl && isMobile && <iframe src={boletoPDFUrl} title="Boleto" />}

      <div className="flex-center flex-column mb-2 gap-3 mt-3">
        <Button
          className=""
          onClick={() => {
            navigator.clipboard.writeText(boletoCode);

            toast("Código copiado com sucesso!", { position: "top-center" });
          }}
        >
          Copiar Código
        </Button>

        <Button className="" onClick={() => handleDownloadClick()}>
          Baixar Boleto
        </Button>

        <Button className="d-flex d-md-none" onClick={goBack}>
          Cancelar
        </Button>
      </div>
      <p className="m-0 text-center">
        Se o pagamento não for confirmado, não se preocupe. O pedido será
        cancelado automaticamente.
      </p>
    </section>
  );
}
