import {
  MEDICAL_RECORDS_REGISTER_REQUEST,
  MEDICAL_RECORDS_REGISTER_SUCCESS,
  MEDICAL_RECORDS_REGISTER_FAIL,
  MEDICAL_RECORDS_REGISTER_RESET,
  MEDICAL_RECORDS_DETAILS_REQUEST,
  MEDICAL_RECORDS_DETAILS_SUCCESS,
  MEDICAL_RECORDS_DETAILS_FAIL,
  MEDICAL_RECORDS_DETAILS_RESET,
  MEDICAL_RECORDS_LIST_REQUEST,
  MEDICAL_RECORDS_LIST_SUCCESS,
  MEDICAL_RECORDS_LIST_FAIL,
  MEDICAL_RECORDS_LIST_RESET,
  MEDICAL_RECORDS_DELETE_REQUEST,
  MEDICAL_RECORDS_DELETE_SUCCESS,
  MEDICAL_RECORDS_DELETE_FAIL,
  MEDICAL_RECORDS_DELETE_RESET,
  MEDICAL_RECORDS_UPDATE_REQUEST,
  MEDICAL_RECORDS_UPDATE_SUCCESS,
  MEDICAL_RECORDS_UPDATE_FAIL,
  MEDICAL_RECORDS_UPDATE_RESET,
} from "../constants/medicalRecordsConstants";

export const medicalRecordDetailsReducer = (
  state = { medical_record: null },
  action
) => {
  switch (action.type) {
    case MEDICAL_RECORDS_DETAILS_REQUEST:
      return { loading: true, medical_record: null };

    case MEDICAL_RECORDS_DETAILS_SUCCESS:
      return {
        loading: false,
        medical_record: action.payload,
      };

    case MEDICAL_RECORDS_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const medicalRecordListReducer = (
  state = { medical_records: [] },
  action
) => {
  switch (action.type) {
    case MEDICAL_RECORDS_LIST_REQUEST:
      return { loading: true, medical_records: [] };

    case MEDICAL_RECORDS_LIST_SUCCESS:
      return {
        loading: false,
        medical_records: action.payload.data,
        page: action.payload.page,
        pages: action.payload.pages,
      };

    case MEDICAL_RECORDS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const medicalRecordRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case MEDICAL_RECORDS_REGISTER_REQUEST:
      return { loading: true };

    case MEDICAL_RECORDS_REGISTER_SUCCESS:
      return { loading: false, medical_record: action.payload };

    case MEDICAL_RECORDS_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const medicalRecordUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MEDICAL_RECORDS_UPDATE_REQUEST:
      return { loading: true };

    case MEDICAL_RECORDS_UPDATE_SUCCESS:
      return { loading: false, medical_record: action.payload };

    case MEDICAL_RECORDS_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};


export const medicalRecordDeleteReducer = (state = { loading: false, payload: {} }, action) => {
  switch (action.type) {

    case MEDICAL_RECORDS_DELETE_SUCCESS:
      return { loading: false, medical_record: action.payload };


    default:
      return state;
  }
};
