import { useHistory } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import { useReadPendentQuery } from "../queries";
import { useExerciseDoneMutation } from "../mutations";

export const usePendentPostController = ({ exercises, pace }) => {
  const { isPatient } = useUserInfo();

  const history = useHistory();

  const { createPostMut } = useExerciseDoneMutation();

  const { pendentPost, readPendentQuery, sheetPatient } = useReadPendentQuery({
    enabled: isPatient,
  });

  const executePendentPost = async () => {
    history.push(
      `/home/pacient/workoutsheet/execution/${pendentPost?.lastExercise?._id}/${pendentPost?._id}`
    );
  };

  const handleExecuteWorkoutClick = async (e) => {
    e?.preventDefault();

    const response = await createPostMut.mutateAsync({
      exercise_id: exercises[0].exercise?._id,
      workout_id: exercises[0].workoutSheet.workout?._id,
      currentExercise: 1,
      totalExercise: exercises.length,
      currentSerie: 0,
      totalSerie: exercises[0].serie,
      pace,
      progress: 0.0,
    });

    const postId = response.data?._id;

    history.push(
      `/home/pacient/workoutsheet/execution/${exercises[0].exercise._id}/${postId}`
    );
  };

  return {
    pendentPost,
    handleExecuteWorkoutClick,
    executePendentPost,
    sheetPatient,
  };
};
