import { React, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Row, Button } from 'react-bootstrap';

function PaginationV2({ page, pages, setCurrentPage, currentPage }) {
  
  const totalPages = Array(pages).fill().map((e, i) => i+1);
  
  const activeElement = (num) => { 
    return num == currentPage ? 'btn-primary': 'text-secondary';
  }

  const changePage = (num) => {
    setCurrentPage(num);
  }

  return (
    <div className="float-end pagination my-3">
        { totalPages.map((num) => {
            return(
              <div 
                key={num}
                className={`btn border-0 fw-bold me-2 ${activeElement(num)}`}
                onClick={ (e) => changePage(num) }
              >
                <span>{ num }</span>
              </div>
            )}) }
    </div>
  );
}

export default PaginationV2;