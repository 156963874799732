import React, { useEffect, useState } from "react";
import { usePrintPage } from "../../../hooks/usePrintPage";
import { SearchPatients } from "../../../components/v2/SearchPatients";
import { RoutineSheet } from "./RoutineSheet";
import { useRoutineMutation } from "../../../mutations";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";

import { ModalHeader } from "../../../components/ModalHeader";
import { PrintButton } from "../../../components/PrintButton";

import PacientProfileV2 from "../PacientDetailsV2/PacientProfileV2";
import DateField from "../../../components/v2/Fields/DateField";
import { toast } from "react-toastify";
import { dateToStringApiFormat, stringToDate } from "../../../utils/date";
import { useQueryClient } from "@tanstack/react-query";
import {
  useReadRoutineDetailsQuery,
  useReadRoutinePatientDetailsQuery,
} from "../../../queries";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { useApiConfig } from "../../../hooks/useApiConfig";
import { getSheetPatientDays } from "../../../queries/routine.query";

export function CreatePatientRoutine({
  routine,
  routineWorkouts = [],
  initialRoutinePatient: initialRoutinePatientParam,
  registeredPatients = [],
  routineSheet,
  onFinish = () => {},
}) {
  const config = useApiConfig();

  const [initialRoutinePatient, setInitialRoutinePatient] = useState(
    initialRoutinePatientParam
  );

  const isEditMode = !!initialRoutinePatient;

  const { routinePatient, readRoutinePatientDetailsQuery } =
    useReadRoutinePatientDetailsQuery({
      enabled: isEditMode,
      params: { id: initialRoutinePatient?._id },
    });

  const [initialDate, setInitialDate] = useState(
    isEditMode ? stringToDate(initialRoutinePatient?.initialDate) : new Date()
  );
  const [finalDate, setFinalDate] = useState(
    isEditMode ? stringToDate(initialRoutinePatient?.finalDate) : new Date()
  );
  const [selectedPatient, setPatient] = useState(
    initialRoutinePatient?.patient
  );

  const patient = selectedPatient ?? initialRoutinePatient?.patient;

  const [error, setError] = useState(null);

  const patientSheet = isEditMode
    ? routinePatient?.TreinosRotina
    : routineSheet;

  const queryClient = useQueryClient();
  const {
    createPatient,
    updatePatient,
    createPatientWorkout,
    updateRoutinePatientWorkoutMutation,
  } = useRoutineMutation();

  const { userDetails } = useUserInfo();

  const isLoading =
    readRoutinePatientDetailsQuery.isLoading ||
    createPatient.isPending ||
    updatePatient.isPending;

  const [messages, setMessages] = useState([]);

  const validateForm = () => {
    if (initialDate.getTime() > finalDate.getTime()) {
      setError("Data de início não pode ser maior que a data de término");
      return false;
    } else if (initialDate.getTime() === finalDate.getTime()) {
      setError("Data de início não pode ser igual a data de término");
      return false;
    }

    setError(null);

    return true;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    let resMessages = [];

    if (!isEditMode) {
      const response = await createPatient.mutateAsync({
        id: routine?._id,
        patientId: patient?.id,
        initialDate: dateToStringApiFormat(initialDate),
        finalDate: dateToStringApiFormat(finalDate),
      });

      setInitialRoutinePatient(response.data.data);

      const workoutDays = routineWorkouts.map((rW) => ({
        workout: rW?.workout?._id,
        days: rW?.sheetPatient?.days ?? rW.days ?? [],
      }));

      const response2 = await createPatientWorkout.mutateAsync({
        patientId: patient?.id,
        initialDate: dateToStringApiFormat(initialDate),
        finalDate: dateToStringApiFormat(finalDate),
        workoutDays,
        routinePatientId: response.data.data._id.toString(),
      });

      resMessages =
        response2.data?.messages
          ?.filter((m) => Array.isArray(m))
          ?.map((m) => m.length > 0 && m[0]) ?? [];

      toast("Aluno adicionado com sucesso", {
        position: "top-center",
        type: "success",
      });
    } else {
      await updatePatient.mutateAsync({
        id: initialRoutinePatient?._id,
        initialDate: dateToStringApiFormat(initialDate),
        finalDate: dateToStringApiFormat(finalDate),
      });

      const [sheetPatientDays] = await getSheetPatientDays(
        initialRoutinePatient?._id,
        config
      );

      const response2 = await updateRoutinePatientWorkoutMutation.mutateAsync({
        initialDate: dateToStringApiFormat(initialDate),
        finalDate: dateToStringApiFormat(finalDate),
        sheetPatientDays: sheetPatientDays,
        patientId: patient?.id,
      });

      resMessages =
        response2.data?.messages
          ?.filter((m) => Array.isArray(m))
          ?.map((m) => m.length > 0 && m[0]) ?? [];

      toast("Aluno atualizado com sucesso", {
        position: "top-center",
        type: "success",
      });
    }

    await queryClient.refetchQueries({
      queryKey: [
        "read-routine-details-query",
        true,
        { id: String(routine?._id) },
      ],
    });

    if (resMessages.length > 0) {
      return setMessages(resMessages);
    }

    onFinish(resMessages);
  };

  const handleResult = (patient) => {
    setPatient(patient);
  };

  const { document, printDocument } = usePrintPage({
    title: "Rotina",
    filename: "rotina",
    bodyContent: [],
    professional: userDetails,
  });

  return (
    <Modal show={true} onHide={() => onFinish()} size="lg">
      <Form onSubmit={submitHandler}>
        <ModalHeader
          title={"Adicionar/Editar Aluno"}
          onClose={() => onFinish()}
        >
          {isEditMode && (
            <PrintButton onClick={() => printDocument()}></PrintButton>
          )}
        </ModalHeader>

        <Modal.Body>
          {messages.map((m, i) => (
            <div key={i} class="alert alert-primary" role="alert">
              {m}
            </div>
          ))}
          <Row>
            <Col className="col-12 mb-0">
              {!isEditMode && (
                <SearchPatients
                  excludeIds={registeredPatients}
                  handleResult={handleResult}
                ></SearchPatients>
              )}

              {patient && (
                <div className="m-md-3">
                  <Row>
                    <PacientProfileV2 withActions={false} pacient={patient} />
                  </Row>
                  <Row className="mt-2">
                    <Col className="col-12 col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicAddress">
                        <Form.Label className="fw-500">Início</Form.Label>
                        <DateField
                          currentDate={initialDate}
                          setDate={setInitialDate}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicGender">
                        <Form.Label className="fw-500">Fim</Form.Label>
                        <DateField
                          currentDate={finalDate}
                          setDate={setFinalDate}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <RoutineSheet
                    isInitialLoading={readRoutinePatientDetailsQuery.isFetching}
                    canEdit={isEditMode}
                    onEdit={() => readRoutinePatientDetailsQuery.refetch()}
                    isPatient={!!routinePatient}
                    sheetRows={patientSheet ?? []}
                  ></RoutineSheet>

                  {error && (
                    <div className="alert alert-danger mt-3" role="alert">
                      {error}
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
          {document}
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={isLoading}
            className="fw-500 float-end"
            variant="primary"
            type="submit"
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
