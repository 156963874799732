import { useReducer } from "react";
import validationRegex from "../../../utils/validations";

const formReducer = (state, action) => {
  switch (action.type) {
    case "CLEAR_FIELDS":
      console.log("CLEAR_FIELDS");
      return {
        notes: null,
        errors: {
          ...state.errors,
        },
      };
    case "SET_STATE_FROM_API":
      return {
        ...state,
        ...action.payload,
        errors: {
          ...state.errors,
        },
      };
    case "SET_NOTES":
      return {
        ...state,
        notes: action.payload,
        errors: {
          ...state.errors,
        },
      };
    default:
      return state;
  }
};

const runRegexTest = (nomeCampo, payload) => {
  return payload.length ? !validationRegex[nomeCampo].test(payload) : false;
};

const useFormReducer = () => {
  const [state, dispatch] = useReducer(formReducer, {});
  return { state, dispatch };
};

export default useFormReducer;
