export function getFeedbackResults(data) {
  if (data?.v2) {
    const count = data.v2.serie[0]?.count ?? 0;
    const timeUpMedia = data.v2.serie[0]?.mediaTimeUp ?? 0;
    const timeDownMedia = data.v2.serie[0]?.mediaTimeDown ?? 0;

    const results = data.v2.results ?? data.v2.resultArticulation;

    const mediaUp = [results[0]?.mediaUp ?? 0, results[1]?.mediaUp ?? 0];
    const mediaDown = [results[0]?.mediaDown ?? 0, results[1]?.mediaDown ?? 0];

    return { count, timeDownMedia, timeUpMedia, mediaUp, mediaDown };
  }

  return data;
}
