import FooterV2 from "../../components/v2/FooterV2";
import HeaderV2 from "../../components/v2/HeaderV2";

import imageSection from "../../assets/v2/img/image-section-1.png";
import sitUps from "../../assets/v2/icons/Sit_Ups.svg";
import pullUps from "../../assets/v2/icons/Pullups.svg";
import treadMill from "../../assets/v2/icons/Treadmill.svg";
import sebrae from "../../assets/v2/img/sebrae.png";
import inovativa from "../../assets/v2/img/inovativa.png";
import imageSection4 from "../../assets/v2/img/image-section-4.jpg";
import imageSection5 from "../../assets/v2/img/image-section-5.jpg";
import check from "../../assets/v2/icons/check.svg";
import slider1 from "../../assets/v2/img/slider-1.jpg";
import landingPage from "../../assets/v2/landing-page.css";
import { useTranslation } from "react-i18next";

import imdLogo from "../../assets/v2/img/imd.svg";
import centelhaLogo from "../../assets/v2/img/centelha.png";

function LandingPageScreenV2({ location, history }) {
  const { t, i18n } = useTranslation();

  const basic = {
    pt: [
      "Grátis por 7 dias, sem cadastrar cartão de crédito",
      "Quantidade de alunos/pacientes ilimitados",
      "Exercícios e Treinos personalizados para seu aluno/paciente",
      "Avaliações e Prontuários",
      "Site disponível para aluno/paciente",
      "Correção de movimento por Inteligência Artificial (bônus)",
      "Videos executados por aluno/paciente, assim como os feedbacks de execução, disponíveis para o profissional, (bônus)",
      "Gestão de agenda",
    ],
    en: [
      "Free for 7 days, no credit card required",
      "Unlimited number of students/patients",
      "Customized exercises and training for your student/patient",
      "Assessments and Records",
      "Website available for student/patient",
      "AI-powered movement correction (bonus)",
      "Videos performed by the student/patient, as well as execution feedback, available to the professional (bonus)",
      "Schedule management",
    ],
  };

  const premium = {
    pt: [
      "Grátis por 7 dias, sem cadastrar cartão de crédito",
      "Quantidade de alunos/pacientes ilimitados",
      "Exercícios e Treinos personalizados para seu aluno/paciente",
      "Avaliações e Prontuários",
      "Site disponível para aluno/paciente",
      "Correção de movimento por Inteligência Artificial (bônus)",
      "Videos executados por aluno/paciente, assim como os feedbacks de execução, disponíveis para o profissional, (bônus)",
      "Gestão de agenda",
      "Registro Financeiro",
      "Chat com o aluno",
      "Dashboard de evoluções do aluno/paciente",
      "Formulários de satisfação para seus alunos/pacientes",
    ],
    en: [
      "Free for 7 days, no credit card required",
      "Unlimited number of students/patients",
      "Customized exercises and training for your student/patient",
      "Assessments and Records",
      "Website available for student/patient",
      "AI-powered movement correction (bonus)",
      "Videos performed by student/patient, along with execution feedback, available to the professional (bonus)",
      "Schedule management",
      "Financial record",
      "Chat with the student",
      "Student/patient progress dashboard",
      "Satisfaction forms for your students/patients",
    ],
  };

  return (
    <>
      <HeaderV2 />
      <style src={landingPage}></style>
      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="display-1 fw-bold text-primary mt-5 mt-lg-0">
                {t("goals_realized")}
              </h1>
              <p className="fw-bold">{t("subtitle")}</p>
            </div>
            <div className="col-md-6">
              <img src={imageSection} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="my-5" id="/#benefits">
        <div className="bg-primary text-white text-center py-5">
          <div className="container">
            <h2 className="display-5 fw-bold text-white">
              {t("unique_experience")}
            </h2>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="icon my-4">
                  <img src={sitUps} alt="" />
                </div>
                <h3 className="fw-bold my-3">{t("appointment_scheduling")}</h3>
                <p>{t("enhanced_productivity")}</p>
              </div>
              <div className="col-md-4">
                <div className="icon my-4">
                  <img src={pullUps} alt="" />
                </div>
                <h3 className="fw-bold my-3">{t("live_feedback")}</h3>
                <p>{t("real_time_feedback")}</p>
              </div>
              <div className="col-md-4">
                <div className="icon my-4">
                  <img src={treadMill} alt="" />
                </div>
                <h3 className="fw-bold my-3">{t("personalized_tracking")}</h3>
                <p>{t("personalized_tracking_description")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5" id="/#partnerships">
        <div className="d-flex px-5 w-100 justify-content-between align-items-center flex-md-row flex-column py-5">
          <a
            href="#"
            target="_blank"
            className="col-7 col-md-2 mb-5 mb-md-0 flex-center"
          >
            <img className="img-fluid" src={sebrae} alt="sebrae" />
          </a>
          <div className="col-7 col-md-2 mb-5 mt-4 mb-md-0 flex-center">
            <a href="#" target="_blank">
              <img className="img-fluid" src={imdLogo} alt="sebrae" />
            </a>
          </div>
          <div className="col-7 col-md-2 flex-center mb-md-0">
            <a href="#" target="_blank">
              <img className="img-fluid" src={centelhaLogo} alt="sebrae" />
            </a>
          </div>
          <div className="col-8 col-md-2 flex-center mb-4 mb-md-0">
            <a href="#" target="_blank">
              <img
                className="img-fluid mt-5 mt-md-0"
                src={inovativa}
                alt="inovativa"
              />
            </a>
          </div>
        </div>
      </section>

      <section className="my-5" id="/#users">
        <div class="left-bg-img">
          <div className="container">
            <div className="row">
              <div className="col-md-6 p-0">
                <img
                  className="img-fluid d-sm-none"
                  src={imageSection4}
                  alt=""
                />
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center position-relative">
                <div className="card card-shadow my-5 border-0 rounded-4 p-3 position-md-absolute">
                  <div className="card-body">
                    <h4 className="fw-bold pb-3">
                      {t("personal_physiotherapists")}
                    </h4>
                    <p>{t("comprehensive_platform")}</p>
                  </div>
                  <a
                    href="/#/sign-up"
                    className="btn btn-primary w-100 position-absolute center-absolute-x fw-bold py-3 btn-card"
                  >
                    {t("sign_up_now")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-bg-img">
          <div className="container">
            <div id="costumer-last-col" className="row my-5">
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center position-relative">
                <div className="card card-shadow my-5 border-0 rounded-4 p-3 position--mdabsolute">
                  <div className="card-body">
                    <h4 className="fw-bold pb-3">
                      {t("Bodybuilders & Entusiastas")}
                    </h4>
                    <p>{t("dynamic_experience")}</p>
                  </div>
                  <a
                    href="/#/sign-up"
                    className="btn btn-primary w-100 position-absolute center-absolute-x fw-bold py-3 btn-card"
                  >
                    {t("sign_up_now")}
                  </a>
                </div>
              </div>
              {/* <div className="col-md-6 p-0">
                <img
                  className="img-fluid float-end d-sm-none"
                  src={imageSection5}
                  alt=""
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="container my-5 text-center" id="/#video">
        <h2 className="fw-bold display-5 text-primary">
          {t("transforming_lives")}
        </h2>
        <div class="ratio ratio-16x9 mt-5 pe-5">
          <iframe
            width="1024"
            height="552"
            src="https://www.youtube.com/embed/_Z8ZX5Eh5R4?si=jm4k17EX0ufBV6rn"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          {/* {i18n.language == "pt" && (
            <iframe
              width="1024"
              height="552"
              src="https://www.youtube.com/embed/_Z8ZX5Eh5R4?si=jm4k17EX0ufBV6rn"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
          {i18n.language == "en" && (
            <iframe
              width="1024"
              height="552"
              src="https://www.youtube.com/embed/q1wIln3PneI?si=7leerFyO7scBN60a"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )} */}
        </div>
      </section>
      <section className="my-5" id="/#plans">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-5 d-flex justify-content-center">
              <div className="card benefits-card border-primary mb-3 overflow-hidden border-2">
                <div className="card-header bg-primary">
                  <h4 className="text-center text-white">
                    {t("basic_plan")} <br /> {t("R$ 25,00")}
                  </h4>
                </div>
                <div className="card-body text-primary p-lg-5 d-flex flex-column justify-content-center bg-body-secondary">
                  <ul className="list-unstyled">
                    {basic[i18n.language].map((i, index) => (
                      <li key={index} className="d-flex mb-3 align-items-start">
                        <img src={check} alt="" />
                        &nbsp;
                        {i}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-flex justify-content-center">
              <div className="card benefits-card border-primary mb-3 overflow-hidden border-2">
                <div className="card-header bg-primary">
                  <h4 className="text-center text-white">
                    {t("premium_plan")} <br /> {t("R$ 45,00")}
                  </h4>
                </div>
                <div className="card-body text-primary p-lg-5 d-flex flex-column justify-content-center bg-body-secondary">
                  <ul className="list-unstyled">
                    {premium[i18n.language].map((i, index) => (
                      <li key={index} className="d-flex mb-3 align-items-start">
                        <img src={check} alt="" />
                        &nbsp;
                        {i}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="my-5 pb-4" id="testmonials">
        <div className="container">
          <h2 className="fw-bold display-5 text-primary text-center mb-5 p-4">
            Descubra o que nossos atletas conquistaram.
          </h2>
          <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active" data-bs-interval="10000">
                <div className="w-80 mx-auto">
                  <div className="card rounded-5 border-0 m-3">
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          src={slider1}
                          alt=""
                          className="img-fluid rounded-start-5"
                        />
                      </div>
                      <div className="col-md-8 pe-md-5 p-4">
                        <div className="px-3 px-md-0">
                          <h3 className="py-3 display-6 fw-bold">Bruno Matos</h3>
                          <p>
                            Essa plataforma é simplesmente incrível! Graças a ela,
                            consegui elevar meus treinos a outro nível.
                          </p>
                          <p>
                            O acompanhamento personalizado me manteve focado nos
                            meus objetivos, e o feedback em tempo real dos
                            exercícios me ajudou a aprimorar minha técnica.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <div className="w-80 mx-auto">
                  <div className="card rounded-5 border-0 m-3">
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          src={slider1}
                          alt=""
                          className="img-fluid rounded-start-5"
                        />
                      </div>
                      <div className="col-md-8 pe-md-5 p-4">
                      <div className="px-3 px-md-0">
                        <h3 className="py-3 display-6 fw-bold">Bruno Matos</h3>
                        <p>
                          Essa plataforma é simplesmente incrível! Graças a ela,
                          consegui elevar meus treinos a outro nível.
                        </p>
                        <p>
                          O acompanhamento personalizado me manteve focado nos
                          meus objetivos, e o feedback em tempo real dos
                          exercícios me ajudou a aprimorar minha técnica.
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="w-80 mx-auto">
                  <div className="card rounded-5 border-0 m-3">
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          src={slider1}
                          alt=""
                          className="img-fluid rounded-start-5"
                        />
                      </div>
                      <div className="col-md-8 pe-md-5 p-4">
                      <div className="px-3 px-md-0">
                        <h3 className="py-3 display-6 fw-bold">Bruno Matos</h3>
                        <p>
                          Essa plataforma é simplesmente incrível! Graças a ela,
                          consegui elevar meus treinos a outro nível.
                        </p>
                        <p>
                          O acompanhamento personalizado me manteve focado nos
                          meus objetivos, e o feedback em tempo real dos
                          exercícios me ajudou a aprimorar minha técnica.
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section> */}
      <FooterV2 />
    </>
  );
}

export default LandingPageScreenV2;
