import {
    MEDICAL_RECORDS_DETAILS_FAIL,
    MEDICAL_RECORDS_DETAILS_REQUEST,
    MEDICAL_RECORDS_DETAILS_SUCCESS,
    MEDICAL_RECORDS_LIST_FAIL,
    MEDICAL_RECORDS_LIST_REQUEST,
    MEDICAL_RECORDS_LIST_SUCCESS,
    MEDICAL_RECORDS_REGISTER_FAIL,
    MEDICAL_RECORDS_REGISTER_REQUEST,
    MEDICAL_RECORDS_REGISTER_SUCCESS,
    MEDICAL_RECORDS_UPDATE_FAIL,
    MEDICAL_RECORDS_UPDATE_REQUEST,
    MEDICAL_RECORDS_UPDATE_SUCCESS,
    MEDICAL_RECORDS_DELETE_FAIL, 
    MEDICAL_RECORDS_DELETE_REQUEST,
    MEDICAL_RECORDS_DELETE_RESET,
    MEDICAL_RECORDS_DELETE_SUCCESS,

} from '../constants/medicalRecordsConstants';
import axios from '../services/axios';

export const listMedicalRecords = (pacientId, page) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_RECORDS_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params: {
                page: page
            }
        }

        const { data } = await axios.get(
            `/api/profiles/medicalrecord/readall/${pacientId}`,
            config
        )

        dispatch({
            type: MEDICAL_RECORDS_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_RECORDS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const registerMedicalRecords = (medicalRecord, pacientId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_RECORDS_REGISTER_REQUEST
        })
        
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/profiles/medicalrecord/create/${pacientId}/`,
            medicalRecord,
            config
        )

        dispatch({
            type: MEDICAL_RECORDS_REGISTER_SUCCESS,
            payload: data
        })

        dispatch(listMedicalRecords(pacientId, 1))
    } catch (error) {
        dispatch({
            type: MEDICAL_RECORDS_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateMedicalRecords = (medicalRecord) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_RECORDS_UPDATE_REQUEST
        })
        
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        console.log('medicalRecord teste', medicalRecord);
        const { data } = await axios.put(
            `/api/profiles/medicalrecord/edit/${medicalRecord._id}/`,
            medicalRecord,
            config
        )

        dispatch({
            type: MEDICAL_RECORDS_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_RECORDS_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteMedicalRecord = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_RECORDS_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/profiles/medicalrecord/delete/${id}`,
            config
        )

        dispatch({
            type: MEDICAL_RECORDS_DELETE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_RECORDS_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const detailsMedicalRecord = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MEDICAL_RECORDS_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/profiles/medicalrecord/read/${id}/`,
            config
        )

        dispatch({
            type: MEDICAL_RECORDS_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MEDICAL_RECORDS_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
