import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Carousel,
  Image,
  Modal,
} from "react-bootstrap";
import { formatDate } from "../../utils/date";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import openSvg from "../../assets/v2/icons/open.svg";
import { useDispatch } from "react-redux";
import { updateFeedbackComment } from "../../actions/feedbackActions";
import { getItemsFromPost } from "../../utils/getItemsFromPost";
import { INTENSITIES } from "../../constants/post.consts";
import { toast } from "react-toastify";
import { respStyle } from "../../utils/respStyle";
import { useUserInfo } from "../../hooks/useUserInfo";

function separateIntoGroupsOfThree(nums) {
  let result = [];
  for (let i = 0; i < nums.length; i += 3) {
    result.push(nums.slice(i, i + 3));
  }
  return result;
}

export function FeedbackCard({ feedback, handleShow }) {
  const mediaValues = useMediaQueryValues();
  const { isXL } = mediaValues;
  const [feedComment, setFeedComment] = useState(
    feedback?.post?.commentsProfessional ?? ""
  );
  const dispatch = useDispatch();

  const { isPatient } = useUserInfo();

  const { post, exercises_done: allExercisesDone } = feedback;

  const exercisesDoneWithVideo = (allExercisesDone ?? []).filter(
    (eD) => !!eD?.exerciseVideo?.video
  );

  const hasExercisesDone =
    exercisesDoneWithVideo && exercisesDoneWithVideo.length > 0;
  const renderInGroup3 = () => {
    const data = exercisesDoneWithVideo ?? [];
    const group3 = separateIntoGroupsOfThree(data);

    return group3.map((group, i) => (
      <Carousel.Item key={i} className="" style={{ width: "100%" }}>
        <div className="d-flex justify-content-center">
          {group.map(renderItem)}
        </div>
      </Carousel.Item>
    ));
  };

  const renderInMobile = () => {
    const data = exercisesDoneWithVideo ?? [];

    return data.map((e, i) => (
      <Carousel.Item key={i} className="" style={{ width: "100%" }}>
        <div className="d-flex justify-content-center">{renderItem(e)}</div>
      </Carousel.Item>
    ));
  };

  const renderItem = (exerciseDone) => {
    const video = exerciseDone?.exerciseVideo?.video;
    const id = exerciseDone?._id;
    const exerciseName = exerciseDone?.exercise?.name;
    const postId = exerciseDone?.post;

    return (
      <div
        key={id}
        className="feed-card-item col-12 col-xl-3 mt-5 p-0 m-0 ms-2 d-flex flex-column justify-content-center align-items-center bg-white mb-4 rounded-4 overflow-hidden"
        onClick={() => handleShow(id, video, postId)}
      >
        <div className={"bg-body-secondary w-100 h-100 d-flex me-2"}>
          <video
            src={video}
            type="video/mp4"
            className="w-100 h-100"
            style={{ maxHeight: 250 }}
            controls={true}
            onClick={(e) => e.stopPropagation()}
          ></video>
        </div>

        <div className="w-100 pointer d-flex justify-content-center align-items-center p-3">
          <p className="text-center fw-bold text-primary p-0 m-0">
            {exerciseName}
          </p>

          <Image
            src={openSvg}
            className="ms-2"
            style={{ maxHeight: 20, maxWidth: 20 }}
          />
        </div>
      </div>
    );
  };

  const renderCarouselItems = mediaValues.isXL
    ? renderInGroup3
    : renderInMobile;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateFeedbackComment({
        comments: feedComment,
        postId: feedback?.post?._id,
      })
    );

    toast("Comentário atualizado", { type: "success", position: "top-center" });
  };

  const items = getItemsFromPost(feedback?.post);

  const renderFeedbackItemResultCard = ({ title, value }, className = "") => {
    return (
      <div
        key={title}
        className={
          "col-6  m-0 p-0 " +
          (hasExercisesDone ? " col-xl-6 " : " col-xl-2 ") +
          className
        }
      >
        <div className="bg-white p-2 m-1 row m-0 rounded">
          <p className="text-primary m-0 p-0 text-wrap text-break">{title}</p>
          <p className="h6 m-0 p-0 text-nowrap">{value}</p>
        </div>
      </div>
    );
  };

  const renderCards = (
    <>
      {items
        .slice(0, items.length - 1)
        .map((item) => renderFeedbackItemResultCard(item))}

      {renderFeedbackItemResultCard(items[items.length - 1], " w-100")}
    </>
  );

  return (
    <div className={"feedback-card-item my-4 m-0 "}>
      <Row
        className={
          "d-flex justify-content-center align-items-center m-0 p-0 py-3 py-lg-0 pb-lg-3  bg-body-secondary rounded-4" +
          respStyle({ xl: "h-100" }, mediaValues)
        }
      >
        <Col className="col-12 d-flex align-items-center row m-0 mb-0 col-md-4 col-xl-3 p-0 h-100">
          <p className="text-primary col-12 fs-4 fw-bold text-center text-capitalize mt-md-3 mb-0">
            {feedback?.post?.workout?.name ?? "-"}
          </p>

          <p className="text-primary text-center text-capitalize">
            {formatDate(feedback?.post?.date)}
          </p>

          {!isPatient && (
            <div
              className={
                "d-flex justify-content-center align-items-center " +
                (!hasExercisesDone && "flex-column")
              }
            >
              <img
                style={{ maxWidth: 75 }}
                className="d-block rounded-4"
                src={
                  feedback?.post?.pacient?.profileImage?.image
                    ? feedback.post?.pacient.profileImage?.image
                    : "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                }
              />
              <p className="text-primary fw-bold text-center text-capitalize mb-0 ms-2">
                {feedback?.post?.pacient?.name}
              </p>
            </div>
          )}

          {hasExercisesDone && (
            <div className="row m-0 mt-2">{renderCards}</div>
          )}
        </Col>
        <Col className="col-12 col-md-8 col-xl-9 d-flex flex-column col justify-content-center align-items-center h-100 p-0 px-1 px-md-3">
          {hasExercisesDone && (
            <Carousel
              className="col-12"
              data-bs-theme="dark"
              indicators={false}
              data-ride="carousel"
              interval={null}
              controls={
                exercisesDoneWithVideo && mediaValues.isXL
                  ? exercisesDoneWithVideo.length > 3
                  : exercisesDoneWithVideo.length > 1
              }
            >
              {renderCarouselItems()}
            </Carousel>
          )}

          {!hasExercisesDone && (
            <div className="row col-12 m-0 my-2">{renderCards}</div>
          )}

          {
            <Form
              className={
                "d-flex col-12 flex-column mt-2" +
                (hasExercisesDone ? "col-xl-12" : "")
              }
              onSubmit={handleSubmit}
              style={{ transform: `translate(${0}px, ${-8}px)` }}
            >
              <Form.Label className="ms-1 text-primary">
                Comentário do Profissional:
              </Form.Label>

              <div className="d-flex col-12">
                <Form.Group
                  className="p-0 container-fluid bg-white"
                  controlId="exampleForm.ControlTextarea1"
                >
                  {!isPatient ? (
                    <Form.Control
                      style={{ height: 60 }}
                      as="textarea"
                      rows={3}
                      value={feedComment}
                      onChange={(e) => setFeedComment(e.target.value)}
                    />
                  ) : (
                    <p className="p-2 rounded">{feedComment}</p>
                  )}
                </Form.Group>

                {!isPatient && (
                  <Button type="submit" className="w-auto h-auto ms-2">
                    Enviar
                  </Button>
                )}
              </div>
            </Form>
          }
        </Col>

        {/* <Col className="col-xl-1"></Col> */}
      </Row>
    </div>
  );
}
