import { useQuery } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";
import { useMemo } from "react";

export function usePatientWorkoutQuery({ enabled, params }) {
  const config = useApiConfig();

  const patientWorkoutQuery = useQuery({
    queryKey: ["patient-workout-query", enabled, params],
    enabled,
    queryFn: () => {
      return api.get(`/api/workouts/workoutpatient/readall/`, config);
    },
  });

  const patientWorkouts = useMemo(
    () => patientWorkoutQuery.data?.data ?? [],
    [patientWorkoutQuery.data]
  );

  return { patientWorkoutQuery, patientWorkouts };
}

export function useReadAllPatientWorkoutsQuery({ enabled, params }) {
  const config = useApiConfig();

  const readAllPatientWorkoutsQuery = useQuery({
    queryKey: ["all-patient-workout-query", enabled, params],
    enabled,
    queryFn: () => {
      const { patientId } = params;
      return api.get(`/api/profiles/workouts/readall/${patientId}/`, config);
    },
  });

  const allPatientWorkouts = useMemo(
    () => readAllPatientWorkoutsQuery.data?.data ?? [],
    [readAllPatientWorkoutsQuery.data]
  );

  const workouts = useMemo(
    () => allPatientWorkouts.map((pW) => pW.workoutSheet.workout),
    [allPatientWorkouts]
  );

  return { readAllPatientWorkoutsQuery, allPatientWorkouts, workouts };
}

export function useReadGraphPatientWorkoutsQuery({ enabled, params }) {
  const config = useApiConfig();

  const readGraphPatientWorkoutsQuery = useQuery({
    queryKey: ["graph-patient-workout-query", enabled, params],
    enabled,
    queryFn: () => {
      const { patientId, workoutId, month } = params;

      return api.get(
        `/api/profiles/graphdata/readall/${patientId}/${workoutId}/?month=${month}`,
        config
      );
    },
  });

  const graphPatientWorkout = useMemo(
    () => readGraphPatientWorkoutsQuery.data?.data ?? [],
    [readGraphPatientWorkoutsQuery.data]
  );

  return { graphPatientWorkout, readGraphPatientWorkoutsQuery };
}
