import { Col, Row } from "react-bootstrap";
import workoutCss from "../../../assets/v2/pacient-workout-list.css";
import { formatDate } from "../../../utils/date";
import { daysMap } from "../../../constants/days.consts";
import { Link } from "react-router-dom";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { useHistory } from "react-router-dom";

function PacientWorkoutListV2({ workoutsList, patient, workout }) {
  const { isPatient } = useUserInfo();

  const history = useHistory();

  const handleClickItem = ({ id }) => {
    if (isPatient) return;
    history.push(`/home/personal/programs/${id}`);
  };

  return (
    <>
      <style src={workoutCss}></style>

      <div className="pwl-root col-12">
        <div className="d-flex justify-content-between align-items-center pwl-header">
          <h3>Treinos</h3>
          {!isPatient && (
            <div
              className="add-button"
              onClick={() => history.push("/home/personal/programs")}
            >
              <img alt="adicionar" src="assets/v2/icons/plus.svg" />
            </div>
          )}
        </div>
        <Row className="">
          {workoutsList &&
            workoutsList.map((wk, id) => {
              const { workoutSheet } = wk;
              const { workout } = workoutSheet;

              return (
                <Col
                  key={id}
                  className={"pwl-item-root col-12 col-md-6 col-lg-12 col-xl-6"}
                >
                  <div
                    onClick={() => handleClickItem({ id: workout?._id })}
                    className={
                      "pwl-item d-flex flex-row justify-content-between align-item-center" +
                      (!isPatient && " pointer")
                    }
                  >
                    <div>
                      <h4>
                        {workout?.name} {id + 1}
                      </h4>
                      <p className="mb-0">
                        Ínicio: {formatDate(wk.initialDate)}
                      </p>
                      <p>Fim: {formatDate(wk.finalDate)}</p>

                      <p className="fw-500 mb-0">Dias:</p>

                      <div className="d-flex flex-wrap gap-1">
                        {wk.days.slice(0, wk.days.length - 1).map((d) => (
                          <p className="mb-0" key={d}>
                            {daysMap[d]},
                          </p>
                        ))}
                        <p className="mb-0">
                          {daysMap[wk.days[wk.days.length - 1]]}.
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      {/* <img alt="menu" src="assets/v2/icons/three-dots.svg" /> */}
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
}

export default PacientWorkoutListV2;
