export const SCHEDULE_CREATE_REQUEST = 'SCHEDULE_CREATE_REQUEST'
export const SCHEDULE_CREATE_SUCCESS = 'SCHEDULE_CREATE_SUCCESS'
export const SCHEDULE_CREATE_FAIL = 'SCHEDULE_CREATE_FAIL'
export const SCHEDULE_CREATE_RESET = 'SCHEDULE_CREATE_RESET'

export const SCHEDULE_LIST_REQUEST = 'SCHEDULE_LIST_REQUEST'
export const SCHEDULE_LIST_SUCCESS = 'SCHEDULE_LIST_SUCCESS'
export const SCHEDULE_LIST_FAIL = 'SCHEDULE_LIST_FAIL'


export const SCHEDULE_DETAILS_REQUEST = 'SCHEDULE_DETAILS_REQUEST'
export const SCHEDULE_DETAILS_SUCCESS = 'SCHEDULE_DETAILS_SUCCESS'
export const SCHEDULE_DETAILS_FAIL = 'SCHEDULE_DETAILS_FAIL'