import { React, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import search from '../../assets/v2/icons/search.svg'

function SearchBarv2({ options, handleResult }) {
    const [selected, setSelected] = useState([]);
    
    const handleOnChange = (selected) => {
        setSelected(selected);
        handleResult(selected);
    }

    return (
        <Row className="py-4">
            <Form.Group className="d-flex">
                <Typeahead
                    // onChange={(selected) => {
                    //     this.setState({ selected });
                    // }}
                    labelKey="name"
                    options={[...options]}
                    // selected={this.state.selected}
                    className='me-2'
                    onChange={ e => handleOnChange(e) }
                    selected={ selected }
                />
                <Button
                    className="search-btn"
                    variant="primary"
                    type="submit"
                >
                    <img src={search} />
                </Button>
            </Form.Group>
        </Row>
    )
}

export default SearchBarv2;
