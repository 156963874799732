import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,

    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_RESET,

    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,

    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_RESET,

    USER_LIST_BODYBUILDERS_REQUEST,
    USER_LIST_BODYBUILDERS_SUCCESS,
    USER_LIST_BODYBUILDERS_FAIL,
    USER_LIST_BODYBUILDERS_RESET,

    USER_LIST_BODYBUILDERS_AVAILABLE_REQUEST,
    USER_LIST_BODYBUILDERS_AVAILABLE_SUCCESS,
    USER_LIST_BODYBUILDERS_AVAILABLE_FAIL,
    USER_LIST_BODYBUILDERS_AVAILABLE_RESET,

    USER_LIST_PERSONAL_REQUEST,
    USER_LIST_PERSONAL_SUCCESS,
    USER_LIST_PERSONAL_FAIL,
    USER_LIST_PERSONAL_RESET,

    USER_INVITATION_LIST_REQUEST,
    USER_INVITATION_LIST_SUCCESS,
    USER_INVITATION_LIST_FAIL,
    USER_INVITATION_LIST_RESET,

    USER_INVITATION_SEND_REQUEST,
    USER_INVITATION_SEND_SUCCESS,
    USER_INVITATION_SEND_FAIL,
    USER_INVITATION_SEND_RESET,

    USER_INVITATION_REPLY_REQUEST,
    USER_INVITATION_REPLY_SUCCESS,
    USER_INVITATION_REPLY_FAIL,
    USER_INVITATION_REPLY_RESET,
    USER_FORGET_PASS_REQUEST,
    USER_FORGET_PASS_SUCCESS,
    USER_FORGET_PASS_FAIL,
    USER_FORGET_PASS_RESET,
    USER_RESET_PASS_REQUEST,
    USER_RESET_PASS_SUCCESS,
    USER_RESET_PASS_FAIL,
    USER_RESET_PASS_RESET,

    USER_IMAGE_CREATE_REQUEST,
    USER_IMAGE_CREATE_SUCCESS,
    USER_IMAGE_CREATE_FAIL,
    USER_UPDATE_PASS_REQUEST,
    USER_UPDATE_PASS_SUCCESS,
    USER_UPDATE_PASS_FAIL,
    USER_LOGIN_UPDATE,

} from '../constants/userConstants'


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_LOGIN_UPDATE:
            return { loading: false, userInfo: { ...state.userInfo, ...action.payload } }

        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}


export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}



export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state, loading: true }

        case USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload }

        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        case USER_DETAILS_RESET:
            return { user: {} }


        default:
            return state
    }
}


export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_PROFILE_REQUEST:
            return { loading: true }

        case USER_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }

        case USER_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload }

        case USER_UPDATE_PROFILE_RESET:
            return {}

        default:
            return state
    }
}


export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true }

        case USER_LIST_SUCCESS:
            return { loading: false, users: action.payload }

        case USER_LIST_FAIL:
            return { loading: false, error: action.payload }

        case USER_LIST_RESET:
            return { users: [] }

        default:
            return state
    }
}


export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true }

        case USER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const userUpdateReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return { loading: true }

        case USER_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case USER_UPDATE_RESET:
            return { user: {} }

        default:
            return state
    }
}

export const userListMyBodybuildersReducer = (state = { bodybuilders: [] }, action) => {
    switch (action.type) {
        case USER_LIST_BODYBUILDERS_REQUEST:
            return { loadingBodybuilders: true }

        case USER_LIST_BODYBUILDERS_SUCCESS:
            return { loadingBodybuilders: false, bodybuilders: action.payload }

        case USER_LIST_BODYBUILDERS_FAIL:
            return { loadingBodybuilders: false, errorBodybuilders: action.payload }

        case USER_LIST_BODYBUILDERS_RESET:
            return { bodybuilders: [] }

        default:
            return state
    }
}

export const userListBodybuildersAvailableReducer = (state = { bodybuildersAvailable: [] }, action) => {
    switch (action.type) {
        case USER_LIST_BODYBUILDERS_AVAILABLE_REQUEST:
            return { loadingBodybuildersAvailable: true }

        case USER_LIST_BODYBUILDERS_AVAILABLE_SUCCESS:
            return { loadingBodybuildersAvailable: false, bodybuildersAvailable: action.payload }

        case USER_LIST_BODYBUILDERS_AVAILABLE_FAIL:
            return { loadingBodybuildersAvailable: false, errorBodybuildersAvailable: action.payload }

        case USER_LIST_BODYBUILDERS_RESET:
            return { bodybuildersAvailable: [] }

        default:
            return state
    }
}

export const userListMyPersonalReducer = (state = { personal: [] }, action) => {
    switch (action.type) {
        case USER_LIST_PERSONAL_REQUEST:
            return { loadingPersonal: true }

        case USER_LIST_PERSONAL_SUCCESS:
            return { loadingPersonal: false, personal: action.payload }

        case USER_LIST_PERSONAL_FAIL:
            return { loadingPersonal: false, errorPersonal: action.payload }

        case USER_LIST_PERSONAL_RESET:
            return { personal: [] }

        default:
            return state
    }
}

export const userListInvitationReducer = (state = { invitations: [] }, action) => {
    switch (action.type) {
        case USER_INVITATION_LIST_REQUEST:
            return { loadingInvitations: true }

        case USER_INVITATION_LIST_SUCCESS:
            return { loadingInvitations: false, invitations: action.payload }

        case USER_INVITATION_LIST_FAIL:
            return { loadingInvitations: false, errorInvitations: action.payload }

        case USER_INVITATION_LIST_RESET:
            return { invitations: [] }

        default:
            return state
    }
}

export const userSendInvitationReducer = (state = { sendInvitation: [] }, action) => {
    switch (action.type) {
        case USER_INVITATION_SEND_REQUEST:
            return { loadingSendInvitation: true }

        case USER_INVITATION_SEND_SUCCESS:
            return { loadingSendInvitation: false, sendInvitation: action.payload }

        case USER_INVITATION_SEND_FAIL:
            return { loadingSendInvitation: false, errorSendInvitation: action.payload }

        case USER_INVITATION_SEND_RESET:
            return { sendInvitation: [] }

        default:
            return state
    }
}

export const userReplyInvitationReducer = (state = { replyInvitation: [] }, action) => {
    switch (action.type) {
        case USER_INVITATION_REPLY_REQUEST:
            return { loadingReplyInvitation: true }

        case USER_INVITATION_REPLY_SUCCESS:
            return { loadingReplyInvitation: false, replyInvitation: action.payload }

        case USER_INVITATION_REPLY_FAIL:
            return { loadingReplyInvitation: false, errorReplyInvitation: action.payload }

        case USER_INVITATION_REPLY_RESET:
            return { replyInvitation: [] }

        default:
            return state
    }
}

export const forgetPassReducer = (state = { forgetPassData: null }, action) => {
    switch (action.type) {
        case USER_FORGET_PASS_REQUEST:
            return { loadingForgetPass: true }

        case USER_FORGET_PASS_SUCCESS:
            return { loadingForgetPass: false, forgetPassData: action.payload }

        case USER_FORGET_PASS_FAIL:
            return { loadingForgetPass: false, errorForgetPass: action.payload }

        case USER_FORGET_PASS_RESET:
            return { forgetPassData: null }

        default:
            return state
    }
}

export const resetPassReducer = (state = { resetPassData: null }, action) => {
    switch (action.type) {
        case USER_RESET_PASS_REQUEST:
            return { loadingResetPass: true }

        case USER_RESET_PASS_SUCCESS:
            return { loadingResetPass: false, resetPassData: action.payload }

        case USER_RESET_PASS_FAIL:
            return { loadingResetPass: false, errorResetPass: action.payload }

        case USER_RESET_PASS_RESET:
            return { resetPassData: null }

        default:
            return state
    }
}

export const userImageCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_IMAGE_CREATE_REQUEST:
            return { loadingImage: true }

        case USER_IMAGE_CREATE_SUCCESS:
            return { loadingImage: false, successimage: true, image: action.payload }

        case USER_IMAGE_CREATE_FAIL:
            return { loadingImage: false, error: action.payload }

        default:
            return state
    }
}

export const updatePassReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_PASS_REQUEST:
            return { loadingUpdatePass: true }

        case USER_UPDATE_PASS_SUCCESS:
            return { loadingUpdatePass: false, successUpdatePass: true, pass: action.payload }

        case USER_UPDATE_PASS_FAIL:
            return { loadingUpdatePass: false, error: action.payload }

        default:
            return state
    }
}
