import { useState } from "react";
import { Card, Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useRoutineMutation } from "../../../mutations";
import { toast } from "react-toastify";

import weigthPng from "../../../assets/v2/icons/weight.png";

export const RoutineCard = ({ routine, selectedWorkouts = [] }) => {
  const history = useHistory();

  const [isDragging, setIsDragging] = useState(false);
  const { updateRoutine } = useRoutineMutation();

  const isLoading = updateRoutine.isPending;

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    setIsDragging(true);
  };

  const handleDrop = async (e) => {
    e.preventDefault();

    setIsDragging(false);

    if (isLoading) return;

    const workout = JSON.parse(e.dataTransfer.getData("text/plain"));

    if (workout.dragType === "workout") {
      const workoutsIds = [...selectedWorkouts, workout?._id];
      const uniqueWorkoutsIds = [...new Set(workoutsIds)];

      await updateRoutine.mutateAsync({
        id: routine?._id,
        ...routine,
        workouts: uniqueWorkoutsIds,
      });

      toast("Treino adicionado com sucesso!", {
        position: "top-center",
        type: "success",
      });
    } else {
      toast(
        "Não é possível adicionar um exercício a uma rotina, somente treinos.",
        {
          position: "top-center",
          type: "error",
        }
      );
    }
  };

  const onCardClick = () => {
    history.push(`/home/personal/routine/${routine?._id}`);
  };

  return (
    <Card
      className={
        `pacient-card border-0 w-100 mb-4 rounded-4 h-100 ` +
        (isDragging && " bg-info ")
      }
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={onCardClick}
      onDragLeave={handleDragLeave}
    >
      <Card.Body className="d-flex align-items-center px-4">
        {isLoading ? (
          <div className="w-100 flex-center">
            <div className="">
              <Spinner
                animation="border"
                role="status"
                style={{
                  height: 30,
                  width: 30,
                }}
              ></Spinner>
            </div>
          </div>
        ) : (
          <>
            <Image variant="top" src={weigthPng} width={45} className="me-2" />
            <div className="d-flex flex-column ms-3">
              <h3 className="text-dark text-decoration-none fs-6 mb-0">
                Rotina
              </h3>
              <p className="text-secondary mb-0">{routine?.name}</p>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};
