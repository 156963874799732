import { useEffect, useState } from "react";
import { Col, Modal, Row, Dropdown } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import PacientWorkoutListV2 from "./PacientWorkoutListV2";
import PacientProfileV2 from "./PacientProfileV2";
import { useDispatch, useSelector } from "react-redux";
import { detailsPacient } from "../../../actions/pacientActions";
import MedicalRecordsV2 from "./MedicalRecordsV2";
import UserMedicalRecordUpdateV2 from "../../../components/v2/UserMedicalRecordUpdateV2/UserMedicalRecordUpdateV2";
import { listMedicalRecords } from "../../../actions/medicalRecordsActions";
import details from "../../../assets/v2/pacient-details-component.css";
import { useUserInfo } from "../../../hooks/useUserInfo";
import {
  useReadAllPatientWorkoutsQuery,
  useReadUserDetailsQuery,
} from "../../../queries";

import { PatientWorkoutGraph } from "../../../components/PatientWorkoutGraph";

import { useGraphFilters } from "../../../hooks/useGraphFilters";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { EvaluationModelList } from "./Evaluations/EvaluationModelList";
import { PatientFormModelList } from "./Forms/PatientFormModelList";

function PacientDetailsV2({ handleModal, unsetModalBody }) {
  let { id: paramsPacientId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { userDetails } = useReadUserDetailsQuery({});
  const { isPatient } = useUserInfo();
  const pacientId = paramsPacientId ?? userDetails?.id;

  const { allPatientWorkouts } = useReadAllPatientWorkoutsQuery({
    enabled: !!pacientId,
    params: { patientId: pacientId },
  });

  const { isLG } = useMediaQueryValues();

  const { pacient: pacientState } = useSelector(
    (state) => state.pacientDetails
  );

  const { medical_records } = useSelector((state) => state.medicalRecordList);

  const [selectedMedicalRecordId, setSelectedMedicalRecordId] = useState(null);
  const [medicalRecordModalShow, setMedicalRecordModalShow] = useState(false);

  const graphFilters = useGraphFilters({ patientId: pacientId });

  const openMedicalRecordModal = (id) => {
    setMedicalRecordModalShow(true);
    setSelectedMedicalRecordId(id);
  };

  const closeMedicalRecordModal = () => {
    setMedicalRecordModalShow(false);
    setSelectedMedicalRecordId(null);
  };

  useEffect(() => {
    if (!pacientId) return;
    if (!isPatient) dispatch(detailsPacient(pacientId));

    dispatch(listMedicalRecords(pacientId, 1));
  }, [pacientId]);

  const patient = pacientState?.paciente ?? userDetails;

  return (
    <>
      <style src={details}></style>
      <Col className="col-12">
        <h2>
          <button onClick={() => history.goBack()}>{"<"}</button> Perfil
        </h2>
        <Row noGutters={true} className="m-0">
          <Col className="col-12 p-md-0 col-md-4 col-xl-3">
            {!isPatient && (
              <Col className="mb-5">
                <PacientProfileV2
                  pacient={patient}
                  handleModal={handleModal}
                  unsetModalBody={unsetModalBody}
                  photoFullWidth
                />
              </Col>
            )}
            <Col className="mb-5">
              <EvaluationModelList patient={patient}></EvaluationModelList>
            </Col>
            <Col className="mb-5">
              <PatientFormModelList patient={patient}></PatientFormModelList>
            </Col>
            <Col className="mb-5">
              <MedicalRecordsV2
                openModal={(id) => openMedicalRecordModal(id)}
                medicalRecordsList={medical_records}
                patientId={pacientId}
              />
            </Col>
          </Col>
          <Col className="col-md-8 col-xl-9">
            <div className="pwl-root">
              <div className="pwl-header d-flex flex-wrap">
                <h3
                  className="mb-0 d-flex   align-items-center col-12 col-lg-auto"
                  style={{ flex: isLG ? 1 : "none" }}
                >
                  Estatísticas
                </h3>

                {graphFilters.workoutFilter}
                {graphFilters.monthFilter}
              </div>
              <Col className="mb-5" style={{ height: 250 }}>
                <PatientWorkoutGraph
                  patientId={pacientId}
                  currentMonth={graphFilters.currentMonth}
                  workoutId={graphFilters.currentWorkoutId}
                  isFilterLoading={graphFilters.isFilterLoading}
                ></PatientWorkoutGraph>
              </Col>

              <Col className="mb-5" style={{ height: 250 }}>
                <PatientWorkoutGraph
                  patientId={pacientId}
                  valueKey={"weightsDay"}
                  title={"Peso por Mês"}
                  tooltipLabel={{
                    x: "Dia",
                    y: "Peso (kg)",
                  }}
                  currentMonth={graphFilters.currentMonth}
                  workoutId={graphFilters.currentWorkoutId}
                  isFilterLoading={graphFilters.isFilterLoading}
                ></PatientWorkoutGraph>
              </Col>

              <div style={{ height: 5, marginBottom: 15 }}></div>
            </div>

            <Col className="mb-5 row m-0">
              <PacientWorkoutListV2
                workoutsList={allPatientWorkouts}
                patient={patient}
              />
            </Col>
          </Col>
        </Row>
        <div className="d-flex flex-column w-25 gap-1">
          <Modal
            show={medicalRecordModalShow}
            onHide={() => closeMedicalRecordModal()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <UserMedicalRecordUpdateV2
                pacientId={pacientId}
                pacienteData={patient}
                medicalRecordId={selectedMedicalRecordId}
                onClose={() => closeMedicalRecordModal()}
              />
            </Modal.Body>
          </Modal>
        </div>
      </Col>
    </>
  );
}

export default PacientDetailsV2;
