
export const VIDEO_INCIALIZE_BODYBUILDER_REQUEST = 'VIDEO_INCIALIZE_BODYBUILDER_REQUEST'
export const VIDEO_INCIALIZE_BODYBUILDER_SUCCESS = 'VIDEO_INCIALIZE_BODYBUILDER_SUCCESS'
export const VIDEO_INCIALIZE_BODYBUILDER_FAIL = 'VIDEO_INCIALIZE_BODYBUILDER_FAIL'

export const VIDEO_INCIALIZE_PERSONAL_REQUEST = 'VIDEO_INCIALIZE_PERSONAL_REQUEST'
export const VIDEO_INCIALIZE_PERSONAL_SUCCESS = 'VIDEO_INCIALIZE_PERSONAL_SUCCESS'
export const VIDEO_INCIALIZE_PERSONAL_FAIL = 'VIDEO_INCIALIZE_PERSONAL_FAIL'

export const VIDEO_HUMANPOINTS_BODYBUILDER_REQUEST = 'VIDEO_HUMANPOINTS_BODYBUILDER_REQUEST'
export const VIDEO_HUMANPOINTS_BODYBUILDER_SUCCESS = 'VIDEO_HUMANPOINTS_BODYBUILDER_SUCCESS'
export const VIDEO_HUMANPOINTS_BODYBUILDER_FAIL = 'VIDEO_HUMANPOINTS_BODYBUILDER_FAIL'

export const VIDEO_HUMANPOINTS_PERSONAL_REQUEST = 'VIDEO_HUMANPOINTS_PERSONAL_REQUEST'
export const VIDEO_HUMANPOINTS_PERSONAL_SUCCESS = 'VIDEO_HUMANPOINTS_PERSONAL_SUCCESS'
export const VIDEO_STOP_PERSONAL_RESET = 'VIDEO_STOP_PERSONAL_RESET'
export const VIDEO_HUMANPOINTS_PERSONAL_FAIL = 'VIDEO_HUMANPOINTS_PERSONAL_FAIL'

export const VIDEO_STOP_BODYBUILDER_REQUEST = 'VIDEO_STOP_BODYBUILDER_REQUEST'
export const VIDEO_STOP_BODYBUILDER_SUCCESS = 'VIDEO_STOP_BODYBUILDER_SUCCESS'
export const VIDEO_STOP_BODYBUILDER_FAIL = 'VIDEO_STOP_BODYBUILDER_FAIL'
export const VIDEO_STOP_BODYBUILDER_RESET = 'VIDEO_STOP_BODYBUILDER_RESET'

export const VIDEO_STOP_PERSONAL_REQUEST = 'VIDEO_STOP_PERSONAL_REQUEST'
export const VIDEO_STOP_PERSONAL_SUCCESS0 = 'VIDEO_STOP_PERSONAL_SUCCESS0'
export const VIDEO_STOP_PERSONAL_SUCCESS1 = 'VIDEO_STOP_PERSONAL_SUCCESS1'
export const VIDEO_STOP_PERSONAL_SUCCESS2 = 'VIDEO_STOP_PERSONAL_SUCCESS2'
export const VIDEO_STOP_PERSONAL_FAIL = 'VIDEO_STOP_PERSONAL_FAIL'

export const VIDEO_UPLOAD_PERSONAL_REQUEST = 'VIDEO_UPLOAD_PERSONAL_REQUEST'
export const VIDEO_UPLOAD_PERSONAL_SUCCESS = 'VIDEO_UPLOAD_PERSONAL_SUCCESS'
export const VIDEO_UPLOAD_PERSONAL_RESET = 'VIDEO_UPLOAD_PERSONAL_RESET'
export const VIDEO_UPLOAD_PERSONAL_FAIL = 'VIDEO_UPLOAD_PERSONAL_FAIL'

export const VIDEO_UPLOAD_BODYBUILDER_REQUEST = 'VIDEO_UPLOAD_BODYBUILDER_REQUEST'
export const VIDEO_UPLOAD_BODYBUILDER_SUCCESS = 'VIDEO_UPLOAD_BODYBUILDER_SUCCESS'
export const VIDEO_UPLOAD_BODYBUILDER_RESET = 'VIDEO_UPLOAD_BODYBUILDER_RESET'
export const VIDEO_UPLOAD_BODYBUILDER_FAIL = 'VIDEO_UPLOAD_BODYBUILDER_FAIL'



