import { useMemo } from "react";
import api from "../services/axios";
import { useApiConfig } from "../hooks/useApiConfig";
import { useQuery } from "@tanstack/react-query";

export const useReadPatientDetailsQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readPatientDetailsQuery = useQuery({
    queryKey: ["read-patient-details-query", enabled, params],
    queryFn: async () => {
      const { id } = params;

      return api.get(`/api/profiles/pacient/read/${id}/`, config);
    },
    enabled,
  });

  const patientDetails = useMemo(
    () => readPatientDetailsQuery.data?.data,
    [readPatientDetailsQuery.data?.data]
  );

  return { patientDetails, readPatientDetailsQuery };
};
