import {
    EXERCISE_LIST_REQUEST,
    EXERCISE_LIST_SUCCESS,
    EXERCISE_LIST_FAIL,

    MUSCLE_LIST_REQUEST,
    MUSCLE_LIST_SUCCESS,
    MUSCLE_LIST_FAIL,

    EXERCISE_DETAILS_REQUEST,
    EXERCISE_DETAILS_SUCCESS,
    EXERCISE_DETAILS_FAIL,

    EXERCISE_DELETE_REQUEST,
    EXERCISE_DELETE_SUCCESS,
    EXERCISE_DELETE_FAIL,
    EXERCISE_DELETE_RESET,

    EXERCISE_CREATE_REQUEST,
    EXERCISE_CREATE_SUCCESS,
    EXERCISE_CREATE_FAIL,
    EXERCISE_CREATE_RESET,

    EXERCISE_UPDATE_REQUEST,
    EXERCISE_UPDATE_SUCCESS,
    EXERCISE_UPDATE_FAIL,
    EXERCISE_UPDATE_RESET,

    EXERCISE_SAVE_DATA,
    EXERCISE_SAVE_INDICATIONS,
    EXERCISE_SAVE_RESULTS,

    EXERCISE_IMAGE_CREATE_REQUEST,
    EXERCISE_IMAGE_CREATE_SUCCESS,
    EXERCISE_IMAGE_CREATE_FAIL,

    EXERCISE_VIDEO_CREATE_REQUEST,
    EXERCISE_VIDEO_CREATE_SUCCESS,
    EXERCISE_VIDEO_CREATE_FAIL,

    EXERCISE_PATTER_LIST_REQUEST,
    EXERCISE_PATTER_SUCCESS,
    EXERCISE_PATTER_FAIL,

    EXERCISE_RESET
} from '../constants/exerciseConstants'


export const exerciseListReducer = (state = { exercises: [] }, action) => {
    switch (action.type) {
        case EXERCISE_LIST_REQUEST:
            return { loading: true, exercises: [] }

        case EXERCISE_LIST_SUCCESS:
            return {
                loading: false,
                exercises: action.payload.data,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case EXERCISE_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const exercisePatterReducer = (state = { exercisesPatter: [] }, action) => {
    switch (action.type) {
        case EXERCISE_PATTER_LIST_REQUEST:
            return { loadingPatter: true, exercisesPatter: [] }

        case EXERCISE_PATTER_SUCCESS:
            return {
                loadingPatter: false,
                exercisesPatter: action.payload,
            }

        case EXERCISE_PATTER_FAIL:
            return { loadingPatter: false, errorPatter: action.payload }

        default:
            return state
    }
}



export const exerciseDetailsReducer = (state = { exercise: { reviews: [] } }, action) => {
    switch (action.type) {
        case EXERCISE_DETAILS_REQUEST:
            return { loading: true, ...state }

        case EXERCISE_DETAILS_SUCCESS:
            return { loading: false, exercise: action.payload }

        case EXERCISE_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const exerciseDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case EXERCISE_DELETE_REQUEST:
            return { loading: true }

        case EXERCISE_DELETE_SUCCESS:
            return { loading: false, deleteExerciseSuccess: true }

        case EXERCISE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        case EXERCISE_DELETE_RESET:
            return []

        default:
            return state
    }
}


export const exerciseCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case EXERCISE_CREATE_REQUEST:
            return { loadingExercise: true }

        case EXERCISE_CREATE_SUCCESS:
            return { loadingExercise: false, createExerciseSucess: true, payload: action.payload }

        case EXERCISE_CREATE_FAIL:
            return { loadingExercise: false, error: action.payload }

        case EXERCISE_CREATE_RESET:
            return { exercise: {} }

        default:
            return state
    }
}

export const exerciseImageCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case EXERCISE_IMAGE_CREATE_REQUEST:
            return { loadingImage: true }

        case EXERCISE_IMAGE_CREATE_SUCCESS:
            return { loadingImage: false, successimage: true, image: action.payload }

        case EXERCISE_IMAGE_CREATE_FAIL:
            return { loadingImage: false, error: action.payload }

        default:
            return state
    }
}

export const exerciseVideoCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case EXERCISE_VIDEO_CREATE_REQUEST:
            return { loadingVideo: true }

        case EXERCISE_VIDEO_CREATE_SUCCESS:
            return { loadingVideo: false, successVideo: true, video: action.payload }

        case EXERCISE_VIDEO_CREATE_FAIL:
            return { loadingVideo: false, error: action.payload }

        case EXERCISE_CREATE_RESET:
            return { video: {}, loadingVideo: false }


        default:
            return state
    }
}



export const exerciseUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case EXERCISE_UPDATE_REQUEST:
        return { loading: true };
  
      case EXERCISE_UPDATE_SUCCESS:
        return { loading: false, success: true, exercise: action.payload };
  
      case EXERCISE_UPDATE_FAIL:
        return { loading: false, error: action.payload };
  
      case EXERCISE_CREATE_RESET:
        return {};
  
      default:
        return state;
    }
  };
  

export const muscleListReducer = (state = { muscles: [] }, action) => {
    switch (action.type) {
        case MUSCLE_LIST_REQUEST:
            return { loading: true, muscles: [] }

        case MUSCLE_LIST_SUCCESS:
            return { loading: false, muscles: action.payload.muscles, }

        case MUSCLE_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const exerciseReducer = (state = { exerciseData: {}, exerciseIndications: {} }, action) => {
    switch (action.type) {

        case EXERCISE_SAVE_DATA:
            return {
                ...state,
                exerciseData: action.payload
            }

        case EXERCISE_SAVE_INDICATIONS:
            return {
                ...state,
                exerciseIndications: action.payload
            }

        case EXERCISE_SAVE_RESULTS:
            return {
                ...state,
                exerciseResults: action.payload
            }

        case "LOAD_IMAGE":
            return {
                ...state,
                exerciseImage: action.payload
            }

        case "LOAD_VIDEO":
            return {
                ...state,
                exerciseVideo: action.payload
            }

        case EXERCISE_RESET:
            return {
                exerciseData: {},
                exerciseIndications: {},
                exerciseResults: {},
                exerciseImage: {},
                exerciseVideo: {}
            }

        default:
            return state
    }
}
