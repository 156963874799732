import { React, useEffect, useState } from "react";
import {
  Card,
  Image,
  InputGroup,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { favoriteExercise } from "../../../actions/exerciseActions";

import thumbPng from "../../../assets/v2/img/programs/card-image.jpg";
import { PatientExerciseExecution } from "../PatientExerciseExecution";
import { setExerciseExecution } from "../../../actions/exerciseDoneActions";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { PROFESSIONAL_ROLE } from "../../../constants/rolesConstants";

function TrainingCardV2({
  exercise,
  img,
  link,
  handleSelectExercise,
  clear,
  checkbox = true,
  execute = false,
  withFavorite = true,
  workoutId = undefined,
  data,
}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const handleShow = () => setShowModal(true);
  const workoutsCreate = useSelector((state) => state.workoutsCreate);
  const { loadingWorkoutCreate } = workoutsCreate;
  const { userRole } = useUserInfo();

  const [isFavorite, setIsFavorite] = useState(exercise.isFavorite);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleFavorite = (exercise) => {
    setIsFavorite(!isFavorite);
    dispatch(favoriteExercise({ ...exercise, isFavorite: !isFavorite }));
  };

  const handleDragStart = (e) => {
    exercise.dragType = "exercise";
    e.dataTransfer.setData("text/plain", JSON.stringify(exercise));
  };

  const handleOnDrag = (e) => {
    if (!e.clientY) return;

    if (e.clientY < 100) {
      window.scroll({ top: e?.pageY - 250 });
    }

    if (e.clientY > window.innerHeight - 100) {
      window.scroll({ top: e.pageY + 100 });
    }
  };

  useEffect(() => {
    if (loadingWorkoutCreate) {
      clear();
    }
  }, [loadingWorkoutCreate]);

  const thumb = exercise?.exerciseVideo?.video;

  const route =
    userRole === PROFESSIONAL_ROLE
      ? `/home/personal/exercises/${exercise?._id}`
      : `/home/pacient/exercises/${exercise?._id}`;

  let description = exercise?.description ?? "";

  description =
    description.length > 100
      ? description.substring(0, 100) + "..."
      : description;

  return (
    <Card
      className="h-100 m-0 p-0 training-card rounded-5 overflow-hidden w-100 bg-body-secondary"
      draggable={true}
      onDragStart={handleDragStart}
      onDrag={handleOnDrag}
    >
      <div className="img-container text-decoration-none p-0">
        {thumb ? (
          <video
            className="w-100 h-100 bg-black"
            style={{ maxHeight: 250 }}
            controls={true}
          >
            <source src={thumb + "#t=0.1"} />
          </video>
        ) : (
          <Image
            src={thumbPng}
            className="w-100 object-fit-contain"
            style={{ maxHeight: 250 }}
          />
        )}
      </div>
      <Card.Body className="px-4 mb-0 pb-0 d-flex flex-column">
        <Link to={route} className="text-decoration-none p-0">
          <h3 className="text-dark text-decoration-none fs-3 text-capitalize">
            {exercise?.name}
          </h3>
        </Link>
        <p className="text-dark">{description}</p>
        <div style={{ flex: 1 }} className=""></div>
        {!execute && (
          <Card.Footer className="d-flex justify-content-between align-items-center border-0 bg-transparent p-0">
            {withFavorite && (
              <Image
                className={`pointer ${isFavorite ? "" : "gray-filter"}`}
                src="assets/v2/icons/star.svg"
                onClick={(e) => handleFavorite(exercise)}
                draggable={false}
              />
            )}
            {checkbox && (
              <InputGroup.Checkbox
                onClick={(e) =>
                  handleSelectExercise(exercise?._id, e.target.checked)
                }
                aria-label="Select exercise"
                {...(loadingWorkoutCreate && { checked: false })}
              />
            )}
          </Card.Footer>
        )}

        <div className="mb-4"></div>

        {execute && (
          <Card.Footer className="d-flex bg-transparent justify-content-end border-0 p-0 mb-4">
            <Button onClick={handleShow}>Executar</Button>
          </Card.Footer>
        )}
      </Card.Body>
      {showModal && (
        <PatientExerciseExecution
          showModal={showModal}
          handleClose={handleClose}
          exercise={exercise}
          workoutId={workoutId}
          data={data}
        ></PatientExerciseExecution>
      )}
    </Card>
  );
}

export default connect((state) => ({ workoutsCreate: state.workoutsCreate }))(
  TrainingCardV2
);
