import { useMemo } from "react";
import api from "../services/axios";
import { useApiConfig } from "../hooks/useApiConfig";
import { useQuery } from "@tanstack/react-query";

export const useReadPaymentCoupomQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readCoupomQuery = useQuery({
    queryKey: ["read-coupom-query", enabled, params],
    queryFn: async () => {
      const { id, coupom } = params;

      return api.get(
        `/api/payment/discount/read/${id}/?code=${coupom}`,
        config
      );
    },
    enabled,
  });

  const coupom = useMemo(
    () => readCoupomQuery.data?.data,
    [readCoupomQuery.data?.data]
  );

  return { coupom, readCoupomQuery };
};

export const useReadBillsQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readBillsQuery = useQuery({
    queryKey: ["read-bills-query", enabled, params],
    queryFn: async () => {
      config.params = params;

      return api.get(`/api/payment/bills/readall`, config);
    },
    enabled,
  });

  const { bills, page, pages } = useMemo(() => {
    const data = readBillsQuery.data?.data;

    const bills = data?.data ?? [];
    const page = data?.page ?? 1;
    const pages = data?.pages ?? 1;

    return { bills, page, pages };
  }, [readBillsQuery.data?.data]);

  return { bills, readBillsQuery, page, pages };
};
