import { useDispatch, useSelector } from "react-redux";
import useFormReducer from "./FormReducer";
import { useEffect } from "react";
import {
  detailsMedicalRecord,
  registerMedicalRecords,
  updateMedicalRecords,
} from "../../../actions/medicalRecordsActions";
import { sanitizeFormData } from "../../../utils/sanitizeFormData";
import medicalRecord from "../../../assets/v2/user-medical-record-update-component.css";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { respStyle } from "../../../utils/respStyle";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { usePrintPage } from "../../../hooks/usePrintPage";

import { PrintButton } from "../../PrintButton";
import { getMedicalRecordPrint } from "../../print/getMedicalRecordPrint";

import download from "../../../assets/v2/icons/download.png";

function UserMedicalRecordUpdateV2({
  onClose,
  pacientId,
  medicalRecordId,
  pacienteData,
}) {
  const { state, dispatch } = useFormReducer();
  const dispatchRedux = useDispatch();

  const mediaValues = useMediaQueryValues();
  const { isLG, isMD } = useMediaQueryValues();

  const { isPatient, userDetails } = useUserInfo();

  const {
    loading: loadingMedicalRecord,
    error: errorMedicalRecord,
    medical_record,
  } = useSelector((state) => state.medicalRecordDetails);

  useEffect(() => {
    dispatch({ type: "CLEAR_FIELDS", payload: null });
    if (medicalRecordId) {
      dispatchRedux(detailsMedicalRecord(medicalRecordId));
    }
  }, []);

  useEffect(() => {
    if (medical_record && medicalRecordId) {
      dispatch({ type: "SET_STATE_FROM_API", payload: medical_record });
    }
  }, [loadingMedicalRecord, errorMedicalRecord, medical_record]);

  const handleChange = (nameField, value) => {
    dispatch({ type: nameField, payload: value });
  };

  const handleOnClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const { printDocument, document } = usePrintPage({
    title: "Prontuário",
    filename: "prontuário",
    patient: pacienteData,
    bodyContent: getMedicalRecordPrint({ text: state?.notes }),
    professional: userDetails,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Dados do formulário:", pacientId, state);
    state.number = 1;

    if (medicalRecordId) {
      dispatchRedux(updateMedicalRecords(sanitizeFormData(state)));
    } else {
      dispatchRedux(registerMedicalRecords(sanitizeFormData(state), pacientId));
    }
    handleOnClose();
  };

  return (
    <>
      <style src={medicalRecord}></style>
      <form
        className="user-medical-record-update-component-root p-1"
        onSubmit={handleSubmit}
      >
        <div className="d-flex justify-content-between">
          <div className="title">Prontuário</div>
          <div className="title-action">
            <PrintButton
              icon={download}
              onClick={() => printDocument()}
            ></PrintButton>

            <img
              alt="close icon"
              onClick={handleOnClose}
              src="/assets/v2/icons/x-square.png"
            />
          </div>
        </div>

        <div className="row m-0">
          <div className="col-12 col-md-3 p-0 m-0 mb-2 mb-md-0">
            <img
              className="user-profile-img rounded-2 col-12 m-0 mt-2 mb-2 w-100"
              alt="img src"
              src={
                pacienteData?.profileImage?.image ??
                pacienteData?.userImage?.image ??
                "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              }
              style={{ objectFit: "contain" }}
            />

            <div className="w-100 d-flex justify-content-center">
              <label className="pacient-name p-0 m-0">
                {pacienteData?.name}
              </label>
            </div>
          </div>

          <div className="col-12 col-md-9">
            <div className="d-flex flex-wrap m-0 p-0 pe-lg-5 ps-4">
              <div
                className={
                  "pacient-item d-flex flex-row justify-content-start align-items-center col-6 m-0 p-0 col-md-6 col-lg-4 mt-2 " +
                  respStyle({ lg: "justify-content-start" }, mediaValues)
                }
              >
                <img alt="pessoa" src="assets/v2/icons/person.svg" />
                <div className="ms-2">
                  {pacienteData?.gender == "F" ? "Feminino" : ""}
                  {pacienteData?.gender == "M" ? "Masculino" : ""}
                  {pacienteData?.gender == "N" ? "Não declarado" : ""}
                </div>
              </div>

              <div
                className={
                  "pacient-item d-flex flex-row justify-content-start align-items-center col-6 col-md-6 col-lg-4 m-0 p-0  mt-2" +
                  respStyle({ lg: "justify-content-center" }, mediaValues)
                }
              >
                <img alt="pessoa" src="assets/v2/icons/cake.svg" />
                <div className="ms-2">
                  {pacienteData?.birthDate?.split("-").reverse().join("/")}
                </div>
              </div>

              <div
                className={
                  "pacient-item d-flex flex-row justify-content-start align-items-center col-6 col-lg-4 m-0 p-0 mt-2 mt-md-4 mt-lg-2" +
                  respStyle({ lg: "justify-content-end" }, mediaValues)
                }
              >
                <img alt="pessoa" src="assets/v2/icons/phone.svg" />
                <div className="ms-2">{pacienteData?.phone}</div>
              </div>

              <div className="pacient-item d-flex flex-row  justify-content-start  align-items-center col-12 col-lg-6  m-0 p-0 mt-4">
                <img alt="pessoa" src="assets/v2/icons/location.svg" />
                <div className="ms-2">{pacienteData?.address}</div>
              </div>

              <div
                className={
                  "pacient-item d-flex flex-row justify-content-start align-items-center col-12 m-0 mt-4 p-0" +
                  respStyle({ lg: "" }, mediaValues)
                }
              >
                <img alt="pessoa" src="assets/v2/icons/message.svg" />
                <div className="ms-2">{pacienteData?.email}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3 page-user-input-container">
          <div className="page-user-input">
            {!isPatient && <label>Escrever prontuário</label>}
            <textarea
              value={state.notes}
              rows={6}
              onChange={(e) => handleChange("SET_NOTES", e.target.value)}
              required
              disabled={isPatient}
            />
            {state.errors?.name && <div>Campo inválido</div>}
          </div>
        </div>

        {!isPatient && (
          <div className="page-btn-container">
            <button className="page-btn-submit" type="submit">
              Salvar
            </button>
          </div>
        )}
      </form>
      {document}
    </>
  );
}

export default UserMedicalRecordUpdateV2;
