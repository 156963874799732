import React, { useRef } from "react";
import { Form, Col, Row, Card, Button } from "react-bootstrap";
import { useState } from "react";

import { useUserInfo } from "../../../hooks/useUserInfo";
import { PaymentForm } from "./PaymentForm";
function PurchaseV2({ plan, managePlans, onPayFinish = () => {} }) {
  const userInfo = useUserInfo();

  const [option, setOption] = useState("is_monthly");
  const isMonthly = option === "is_monthly";

  const planId = isMonthly ? plan.ids[0] : plan.ids[1];
  const planPrice = isMonthly ? plan.value : plan.valueYear;

  const [renderInfo, setRenderInfo] = useState(true);

  const renderPaymentTimeCard = ({ time, price, type, date }) => (
    <Card className="col-12 rounded-4 border-0 plan-value">
      <Card.Body className="d-flex justify-content-between">
        <Form.Check
          type="radio"
          id={`recurrence`}
          name="recurrence"
          className="d-flex align-items-center ms-1 me-2"
          onClick={() => setOption(type)}
          value={type}
          checked={option == type}
        />
        <div className="me-2">
          <p className="fw-bold fs-4 m-0">Plano {time}</p>
          <p className="text-secondary">Valido até XX/XX/XX</p>
        </div>
        <p>
          <span className="fw-bold text-primary">R$ {price}</span> /
          {time.toLowerCase()}
        </p>
      </Card.Body>
    </Card>
  );

  const goBack = () => {
    managePlans(0);
    setRenderInfo(true);
  };

  return (
    <section>
      <div>
        {renderInfo && (
          <Row className="flex-row m-0">
            <p className="fw-bold fs-3">Suas compras</p>
            <Row className="m-0 p-0">
              <div className="col-12 col-md-6 col-xl-auto p-0 mb-2 mb-md-0">
                {renderPaymentTimeCard({
                  time: "Mensal",
                  price: plan.value,
                  type: "is_monthly",
                })}
              </div>

              <div className="col-12 col-md-6 col-xl-auto p-0 ps-md-2">
                {renderPaymentTimeCard({
                  time: "Anual",
                  price: plan.valueYear,
                  type: "is_yearly",
                })}
              </div>
            </Row>
          </Row>
        )}

        {renderInfo && (
          <Row className="flex-row m-0">
            <p className="fw-bold fs-3 mt-3">Descrição</p>

            <div className="card border-primary p-0 overflow-hidden border-2">
              <div className="card-header bg-primary pt-5 px-lg-5 d-flex justify-content-between">
                <h4 className="text-white">{plan.title}</h4>
                <h4 className="text-white text-capitalize">
                  {option == "is_yearly" ? "anual" : "mensal"}
                </h4>
              </div>
              <div
                className={`bg-primary card-body text-primary px-lg-5 d-flex flex-column justify-content-between`}
              >
                <ul className="list-unstyled plan-items row">
                  {plan.features}
                </ul>
              </div>
            </div>
          </Row>
        )}

        <PaymentForm
          plan={plan}
          isMonthly={isMonthly}
          onPayFinish={onPayFinish}
          planId={planId}
          planPrice={planPrice}
          goBack={goBack}
          onConfirm={() => setRenderInfo(false)}
        ></PaymentForm>
      </div>
    </section>
  );
}

export default PurchaseV2;
