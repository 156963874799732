import { useMemo } from "react";
import api from "../services/api";
import { useQuery } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";

const key1 = "read-all-form-model";
export const useReadAllPatientFormModelQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readAllPatientFormModelQuery = useQuery({
    queryKey: [key1, params, enabled],
    queryFn: async () => {
      return api.get(
        `/api/profiles/form/model/readall/${params.patientId}/`,
        config
      );
    },
    enabled,
  });

  const allPatientFormsModel = useMemo(() => {
    return readAllPatientFormModelQuery.data?.data ?? [];
  }, [readAllPatientFormModelQuery.data?.data]);

  return { allPatientFormsModel, readAllPatientFormModelQuery };
};
useReadAllPatientFormModelQuery.key = key1;

const key2 = "read-all-form-model";
export const useReadAllFilledPatientFormQuery = ({
  enabled = true,
  params,
}) => {
  const config = useApiConfig();

  const readAllFilledPatientFormQuery = useQuery({
    queryKey: [key2, params, enabled],
    queryFn: async () => {
      return api.get(
        `/api/profiles/form/model/read/${params.patientId}/${params.formId}/`,
        config
      );
    },
    enabled,
  });

  const allFilledPatientForms = useMemo(() => {
    return readAllFilledPatientFormQuery.data?.data ?? [];
  }, [readAllFilledPatientFormQuery.data?.data]);

  return { allFilledPatientForms, readAllFilledPatientFormQuery };
};
useReadAllFilledPatientFormQuery.key = key2;

const key3 = "read-all-questions-form";
export const useReadAllQuestionsPatientFormQuery = ({
  enabled = true,
  params,
}) => {
  const config = useApiConfig();

  const readAllQuestionsPatientFormQuery = useQuery({
    queryKey: [key3, params, enabled],
    queryFn: async () => {
      return api.get(
        `/api/profiles/form/question/readall/${params.formModelId}/`,
        config
      );
    },
    enabled,
  });

  const allQuestions = useMemo(() => {
    return (
      readAllQuestionsPatientFormQuery.data?.data?.questions?.map((i) => ({
        ...i.question,
        choices: i.choices ?? [],
      })) ?? []
    );
  }, [readAllQuestionsPatientFormQuery.data?.data]);

  return { allQuestions, readAllQuestionsPatientFormQuery };
};
useReadAllQuestionsPatientFormQuery.key = key3;

const key4 = "read-all-answers-form";
export const useReadAllAnswersPatientFormQuery = ({
  enabled = true,
  params,
}) => {
  const config = useApiConfig();

  const readAllAnswerPatientFormQuery = useQuery({
    queryKey: [key4, params, enabled],
    queryFn: async () => {
      return api.get(
        `/api/profiles/form/answer/readall/${params.filledPatientFormId}/`,
        config
      );
    },
    enabled,
  });

  const allQuestionAnswers = useMemo(() => {
    return readAllAnswerPatientFormQuery.data?.data?.questions ?? [];
  }, [readAllAnswerPatientFormQuery.data?.data]);

  return { allQuestionAnswers, readAllAnswerPatientFormQuery };
};
useReadAllAnswersPatientFormQuery.key = key4;
