import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import {
  useReadAllFilledEvaluationQuery,
  useReadAllQuestionsEvaluationQuery,
} from "../../../../queries/evaluation.query";
import { useQueryClient } from "@tanstack/react-query";
import { SpinnerButton } from "../../../../components/v2/SpinnerButton";
import { QuestionInput } from "./QuestionInput";
import { useEvaluationMutation } from "../../../../mutations/evaluation.mutation";
import { getQuestionsAnswers } from "../../../../utils/question.utils";

export function CreateFilledEvaluation({
  evaluationModel,
  patientId,
  onClose = () => {},
}) {
  const { allQuestions, readAllQuestionsEvaluationQuery } =
    useReadAllQuestionsEvaluationQuery({
      enabled: true,
      params: { evaluationModelId: evaluationModel.data._id },
    });

  const {
    createFilledEvaluationMutation,
    createAnswerEvaluationMutation,
    uploadEvaluationImage,
  } = useEvaluationMutation();

  const [formData, setFormData] = useState({ name: "" });

  const updateFormData = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const updateFormQuestion = (e, value) => {
    setFormData({
      ...formData,
      [e.target.name]: { value, type: e.question.type, id: e.question.id },
    });
  };

  const queryClient = useQueryClient();

  const getQuestionKey = (q) => `question${q.id}`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, ...questions } = formData;

    const questionsAnswers = await getQuestionsAnswers({
      questions: Object.values(questions),
      uploadImageMutation: uploadEvaluationImage,
    });

    const response = await createFilledEvaluationMutation.mutateAsync({
      patientId,
      evaluationModelId: evaluationModel.data._id,
      name,
    });

    const patientEvaluationId = response.data._id;

    await createAnswerEvaluationMutation.mutateAsync({
      patientEvaluationId,
      patientId,
      name,
      questionsAnswers, // [{question_id: 118, answerText: 'sdasd'}]
    });

    queryClient.refetchQueries({
      queryKey: [useReadAllFilledEvaluationQuery.key],
    });

    onClose();
  };

  const isLoading =
    readAllQuestionsEvaluationQuery.isFetching ||
    createFilledEvaluationMutation.isPending ||
    createAnswerEvaluationMutation.isPending ||
    uploadEvaluationImage.isPending;

  useEffect(() => {
    const initialData = {};

    allQuestions.map((q) => {
      initialData[getQuestionKey(q)] = {
        value: null,
        type: q.type,
        id: q.id,
      };
    });

    setFormData({
      ...formData,
      ...initialData,
    });
  }, [allQuestions]);

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <ModalHeader
        title={"Adicionar Avaliação"}
        onClose={onClose}
      ></ModalHeader>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Título</Form.Label>
            <Form.Control
              name="name"
              onChange={updateFormData}
              type="text"
              placeholder="Digite o Título"
              required
              value={formData["name"]}
            />
          </Form.Group>

          {allQuestions.map((q) => {
            const key = `question${q.id}`;
            return (
              <QuestionInput
                className="my-3"
                key={key}
                name={key}
                question={q}
                value={formData[key]?.value}
                onChange={updateFormQuestion}
              ></QuestionInput>
            );
          })}
        </Modal.Body>

        <Modal.Footer>
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary"
          >
            <SpinnerButton isLoading={isLoading}></SpinnerButton>
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
