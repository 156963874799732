import React, { useState } from "react";
import { useReadAllEvaluationModelQuery } from "../../../../queries/evaluation.query";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";

import trash from "../../../../assets/v2/icons/trash.svg";
import eye from "../../../../assets/v2/icons/eye.svg";
import { FilledEvaluationList } from "./FilledEvaluationList";
import { CreateEvaluationModel } from "./CreateEvaluationModel";
import { ViewEvaluationModel } from "./ViewEvaluationModel";
import { useEvaluationMutation } from "../../../../mutations/evaluation.mutation";
import { useUserInfo } from "../../../../hooks/useUserInfo";

export function EvaluationModelList({ patient }) {
  const { allEvaluationsModel, readAllEvaluationModelQuery } =
    useReadAllEvaluationModelQuery({
      enabled: patient?.id !== undefined,
      params: { patientId: patient?.id },
    });

  const { isProfessional, isPatient } = useUserInfo();

  const { deleteEvaluationModelMutation } = useEvaluationMutation();

  const [selectedEvaluationModel, setSelectedEvaluationModel] = useState();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [showEvaluationModel, setShowEvaluationModel] = useState(undefined);

  const tooltip = (
    <Tooltip id="tooltip" placement="top">
      <p className="mb-0">Ver Modelo</p>
    </Tooltip>
  );

  const handleDeleteClick = async (e, evModel) => {
    const response = await deleteEvaluationModelMutation.mutateAsync({
      evaluationModelId: evModel.data._id,
    });

    readAllEvaluationModelQuery.refetch();
  };

  return (
    <div className="pwl-root">
      <div className="pwl-header flex-between align-items-center">
        <h3 className="m-0">Avaliações</h3>

        {isProfessional && (
          <div className="add-button" onClick={() => setOpenCreateModal(true)}>
            <img alt="adicionar" src="assets/v2/icons/plus.svg" />
          </div>
        )}
      </div>

      <div className="d-flex flex-column">
        {allEvaluationsModel.map((ev, i) => {
          if (isPatient && ev.count === 0) return;

          return (
            <div key={i} className="d-flex p-1 align-items-center mb-3">
              <OverlayTrigger placement="top" overlay={tooltip}>
                <Image
                  width={30}
                  className="p-1 me-3 pointer"
                  onClick={() => setShowEvaluationModel(ev)}
                  src={eye}
                ></Image>
              </OverlayTrigger>

              <p
                className="mb-0 pointer"
                onClick={() => setSelectedEvaluationModel(ev)}
              >
                {ev.data.name}

                <span className="ms-2 mb-0 text-primary fw-bold">
                  ({ev.count})
                </span>
              </p>

              {!ev.data.isExercitium && isProfessional && (
                <div className="d-flex flex-grow-1 justify-content-end ">
                  <Image
                    width={30}
                    onClick={(e) => handleDeleteClick(e, ev)}
                    className="p-1 pointer"
                    src={trash}
                  ></Image>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {selectedEvaluationModel && (
        <FilledEvaluationList
          patient={patient}
          onClose={() => setSelectedEvaluationModel(undefined)}
          evaluationModel={selectedEvaluationModel}
        ></FilledEvaluationList>
      )}

      {openCreateModal && (
        <CreateEvaluationModel
          onClose={() => setOpenCreateModal(false)}
        ></CreateEvaluationModel>
      )}

      {showEvaluationModel && (
        <ViewEvaluationModel
          evaluationModel={showEvaluationModel}
          onClose={() => setShowEvaluationModel(undefined)}
        ></ViewEvaluationModel>
      )}
    </div>
  );
}
