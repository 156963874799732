
export default function messageTranslation(message) {
  const translations = {
    'No active account found with the given credentials':  'Usuário não encontrado',
    'students': 'meus alunos',
    'actives': 'ativos',
    'archiveds': 'arquivados',
    'New Student': 'Novo aluno'
  };

  return translations[message] ? translations[message] : message;
}