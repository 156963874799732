import { useQuery } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";
import { useMemo } from "react";

export const useReadAllRoutineQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readAllRoutineQuery = useQuery({
    queryKey: ["read-all-routine-query", enabled, params],
    queryFn: async () => {
      return api.get(`/api/routines/readall/`, config);
    },
    enabled,
  });

  const allRoutines = useMemo(
    () => readAllRoutineQuery.data?.data ?? [],
    [readAllRoutineQuery.data?.data]
  );

  return { allRoutines, readAllRoutineQuery };
};

export const useReadRoutineDetailsQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readRoutineDetailsQuery = useQuery({
    queryKey: ["read-routine-details-query", enabled, params],
    queryFn: async () => {
      const { id } = params;
      return api.get(`/api/routines/read/${id}/`, config);
    },
    enabled,
  });

  const [routine, routineWorkouts, patientsRoutine] = useMemo(() => {
    if (readRoutineDetailsQuery.data?.data)
      return [
        readRoutineDetailsQuery.data?.data?.Rotina,
        readRoutineDetailsQuery.data?.data?.RotinaTreinos,
        readRoutineDetailsQuery.data?.data?.PacientesRotina,
      ];

    return [undefined, [], []];
  }, [readRoutineDetailsQuery.data?.data]);

  return { routine, patientsRoutine, routineWorkouts, readRoutineDetailsQuery };
};

export const useReadRoutinePatientDetailsQuery = ({
  enabled = true,
  params,
}) => {
  const config = useApiConfig();

  const readRoutinePatientDetailsQuery = useQuery({
    queryKey: ["read-routine-patient-details-query", enabled, params],
    queryFn: async () => {
      const { id } = params;
      return api.get(`/api/routines/pacient/read/${id}/`, config);
    },
    enabled,
  });

  const routinePatient = useMemo(
    () => readRoutinePatientDetailsQuery.data?.data,
    [readRoutinePatientDetailsQuery.data?.data]
  );

  return { routinePatient, readRoutinePatientDetailsQuery };
};

export const getSheetPatientDays = async (routinePatientId, config) => {
  const response = await api.get(
    `/api/routines/pacient/read/${routinePatientId}/`,
    config
  );

  const patientSheetDays = response.data.TreinosRotina.filter(
    (t) => t.sheetPatient !== null
  ).map((t) => ({
    routineWorkoutId: t._id ?? -1,
    days: t.sheetPatient?.days ?? t.days,
    sheetPatient: t.sheetPatient?._id ?? -1,
  }));

  const data = [patientSheetDays, response.data];

  return data;
};
