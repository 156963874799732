export const FEEDBACK_SOCKET_SOURCES = {
  PROFESSIONAL_START: "professional.start",
  PROFESSIONAL_BODYPOINTS: "professional.bodypoints",
  PROFESSIONAL_STOP: "professional.stop",
  PROFESSIONAL_RESULTPART: "professional.resultpart",
  PROFESSIONAL_RESULTFINAL: "professional.resultfinal",
  PROFESSIONAL_UPLOAD: "professional.upload",
  //
  GYMMEMBER_UPLOAD: "gymmember.upload",
  GYMMEMBER_STOP: "gymmember.stop",
  GYMMENBER_BODYPOINTS: "gymmember.bodypoints",
  GYMMENBER_START: "gymmember.start",
  GYMMENBER_RESULTPART: "gymmember.resultpart",
  GYMMENBER_RESULTFINAL: "gymmember.resultfinal",
};
