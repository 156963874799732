import { PrintBody } from "./PrintBody";
import { WithPageSection } from "./WithPageSection";
import React from "react";
export function getMedicalRecordPrint({ text }) {
  return [
    WithPageSection(
      <PrintBody title={"Prontuário"}>
        <p>{text}</p>
      </PrintBody>
    ),
  ];
}
