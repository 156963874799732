export const WORKOUTSHEET_CREATE_REQUEST = 'WORKOUTSHEET_CREATE_REQUEST'
export const WORKOUTSHEET_CREATE_SUCCESS = 'WORKOUTSHEET_CREATE_SUCCESS'
export const WORKOUTSHEET_CREATE_FAIL = 'WORKOUTSHEET_CREATE_FAIL'

export const WORKOUTSHEET_DETAILS_REQUEST = 'WORKOUTSHEET_DETAILS_REQUEST'
export const WORKOUTSHEET_DETAILS_SUCCESS = 'WORKOUTSHEET_DETAILS_SUCCESS'
export const WORKOUTSHEET_DETAILS_FAIL = 'WORKOUTSHEET_DETAILS_FAIL'
export const WORKOUTSHEET_DETAILS_RESET = 'WORKOUTSHEET_DETAILS_RESET'

export const WORKOUTSHEET_LIST_REQUEST = 'WORKOUTSHEET_LIST_REQUEST'
export const WORKOUTSHEET_LIST_SUCCESS = 'WORKOUTSHEET_LIST_SUCCESS'
export const WORKOUTSHEET_LIST_FAIL = 'WORKOUTSHEET_LIST_FAIL'
export const WORKOUTSHEET_LIST_RESET = 'WORKOUTSHEET_LIST_RESET'

export const WORKOUTSHEET_ITEM_DELETE_REQUEST = 'WORKOUTSHEET_ITEM_DELETE_REQUEST'
export const WORKOUTSHEET_ITEM_DELETE_SUCCESS = 'WORKOUTSHEET_DELETE_SUCCESS'
export const WORKOUTSHEET_ITEM_DELETE_FAIL = 'WORKOUTSHEET_DELETE_FAIL'

export const WORKOUTSHEET_UPDATE_REQUEST = 'WORKOUTSHEET_UPDATE_REQUEST'
export const WORKOUTSHEET_UPDATE_SUCCESS = 'WORKOUTSHEET_UPDATE_SUCCESS'
export const WORKOUTSHEET_UPDATE_FAIL = 'WORKOUTSHEET_UPDATE_FAIL'