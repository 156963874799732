export const MEDICAL_RECORDS_REGISTER_REQUEST = 'MEDICAL_RECORDS_REGISTER_REQUEST'
export const MEDICAL_RECORDS_REGISTER_SUCCESS = 'MEDICAL_RECORDS_REGISTER_SUCCESS'
export const MEDICAL_RECORDS_REGISTER_FAIL = 'MEDICAL_RECORDS_REGISTER_FAIL'
export const MEDICAL_RECORDS_REGISTER_RESET = 'MEDICAL_RECORDS_REGISTER_RESET'

export const MEDICAL_RECORDS_DETAILS_REQUEST = 'MEDICAL_RECORDS_DETAILS_REQUEST'
export const MEDICAL_RECORDS_DETAILS_SUCCESS = 'MEDICAL_RECORDS_DETAILS_SUCCESS'
export const MEDICAL_RECORDS_DETAILS_FAIL = 'MEDICAL_RECORDS_DETAILS_FAIL'
export const MEDICAL_RECORDS_DETAILS_RESET = 'MEDICAL_RECORDS_DETAILS_RESET'

export const MEDICAL_RECORDS_LIST_REQUEST = 'MEDICAL_RECORDS_LIST_REQUEST'
export const MEDICAL_RECORDS_LIST_SUCCESS = 'MEDICAL_RECORDS_LIST_SUCCESS'
export const MEDICAL_RECORDS_LIST_FAIL = 'MEDICAL_RECORDS_LIST_FAIL'
export const MEDICAL_RECORDS_LIST_RESET = 'MEDICAL_RECORDS_LIST_RESET'

export const MEDICAL_RECORDS_DELETE_REQUEST = 'MEDICAL_RECORDS_DELETE_REQUEST'
export const MEDICAL_RECORDS_DELETE_SUCCESS = 'MEDICAL_RECORDS_DELETE_SUCCESS'
export const MEDICAL_RECORDS_DELETE_FAIL = 'MEDICAL_RECORDS_DELETE_FAIL'
export const MEDICAL_RECORDS_DELETE_RESET = 'MEDICAL_RECORDS_DELETE_RESET'

export const MEDICAL_RECORDS_UPDATE_REQUEST = 'MEDICAL_RECORDS_UPDATE_REQUEST'
export const MEDICAL_RECORDS_UPDATE_SUCCESS = 'MEDICAL_RECORDS_UPDATE_SUCCESS'
export const MEDICAL_RECORDS_UPDATE_FAIL = 'MEDICAL_RECORDS_UPDATE_FAIL'
export const MEDICAL_RECORDS_UPDATE_RESET = 'MEDICAL_RECORDS_UPDATE_RESET'