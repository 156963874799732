import { Button, Col, Row } from "react-bootstrap";
import WorkoutSheetV2 from "../../../components/v2/WorkoutSheetV2";
import { useHistory } from "react-router-dom";
import TrainingCardV2 from "../../../components/v2/Cards/TrainingCardV2";
import { usePendentPostController } from "../../../controllers";

function WorkoutPacientDetailsV2({
  handleModal,
  unsetModalBody,
  exercises,
  startDate,
  finalDate,
  pace,
  weekDay,
}) {
  const history = useHistory();

  const { handleExecuteWorkoutClick, pendentPost, sheetPatient } =
    usePendentPostController({
      exercises,
      pace,
    });

  const handlePendentPostClick = (e) => {
    e.preventDefault();

    history.push(
      `/home/pacient/workoutsheet/execution/${pendentPost?.lastExercise?._id}/${pendentPost?._id}`
    );
    return;
  };

  const hasPendentForToday =
    sheetPatient && sheetPatient.days.includes(weekDay);

  return (
    <>
      {exercises.length > 0 && (
        <Row className="m-0 p-0">
          <Col className="col-12">
            <WorkoutSheetV2
              startDate={startDate}
              finalDate={finalDate}
              sheetRows={exercises}
              handleModal={handleModal}
              unsetModalBody={unsetModalBody}
              edit={false}
            />
          </Col>
          <Row className="m-0 p-0 mt-4 mb-2">
            <Col className="container-fluid">
              <h3 className="m-0">Exercícios</h3>
            </Col>

            <div className="p-md-0 pe-md-2 col-12 col-md-auto mb-1 mb-md-0">
              {hasPendentForToday && (
                <div
                  onClick={handlePendentPostClick}
                  className="btn col-12 col-md-auto float-end"
                  style={{ backgroundColor: "#cfe2ff" }}
                >
                  Treino Pendente
                </div>
              )}
            </div>

            <div className="p-md-0 col-12 col-md-auto">
              {!hasPendentForToday && (
                <Button
                  onClick={handleExecuteWorkoutClick}
                  className="col-12 col-md-auto float-end"
                >
                  Executar Treino
                </Button>
              )}
            </div>
          </Row>
          <Col className="col-12 d-flex">
            <Row className="w-100">
              {exercises?.map((exercise, i) => (
                <div key={i} className="col-12 col-md-6 col-xl-3 mb-4">
                  <TrainingCardV2
                    exercise={exercise.exercise}
                    img={"assets/v2/img/programs/card-image.jpg"}
                    checkbox={false}
                    execute={true}
                    withFavorite={false}
                    workoutId={exercise?.workoutSheet._id}
                    data={exercise}
                  />
                </div>
              ))}
            </Row>
          </Col>
        </Row>
      )}

      {!exercises.length && (
        <Col className="col-12">
          <p className="text-center mt-5">Não há treinos cadastrados.</p>
        </Col>
      )}
    </>
  );
}

export default WorkoutPacientDetailsV2;
