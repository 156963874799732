import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import { Button, Row, Spinner } from "react-bootstrap";
import Webcam from "react-webcam";

import {
  PoseLandmarker,
  FilesetResolver,
  DrawingUtils,
} from "@mediapipe/tasks-vision";
import * as cam from "@mediapipe/camera_utils";
import { useFeedbackStreamController } from "../../controllers/feedback-stream-controller";
import { useUserInfo } from "../../hooks/useUserInfo";
import {
  PATIENT_ROLE,
  PROFESSIONAL_ROLE,
} from "../../constants/rolesConstants";
import { respStyle } from "../../utils/respStyle";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { toast } from "react-toastify";
import i18n from "i18next";

function WebcamStreamCapture({
  uploaderHandler,
  muscle = "",
  onResultFinal = () => {},
  withFeedbackProcess = false,
  professionalFeedback = {},
}) {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const mediaValues = useMediaQueryValues();
  const { userRole } = useUserInfo();

  const firstRunRef = useRef(true);

  const videoDataRef = useRef();

  const isPatient = userRole === PATIENT_ROLE;

  const feedbackStreamController = useFeedbackStreamController({
    forceNewSocket: true,
  });

  const [currentMessage, setCurrentMessage] = useState("");

  const { isSocketOpen, status, loadingState, resultFinal, resultPart } =
    feedbackStreamController;

  const canvasRef = useRef(null);

  const predictWebcamRef = useRef(() => {});

  const [poseLandmarker, setPoseLandmarker] = useState(undefined);
  let runningMode = "VIDEO";

  let finaliza = false;
  let resultsOld = 0;
  let cont = 0;
  let lastVideoTime = -1;
  const camera = useRef(null);

  const handleUpload = (blob) => {
    if (blob) {
      videoDataRef.current = blob;

      if (withFeedbackProcess && (loadingState.resultFinal || status === "ok"))
        return;

      uploaderHandler(videoDataRef.current);
    }
  };

  const handleCancel = (error) => {
    if (error) {
      toast(error, {
        type: "error",
        position: "top-center",
      });
    }

    return uploaderHandler();
  };

  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      handleUpload(data);
    }
  };

  const handleStartCaptureClick = () => {
    setCapturing(true);

    if (MediaRecorder.isTypeSupported("video/webm; codecs=vp9")) {
      var options = { mimeType: "video/webm; codecs=vp9" };
    } else if (MediaRecorder.isTypeSupported("video/webm")) {
      var options = { mimeType: "video/webm" };
    } else if (MediaRecorder.isTypeSupported("video/mp4")) {
      var options = { mimeType: "video/mp4", videoBitsPerSecond: 100000 };
    } else {
      console.error("no suitable mimetype found for this device");
    }

    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current.stream,
      options
    );

    mediaRecorderRef.current.start();

    if (withFeedbackProcess)
      feedbackStreamController.startFeedback({
        muscle,
        isPatient,
        feedback: professionalFeedback,
      });
  };

  const handleStopCaptureClick = () => {
    camera.current?.stop();
    mediaRecorderRef.current?.stop();
    setCapturing(false);

    if (withFeedbackProcess)
      feedbackStreamController.stopFeedback({ isPatient });
  };

  const sendHumanPoints = async (results) => {
    if (!results || !withFeedbackProcess) return;
    if (loadingState.resultFinal) return;

    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;

    feedbackStreamController.sendBodypoints({
      imageHeight: videoHeight,
      imageWidth: videoWidth,
      results,
      isPatient,
    });
  };

  const predictWebcam = async () => {
    if (!poseLandmarker) {
      return;
    }

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement?.getContext("2d");

    if (!canvasCtx) return;

    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    if (runningMode === "IMAGE") {
      runningMode = "VIDEO";
      await poseLandmarker.setOptions({ runningMode: "VIDEO" });
    }
    let startTimeMs = performance.now();
    if (lastVideoTime !== webcamRef.currentTime) {
      lastVideoTime = webcamRef.current.video.currentTime;

      poseLandmarker.detectForVideo(
        webcamRef.current.video,
        startTimeMs,
        (result) => {
          canvasCtx.save();
          canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
          if (result.landmarks != undefined) {
            if (result.landmarks[0] != resultsOld && !finaliza) {
              sendHumanPoints(result.landmarks[0]);
            }
            resultsOld = result.landmarks[0];
          }

          canvasCtx.restore();
        }
      );
    }
  };

  const createPoseLandmarker = async () => {
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
    );
    let poseLand = await PoseLandmarker.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
        //modelAssetBuffer: 'pose_landmarker_lite.task',
        delegate: "GPU",
      },
      runningMode: runningMode,
      numPoses: 1,
      min_pose_detection_confidence: 0,
      min_pose_presence_confidence: 0,
      min_tracking_confidence: 0,
    });

    setPoseLandmarker(poseLand);
  };

  function setInitial() {
    cont = cont + 1;

    if (
      typeof webcamRef.current.video !== "undefined" &&
      webcamRef.current.video !== null &&
      !finaliza
    ) {
      camera.current = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          predictWebcamRef.current();
        },
        width: 640,
        height: 480,
      });
      camera.current.start();
    }
  }

  useEffect(() => {
    if (status === "ok") {
      setInitial();
    }
  }, [status]);

  useEffect(() => {
    if (!mediaRecorderRef.current) return;

    const func = handleDataAvailable;

    mediaRecorderRef.current?.addEventListener("dataavailable", func);

    return () => {
      mediaRecorderRef.current?.removeEventListener("dataavailable", func);
    };
  }, [mediaRecorderRef.current, handleDataAvailable]);

  useEffect(() => {
    if (resultFinal && capturing) {
      return handleCancel("Encerramento inesperado da análise de movimento!");
    }

    if (resultFinal) {
      onResultFinal(resultFinal);
      uploaderHandler(videoDataRef.current);
    }
  }, [resultFinal]);

  useEffect(() => {
    if (withFeedbackProcess) feedbackStreamController.connect();

    createPoseLandmarker();
    if (!withFeedbackProcess) setInitial();
  }, []);

  useEffect(() => {
    if (!firstRunRef.current && !isSocketOpen) {
      return handleCancel(
        "Falha na conexão com serviço de análise de movimento"
      );
    }

    firstRunRef.current = false;
  }, [isSocketOpen]);

  const buttonText = useMemo(() => {
    if (withFeedbackProcess) {
      if (!isSocketOpen) return "Iniciando conexão...";
      if (loadingState.resultFinal) return "Salvando dados...";
    }

    if (capturing) return "Parar";
    return "Gravar";
  }, [isSocketOpen, loadingState, capturing]);

  const buttonDisabled = withFeedbackProcess
    ? !isSocketOpen || loadingState.resultFinal
    : false;

  useEffect(() => {
    if (
      resultPart?.resultPointsHuman?.message &&
      resultPart?.resultPointsHuman?.message !== ""
    ) {
      setCurrentMessage(resultPart?.resultPointsHuman?.message);
    }
  }, [resultPart]);

  const { mainAngle, count } = useMemo(() => {
    const resultPointsHuman = resultPart?.resultPointsHuman;
    const mainAngle =
      resultPointsHuman?.mainAngle && resultPointsHuman?.mainAngle !== 0
        ? resultPart?.resultPointsHuman?.mainAngle
        : undefined;

    const count = resultPointsHuman?.count ?? 0;

    return { mainAngle, count };
  }, [resultPart]);

  predictWebcamRef.current = useMemo(() => predictWebcam, [predictWebcam]);

  useEffect(() => {
    let timeRef = undefined;

    if (capturing) {
      timeRef = setTimeout(() => {
        handleStopCaptureClick();
      }, (withFeedbackProcess ? 40 : 60) * 1000);

      return () => clearTimeout(timeRef);
    }
  }, [capturing]);

  useEffect(() => {
    if (currentMessage) {
      window.speechSynthesis.cancel();

      const voices = window.speechSynthesis.getVoices();

      const voice =
        i18n.language === "pt"
          ? voices.find((v) => v.lang === "pt-BR")
          : voices.find((v) => v.lang === "en-US");

      const ult = new SpeechSynthesisUtterance(currentMessage);
      ult.voice = voice;

      window.speechSynthesis.speak(ult);
    }
  }, [currentMessage]);

  // useEffect(() => {
  //   setCurrentMessage("mensagem fdsifjds fsdi jf" + Math.random());
  // }, [capturing]);

  useEffect(() => {
    if (!capturing) window.speechSynthesis.cancel();

    return () => {
      window.speechSynthesis.cancel();
    };
  }, [capturing]);

  return (
    <div
      className="position-fixed top-0 h-100 w-100 start-0 
      d-flex justify-content-center align-items-center flex-column 
      bg-black bg-opacity-50"
    >
      <Webcam
        className="w-100 h-100 rounded-4"
        audio={undefined}
        ref={webcamRef}
      />

      <Row className="position-absolute mb-5 bottom-0 d-flex justify-content-center mt-3">
        <Button
          disabled={buttonDisabled && isSocketOpen}
          className="fw-500 w-auto me-2"
          variant="primary"
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>
        <Button
          disabled={buttonDisabled}
          className="fw-500 w-auto"
          variant="primary"
          onClick={capturing ? handleStopCaptureClick : handleStartCaptureClick}
        >
          {buttonText}
        </Button>
      </Row>

      {loadingState.resultFinal && (
        <div
          style={{ zIndex: 30 }}
          className="bg-black position-absolute w-100 h-100 flex-center flex-column"
        >
          <div className="">
            <Spinner
              animation="border"
              role="status"
              className="text-white"
              style={{
                height: "30px",
                width: "30px",
              }}
            ></Spinner>
          </div>
          <p className="text-white">Processando Dados</p>
        </div>
      )}

      {withFeedbackProcess && (
        <div
          className={
            "position-absolute top-0 card col-12 col-md-6 col-lg-4 col-xl-3 mt-2" +
            respStyle({ md: "end-0 me-5 mt-5" }, mediaValues)
          }
        >
          <div className="card-body">
            <p className="fw-bold">Feedback</p>
            <p>{currentMessage}</p>
            {mainAngle && (
              <p>
                <span className="fw-bold">Ângulo Principal: </span> {mainAngle}
                <br></br>
                <span className="fw-bold">Contagem: </span> {count}
              </p>
            )}
          </div>
        </div>
      )}
      <canvas
        className="w-100 rounded-4 d-none"
        audio={false}
        ref={canvasRef}
      />
    </div>
  );
}

export default WebcamStreamCapture;
