import {
    WORKOUT_LIST_REQUEST,
    WORKOUT_LIST_SUCCESS,
    WORKOUT_LIST_FAIL,
    WORKOUT_LIST_RESET,

    WORKOUT_DELETE_REQUEST,
    WORKOUT_DELETE_SUCCESS,
    WORKOUT_DELETE_FAIL,
    WORKOUT_DELETE_RESET,

    WORKOUT_LIST_DETAILS_REQUEST,
    WORKOUT_LIST_DETAILS_SUCCESS,
    WORKOUT_LIST_DETAILS_FAIL,
    WORKOUT_LIST_DETAILS_RESET,

    WORKOUT_BODYBUILDER_LIST_REQUEST,
    WORKOUT_BODYBUILDER_LIST_SUCCESS,
    WORKOUT_BODYBUILDER_LIST_FAIL,
    WORKOUT_BODYBUILDER_LIST_RESET,

    WORKOUT_CREATE_REQUEST,
    WORKOUT_CREATE_SUCCESS,
    WORKOUT_CREATE_FAIL,
    WORKOUT_CREATE_RESET,

    WORKOUT_BODYBUILDER_CREATE_REQUEST,
    WORKOUT_BODYBUILDER_CREATE_SUCCESS,
    WORKOUT_BODYBUILDER_CREATE_FAIL,
    WORKOUT_BODYBUILDER_CREATE_RESET,

    WORKOUT_PATIENT_LIST_REQUEST,
    WORKOUT_PATIENT_LIST_SUCCESS,
    WORKOUT_PATIENT_LIST_FAIL,
    WORKOUT_PATIENT_LIST_RESET,

    WORKOUT_UPDATE_SUCCESS
} from '../constants/workoutConstants'

export const workoutListReducer = (state = { workoutList: [] }, action) => {
    switch (action.type) {
        case WORKOUT_LIST_REQUEST:
            return { loadingWorkoutList: true }

        case WORKOUT_LIST_SUCCESS:
            return { loadingWorkoutList: false, workoutList: action.payload }

        case WORKOUT_LIST_FAIL:
            return { loadingWorkoutList: false, errorWorkoutList: action.payload }

        case WORKOUT_LIST_RESET:
            return { workoutList: [] }

        default:
            return state
    }
}

export const workoutListDetailsReducer = (state = { workoutListDetails: [] }, action) => {
    switch (action.type) {
        case WORKOUT_LIST_DETAILS_REQUEST:
            return { loadingWorkoutListDetails: true }

        case WORKOUT_LIST_DETAILS_SUCCESS:
            return { loadingWorkoutListDetails: false, workoutListDetails: action.payload }

        case WORKOUT_LIST_DETAILS_FAIL:
            return { loadingWorkoutListDetails: false, errorWorkoutListDetails: action.payload }

        case WORKOUT_LIST_DETAILS_RESET:
            return { workoutListDetails: [] }

        default:
            return state
    }
}

export const workoutBodybuilderListReducer = (state = { workoutBodybuilderList: [] }, action) => {
    switch (action.type) {
        case WORKOUT_BODYBUILDER_LIST_REQUEST:
            return { loadingWorkoutBodybuilderList: true }

        case WORKOUT_BODYBUILDER_LIST_SUCCESS:
            return { loadingWorkoutBodybuilderList: false, workoutBodybuilderList: action.payload }

        case WORKOUT_BODYBUILDER_LIST_FAIL:
            return { loadingWorkoutBodybuilderList: false, errorWorkoutBodybuilderList: action.payload }

        case WORKOUT_BODYBUILDER_LIST_RESET:
            return { workoutBodybuilderList: [] }

        default:
            return state
    }
}

export const workoutCreateReducer = (state = { workoutCreate: [] }, action) => {
    switch (action.type) {
        case WORKOUT_CREATE_REQUEST:
            return { loadingWorkoutCreate: true }

        case WORKOUT_CREATE_SUCCESS:
            return { loadingWorkoutCreate: false, createWorkoutSuccess: true, workoutCreate: action.payload }

        case WORKOUT_CREATE_FAIL:
            return { loadingWorkoutCreate: false, errorWorkoutCreate: action.payload }

        case WORKOUT_CREATE_RESET:
            return { workoutCreate: [] }

        default:
            return state
    }
}

export const workoutDeleteReducer = (state = { workoutDelete: [] }, action) => {
    switch (action.type) {
        case WORKOUT_DELETE_REQUEST:
            return { loadingWorkoutDelete: true }

        case WORKOUT_DELETE_SUCCESS:
            return { loadingWorkoutDelete: false, deleteWorkoutSuccess: true, workoutDelete: action.payload }

        case WORKOUT_DELETE_FAIL:
            return { loadingWorkoutDelete: false, errorWorkoutDelete: action.payload }

        case WORKOUT_DELETE_RESET:
            return []

        default:
            return state
    }
}

export const workoutUpdateReducer = (state = { }, action) => {
    switch (action.type) {
        case WORKOUT_UPDATE_SUCCESS:
            return { loading: false, payload: action.payload }

        default:
            return state
    }
}

export const workoutCreateBodybuilderReducer = (state = { workoutCreateBodybuilder: [] }, action) => {
    switch (action.type) {
        case WORKOUT_BODYBUILDER_CREATE_REQUEST:
            return { loadingWorkoutCreateBodybuilder: true }

        case WORKOUT_BODYBUILDER_CREATE_SUCCESS:
            return { loadingWorkoutCreateBodybuilder: false, workoutCreateBodybuilder: action.payload }

        case WORKOUT_BODYBUILDER_CREATE_FAIL:
            return { loadingWorkoutCreateBodybuilder: false, errorWorkoutCreateBodybuilder: action.payload }

        case WORKOUT_BODYBUILDER_CREATE_RESET:
            return { workoutCreateBodybuilder: [] }

        default:
            return state
    }
}

export const workoutPatientListReducer = (state = { workoutListDetails: [] }, action) => {
    switch (action.type) {
        case WORKOUT_PATIENT_LIST_REQUEST:
            return { loadingWorkoutListDetails: true }

        case WORKOUT_PATIENT_LIST_SUCCESS:
            return { loadingWorkoutListDetails: false, workoutListDetails: action.payload }

        case WORKOUT_PATIENT_LIST_FAIL:
            return { loadingWorkoutListDetails: false, errorWorkoutListDetails: action.payload }

        case WORKOUT_PATIENT_LIST_RESET:
            return { workoutListDetails: [] }

        default:
            return state
    }
}