export const PACIENT_LOGIN_REQUEST = 'PACIENT_LOGIN_REQUEST'
export const PACIENT_LOGIN_SUCCESS = 'PACIENT_LOGIN_SUCCESS'
export const PACIENT_LOGIN_FAIL = 'PACIENT_LOGIN_FAIL'

export const PACIENT_LOGOUT = 'PACIENT_LOGOUT'

export const PACIENT_REGISTER_REQUEST = 'PACIENT_REGISTER_REQUEST'
export const PACIENT_REGISTER_SUCCESS = 'PACIENT_REGISTER_SUCCESS'
export const PACIENT_REGISTER_FAIL = 'PACIENT_REGISTER_FAIL'

export const PACIENT_DETAILS_REQUEST = 'PACIENT_DETAILS_REQUEST'
export const PACIENT_DETAILS_SUCCESS = 'PACIENT_DETAILS_SUCCESS'
export const PACIENT_DETAILS_FAIL = 'PACIENT_DETAILS_FAIL'
export const PACIENT_DETAILS_RESET = 'PACIENT_DETAILS_RESET'

export const PACIENT_UPDATE_PROFILE_REQUEST = 'PACIENT_UPDATE_PROFILE_REQUEST'
export const PACIENT_UPDATE_PROFILE_SUCCESS = 'PACIENT_UPDATE_PROFILE_SUCCESS'
export const PACIENT_UPDATE_PROFILE_FAIL = 'PACIENT_UPDATE_PROFILE_FAIL'
export const PACIENT_UPDATE_PROFILE_RESET = 'PACIENT_UPDATE_PROFILE_RESET'

export const PACIENT_LIST_REQUEST = 'PACIENT_LIST_REQUEST'
export const PACIENT_LIST_SUCCESS = 'PACIENT_LIST_SUCCESS'
export const PACIENT_LIST_FAIL = 'PACIENT_LIST_FAIL'
export const PACIENT_LIST_RESET = 'PACIENT_LIST_RESET'

export const PACIENT_DELETE_REQUEST = 'PACIENT_DELETE_REQUEST'
export const PACIENT_DELETE_SUCCESS = 'PACIENT_DELETE_SUCCESS'
export const PACIENT_DELETE_FAIL = 'PACIENT_DELETE_FAIL'

export const PACIENT_UPDATE_REQUEST = 'PACIENT_UPDATE_REQUEST'
export const PACIENT_UPDATE_SUCCESS = 'PACIENT_UPDATE_SUCCESS'
export const PACIENT_UPDATE_FAIL = 'PACIENT_UPDATE_FAIL'
export const PACIENT_UPDATE_RESET = 'PACIENT_UPDATE_RESET'

export const PACIENT_LIST_BODYBUILDERS_REQUEST = 'PACIENT_LIST_BODYBUILDERS_REQUEST'
export const PACIENT_LIST_BODYBUILDERS_SUCCESS = 'PACIENT_LIST_BODYBUILDERS_SUCCESS'
export const PACIENT_LIST_BODYBUILDERS_FAIL = 'PACIENT_LIST_BODYBUILDERS_FAIL'
export const PACIENT_LIST_BODYBUILDERS_RESET = 'PACIENT_LIST_BODYBUILDERS_RESET'

export const PACIENT_LIST_BODYBUILDERS_AVAILABLE_REQUEST = 'PACIENT_LIST_BODYBUILDERS_AVAILABLE_REQUEST'
export const PACIENT_LIST_BODYBUILDERS_AVAILABLE_SUCCESS = 'PACIENT_LIST_BODYBUILDERS_AVAILABLE_SUCCESS'
export const PACIENT_LIST_BODYBUILDERS_AVAILABLE_FAIL = 'PACIENT_LIST_BODYBUILDERS_AVAILABLE_FAIL'
export const PACIENT_LIST_BODYBUILDERS_AVAILABLE_RESET = 'PACIENT_LIST_BODYBUILDERS_AVAILABLE_RESET'

export const PACIENT_LIST_PERSONAL_REQUEST = 'PACIENT_LIST_PERSONAL_REQUEST'
export const PACIENT_LIST_PERSONAL_SUCCESS = 'PACIENT_LIST_PERSONAL_SUCCESS'
export const PACIENT_LIST_PERSONAL_FAIL = 'PACIENT_LIST_PERSONAL_FAIL'
export const PACIENT_LIST_PERSONAL_RESET = 'PACIENT_LIST_PERSONAL_RESET'

export const PACIENT_INVITATION_LIST_REQUEST = 'PACIENT_INVITATION_LIST_REQUEST'
export const PACIENT_INVITATION_LIST_SUCCESS = 'PACIENT_INVITATION_LIST_SUCCESS'
export const PACIENT_INVITATION_LIST_FAIL = 'PACIENT_INVITATION_LIST_FAIL'
export const PACIENT_INVITATION_LIST_RESET = 'PACIENT_INVITATION_LIST_RESET'

export const PACIENT_INVITATION_SEND_REQUEST = 'PACIENT_INVITATION_SEND_REQUEST'
export const PACIENT_INVITATION_SEND_SUCCESS = 'PACIENT_INVITATION_SEND_SUCCESS'
export const PACIENT_INVITATION_SEND_FAIL = 'PACIENT_INVITATION_SEND_FAIL'
export const PACIENT_INVITATION_SEND_RESET = 'PACIENT_INVITATION_SEND_RESET'

export const PACIENT_INVITATION_REPLY_REQUEST = 'PACIENT_INVITATION_REPLY_REQUEST'
export const PACIENT_INVITATION_REPLY_SUCCESS = 'PACIENT_INVITATION_REPLY_SUCCESS'
export const PACIENT_INVITATION_REPLY_FAIL = 'PACIENT_INVITATION_REPLY_FAIL'
export const PACIENT_INVITATION_REPLY_RESET = 'PACIENT_INVITATION_REPLY_RESET'

export const PACIENT_CREATE_REQUEST = 'PACIENT_CREATE_REQUEST'
export const PACIENT_CREATE_SUCCESS = 'PACIENT_CREATE_SUCCESS'
export const PACIENT_CREATE_FAIL = 'PACIENT_CREATE_FAIL'
export const PACIENT_CREATE_RESET = 'PACIENT_CREATE_RESET'

export const PACIENT_IMAGE_CREATE_REQUEST = 'PACIENT_IMAGE_CREATE_REQUEST'
export const PACIENT_IMAGE_CREATE_SUCCESS = 'PACIENT_IMAGE_CREATE_SUCCESS'
export const PACIENT_IMAGE_CREATE_FAIL = 'PACIENT_IMAGE_CREATE_FAIL'