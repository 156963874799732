export class SocketService {
  static instance;

  constructor() {}
  activeSockets = [];

  static getInstance() {
    if (!SocketService.instance) {
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  createSocket = (url, forceNewSocket) => {
    if (this.activeSockets.length === 0 || forceNewSocket)
      return this.addSocket(url);

    for (let i = 0; i < this.activeSockets.length; i++) {
      if (
        this.activeSockets[i].ws.url === url &&
        this.activeSockets[i].ws.readyState === 1
      ) {
        return this.activeSockets[i].ws;
      }
    }

    return this.addSocket(url);
  };

  addSocket = (url) => {
    const ws = new WebSocket(url);
    const id = Date.now();

    ws.addEventListener("close", this.onClose(id));
    this.activeSockets.push({ ws, id: Date.now() });

    return ws;
  };

  onClose = (id) => (e) => {
    this.activeSockets = this.activeSockets.filter((s) => s.id !== id);
  };
}
