import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { getWorkoutSheetPrint } from "../components/print/getWorkoutSheetPrint";
import { getMedicalRecordPrint } from "../components/print/getMedicalRecordPrint";
import { getEvaluationPrint } from "../components/print/getEvaluationPrint";
import { getPurchasePrint } from "../components/print/getPurchasePrint";

import { usePrintPage } from "../hooks/usePrintPage";

import api from "../services/api";

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5Njk5Nzg2LCJpYXQiOjE3MTcxMDc3ODYsImp0aSI6ImE5MDIzZmQ1ZTQxYzRmOWY4NDllYWVhNGFmYjUzYjQ1IiwidXNlcl9pZCI6MX0.5qACbqI8gNLL-T8-2iAAMscjC910meYECn9mGFkeqDI

export function GeneratePDFScreen() {
  const { search, pathname } = useLocation();

  const [token, dataType, patientId, dataId, dataName] = useMemo(() => {
    const params = new URLSearchParams(search);

    return [
      params.get("token"),
      params.get("dataType"),
      params.get("patientId"),
      params.get("dataId"),
      params.get("dataName"),
    ];
  }, [search]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  };

  const [printData, setPrintData] = useState({
    title: "",
    patient: null,
    subtitle: null,
    bodyContent: [],
    filename: "",
    professional: null,
  });

  const getPatientData = async () => {
    const { data: patient } = await api.get(
      `/api/profiles/pacient/read/${patientId}/`,
      {
        headers,
      }
    );

    return patient.paciente;
  };

  const getProfessionalData = async () => {
    const { data } = await api.get(`/api/users/details/read/`, {
      headers,
    });

    return data.data;
  };

  const setWorkoutSheetData = async () => {
    // http://localhost:3000/#/generate-pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5Njk5Nzg2LCJpYXQiOjE3MTcxMDc3ODYsImp0aSI6ImE5MDIzZmQ1ZTQxYzRmOWY4NDllYWVhNGFmYjUzYjQ1IiwidXNlcl9pZCI6MX0.5qACbqI8gNLL-T8-2iAAMscjC910meYECn9mGFkeqDI&dataType=workoutSheet&patientId=3&dataId=1

    const { data } = await api.get(`/api/workouts/read/${dataId}`, {
      headers,
    });

    const professional = await getProfessionalData();

    setPrintData({
      title: "Treino - " + data.treino.name,
      filename: "treino",
      bodyContent: getWorkoutSheetPrint({ workoutSheet: data.ExerciciosFicha }),
      professional,
    });
  };

  const setEvaluationData = async () => {
    // http://localhost:3000/#/generate-pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5Njk5Nzg2LCJpYXQiOjE3MTcxMDc3ODYsImp0aSI6ImE5MDIzZmQ1ZTQxYzRmOWY4NDllYWVhNGFmYjUzYjQ1IiwidXNlcl9pZCI6MX0.5qACbqI8gNLL-T8-2iAAMscjC910meYECn9mGFkeqDI&dataType=evaluation&patientId=3&dataId=67&dataName=asdasd

    const { data } = await api.get(
      `/api/profiles/evaluation/answer/readall/${dataId}/`,
      { headers }
    );

    const patient = await getPatientData();

    const professional = await getProfessionalData();

    setPrintData({
      title: "Avaliação",
      filename: "avaliacao",
      withProfiles: true,
      patient: patient,
      professional,
      bodyContent: getEvaluationPrint({
        name: dataName,
        questionsAnswers: data.questions ?? [],
      }),
    });
  };

  const setPatientFormData = async () => {
    // http://localhost:3000/#/generate-pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5Njk5Nzg2LCJpYXQiOjE3MTcxMDc3ODYsImp0aSI6ImE5MDIzZmQ1ZTQxYzRmOWY4NDllYWVhNGFmYjUzYjQ1IiwidXNlcl9pZCI6MX0.5qACbqI8gNLL-T8-2iAAMscjC910meYECn9mGFkeqDI&dataType=patientForm&patientId=71&dataId=55&dataName=asdasd

    const { data } = await api.get(
      `/api/profiles/form/answer/readall/${dataId}/`,
      { headers }
    );

    const patient = await getPatientData();

    const professional = await getProfessionalData();

    setPrintData({
      title: "Formulário",
      filename: "formulario",
      withProfiles: true,
      patient: patient,
      professional,
      bodyContent: getEvaluationPrint({
        name: dataName,
        questionsAnswers: data.questions ?? [],
      }),
    });
  };

  const setMedicalRecordData = async () => {
    // http://localhost:3000/#/generate-pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5Njk5Nzg2LCJpYXQiOjE3MTcxMDc3ODYsImp0aSI6ImE5MDIzZmQ1ZTQxYzRmOWY4NDllYWVhNGFmYjUzYjQ1IiwidXNlcl9pZCI6MX0.5qACbqI8gNLL-T8-2iAAMscjC910meYECn9mGFkeqDI&dataType=medicalRecord&patientId=3&dataId=15
    const { data } = await api.get(
      `api/profiles/medicalrecord/read/${dataId}/`,
      {
        headers,
      }
    );

    const patient = await getPatientData();
    const professional = await getProfessionalData();

    setPrintData({
      title: "Prontuário",
      filename: "prontuario",
      patient: patient,
      professional,
      bodyContent: getMedicalRecordPrint({ text: data.notes }),
    });
  };

  const setPurchaseData = async () => {
    // http://localhost:3000/#/generate-pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE5Njk5Nzg2LCJpYXQiOjE3MTcxMDc3ODYsImp0aSI6ImE5MDIzZmQ1ZTQxYzRmOWY4NDllYWVhNGFmYjUzYjQ1IiwidXNlcl9pZCI6MX0.5qACbqI8gNLL-T8-2iAAMscjC910meYECn9mGFkeqDI&dataType=purchase&dataId=335
    const { data } = await api.get(`api/payment/bills/read/${dataId}/`, {
      headers,
    });

    const professional = await getProfessionalData();

    setPrintData({
      title: "Fatura",
      filename: "fatura",
      withProfiles: false,
      bodyContent: getPurchasePrint({
        purchase: data,
        userDetails: professional,
      }),
      professional: professional,
    });
  };

  const setPatientWorkoutSheetData = async () => {
    // http://localhost:3000/#/generate-pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIyNTE0MjU4LCJpYXQiOjE3MTk5MjIyNTgsImp0aSI6ImUxYzgxMmQzY2Q2ZjQwNzE5ODJiYTBlODI3ZjNlYWNiIiwidXNlcl9pZCI6MX0.1hZ4JooLaQeVvrkoDzBgunQLWzGKnUQOCU-xglbW3rs&dataType=patientWorkoutSheet&patientId=3&dataId=389
    const { data } = await api.get(`api/workouts/pacient/read/${dataId}/`, {
      headers,
    });

    const patient = await getPatientData();

    const professional = await getProfessionalData();

    setPrintData({
      filename: "ficha-de-treino",
      title: "Treino - " + data.ExerciciosFicha[0]?.workoutSheet?.workout?.name,
      patient: patient,
      professional,
      subtitle: "Ficha de Treino",
      bodyContent: getWorkoutSheetPrint({
        workoutSheet: data.ExerciciosFicha,
        initialDate: data["Ficha Paciente"]?.initialDate,
        finalDate: data["Ficha Paciente"]?.finalDate,
      }),
    });
  };

  const setPatientWorkoutSheetExecutionData = async () => {
    // http://localhost:3000/#generate-pdf?dataId=546&dataType=patientWorkoutSheet&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIyNTUwNDkwLCJpYXQiOjE3MTk5NTg0OTAsImp0aSI6IjIzYTYxZjhkNDhjNjQxNTY5NjkyZmM2NzUzMTY4NWMzIiwidXNlcl9pZCI6NzF9.OkOnAMD0Ich08m9h6fYIfS1CEQnONl1Gf0ByGMwUj7Q&patientId=87

    const { data } = await api.get(`api/workouts/workoutpatient/readall/`, {
      headers,
    });

    const patientWorkout = data.find(
      (pW) => pW?.["Ficha Paciente"]?.workoutSheet.toString() === dataId
    );

    const patient = await getPatientData();

    setPrintData({
      filename: "ficha-de-treino",
      title:
        "Treino - " +
        patientWorkout.ExerciciosFicha[0]?.[0]?.workoutSheet?.workout?.name,
      patient: patient,
      professional: patient?.professional,
      subtitle: "Ficha de Treino",
      bodyContent: getWorkoutSheetPrint({
        workoutSheet: patientWorkout?.ExerciciosFicha?.[0],
        initialDate: patientWorkout["Ficha Paciente"]?.initialDate,
        finalDate: patientWorkout["Ficha Paciente"]?.finalDate,
      }),
    });
  };

  const handlePrintData = async () => {
    switch (dataType) {
      case "workoutSheet":
        return setWorkoutSheetData();
      case "patientWorkoutSheet":
        return setPatientWorkoutSheetData();
      case "medicalRecord":
        return setMedicalRecordData();
      case "evaluation":
        return setEvaluationData();
      case "patientForm":
        return setPatientFormData();
      case "purchase":
        return setPurchaseData();
      case "patientWorkoutSheetExecution":
        return setPatientWorkoutSheetExecutionData();
    }
  };

  const { printDocument, document, dataUri } = usePrintPage({
    ...printData,
    withDataUri: true,
  });

  useEffect(() => {
    handlePrintData();
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      window.printDocument = printDocument;

      if (window?.ReactNativeWebView?.postMessage) {
        printDocument();
      }
    }, 2000);

    return () => clearTimeout(id);
  }, [printDocument]);

  return <div>{document}</div>;
}
