import { useMutation } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";

import { getFilenameFromURL } from "../utils/file.utils";

export function useUserMutation() {
  const config = useApiConfig();
  const configForm = useApiConfig("multipart/form-data");

  const createPatient = useMutation({
    mutationFn: async (params) => {
      return api.post(`/api/profiles/pacient/create/`, params, config);
    },
  });

  const updateProfile = useMutation({
    mutationFn: async (params) => {
      return api.put("/api/users/update/user/", params, config);
    },
  });

  const uploadImage = useMutation({
    mutationFn: async (params) => {
      const { file, name } = params;

      const url = URL.createObjectURL(file);
      const formData = new FormData();

      formData.append("image", file);
      formData.append("linkImage", url);
      formData.append("imageName", name);

      return api.post("/api/profiles/pacient/upload/", formData, configForm);
    },
  });

  return { createPatient, updateProfile, uploadImage };
}
