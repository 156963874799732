import React, { useState } from "react";
import { Container, Modal, CloseButton } from "react-bootstrap";
import NavTabsV2 from "../../components/v2/NavTabsV2";
import ActiveProgramsV2 from "./ProgramsV2/ActiveProgramsV2";
import ExerciseDetailsV2 from "./ProgramsV2/ExerciseDetailsV2";
import WorkoutDetailsV2 from "./ProgramsV2/WorkoutDetailsV2";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import PatientsListV2 from "./PacientDetailsV2/PatientsListV2";
import PacientDetailsV2 from "./PacientDetailsV2/PacientDetailsV2";
import RegisterCostumerV2 from "../../components/v2/RegisterCostumerV2";
import RegisterWorkoutV2 from "../../components/v2/RegisterWorkoutV2";
import dashboard from "../../assets/v2/dashboard.css";
import FeedbackScreenV2 from "./Feedback/FeedbackScreenV2";
import UserProfileV2 from "./Configs/UserProfileV2";
import PlansV2 from "./Configs/PlansV2";
import RegisterEventV2 from "../../components/v2/RegisterEventV2";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { PageRouteContainer } from "../PageRouteContainer";
import Schedule from "../../components/v2/Schedule";
import { useMemo } from "react";
import { CreateRoutineModal } from "../../screens/v2/ProgramsV2/CreateRoutineModal";
import { RoutineDetails } from "./ProgramsV2/RoutineDetails";
import { FinanceScreen } from "../../screens/v2/Finance/FinanceScreen";
import { CreateFinanceModal } from "./Finance/CreateFinanceModal";
import { PrivateRoute } from "../../components/PrivateRoute";
import { DashboardView } from "../../screens/v2/Dashboard/DasboardView";

import { PREMIUM_PLANS_IDS } from "../../constants/plan.consts";
import { AUTH_VIEWS } from "../../constants/auth.consts";

import { useUserInfo } from "../../hooks/useUserInfo";

function HomePersonalScren({ location, history }) {
  let { path, url } = useRouteMatch();

  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalVisible, setVisible] = useState(true);
  const [modalBody, setModalBody] = useState([]);

  const [currentScheduleTab, setCurrentScheduleTab] = useState(0);
  const mediaValues = useMediaQueryValues();
  const { isMD, isLG } = mediaValues;

  const { userDetails, userViews } = useUserInfo();

  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const getModalBody = (body, title, visible = true) => {
    setModalTitle(title);
    setModalShow(true);
    setVisible(visible);
    setModalBody(body);
  };

  const changeVisbility = (visible) => {
    setVisible(visible);
    return modalVisible;
  };

  const unsetModalBody = () => {
    setModalBody([]);
    setModalShow(false);
  };

  const pacientsTabs = [
    {
      name: "students",
      body: (
        <PrivateRoute
          userViews={userViews}
          renderPremiumView
          allowedViews={[AUTH_VIEWS.pacients]}
          withoutRoute
          component={PatientsListV2}
        ></PrivateRoute>
      ),
    },
    {
      name: "feedback",
      body: (
        <PrivateRoute
          userViews={userViews}
          renderPremiumView
          allowedViews={[AUTH_VIEWS.feedback]}
          withoutRoute
          component={FeedbackScreenV2}
        ></PrivateRoute>
      ),
      params: "feedback",
    },
  ];

  const Programs = useMemo(() => {
    return () => (
      <ActiveProgramsV2
        handleModal={getModalBody}
        unsetModalBody={unsetModalBody}
        visibilityModal={changeVisbility}
      />
    );
  }, []);

  const programsTabs = [
    {
      name: "actives",
      body: (
        <PrivateRoute
          userViews={userViews}
          renderPremiumView
          allowedViews={[AUTH_VIEWS.programs]}
          withoutRoute
          component={Programs}
        ></PrivateRoute>
      ),
    },
  ];

  const managementTabs = [
    {
      name: "Agenda",
      body: (
        <PrivateRoute
          renderPremiumView
          userViews={userViews}
          allowedViews={[AUTH_VIEWS.agenda]}
          component={Schedule}
        ></PrivateRoute>
      ),
    },
    {
      name: "Finanças",
      body: (
        <PrivateRoute
          renderPremiumView
          userViews={userViews}
          allowedViews={[AUTH_VIEWS.finance]}
          component={FinanceScreen}
        ></PrivateRoute>
      ),
      params: "finance",
    },
  ];
  const profileTabs = [
    {
      name: "Meu perfil",
      body: <UserProfileV2 />,
    },
    {
      name: "Planos & pagamentos",
      body: <PlansV2 />,
      params: "plans",
    },
  ];

  const getProfileTabsInitialKey = () => {
    const all = Array.from(searchParams.entries()).map((e) => e[0]);

    if (all.includes("plans")) return profileTabs[1].name;

    return profileTabs[0].name;
  };

  return (
    <Container fluid className="ps-0 h-100 p-0 m-0">
      <style src={dashboard}></style>
      <PageRouteContainer
        changeVisbility={changeVisbility}
        getModalBody={getModalBody}
        unsetModalBody={unsetModalBody}
      >
        <Switch>
          <Route exact path={`${path}`}>
            <h1 className="text-primary">Dashboard</h1>
          </Route>
          <Route exact path={`${path}/pacients`}>
            <NavTabsV2
              actionButton="Novo aluno"
              defaultKey={
                searchParams.get("feedback") === "" ? "feedback" : "students"
              }
              handleModal={getModalBody}
              modal={<RegisterCostumerV2 unsetModalBody={unsetModalBody} />}
              tabs={pacientsTabs}
            />
          </Route>
          <Route
            path={`${path}/pacients/:id`}
            children={
              <PacientDetailsV2
                handleModal={getModalBody}
                unsetModalBody={unsetModalBody}
              />
            }
          />

          <PrivateRoute
            exact
            path={`${path}/dashboard`}
            userViews={userViews}
            // allowedViews={[AUTH_VIEWS.dashboard]}
            renderPremiumView
            component={DashboardView}
          ></PrivateRoute>
          <Route exact path={`${path}/programs`}>
            <h1 className="text-primary">Treinos</h1>
            <NavTabsV2
              actionButton="Novo treino"
              actionButtonMobileIcon={"assets/v2/icons/folder.svg"}
              secondActionButton="Nova Rotina"
              SecondActionContent={CreateRoutineModal}
              defaultKey="actives"
              tabs={programsTabs}
              modal={<RegisterWorkoutV2 unsetModalBody={unsetModalBody} />}
              handleModal={getModalBody}
              unsetModalBody={unsetModalBody}
            />
          </Route>
          <Route path={`${path}/programs/:id`}>
            <WorkoutDetailsV2
              handleModal={getModalBody}
              unsetModalBody={unsetModalBody}
            />
          </Route>
          <Route path={`${path}/exercises/:id`}>
            <ExerciseDetailsV2
              handleModal={getModalBody}
              unsetModalBody={unsetModalBody}
            />
          </Route>
          <Route exact path={`${path}/feedback/`}>
            <FeedbackScreenV2 />
          </Route>
          <Route exact path={`${path}/profile`}>
            <NavTabsV2
              defaultKey={getProfileTabsInitialKey()}
              tabs={profileTabs}
            />
          </Route>
          <Route exact path={`${path}/admin`}>
            <NavTabsV2
              actionButton={
                currentScheduleTab === 0 ? "Criar Evento" : "Novo Lançamento"
              }
              defaultKey={
                searchParams.get("finance") === "" ? "Finanças" : "Agenda"
              }
              handleModal={getModalBody}
              modal={<RegisterEventV2 onFinish={unsetModalBody} />}
              tabs={managementTabs}
              onChangeTab={setCurrentScheduleTab}
              CustomActionButtonModal={
                currentScheduleTab === 1 ? CreateFinanceModal : null
              }
            />
          </Route>

          <Route path={`${path}/routine/:id`}>
            <RoutineDetails></RoutineDetails>
          </Route>
        </Switch>
      </PageRouteContainer>

      {modalShow && (
        <Modal
          show={modalShow}
          className={`${
            modalVisible ? "visible" : "invisible overflow-hidden"
          } `}
          onHide={() => unsetModalBody()}
          size="lg"
          aria-labelledby="contained-modal-title-center"
          centered
        >
          <Modal.Header className="justify-content-between align-items-center">
            <p className="mb-0 text-primary fw-500 fs-5">{modalTitle}</p>
            <CloseButton
              className="btn text-danger fs-4"
              onClick={unsetModalBody}
            />
          </Modal.Header>
          <Modal.Body children={modalBody} />
        </Modal>
      )}
    </Container>
  );
}

export default HomePersonalScren;
