import React from "react";
import { formatDate } from "../../utils/date";
import { Button, Image } from "react-bootstrap";
import imageSection from "../../assets/v2/img/image-section-1.png";
import { useHistory } from "react-router-dom";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { respStyle } from "../../utils/respStyle";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useReadUserDetailsQuery } from "../../queries";

import exercitiumLogo from "../../assets/v2/icons/logo.svg";
import lockSvg from "../../assets/v2/icons/lock.svg";

import { useDispatch } from "react-redux";
import { logout } from "../../actions/userActions";
import { useEffect } from "react";
import { ALLOWED_PLANS_IDS } from "../../constants/plan.consts";

export function PremiumView() {
  const mediaValues = useMediaQueryValues();

  const history = useHistory();

  const { isMD, isMobile } = mediaValues;

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    history.replace("/");
  };

  return (
    <div className="bg-body-secondary h-100 d-flex justify-content-center align-items-center p-0 w-100 overflow-hidden overflow-y-auto rounded">
      <div
        className={
          "d-flex flex-column flex-md-row-reverse align-items-center p-0 p-md-0"
        }
      >
        <div
          className="d-flex flex-column bg-body-secondary p-5 pt-2"
          style={{ zIndex: 30 }}
        >
          <Image
            className="align-self-center mt-3"
            style={{ width: 80, height: 80 }}
            src={lockSvg}
          ></Image>

          <p className="mt-2">
            Para ter acesso a essa funcionalidade, faça o upgrade para a{" "}
            <span className="text-primary fw-500"> conta premium</span>
          </p>

          <div className="d-flex flex-wrap">
            <Button
              onClick={() => history.replace("/home/personal/profile?plans")}
              className="w-100"
            >
              Assinar Agora
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
