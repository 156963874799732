import { React, useEffect, useMemo } from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { useFormInput } from "../../hooks/useFormInput";
import { useDispatch, useSelector } from "react-redux";

import { ResultFinalCard } from "../ResultFinalCard";
import { useExerciseInputVideoController } from "../../controllers";
import { useExerciseMutation, useFeedbackMutation } from "../../mutations";
import { SpinnerButton } from "./SpinnerButton";

function RegisterExerciseVideoV2({ exercise = null, onFinish = () => {} }) {
  const isEditMode = !!exercise;
  const muscleList = useSelector((state) => state.muscleList);
  const { errorM, loadingM, muscles } = muscleList;

  const { createExerciseMutation, editExerciseMutation } =
    useExerciseMutation();
  const { createFeedback } = useFeedbackMutation();

  const exerciseData = {
    name: useFormInput(exercise?.name ?? ""),
    description: useFormInput(exercise?.description ?? ""),
    muscle_id: useFormInput(exercise?.muscle?._id),
    exerciseImage_id: null,
    exerciseVideo_id: null,
    isFavorite: null,
  };

  const muscleName = useMemo(() => {
    const m = muscles?.find((elem) => {
      if (elem._id == exerciseData?.muscle_id.value) {
        return elem;
      }
    });

    return m?.name ?? "";
  }, [exerciseData?.muscle_id?.value]);

  const createOrEditExercise = async (
    e,
    { videoToUpload, feedbackResultFinal, withFeedback }
  ) => {
    let response;

    if (isEditMode) {
      const videoId = videoToUpload
        ? videoToUpload._id
        : exercise.exerciseVideo._id;

      const imageId = videoToUpload
        ? videoToUpload.imageId
        : exercise.exerciseImage._id;

      response = await editExerciseMutation.mutateAsync({
        _id: exercise._id,
        name: exerciseData.name.value,
        description: exerciseData.description.value,
        muscle_id: Number(exerciseData.muscle_id.value),
        hasFeedback: withFeedback,
        exerciseVideo_id: videoId,
        exerciseImage_id: imageId,
      });
    } else {
      response = await createExerciseMutation.mutateAsync({
        ...exerciseData,
        name: exerciseData.name.value,
        muscle_id: exerciseData.muscle_id.value,
        description: exerciseData.description.value,
        exerciseVideo_id: videoToUpload._id,
        exerciseImage_id: videoToUpload?.imageId,
        isFavorite: false,
        hasFeedback: withFeedback,
      });
    }

    const exerciseId = isEditMode ? exercise._id : response.data._id;

    console.log({ feedbackResultFinal });

    if (withFeedback && feedbackResultFinal) {
      const feedResponse = await createFeedback.mutateAsync({
        exerciseId,
        ...feedbackResultFinal,
      });
    }

    onFinish();
  };

  const exerciseInputController = useExerciseInputVideoController({
    handleSendClick: createOrEditExercise,
    muscleName: muscleName,
    withFeedback: !!exercise?.hasFeedback,
    defaultVideo: exercise?.exerciseVideo,
    sendButtonText: isEditMode ? "Atualizar" : "Cadastrar",

    formIsValid:
      exerciseData?.name?.value?.length > 0 &&
      !!exerciseData?.muscle_id?.value &&
      !!exerciseData?.description?.value?.length > 0,
  });

  const {
    renderActions,
    renderPlayer,
    videoToUpload,
    feedbackResultFinal,
    videoIsLoading,
    withFeedbackValue,
    renderFeedbackInputComp,
    isWaitingFeedback,
  } = exerciseInputController;

  const submitHandler = (e) => {
    e.preventDefault();
  };

  console.log({ isWaitingFeedback });

  return (
    <Form onSubmit={submitHandler}>
      <Row>
        <Col className="col-12">
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label className="fw-500">Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="ex.: Treino de Superiores"
              {...exerciseData?.name}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDescription">
            <Form.Label className="fw-500">Descrição</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Descrição"
              {...exerciseData?.description}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTag">
            <Form.Label className="fw-500">Músculo</Form.Label>
            <Form.Control
              as="select"
              className="h-100 w-100 me-3"
              {...exerciseData?.muscle_id}
              required
              disabled={
                videoToUpload?.uploaded ||
                (withFeedbackValue && feedbackResultFinal) ||
                videoIsLoading
              }
            >
              <option>Selecionar grupo muscular</option>
              {muscles?.map((muscle) => (
                <option value={muscle._id}>{muscle.name}</option>
              ))}
            </Form.Control>
          </Form.Group>

          {renderFeedbackInputComp}

          {renderPlayer}

          {withFeedbackValue && feedbackResultFinal && (
            <div className="mt-2">
              <ResultFinalCard
                resultFinal={feedbackResultFinal}
              ></ResultFinalCard>
            </div>
          )}
        </Col>
      </Row>

      <Modal.Footer className="m-0 p-0 pt-2 mt-2 d-flex justify-content-end">
        {renderActions}

        {isEditMode && !videoToUpload && (
          <Row
            className="w-100 mt-2"
            onClick={(e) =>
              createOrEditExercise(e, {
                feedbackResultFinal,
                videoToUpload,
                withFeedback: withFeedbackValue,
              })
            }
          >
            <Button disabled={videoIsLoading} className="col-12">
              <SpinnerButton
                isLoading={videoIsLoading || isWaitingFeedback}
                text="Salvar Edição"
              ></SpinnerButton>
            </Button>
          </Row>
        )}
      </Modal.Footer>
    </Form>
  );
}

export default RegisterExerciseVideoV2;
