import { useEffect, useMemo, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { PacientProfilePrint } from "./PacientProfilePrint";
import { formatDate } from "../../utils/date";
import exercitiumLogo from "../../assets/v2/icons/logo.png";

export const PrintPage = ({
  bodyContent,
  withProfiles,
  title,
  patient,
  classIdentifier,
  professional,
}) => {
  const divRef = useRef(null);
  const mainRef = useRef(null);
  const footerRef = useRef(null);

  const [isReady, setReady] = useState();

  const hadOverflow = useRef({ status: false, overflowIndex: null });

  if (professional) {
    professional.profileImage = professional?.userImage;
    professional.email = professional?.user?.email;
  }

  const hasOverflow = useMemo(() => {
    if (!divRef.current) return;

    // we need to maintain the recursive
    if (hadOverflow.current.status) return true;

    const { scrollHeight, clientHeight } = divRef?.current;

    hadOverflow.current = scrollHeight > clientHeight;

    return hadOverflow.current;
  }, [
    divRef.current?.scrollHeight,
    divRef.current?.clientHeight,
    divRef.current,
  ]);

  const getOverflowChildIndex = () => {
    const arrayChildren = Array.from(mainRef.current.children);

    for (let i = 0; i < arrayChildren.length; i++) {
      const child = arrayChildren[i];
      const threshold = 50;
      const childPos = child.offsetTop + child.offsetHeight + threshold;

      if (childPos >= footerRef.current.offsetTop) {
        return i;
      }
    }
  };

  useEffect(() => {
    // We need to wait the images to be loaded
    setTimeout(() => {
      setReady(true);
    }, 1500);
  }, []);

  const render = (contents, index) => {
    const [FirstContent, ...rest] = contents;

    return (
      <section
        className="w-100 d-flex justify-content-center align-items-center"
        style={{ height: 0, overflow: "hidden" }}
      >
        <div
          ref={divRef}
          id={"divToPrint" + index}
          className={`m-0 p-5 pt-4 pb-2 d-flex flex-column position-relative ${classIdentifier}`}
          style={{
            width: "21cm",
            height: "29.7cm",
            minWidth: "21cm",
            maxWidth: "21cm",
            minHeight: "29.7cm",
            maxHeight: "29.7cm",

            // to view the size
            // backgroundColor: "gray",
            // backgroundColor: "white",
          }}
        >
          {/* <img src={img}></img> */}
          <header>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Image height={50} src={exercitiumLogo}></Image>
              <p className="text-primary small mb-0">
                Emitido em: {formatDate(new Date())}
              </p>
            </div>

            {title && <p className="h2 text-primary">{title}</p>}

            {withProfiles && (
              <div
                className="d-flex position-relative"
                style={{ marginBottom: patient ? 220 : 150 }}
              >
                {patient && (
                  <div
                    className="position-absolute left-0 col-11"
                    style={{
                      transform: `scale(.5, .5)`,
                      transformOrigin: "left top",
                    }}
                  >
                    <PacientProfilePrint
                      pacient={patient}
                      withStatus={false}
                      label="Aluno"
                    ></PacientProfilePrint>
                  </div>
                )}

                <div
                  className={
                    "position-absolute col-11 " +
                    (patient ? "end-0" : "start-0")
                  }
                  style={{
                    transform: `scale(.5, .5)`,
                    transformOrigin: patient ? "right top" : "left top",
                  }}
                >
                  <PacientProfilePrint
                    pacient={professional}
                    withStatus={false}
                    withAddress={false}
                    withBirth={false}
                    withGender={false}
                    label="Profissional"
                  ></PacientProfilePrint>
                </div>
              </div>
            )}
          </header>

          <main
            ref={mainRef}
            id={"mainPdfContent" + index}
            style={{ marginBottom: 0, flex: 1 }}
          >
            <div>
              {FirstContent && <FirstContent isFirst={true}></FirstContent>}
            </div>
            {rest &&
              rest.map((Item, i) => (
                <div key={i}>
                  <Item></Item>
                </div>
              ))}
          </main>

          <footer
            ref={footerRef}
            className="d-flex position-absolute flex-column bottom-0 m-0 p-0 mb-2"
            style={{ width: "697px" }}
          >
            <div
              className=" bg-primary rounded mt-2"
              style={{ height: 2 }}
            ></div>
            <p className="text-primary small m-0">Exercitium</p>

            <div className="flex-between">
              <p className="text-primary small m-0">Natal, Brasil</p>

              <p className="text-primary small m-0">
                contato@exercitium.com.br
              </p>
            </div>
          </footer>
        </div>
      </section>
    );
  };

  if (isReady && hasOverflow && bodyContent.length > 1) {
    const startOverflowIndex = getOverflowChildIndex();

    if (!hadOverflow.current.status)
      hadOverflow.current = { status: true, overflowIndex: startOverflowIndex };

    const sliceIndex = hadOverflow.current.overflowIndex ?? startOverflowIndex;

    const [first, second] = [
      bodyContent.slice(0, sliceIndex),
      bodyContent.slice(sliceIndex),
    ];

    return (
      <>
        {render(first, bodyContent.length - 1)}
        {second && second.length > 0 && (
          <PrintPage
            classIdentifier={classIdentifier}
            bodyContent={second}
            withProfiles={false}
          ></PrintPage>
        )}
      </>
    );
  }

  return render(bodyContent, bodyContent.length - 1);
};
