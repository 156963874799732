import { useMutation } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";

export function useScheduleMutation() {
  const config = useApiConfig();

  //   {
  //     "nameEvent": "dsfsdf",
  //     "note": "sdf",
  //     "date": "2024-05-30",
  //     "hourInitial": "20:24",
  //     "hourFinal": "23:23",
  //     "isOnline": true,
  //     "patient_id": 21,
  //     "isInPerson": null,
  //     "procedure_id": 2
  // }
  const createEventMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(`/api/management/agenda/create/`, params, config);
    },
  });

  const updateEventMutation = useMutation({
    mutationFn: async (params) => {
      const { id, ...rest } = params;

      return api.put(`/api/management/agenda/edit/${id}/`, rest, config);
    },
  });

  const deleteEventMutation = useMutation({
    mutationFn: async (params) => {
      const { id } = params;

      return api.delete(`/api/management/agenda/delete/${id}/`, config);
    },
  });

  return { createEventMutation, updateEventMutation, deleteEventMutation };
}
