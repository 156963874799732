import { useMemo, useState } from "react";
import { useReadDashboardQuery } from "../../../../queries/dashboard.query";

export function useController() {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);

  const [dashboardList, setDashboardList] = useState({
    list: null,
    type: null,
    title: null,
  });

  const { dashboardData, isFetching } = useReadDashboardQuery({
    enabled: true,
    params: {
      month: currentMonth === "-1" ? undefined : currentMonth,
    },
  });

  const financesInData = dashboardData?.financeGraph.financesIN ?? [];
  const financesOutData = dashboardData?.financeGraph.financesOUT ?? [];

  const financesGraphData = useMemo(() => {
    const data = {};

    financesInData.map((item, index) => {
      data[`${item[0]}`] = {
        name: item[0],
        uv: item[0],
        pv: item[1],
        amt: 0,
      };
    });

    financesOutData.map((item, index) => {
      if (data[`${item[0]}`]) {
        data[`${item[0]}`].pv2 = item[1];
        return;
      }

      data[`${item[0]}`] = {
        name: item[0],
        uv: item[0],
        pv: 0,
        pv2: item[1],
        amt: 0,
      };
    });

    const final = Object.values(data);

    return final;
  }, [financesInData, financesOutData]);

  return {
    dashboardData,
    currentMonth,
    setCurrentMonth,
    isFetching,
    financesGraphData,
    dashboardList,
    setDashboardList,
  };
}
