import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

import exerciseImg from "../../assets/v2/img/programs/card-image(1).jpg";
import { PrintBody } from "./PrintBody";
import { subdivideArray } from "../../utils/page.utils";
import { WithPageSection } from "./WithPageSection";
import { formatDate } from "../../utils/date";
import WorkoutSheetV2 from "../v2/WorkoutSheetV2";
import { fetchImageWithHeaders } from "../../utils/image.utils";

export function getWorkoutSheetPrint({
  workoutSheet = [],
  initialDate,
  finalDate,
}) {
  const ExerciseCard = ({ name, repetitions, weight, description }) => (
    <div className="d-flex flex-column w-100">
      <div className="flex-between">
        <p className="small fw-bold m-0">{name}</p>
      </div>
      <div className="d-flex flex-column">
        <p className="text-secondary extra-small text-break word-break">
          {description}
        </p>
      </div>
    </div>
  );

  const [first, ...restGroups] = workoutSheet;
  const [firstExercise, ...restExercisesGroups] = workoutSheet;

  const WorkoutSheetBody = ({ sheet, i }) => (
    <div
      key={i}
      className={"d-flex flex-direction-row fw-bold w-100"}
      style={{ transform: `translate(${i >= 9 && -9}px, ${0}px)` }}
    >
      <p className="pe-1 mb-0 border-end border-dark border-1">{i + 1}</p>
      <div className="d-flex flex-column w-100 mb-4">
        <div className="d-flex justify-content-between w-100">
          <p className={"ms-2 text-capitalize col-4 mb-0"}>
            {sheet.exercise?.name}
          </p>

          <p className="d-flex text-center d-lg-flex mb-0">
            {sheet.repetitions} rep - {sheet.serie} ser
          </p>
          <p className="d-flex text-center d-lg-flex mb-0">
            {sheet.restTime} seg
          </p>
          <p
            className="d-flex text-center d-lg-flex mb-0"
            style={{ transform: `translate(${i >= 9 && 8}px, ${0}px)` }}
          >
            {sheet.weight}kg
          </p>
        </div>

        {sheet?.notes && (
          <p className="ms-2 mb-0 fw-normal">
            <span className="fw-500">Observações: </span>
            {sheet?.notes}
          </p>
        )}
      </div>
    </div>
  );

  const ExerciseImage = ({ url }) => {
    const [imageUrl, setImageUrl] = useState();

    useEffect(() => {
      fetchImageWithHeaders(url).then((value) => setImageUrl(value));
    }, [url]);

    return (
      <Image
        width={200}
        height={150}
        style={{ minWidth: 200, aspectRatio: "1/1", objectFit: "contain" }}
        src={imageUrl}
        className="me-2 rounded-2"
      ></Image>
    );
  };

  return [
    WithPageSection(
      <PrintBody
        className="pb-0"
        titleComp={
          <div className="flex-between mb-3">
            <p className="h6 text-primary">Ficha de Treino</p>
            {initialDate && finalDate && (
              <p className="h6 text-primary">
                {formatDate(initialDate)} - {formatDate(finalDate)}
              </p>
            )}
          </div>
        }
      >
        <div className="col-12">
          {first && <WorkoutSheetBody sheet={first} i={0} />}
        </div>
      </PrintBody>
    ),
    ...(restGroups ?? []).map((item, index) =>
      WithPageSection(
        <PrintBody key={index} className="pb-0">
          <div key={index} className="col-12">
            <WorkoutSheetBody sheet={item} i={index + 1} />
          </div>
        </PrintBody>
      )
    ),
    WithPageSection(
      <PrintBody title={"Exercícios"} className="mt-2">
        <div className="row m-0 p-0">
          <div className="d-flex col-12 p-0 pe-2 mb-3 px-2 align-item-center">
            <ExerciseImage
              url={firstExercise?.exercise?.exerciseImage?.image ?? exerciseImg}
            ></ExerciseImage>
            <ExerciseCard
              name={firstExercise?.exercise?.name}
              repetitions={firstExercise?.repetitions}
              weight={firstExercise?.weight}
              description={firstExercise?.exercise?.description}
            ></ExerciseCard>
          </div>
        </div>
      </PrintBody>
    ),
    ...(restExercisesGroups ?? []).map((item, index) =>
      WithPageSection(
        <PrintBody key={index}>
          <div className="row m-0 p-0">
            <div className="d-flex col-12 p-0 pe-2 mb-3 px-2" key={index}>
              <ExerciseImage
                url={item?.exercise?.exerciseImage?.image ?? exerciseImg}
              ></ExerciseImage>
              <ExerciseCard
                name={item?.exercise?.name}
                repetitions={item?.repetitions}
                weight={item?.weight}
                description={item?.exercise?.description}
              ></ExerciseCard>
            </div>
          </div>
        </PrintBody>
      )
    ),
  ];
}
