import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import LoginScreenV2 from "./screens/v2/LoginScreenV2";
import LandingPageScreenV2 from "./screens/v2/LandingPageScreenV2";
import HomePersonalScreenV2 from "./screens/v2/HomePersonalScreenV2";
import HomePacientScreenV2 from "./screens/v2/HomePacientScreenV2";
import SignUpScreenV2 from "./screens/v2/SignUpScreen/SignUpScreenV2";
import ForgetPassScreenV2 from "./screens/v2/ForgetPassScreenV2";
import ResetPassScreenV2 from "./screens/v2/ResetPassScreenV2";
import { Paywall } from "./screens/v2/Paywall";
import { PrivateRoute } from "./components/PrivateRoute";
import { PROFESSIONAL_ROLE, PATIENT_ROLE } from "./constants/rolesConstants";
import { useUserInfo } from "./hooks/useUserInfo";
import "./assets/index.css";
import { PLANS_IDS } from "./constants/plan.consts";
import { Payment } from "./screens/v2/Payment";

import { GeneratePDFScreen } from "./screens/GeneratePDFScreen";

import "../src/utils/translations/i18n";

function App() {
  const { userRole, planProfessional } = useUserInfo();

  return (
    <Router>
      <Route path="/" component={LandingPageScreenV2} exact />
      <Route path="/login" component={LoginScreenV2} exact />
      <Route path="/sign-up" component={SignUpScreenV2} exact />
      <Route path="/forget-pass" component={ForgetPassScreenV2} exact />
      <Route path="/reset-pass/:token" component={ResetPassScreenV2} />
      <Route path="/paywall" component={Paywall} />

      <PrivateRoute
        userRole={userRole}
        routeRoles={[PROFESSIONAL_ROLE]}
        routePlans={[
          PLANS_IDS.BLOCKED,
          PLANS_IDS.PREMIUM_MONTH,
          PLANS_IDS.PREMIUM_YEAR,
        ]}
        routePlanFallback="/login"
        userPlan={planProfessional ?? PLANS_IDS.BLOCKED}
        path="/payment"
        component={Payment}
      />

      <PrivateRoute
        userRole={userRole}
        userPlan={planProfessional}
        routePlans={[
          PLANS_IDS.BASIC_MONTH,
          PLANS_IDS.BASIC_YEAR,
          PLANS_IDS.PREMIUM_MONTH,
          PLANS_IDS.PREMIUM_YEAR,
          PLANS_IDS.FREE,
        ]}
        routeRoles={[PROFESSIONAL_ROLE]}
        path="/home/personal"
        component={HomePersonalScreenV2}
      />

      <PrivateRoute
        userRole={userRole}
        routeRoles={[PATIENT_ROLE]}
        path="/home/pacient"
        component={HomePacientScreenV2}
      />

      <Route path={"/generate-pdf"} component={GeneratePDFScreen}></Route>
    </Router>
  );
}

export default App;
