import { React, forwardRef } from "react";
import { Form, Image } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/v2/datepicker.css";

import trash2Svg from "../../../assets/v2/icons/trash.svg";

function DateField({
  currentDate,
  setDate,
  target = false,
  required = true,
  disabled = false,
}) {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="d-flex">
      <Form.Control
        type="text"
        placeholder="dd/mm/yyyy"
        value={value}
        onClick={onClick}
        ref={ref}
        className="w-100"
        autoComplete="off"
        required={required}
        disabled={disabled}
        onChange={() => {}}
      />

      {currentDate && !disabled && (
        <Image
          className="p-2"
          onClick={() => setDate(undefined)}
          src={trash2Svg}
        ></Image>
      )}
    </div>
  ));

  return (
    <DatePicker
      required={required}
      selected={currentDate}
      onChange={setDate}
      disabled={disabled}
      customInput={<CustomInput />}
      dateFormat="dd/MM/yyyy"
      showYearDropdown
      showMonthDropdown
      wrapperClassName="w-100"
      dropdownMode="select"
    />
  );
}

export default DateField;
