import React, { useState } from "react";
import { Button, Card, Form, Image, Row } from "react-bootstrap";

import { formatDate, formatTime } from "../../utils/date";

import { getItemsFromPost } from "../../utils/getItemsFromPost";
import { useExerciseDoneMutation } from "../../mutations";
import { useExerciseQuery, useReadPostResumeQuery } from "../../queries";

import { useHistory } from "react-router-dom";

import { INTENSITIES } from "../../constants/post.consts";

export function FinishWorkoutCard({
  patientWorkout,
  workout,
  post,
  onFinish = () => {},
  onClose = () => {},
}) {
  const patient = patientWorkout["Ficha Paciente"]?.patient;

  const history = useHistory();

  const [formData, setFormData] = useState({ comment: "", itensity: 0 });

  const { finishPostExerciseMut } = useExerciseDoneMutation();

  const { postResume } = useReadPostResumeQuery({
    enabled: true,
    params: { postId: post?._id },
  });

  const handleSaveBtClick = async (e) => {
    e.preventDefault();

    const {
      currentExercise,
      totalExercise,
      currentSerie,
      totalSerie,
      progress,
    } = post;

    const response = await finishPostExerciseMut.mutateAsync({
      postId: post?._id,
      currentExercise,
      totalExercise,
      totalSerie,
      currentSerie: currentSerie - 1,
      progress,
      intensity_id: formData.itensity,
      commentsPatient: formData.comment,
      totalWeight: postResume?.totalWeight,
      totalTime: postResume?.totalTime,
      totalRepetitions: postResume?.totalRepetitions,
    });

    history.replace(`/home/pacient/`);

    onFinish();
  };

  const CardItem = ({ title, value }) => {
    return (
      <div className="col-6 col-md-4 m-0 p-0">
        <div className="bg-body-secondary p-2 m-1 row m-0 rounded">
          <p className="text-primary h6">{title}</p>
          <p className="h5">{value}</p>
        </div>
      </div>
    );
  };

  const handleCheckChange = (e) => {
    setFormData((prev) => ({ ...prev, itensity: Number(e.target.value) }));
  };

  const items = getItemsFromPost({
    ...postResume,
    currentExercise: postResume?.totalExercises,
  });

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <p className="m-0 text-primary h5">Treino Concluído</p>
        <img
          alt="close icon"
          className="pointer"
          src="/assets/v2/icons/x-square.png"
          onClick={() => onClose()}
        />
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <Image
            className="h-100 ms-2 me-4"
            style={{ width: "30%" }}
            src={
              patient?.userImage?.image ??
              "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
            }
          ></Image>
          <div className="d-flex flex-column">
            <p className="fw-500 text-primary mb-1">{patient?.name}</p>
            <p>
              {workout?.name} - {formatDate(post?.createdAt)}
            </p>
          </div>
        </div>

        <div className="row m-0 mt-2 p-1">
          <CardItem title={"Músculo"} value={"-"}></CardItem>

          {items.map((item) => (
            <CardItem
              key={item.title}
              title={item.title}
              value={item.value}
            ></CardItem>
          ))}
        </div>

        <Row className="m-0">
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="text-primary fw-bold">Mensagem</Form.Label>
            <Form.Control
              value={formData.comment}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, comment: e.target.value }))
              }
              style={{ height: 100 }}
              as="textarea"
              rows={3}
            />
          </Form.Group>

          <Form.Group className="">
            <Form.Label className="fw-bold text-primary">
              Nível de Itensidade
            </Form.Label>

            <div className="row m-0 bg-body-secondary justify-content-between p-2 ps-lg-5">
              <Form.Check
                required
                type="radio"
                label={INTENSITIES[0]}
                value={1}
                onChange={handleCheckChange}
                checked={formData.itensity === 1}
                className="col-12 col-sm-6 col-md-3 col-lg-2"
              />
              <Form.Check
                type="radio"
                label={INTENSITIES[1]}
                value={2}
                onChange={handleCheckChange}
                checked={formData.itensity === 2}
                className="col-12 col-sm-6 col-md-3 col-lg-3"
              />
              <Form.Check
                type="radio"
                label={INTENSITIES[2]}
                value={3}
                onChange={handleCheckChange}
                checked={formData.itensity === 3}
                className="col-12 col-sm-6 col-md-3 col-lg-2"
              />
              <Form.Check
                type="radio"
                label={INTENSITIES[3]}
                value={4}
                onChange={handleCheckChange}
                checked={formData.itensity === 4}
                className="col-12 col-sm-6 col-md-3 col-lg-2"
              />
              <Form.Check
                type="radio"
                label={INTENSITIES[4]}
                value={5}
                onChange={handleCheckChange}
                checked={formData.itensity === 5}
                className="col-12 col-sm-6 col-md-3 col-lg-3"
              />
            </div>
          </Form.Group>

          <div className="d-flex justify-content-center align-items-center">
            <Button className="mt-2" onClick={handleSaveBtClick}>
              Salvar
            </Button>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
}
