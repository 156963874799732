import axios from "../services/axios";
import {
  WORKOUT_LIST_REQUEST,
  WORKOUT_LIST_SUCCESS,
  WORKOUT_LIST_FAIL,
  WORKOUT_LIST_RESET,
  WORKOUT_LIST_DETAILS_REQUEST,
  WORKOUT_LIST_DETAILS_SUCCESS,
  WORKOUT_LIST_DETAILS_FAIL,
  WORKOUT_LIST_DETAILS_RESET,
  WORKOUT_BODYBUILDER_LIST_REQUEST,
  WORKOUT_BODYBUILDER_LIST_SUCCESS,
  WORKOUT_BODYBUILDER_LIST_FAIL,
  WORKOUT_BODYBUILDER_LIST_RESET,
  WORKOUT_CREATE_REQUEST,
  WORKOUT_CREATE_SUCCESS,
  WORKOUT_CREATE_FAIL,
  WORKOUT_CREATE_RESET,
  WORKOUT_BODYBUILDER_CREATE_REQUEST,
  WORKOUT_BODYBUILDER_CREATE_SUCCESS,
  WORKOUT_BODYBUILDER_CREATE_FAIL,
  WORKOUT_BODYBUILDER_CREATE_RESET,
  WORKOUT_UPDATE_REQUEST,
  WORKOUT_UPDATE_SUCCESS,
  WORKOUT_UPDATE_FAIL,
  WORKOUT_UPDATE_RESET,
  WORKOUT_DETAILS_REQUEST,
  WORKOUT_DETAILS_SUCCESS,
  WORKOUT_DETAILS_FAIL,
  WORKOUT_DETAILS_RESET,
  WORKOUT_DELETE_REQUEST,
  WORKOUT_DELETE_SUCCESS,
  WORKOUT_DELETE_FAIL,
  WORKOUT_DELETE_RESET,
  WORKOUT_PATIENT_LIST_REQUEST,
  WORKOUT_PATIENT_LIST_SUCCESS,
  WORKOUT_PATIENT_LIST_FAIL,
  WORKOUT_PATIENT_LIST_RESET,
} from "../constants/workoutConstants";

export const listMyWorkouts = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WORKOUT_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      params: {
        page: page,
      },
    };

    const { data } = await axios.get(`/api/workouts/readall/`, config);

    dispatch({
      type: WORKOUT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKOUT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listMyWorkoutsDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: listMyWorkoutsDetails,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/workouts/read/${id}`, config);

    dispatch({
      type: WORKOUT_LIST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKOUT_LIST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listWorkoutBodybuilder = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: WORKOUT_BODYBUILDER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/workouts/workoutBB`, config);

    dispatch({
      type: WORKOUT_BODYBUILDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKOUT_BODYBUILDER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createWorkout = (workout) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WORKOUT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      "/api/workouts/create/",
      {
        ...workout,
        name: workout.name.value,
        description: workout.description.value,
        tag: workout.tag.value,
        exercises: workout.exercises,
      },
      config
    );

    dispatch({
      type: WORKOUT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKOUT_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createWorkoutBodybuilder =
  (info) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WORKOUT_BODYBUILDER_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        "/api/workouts/add/bodybuilders/",
        info,
        config
      );

      dispatch({
        type: WORKOUT_BODYBUILDER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WORKOUT_BODYBUILDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const updateWorkout = (workout) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WORKOUT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/workouts/edit/${workout._id}/`,
      workout,
      config
    );
    dispatch({
      type: WORKOUT_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: WORKOUT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKOUT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteWorkout = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WORKOUT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/workouts/delete/${id}/`, config);

    dispatch({
      type: WORKOUT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: WORKOUT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const resetDeleteWorkout = (id) => async (dispatch, getState) => {
  dispatch({
    type: WORKOUT_DELETE_RESET,
  });
};

export const listPatientWorkout = () => async (dispatch, getState) => {
  try {
    dispatch({ type: WORKOUT_PATIENT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/workouts/workoutpatient/readall/`,
      config
    );

    dispatch({
      type: WORKOUT_PATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKOUT_PATIENT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
