import React from "react";

import { PrintBody } from "./PrintBody";
import { formatDate } from "../../utils/date";
import { COUNTRIES } from "../../constants/countries";
import { useUserInfo } from "../../hooks/useUserInfo";
export function getPurchasePrint({ purchase, userDetails }) {
  console.log({ purchase });

  const [number, street, bairro] = purchase?.bill?.line1?.split(",") ?? [];

  const cep = purchase?.bill?.zipCode?.replace("/D/g", "");

  return [
    () => (
      <div>
        <div className="row m-0 p-0">
          <div className="col-4 p-0">
            <p className="small fw-bold">Fatura Para:</p>
            <p className="small mb-0">{userDetails?.name}</p>
            <p className="small mb-0">
              {street}, {number}
            </p>
            <p className="small mb-0">
              {purchase?.bill?.city}, {purchase?.bill?.state}
            </p>

            <p className="small mb-0">
              {cep ? cep.slice(0, 5) + "-" + cep.slice(5) : ""}
            </p>

            <p className="small">{COUNTRIES[purchase?.bill?.country]}</p>

            <p className="small">{userDetails?.email}</p>
          </div>

          <div className="col-4 p-0 d-flex align-items-center flex-column">
            <div className="">
              <p className="small fw-bold mb-0">Detalhes do Plano:</p>
              <p className="small mb-2">{purchase?.bill?.plan?.name}</p>
              <p className="small mb-0">
                <span className="fw-bold">Auto Renovação:</span>{" "}
                {purchase?.bill?.plan?.is_yearly ? "Sim" : "Não"}
              </p>
            </div>
          </div>

          <div className="col-4 p-0 d-flex align-items-end flex-column">
            <div>
              <div>
                <p className="small fw-bold mb-0">Emitido em:</p>
                <p className="small mb-0">{formatDate(new Date())}</p>
              </div>

              <div className="mt-2">
                <p className="small fw-bold mb-0">Pago em:</p>
                <p className="small mb-0">
                  {formatDate(purchase?.bill?.createdAt)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <PrintBody title={"Detalhes"} className="mt-5" isFirst={true}>
          <div className="row m-0 mt-3">
            <p className="col-2 m-0 p-0 fw-bold small">ITEM</p>
            <p className="col-2 m-0 fw-bold  small">QUANTIDADE</p>
            <p className="col-4 m-0 fw-bold text-nobreak  small text-center">
              PREÇO UNITÁRIO
            </p>
            <p className="col-2 m-0 fw-bold text-nobreak text-center  small">
              DESCONTO
            </p>
            <p className="col-2 m-0 fw-bold text-end  text-center small">
              TOTAL
            </p>

            <div
              className="w-100 bg-black my-1 bg-opacity-50"
              style={{ height: 1 }}
            ></div>

            <p className="col-2 m-0 p-0 small">
              Plano {purchase?.bill?.plan?.name}
            </p>
            <p className="col-2 m-0 small text-center">
              {purchase?.bill?.plan?.is_yearly ? 12 : 1}
            </p>
            <p className="col-4 m-0 small text-center">
              R$ {purchase?.bill?.unitPrice?.toFixed(2)}
            </p>
            <p className="col-2 m-0 small text-center">
              {/* R$ {Math.abs(purchase?.bill?.total - purchase?.bill?.unitPrice)} */}
            </p>
            <p className="col-2 text-end m-0 small text-center">
              R$ {(purchase?.bill?.total / 100).toFixed(2)}
            </p>
          </div>
        </PrintBody>
      </div>
    ),
  ];
}
