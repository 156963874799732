import { useSelector } from "react-redux";
import { PATIENT_ROLE, PROFESSIONAL_ROLE } from "../constants/rolesConstants";
import { useReadUserAccessQuery, useReadUserDetailsQuery } from "../queries";
import { updateUserInfo } from "../actions/userActions";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import {
  BASIC_PLANS_IDS,
  PLANS_IDS,
  PREMIUM_PLANS_IDS,
} from "../constants/plan.consts";

export function useUserInfo() {
  const userInfo = useSelector((state) => state.userLogin?.userInfo);
  const { userDetails } = useReadUserDetailsQuery({ enabled: !!userInfo });
  const { userAccess } = useReadUserAccessQuery({ enabled: true });

  const userViews = useMemo(() => {
    if (userDetails?.plan?._id === undefined) return [];

    const isBasic = BASIC_PLANS_IDS.includes(userDetails?.plan?._id);
    const isPremium = PREMIUM_PLANS_IDS.includes(userDetails?.plan?._id);

    if (isBasic)
      return userAccess.filter((a) => a.allowBasic).map((a) => a.name);

    if (isPremium)
      return userAccess.filter((a) => a.allowPremium).map((a) => a.name);

    return userAccess.filter((a) => a.allowFree).map((a) => a.name);
  }, [userDetails?.plan?._id, userAccess]);

  const dispatch = useDispatch();

  if (userInfo) {
    const userRole =
      userInfo?.isPersonal || userInfo?.isPhysio
        ? PROFESSIONAL_ROLE
        : PATIENT_ROLE;

    const planProfessional =
      userDetails?.plan?._id ?? userInfo.planProfessional;

    if (
      userDetails?.plan?._id &&
      userDetails?.plan?._id !== userInfo.planProfessional
    ) {
      dispatch(updateUserInfo({ planProfessional }, userInfo));
    }

    return {
      ...userInfo,
      userRole,
      isPatient: userRole === PATIENT_ROLE,
      isProfessional: userRole === PROFESSIONAL_ROLE,
      planProfessional,
      userDetails,
      userViews,
    };
  }

  return { userRole: null, isPatient: null };
}
