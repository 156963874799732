import React, { useEffect } from "react";
import PlansV2 from "./Configs/PlansV2";
import { PageRouteContainer } from "../PageRouteContainer";
import { useReadUserDetailsQuery } from "../../queries";
import { PLANS_IDS } from "../../constants/plan.consts";
import { useDispatch } from "react-redux";
import { logout, updateUserInfo } from "../../actions/userActions";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useUserInfo } from "../../hooks/useUserInfo";

export function Payment() {
  const userInfo = useUserInfo();

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      [PLANS_IDS.BASIC_MONTH, PLANS_IDS.BASIC_YEAR].includes(
        userInfo?.planProfessional
      )
    ) {
      history.replace("/home/personal");
    }
  }, [userInfo]);

  return (
    <PageRouteContainer
      changeVisbility={() => {}}
      getModalBody={() => {}}
      unsetModalBody={() => {}}
      withNavbar={false}
      className="bg-body-primary"
    >
      <div className="p-0 m-0 pt-0">
        <div className="h2 mb-3 text-primary fw-500">
          <span className="me-2 pointer" onClick={() => history.goBack()}>
            {"<"}
          </span>
          Pagamento
        </div>
        <PlansV2></PlansV2>
      </div>
    </PageRouteContainer>
  );
}
