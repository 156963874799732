import { React, useState, useEffect, useMemo } from "react";
import { Row, Form, ListGroup, Spinner } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { listPacients } from "../../actions/pacientActions";
import { useDispatch, useSelector } from "react-redux";

export function SearchPatients({
  defaultSelectedName = null,
  excludeIds = [],
  handleResult = () => {},
  required = false,
  customRequiredText = null,

  disabled = false,
}) {
  const [selected, setSelected] = useState();
  const { pacients, pages, page } = useSelector((state) => state.pacientList);
  const [text, setText] = useState(defaultSelectedName);
  const IsReachedEnd = page ?? 0 >= pages ?? 0;
  const [allPatients, setAllPatients] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOnSelected = (selected) => {
    setText(selected.name);
    setSelected(selected);
  };

  useEffect(() => {
    dispatch(listPacients(1));
    dispatch(listPacients(2));
  }, []);

  const fetchPatients = () => {
    if (!page || !pages) return;
    if (page + 1 > pages) return;

    dispatch(listPacients(page + 1, text));
  };

  const onScroll = (e) => {
    const { target } = e;

    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      fetchPatients();
    }
  };

  useEffect(() => {
    if (!pacients) return;

    setAllPatients((prev) => {
      const values = [...prev, ...pacients];

      const uniques = values.reduce((acc, current) => {
        if (!acc.find((p) => p.id === current.id)) {
          acc.push(current);
        }

        return acc;
      }, []);

      return uniques;
    });
  }, [pacients]);

  useEffect(() => {
    handleResult(selected);
  }, [selected]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setAllPatients([]);
      dispatch(listPacients(1, text));
    }, 300);

    return () => clearTimeout(timeId);
  }, [text]);

  const filteredPatients = useMemo(() => {
    return allPatients
      .filter((p) => !excludeIds.includes(p.id))
      .reduce((acc, item, index) => {
        if (acc.find((patient) => patient.id === item.id)) {
          return acc;
        }

        return [...acc, item];
      }, []);
  }, [text, allPatients]);

  useEffect(() => {
    const listener = function (e) {
      const isChild = document
        .getElementById("searchPatientsForm")
        .contains(e.target);

      const id = e.target?.id ?? "";

      if (isChild || id.includes("searchPatientItem")) {
      } else {
        setOpen(false);
      }
    };

    window.addEventListener("click", listener);

    return () => window.removeEventListener("click", listener);
  }, []);

  useEffect(() => {
    if (defaultSelectedName) setText(defaultSelectedName);
  }, [defaultSelectedName]);

  useEffect(() => {
    if (text?.length !== undefined && text.length === 0) {
      setSelected(null);
    }
  }, [text]);

  const error =
    (text || text === "") && !open && !selected && required
      ? "Aluno não selecionado"
      : null;

  return (
    <Row className="m-0 p-0">
      <Form.Group id="searchPatientsForm" className="d-flex flex-column p-0">
        {error && (
          <Form.Control.Feedback type="invalid" className="d-flex">
            {customRequiredText ?? error}
          </Form.Control.Feedback>
        )}
        <Form.Control
          disabled={disabled}
          className={
            "" +
            (selected && " fw-bold text-primary ") +
            (error && " text-danger ")
          }
          onClick={() => {
            setOpen(true);
            setText(null);
            setSelected(undefined);
          }}
          onChange={(e) => {
            setSelected(undefined);
            setText(e.target.value);
          }}
          value={text}
          type="text"
          placeholder="Alunos"
          required={required}
        ></Form.Control>

        {!selected && open && (
          <ListGroup
            style={{ overflowY: "scroll", height: 150 }}
            className="d-flex flex-column"
            onScroll={onScroll}
          >
            {filteredPatients.map((p) => (
              <ListGroup.Item
                id={"searchPatientItem" + p.id}
                onClick={() => handleOnSelected(p)}
                key={p.id}
              >
                {p.name}
              </ListGroup.Item>
            ))}

            {IsReachedEnd ? (
              <></>
            ) : (
              <div className="align-self-center my-4">
                <Spinner
                  animation="border"
                  role="status"
                  style={{
                    height: "25px",
                    width: "25px",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )}
          </ListGroup>
        )}
      </Form.Group>
    </Row>
  );
}
