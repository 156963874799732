import React, { useState } from "react";

import personPng from "../../../assets/v2/icons/user.png";
import { Image } from "react-bootstrap";

import { getDaysLeftText } from "../../../utils/date";
import { PatientFinanceModal } from "./PatientFinanceModal";

export function FinanceCardItem({
  patientFinance = {
    lastFinance: undefined,
    patient: undefined,
  },
}) {
  const { lastFinance, patient } = patientFinance;

  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <div
      className="bg-white rounded d-flex"
      onClick={() => setShowEditModal(true)}
      style={{ minHeight: 70 }}
    >
      <Image
        width={100}
        src={patient?.profileImage?.image ?? personPng}
        style={{ objectFit: "cover", height: 100, minWidth: 100 }}
      ></Image>

      <div className="d-flex flex-column w-100 ms-2 me-2 ">
        <div className="flex-between w-100 mt-2">
          <p className="mb-0">{patientFinance?.patient?.name}</p>
          {lastFinance?.value && (
            <p className="mb-0 text-primary fw-bold"> ${lastFinance?.value}</p>
          )}
        </div>

        {lastFinance?.dateFinal && (
          <p className="mb-0 fw-bold text-small fs-7 mt-2">
            <span className="">
              {getDaysLeftText(lastFinance?.dateFinal).join("")}
            </span>
          </p>
        )}
      </div>

      {showEditModal && (
        <PatientFinanceModal
          vinculed={true}
          onClose={() => {
            setShowEditModal(false);
          }}
          patientFinance={patientFinance}
        ></PatientFinanceModal>
      )}
    </div>
  );
}
