import React, { useState } from "react";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { PaymentForm } from "../Configs/PaymentForm";
import { Card, Row } from "react-bootstrap";
import { formatDate } from "../../../utils/date";

export function PatientPayment() {
  const { userDetails } = useUserInfo();

  const plansPatient = userDetails?.plansPatient;
  const charge = userDetails?.charge;
  const professional = userDetails?.professional;

  const plan = { title: plansPatient?.name_type };

  const [renderInfo, setRenderInfo] = useState(true);

  if (plansPatient?._id === 1 || !charge) {
    return (
      <h5>
        Você não possui nenhum plano ou cobrança associado ao profissional.
      </h5>
    );
  }

  return (
    <section className="row m-0 p-0">
      <h3 className="text-primary mb-3">{plansPatient?.name_type}</h3>

      {renderInfo && (
        <Row className="m-0 p-0 col-12 col-md-12 col-lg-6 h-100 mb-3">
          <Card className="col-12">
            <Card.Header className="bg-white">
              <Card.Title className="h5 mb-0">Resumo</Card.Title>
            </Card.Header>
            <Card.Body className="row m-0  p-0 pt-3 px-2">
              <Card.Text className="col-5 col-lg-4 flex-column flex-md-row">
                Cobrado pelo profissional:{" "}
                <p className="text-primary fw-500 m-0">{professional?.name}</p>
              </Card.Text>
              <Card.Text className="col-4 flex-center align-items-start text-primary fw-500">
                {plansPatient?.name_type?.split(" ")[1]}
              </Card.Text>
              <Card.Text className="col-3">
                Valor Final:{" "}
                <span className="text-primary fw-bold">
                  R$ {charge?.value?.toFixed(2).replace(".", ",")}
                </span>
              </Card.Text>

              <Card.Text className="col-5 col-lg-4 ">
                Status:{" "}
                <span className="text-primary fw-500">
                  {charge?.status ? "Ativo" : "Pendente"}
                </span>
              </Card.Text>
              <Card.Text className="col-auto mb-3 flex-center flex-column text-center flex-md-row">
                Data de Pagamento:{" "}
                <span className="text-primary ms-1 fw-500">
                  {formatDate(charge?.nextChargeDate)}
                </span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      )}

      <PaymentForm
        isMonthly={true}
        onPayFinish={() => {
          setRenderInfo(true);
        }}
        planId={plansPatient?._id}
        planPrice={charge?.value}
        goBack={() => {
          setRenderInfo(true);
        }}
        onConfirm={() => {
          setRenderInfo(true);
        }}
        plan={plan}
        renderResume={false}
        renderCupomInput={false}
      ></PaymentForm>
    </section>
  );
}
