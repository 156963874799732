import { useMutation } from "@tanstack/react-query";
import api from "../services/api";

import { useApiConfig } from "../hooks/useApiConfig";

export function useExerciseDoneMutation() {
  const config = useApiConfig();

  const createPostMut = useMutation({
    mutationFn: async (params) => {
      //   {
      //     "exercise_id" : 214,
      //     "workout_id" : 301,
      //     "currentExercise": 1,
      //     "totalExercise" : 10,
      //     "currentSerie" : 1,
      //     "totalSerie": 3,
      //     "pace" : 0.4,
      //     "progress": 0.0
      // }
      return api.post("api/exercises_done/post/create/", params, config);
    },
  });

  const createExerciseDoneMut = useMutation({
    mutationFn: async (params) => {
      // "exercise_id" : 214,
      // "finish_serie" : null,
      // "professional_id" : 15,
      // "workout_id" : 301,
      // "post_id" : 7,
      // "currentSerie" : 1,
      // "weight" : 10,
      // "restTime" : 50
      //"exerciseVideo_id" : 734,
      // "hasFeedback" : true,
      // "mediaTimeUp": 1.436,
      //     "SDTimeUp": 0.125,
      //     "mediaTimeDown": 1.85,
      //     "SDTimeDown": 0.599,
      //     "mediaUp": [
      //         41.667,
      //         6.667,
      //         178.667
      //     ],
      //     "SDUp": [
      //         0.471,
      //         0.943,
      //         0.943
      //     ],
      //     "mediaDown": [
      //         156.667,
      //         1.667,
      //         172.667
      //     ],
      //     "SDDown": [
      //         2.055,
      //         2.357,
      //         1.247
      //     ],
      //     "count": 3.0,
      //     "stoppedArticulation": [
      //         false,
      //         false,
      //         true
      //     ],
      //     "articulation_id" : [
      //         3,
      //         2,
      //         1
      //     ]
      return api.post("api/exercises_done/create/", params, config);
    },
  });

  const createExerciseNotDoneMut = useMutation({
    mutationFn: async (params) => {
      // "exercise_id" : 214,
      // "finish_serie" : null,
      // "professional_id" : 15,
      // "workout_id" : 301,
      // "post_id" : 7

      return api.post("api/exercises_done/create/notdone/", params, config);
    },
  });

  const editExerciseDonePostMut = useMutation({
    mutationFn: async (params) => {
      //   {
      //     "exercise_id" : 214,
      //     "currentExercise": 1,
      //     "totalExercise" : 10,
      //     "currentSerie" : 2,
      //     "totalSerie": 3,
      //     "progress": 0.4
      // }
      const { postId, pace, currentProgress, skipProgress, ...rest } = params;

      const progress = pace + currentProgress;
      rest.progress = skipProgress ? currentProgress : progress;

      return api.put(`api/exercises_done/post/edit/${postId}/`, rest, config);
    },
  });

  const finishPostExerciseMut = useMutation({
    mutationFn: async (params) => {
      const { postId, ...rest } = params;

      return api.put(
        `api/exercises_done/post/finish/edit/${postId}/`,
        rest,
        config
      );
    },
  });

  return {
    createPostMut,
    createExerciseDoneMut,
    editExerciseDonePostMut,
    finishPostExerciseMut,
    createExerciseNotDoneMut,
  };
}
