import {
  usePatientWorkoutQuery,
  useReadExerciseDetailsQuery,
  useReadExercisePostQuery,
} from "../../queries";
import { useExerciseDoneMutation } from "../../mutations";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import api from "../../services/api";
import { useApiConfig } from "../../hooks/useApiConfig";

export function useController() {
  const history = useHistory();
  const { postId, workoutId, exerciseId } = useParams();

  const config = useApiConfig();

  const { exercisePost, readExercisePostQuery } = useReadExercisePostQuery({
    enabled: true,
    params: { postId, exerciseId },
  });

  const { editExerciseDonePostMut, createExerciseNotDoneMut } =
    useExerciseDoneMutation();
  const { patientWorkouts, patientWorkoutQuery } = usePatientWorkoutQuery({
    patientWorkoutQueryOptions: { enabled: true },
  });

  const [showExecuteModal, setShowExecuteModal] = useState(false);

  const [restTimerCount, setRestTimerCount] = useState(undefined);

  const { post, exercises_done } = exercisePost ?? {
    post: {},
    exercises_done: [],
  };

  const {
    lastExercise,
    totalSerie,
    currentSerie,
    workout,
    currentExercise,
    totalExercise,
    progress,
    pace,
  } = post;

  const { exercise, readExerciseQuery } = useReadExerciseDetailsQuery({
    enabled: !!lastExercise?._id,
    params: { id: lastExercise?._id },
  });

  const currentPatientWorkout = useMemo(() => {
    const result = patientWorkouts.filter((pW) =>
      pW?.ExerciciosFicha[0]?.find(
        (e) => e?.workoutSheet?.workout?._id === workout?._id
      )
    );

    if (result && result[0]) return result[0];

    return null;
  }, [patientWorkouts, post]);

  const currentSheetItem = useMemo(() => {
    if (!currentPatientWorkout?.ExerciciosFicha[0]) return;
    if (currentExercise === undefined) return;

    return currentPatientWorkout?.ExerciciosFicha[0][currentExercise - 1];
  }, [currentPatientWorkout, post]);

  const nextSheetItem =
    currentPatientWorkout?.ExerciciosFicha[0][currentExercise];

  const serieArray = useMemo(() => {
    return Array.from(Array(totalSerie).keys());
  }, [totalSerie]);

  const handleExecuteClick = (e) => {
    e.preventDefault();
    setShowExecuteModal(true);
  };

  const handleNextExerciseClick = async (e) => {
    e.preventDefault();

    if (nextSheetItem) {
      const response = await api.get(
        `api/exercises_done/post/read/${postId}/${nextSheetItem?.exercise?._id}/`,
        config
      );

      const eDs = response.data.exercises_done ?? [];

      let editPostData = {
        postId: post?._id,
        exercise_id: nextSheetItem?.exercise?._id,
        currentExercise: currentExercise + 1,
        totalExercise: totalExercise,
        currentSerie: eDs.length,
        totalSerie: nextSheetItem?.serie,
        currentProgress: progress,
        pace,
        skipProgress: true,
      };

      await editExerciseDonePostMut.mutateAsync(editPostData);

      history.replace(
        `/home/pacient/workoutsheet/execution/${nextSheetItem?.exercise?._id}/${post?._id}`
      );
    }
  };

  const handleRestClick = (e, time) => {
    e.preventDefault();

    setRestTimerCount(time);
  };

  const handleSkipSerie = async (e) => {
    e.preventDefault();

    const response = await createExerciseNotDoneMut.mutateAsync({
      exercise_id: currentSheetItem?.exercise?._id,
      finish_serie: null,
      professional_id: currentSheetItem?.exercise?.professional,
      workout_id: workout?._id,
      post_id: post?._id,
    });

    let editPostData = {
      postId: post?._id,
      exercise_id: currentSheetItem?.exercise?._id,
      currentExercise: currentExercise,
      totalExercise: totalExercise,
      currentSerie: currentSerie + 1,
      totalSerie: totalSerie,
      pace,
      currentProgress: progress,
      skipProgress: true,
    };

    await editExerciseDonePostMut.mutateAsync(editPostData);

    readExercisePostQuery.refetch();
  };

  const isLoading =
    readExercisePostQuery.isFetching ||
    patientWorkoutQuery.isFetching ||
    readExerciseQuery.isFetching;

  return {
    showExecuteModal,
    handleSkipSerie,
    handleRestClick,
    handleExecuteClick,
    handleNextExerciseClick,
    serieArray,
    exercise,
    exercises_done,
    post,
    readExercisePostQuery,
    nextSheetItem,
    isLoading,
    currentSheetItem,
    exercisePost,
    setRestTimerCount,
    setShowExecuteModal,
    currentPatientWorkout,
    workout,
    restTimerCount,
  };
}
