import { dataURLtoFile } from "./file.utils";

export function getValueKeyByQuestionType(questionType, isPatientForm) {
  if (questionType === "0") return "answerText";
  if (questionType === "1") return "choice_id";
  if (questionType === "2")
    return isPatientForm ? "imageForm_id" : "imageEvaluation_id";
}

export async function getQuestionsAnswers({
  questions,
  uploadImageMutation,
  key = "question_id",
  isPatientForm = false,
}) {
  const questionsAnswers = [];
  for (let i = 0; i < questions.length; i++) {
    const data = questions[i];

    const valueKey = getValueKeyByQuestionType(data.type, isPatientForm);

    let value = data.value ?? data.newValue;

    if (data.type === "2") {
      const ids = [];

      for (let imgIndex = 0; imgIndex < value.length; imgIndex++) {
        const name = `questionImage${data.id}index${imgIndex}.png`;

        const fileImage = await dataURLtoFile(data.value[imgIndex], name);

        const responseImage = await uploadImageMutation.mutateAsync({
          file: fileImage,
          caption: name,
        });

        ids.push(responseImage.data._id);
      }

      value = ids;
    }

    questionsAnswers.push({ [key]: data.id, [valueKey]: value });
  }

  return questionsAnswers;
}
