import { stringToDate, formatDate } from "../../../utils/date";
import Stepbar from "../../../components/v2/Stepbar/Stepbar";
import records from "../../../assets/v2/medical-records-list.css";
import { useState, useEffect } from "react";
import { Button, Col, Container, Modal, Row, Image } from "react-bootstrap";
import PaginationV2 from "../../../components/v2/PaginationV2";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteMedicalRecord,
  listMedicalRecords,
} from "../../../actions/medicalRecordsActions";
import trash from "../../../assets/v2/icons/trash.svg";
import { useUserInfo } from "../../../hooks/useUserInfo";

function MedicalRecordsV2({ medicalRecordsList, openModal, patientId }) {
  const [allMedicalModalShow, setAllMedicalModalShow] = useState(false);

  const deleteMedicalRecordState = useSelector(
    (state) => state.medicalRecordDelete
  );
  const dispatch = useDispatch();

  const { isPatient } = useUserInfo();

  const {
    loading,
    error,
    medical_records,
    page,
    pages: pagesMedicalRecords,
  } = useSelector((state) => state.medicalRecordList);

  const [pages, setPages] = useState(0);

  const fetchPage = (page) => {
    dispatch(listMedicalRecords(patientId, page));
  };

  useEffect(() => {
    if (pagesMedicalRecords) {
      setPages(pagesMedicalRecords);
    }
  }, [pagesMedicalRecords]);

  useEffect(() => {
    dispatch(listMedicalRecords(patientId, 1));
  }, [deleteMedicalRecordState.payload]);

  const handleDeleteClick = (id) => {
    dispatch(deleteMedicalRecord(id));
  };

  const renderBody = (wk, index) => {
    return (
      <Col
        key={index}
        md={12}
        className="mrl-item-root d-flex align-items-center justify-content-between"
        style={{ cursor: "auto" }}
      >
        <div
          onClick={() => openModal(wk._id)}
          className="mrl-item d-flex flex-row justify-content-between align-item-center"
        >
          Prontuário {String(index + 1).padStart(2, "0")} -{" "}
          {formatDate(wk.createdAt)}
        </div>

        {!isPatient && (
          <div className="ms-2">
            <Image
              className="pointer ms-auto"
              src={trash}
              onClick={() => handleDeleteClick(wk._id)}
            />
          </div>
        )}
      </Col>
    );
  };

  return (
    <>
      <style src={records}></style>
      <div className="mrl-root">
        <div>
          <div className="d-flex justify-content-between align-items-center mrl-header">
            <h3>Prontuários</h3>
            {!isPatient && (
              <div className="add-button" onClick={() => openModal()}>
                <img alt="adicionar" src="assets/v2/icons/plus.svg" />
              </div>
            )}
          </div>
          <Row className="">
            {medicalRecordsList?.map((wk, index) => renderBody(wk, index))}
            <p
              onClick={() => setAllMedicalModalShow(true)}
              className="text-primary fw-500 text-end mt-3 pointer"
            >
              {"Ver todos >>"}
            </p>
          </Row>
        </div>
      </div>

      <Modal
        show={allMedicalModalShow}
        onHide={() => setAllMedicalModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-4 pal-root">
          <div className="d-flex justify-content-between mb-4 pal-header">
            <h3 className="title">Prontuários</h3>
            <div
              className="title-action"
              onClick={() => setAllMedicalModalShow(false)}
            >
              <img alt="close icon" src="/assets/v2/icons/x-square.png" />
            </div>
          </div>

          <Row className="">
            {medicalRecordsList?.map((wk, index) => renderBody(wk, index))}
          </Row>

          <PaginationV2
            pages={pages}
            currentPage={page}
            setCurrentPage={fetchPage}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MedicalRecordsV2;
