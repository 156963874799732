import { useMutation } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";

export function useWorkoutMutation() {
  const config = useApiConfig();

  const createWorkoutSheetMutation = useMutation({
    mutationFn: async (params) => {
      const { pacientId, sheet } = params;

      const { workoutID, ...data } = sheet;

      return api.post(
        `/api/workouts/pacient/create/${pacientId}/${workoutID}/`,
        data,
        config
      );
    },
  });

  const editWorkoutSheetMutation = useMutation({
    mutationFn: async (params) => {
      const { patientSheetId, workoutSheetId, sheet } = params;

      return api.put(
        `/api/workouts/pacient/edit/${patientSheetId}/`,
        sheet,
        config
      );
    },
  });

  return { createWorkoutSheetMutation, editWorkoutSheetMutation };
}
