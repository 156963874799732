import { useMutation } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";

export function useRoutineMutation() {
  const config = useApiConfig();

  //   {
  //     "name" : "Treino emagrecimento",
  //     "description" : "treino para mulheres entre 30 a 50 anos",
  //     "tag" : "emagrecimento",
  //     "workouts" : [4, 5, 7] // caso seja criado sem exercicio exercises = []
  // }
  const createRoutine = useMutation({
    mutationFn: async (params) => {
      return api.post(`/api/routines/create/`, params, config);
    },
  });

  //   {
  //     "initialDate" : "2025-06-30",
  //     "finalDate" : "2025-09-30"
  // }
  const createPatient = useMutation({
    mutationFn: async (params) => {
      const { id, patientId, ...rest } = params;

      return api.post(
        `/api/routines/pacient/create/${patientId}/${id}/`,
        rest,
        config
      );
    },
  });

  const createPatientWorkout = useMutation({
    mutationFn: async (params) => {
      const { patientId, routinePatientId, ...rest } = params;

      return api.post(
        `/api/routines/pacient/workouts/create/${patientId}/${routinePatientId}/`,
        rest,
        config
      );
    },
  });

  //   {
  //     "name" : "Treino emagrecimento at",
  //     "description" : "treino para mulheres entre 30 a 50 anos at",
  //     "tag" : "emagrecimento at",
  //     "workouts" : [2] // eh adicionar
  // }
  const updateRoutine = useMutation({
    mutationFn: async (params) => {
      const { id, ...rest } = params;

      return api.put(`/api/routines/edit/${id}/`, rest, config);
    },
  });

  //   {
  //     "days" : [1, 2]
  // }
  const updateRoutineWorkout = useMutation({
    mutationFn: async (params) => {
      const { id, ...rest } = params;

      return api.put(`/api/routines/workout/edit/${id}/`, rest, config);
    },
  });

  //   {
  //     "initialDate" : "2025-07-30",
  //     "finalDate" : "2025-08-30"
  // }
  const updatePatient = useMutation({
    mutationFn: async (params) => {
      const { id, routineId, ...rest } = params;

      return api.put(`/api/routines/pacient/edit/${id}/`, rest, config);
    },
  });

  const updateRoutinePatientWorkoutMutation = useMutation({
    mutationFn: async (params) => {
      const { patientId, ...rest } = params;

      return api.put(
        `/api/routines/pacient/workouts/edit/${patientId}/`,
        rest,
        config
      );
    },
  });

  const deleteRoutine = useMutation({
    mutationFn: async (params) => {
      const { id } = params;

      return api.delete(`/api/routines/delete/${id}/`, config);
    },
  });

  const deleteRoutinePatient = useMutation({
    mutationFn: async (params) => {
      const { id } = params;

      return api.delete(`/api/routines/pacient/delete/${id}/`, config);
    },
  });

  const deleteRoutineWorkout = useMutation({
    mutationFn: async (params) => {
      const { id } = params;

      return api.delete(`/api/routines/workout/delete/${id}/`, config);
    },
  });

  return {
    createRoutine,
    createPatient,
    updateRoutine,
    updatePatient,
    updateRoutinePatientWorkoutMutation,
    updateRoutineWorkout,
    deleteRoutine,
    deleteRoutinePatient,
    deleteRoutineWorkout,
    createPatientWorkout,
  };
}
