import { useMutation } from "@tanstack/react-query";
import api from "../services/axios";
import { useApiConfig } from "../hooks/useApiConfig";

export function useFinanceMutation() {
  const config = useApiConfig();

  //   {
  //     "name" : "Test Asd1",
  //     "category" : 0,
  //     "date" : "2024-03-12",
  //     "dateInitial" : "2024-03-12",
  //     "dateFinal" : "2024-04-12",
  //     "note" : "teste teste",
  //     "value" : 150.5,
  //     "patient_id" : 8,
  //     "plansPatient_id":
  // }
  const createFinance = useMutation({
    mutationFn: async (params) => {
      return api.post(`/api/management/finance/create/`, params, config);
    },
  });

  //   {
  //     "name" : "Test Asd1",
  //     "category" : 0,
  //     "date" : "2024-03-12",
  //     "dateInitial" : "2024-02-12",
  //     "dateFinal" : "2024-02-12",
  //     "note" : "teste teste",
  //     "value" : 120.5,
  //     "patient_id" : 8,
  //     "plansPatient_id": 2
  // }
  const updateFinance = useMutation({
    mutationFn: async (params) => {
      const { id, ...rest } = params;

      return api.put(`/api/management/finance/edit/${id}/`, rest, config);
    },
  });

  const deleteFinance = useMutation({
    mutationFn: async (params) => {
      const { id } = params;

      return api.delete(`/api/management/finance/delete/${id}/`, config);
    },
  });

  return { createFinance, updateFinance, deleteFinance };
}
