import React from "react";
import { useTranslation } from "react-i18next";

export function LangSwitcher() {
  const { i18n } = useTranslation();

  const selectedClass = " bg-primary text-white";

  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault();
          i18n.changeLanguage("pt");
        }}
        className={"btn fw-500" + (i18n.language === "pt" ? selectedClass : "")}
      >
        PT
      </button>

      <button
        onClick={(e) => {
          e.preventDefault();
          i18n.changeLanguage("en");
        }}
        className={"btn fw-500" + (i18n.language === "en" ? selectedClass : "")}
      >
        EN
      </button>
    </div>
  );
}
