import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CloseButton,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import VideoPlayer from "./v2/VideoPlayer";
import WebcamStreamCapture from "./v2/WebcamStreamCapture";
import { useExerciseInputVideoController } from "../controllers";
import { useExerciseDoneMutation, useExerciseMutation } from "../mutations";
import { WebcamPlayer } from "./v2/WebcamPlayer";
import { SpinnerButton } from "./v2/SpinnerButton";
import { ResultFinalCard } from "./ResultFinalCard";
import { useReadExerciseDetailsQuery } from "../queries";

function SheetItemExecution({
  weigthValue,
  repetitionsValue,
  exercisePost,
  onFinish = () => {},
}) {
  const { post } = exercisePost;

  const exercise = post.lastExercise;
  const {
    currentSerie,
    workout,
    totalSerie,
    progress,
    currentExercise,
    totalExercise,
    pace,
  } = post;

  const hasFeedback = exercise?.hasFeedback;
  const muscleName = exercise?.muscle?.name;

  const [{ weigth, repetitions }, setFormData] = useState({
    weigth: weigthValue,
    repetitions: repetitionsValue,
  });

  const { createExerciseDoneMut, editExerciseDonePostMut } =
    useExerciseDoneMutation();

  const { exercise: exerciseDetails } = useReadExerciseDetailsQuery({
    params: { id: exercise?._id, enabled: !!exercise?._id },
  });

  const handleSendClick = async (e, { videoToUpload, feedbackResultFinal }) => {
    e?.preventDefault();

    let data = {
      exercise_id: exercise?._id,
      finish_serie: null,
      professional_id: exercise?.professional,
      workout_id: workout?._id,
      post_id: post._id,
      currentSerie: currentSerie,
      weight: Number(weigth),
      restTime: exercise?.restTime,
      exerciseVideo_id: videoToUpload?._id,
      hasFeedback: !!feedbackResultFinal,
      count: Number(repetitions),
    };

    if (hasFeedback && feedbackResultFinal)
      data = { ...data, ...feedbackResultFinal };

    await createExerciseDoneMut.mutateAsync(data);

    let editPostData = {
      postId: post?._id,
      exercise_id: exercise._id,
      currentExercise: currentExercise,
      totalExercise: totalExercise,
      currentSerie: currentSerie + 1,
      totalSerie: totalSerie,
      pace,
      currentProgress: progress,
    };

    await editExerciseDonePostMut.mutateAsync(editPostData);

    onFinish();
  };

  const exerciseInputController = useExerciseInputVideoController({
    withFeedback: exercise?.hasFeedback,
    muscleName,
    handleSendClick,
    professionalFeedback: exerciseDetails?.feedback?.socketFeedback,
  });

  const {
    renderActions,
    videoToUpload,
    renderPlayer,
    feedbackResultFinal,
    renderWithFeedbackInput,
    videoIsLoading,
  } = exerciseInputController;

  return (
    <Form>
      <Modal.Header>
        <Modal.Title>
          Serie {currentSerie} - {exercise?.name}
        </Modal.Title>
        <CloseButton
          className="btn text-danger fs-4 my-0 py-0"
          onClick={() => onFinish()}
        />
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="">
          <Form.Label className="fw-bold">Peso Usado</Form.Label>
          <Form.Control
            value={weigth}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, weigth: e.target.value }))
            }
            type="number"
            placeholder="Peso"
            required
          />
        </Form.Group>

        <Form.Group className="mt-2">
          <Form.Label className="fw-bold text-nowrap">
            Repetições Feitas
          </Form.Label>
          <Form.Control
            value={repetitions}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, repetitions: e.target.value }))
            }
            type="number"
            placeholder="Repetições"
            required
          />
        </Form.Group>

        <div className="mt-2"></div>

        {exercise?.hasFeedback && renderWithFeedbackInput}

        <div className="mt-2"></div>

        {renderPlayer}

        <div className="mt-2"></div>

        {feedbackResultFinal && (
          <ResultFinalCard resultFinal={feedbackResultFinal}></ResultFinalCard>
        )}
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        {videoToUpload?.url && <div></div>}

        <div>{renderActions}</div>

        {!videoToUpload?.url && (
          <Button
            disabled={createExerciseDoneMut.isPending || videoIsLoading}
            onClick={(e) => handleSendClick(e, {})}
          >
            {createExerciseDoneMut.isPending ? (
              <SpinnerButton></SpinnerButton>
            ) : (
              "Feito"
            )}
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
}

export default SheetItemExecution;
