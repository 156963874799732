import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal, Spinner } from "react-bootstrap";
import useFormReducer from "./FormReducer";
import { register } from "../../../actions/userActions";
import signUp from "../../../assets/v2/sign-up-page.css";
import { Link } from "react-router-dom";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { t } from "i18next";

function SignUpScreenV2({ location, history }) {
  const dispatchRedux = useDispatch();
  const { state, dispatch } = useFormReducer();

  const [successModalShow, setSuccessModalShow] = useState(false);

  const { isLowerHeight } = useMediaQueryValues();

  const handleChange = (nameField, value) => {
    dispatch({ type: nameField, payload: value });
  };

  const redirect = location.search ? location.search.split("=")[1] : "/home";

  const { error, loading, userInfo } = useSelector(
    (state) => state.userRegister
  );

  const closeSuccessModal = () => {
    setSuccessModalShow(false);
    history.replace("/login");
  };

  useEffect(() => {
    if (userInfo) {
      setSuccessModalShow(true);
    }
  }, [history, userInfo, redirect]);

  useEffect(() => {
    console.log(error, loading, userInfo);
  }, [error, loading, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatchRedux(
      register(
        state.name,
        state.email,
        state.password,
        state.re_password,
        state.role === "isPersonal",
        state.role === "isPhysiotherapist",
        state.role === "isPacient"
      )
    );
  };

  useEffect(() => {
    // set default value
    handleChange("SET_ROLE", "isPersonal");
  }, []);

  return (
    <div
      className="main-background-2 h-100"
      style={{ overflow: "hidden", overflowY: "scroll" }}
    >
      <style src={signUp}></style>
      <div className="page-alert">
        {error && <Alert variant="danger">{error}</Alert>}
        {userInfo && <Alert variant="success">{""}</Alert>}
      </div>
      <div className="main-background sign-up-container h-100 main-background-2">
        <form
          className="page-card d-flex justify-content-center flex-column rounded-0 pt-0 sign-up-form-container"
          onSubmit={submitHandler}
          style={
            isLowerHeight
              ? {
                  transform: `scale(.7, .7)`,
                  maxHeight: "700px",
                }
              : {}
          }
        >
          <Link to={"/"} className="align-self-center">
            <img
              className="mb-2 singup-logo"
              src="assets/v2/icons/logo.svg"
              alt=""
            />
          </Link>

          <div className="page-title sing-up-title">{t("signup.title")}</div>
          {/* <button className="page-with-google" type="button">
            <img src="assets/v2/icons/login/google.svg" alt="" />
            Fazer cadastro com o Google
          </button> */}
          <div className="page-user-input">
            <label>{t("field.name")}</label>
            <input
              type="text"
              placeholder={t("field.namePlaceholder")}
              value={state.name}
              onChange={(e) => handleChange("SET_NAME", e.target.value)}
              required
            />
            {state.errors?.name && <div>{t("error.invalidField")}</div>}
          </div>
          <div className="page-user-input mb">
            <label>E-mail</label>
            <input
              type="email"
              placeholder={t("field.email")}
              value={state.email}
              onChange={(e) => handleChange("SET_EMAIL", e.target.value)}
              required
            />
            {state.errors?.email && <div>{t("error.invalidField")}</div>}
          </div>
          <div className="page-user-input mb">
            <label>{t("field.role")}</label>
            <select
              value={state.role}
              onChange={(e) => handleChange("SET_ROLE", e.target.value)}
              defaultValue={"isPersonal"}
              required
            >
              <option value={"isPersonal"}>
                {t("field.roleOption.personal")}
              </option>
              <option value={"isPhysiotherapist"}>
                {t("field.roleOption.physio")}
              </option>
              <option value={"isPacient"}>
                {t("field.roleOption.patient")}
              </option>
            </select>
            {state.errors?.role && <div>{t("error.invalidField")}</div>}
          </div>
          {state.role !== "isPacient" && (
            <div className="page-user-input mb">
              <label>{t("field.plan")}</label>
              <select defaultValue={"0"} required>
                <option value={"0"}>{t("field.planOption.basic")}</option>
              </select>
              {state.errors?.role && <div>{t("error.invalidField")}</div>}
            </div>
          )}
          <div className="page-passwords-input d-flex flex-column flex-lg-row">
            <div className="page-user-input pass-input">
              <label>{t("field.password")}</label>
              <input
                type="password"
                placeholder="*********"
                value={state.password}
                onChange={(e) => handleChange("SET_PASSWORD", e.target.value)}
                required
              />
            </div>
            <div className="page-user-input pass-input">
              <label>{t("field.confirmPassword")}</label>
              <input
                type="password"
                placeholder="*********"
                value={state.re_password}
                onChange={(e) =>
                  handleChange("SET_CONFIRM_PASSWORD", e.target.value)
                }
                required
              />
              {state.errors?.re_password && (
                <div>{t("error.invalidField")}</div>
              )}
            </div>
          </div>
          <div className="page-footer flex-col justify-content-between">
            <div className="page-signin">
              {t("register.alreadyHaveAccount")}
              <a href="/#/login">{t("register.login")}</a>
            </div>
            <button disabled={loading} className="page-btn-login">
              {loading ? (
                <div style={{}}>
                  <Spinner
                    style={{ height: 25, width: 25 }}
                    animation="border"
                    role="status"
                  />
                </div>
              ) : (
                t("button.create")
              )}
            </button>
          </div>
        </form>
      </div>

      <Modal
        show={successModalShow}
        onHide={() => closeSuccessModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h2 className="h1 text-primary">{t("createdAccount.text1")}</h2>
          <b>{state.name}</b>
          {t("createdAccount.text2")}
          <b>{state.email}</b> {t("createdAccount.text4")}
          <br />
          <b>{t("createdAccount.text5")}</b> {t("createdAccount.text6")}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SignUpScreenV2;
