import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { ModalHeader } from "../../../components/ModalHeader";

import PacientProfileV2 from "../PacientDetailsV2/PacientProfileV2";
import { FINANCE_PLANS } from "../../../constants/plan.consts";

import { FinanceSheet } from "./FinanceSheet";
import { useReadPatientFinancesQuery } from "../../../queries";
import { formatDate, getDaysLeftText } from "../../../utils/date";

export function PatientFinanceModal({
  patientFinance = { patient: null, lastFinance: null },
  onClose = () => {},
  vinculed = true,
}) {
  const { patient, lastFinance } = patientFinance;

  const { finances } = useReadPatientFinancesQuery({
    params: { id: patient.id },
  });

  const dateFinal = lastFinance?.dateFinal
    ? getDaysLeftText(lastFinance?.dateFinal)
    : null;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal show={true} onHide={() => onClose()} size="lg">
        <ModalHeader
          title={"Finanças do Aluno"}
          onClose={() => onClose()}
        ></ModalHeader>

        <Modal.Body>
          <PacientProfileV2
            pacient={patient}
            withActions={false}
          ></PacientProfileV2>

          <div className="d-flex mt-5">
            <div className="row m-0 p-0  rounded">
              <p className="mb-0">
                <span className="text-primary fw-500">Tipo de Plano:</span>{" "}
                {FINANCE_PLANS[patient?.plansPatient?._id].name}
              </p>
            </div>

            {lastFinance?.dateFinal && (
              <div className="m-0 p-0 ms-5  rounded d-flex">
                <p className="mb-0">
                  <span className="text-primary fw-500">{dateFinal[0]}</span>
                  {dateFinal[1]}
                </p>
              </div>
            )}
          </div>

          <FinanceSheet
            vinculed={vinculed}
            canEdit={true}
            sheetRows={finances}
          ></FinanceSheet>
        </Modal.Body>
      </Modal>
    </div>
  );
}
