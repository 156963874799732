import axios from "../services/axios";
import {
  EXERCISE_LIST_REQUEST,
  EXERCISE_LIST_SUCCESS,
  EXERCISE_LIST_FAIL,
  MUSCLE_LIST_REQUEST,
  MUSCLE_LIST_SUCCESS,
  MUSCLE_LIST_FAIL,
  EXERCISE_DETAILS_REQUEST,
  EXERCISE_DETAILS_SUCCESS,
  EXERCISE_DETAILS_FAIL,
  EXERCISE_DELETE_REQUEST,
  EXERCISE_DELETE_SUCCESS,
  EXERCISE_DELETE_FAIL,
  EXERCISE_DELETE_RESET,
  EXERCISE_CREATE_REQUEST,
  EXERCISE_CREATE_SUCCESS,
  EXERCISE_CREATE_FAIL,
  EXERCISE_CREATE_RESET,
  EXERCISE_UPDATE_REQUEST,
  EXERCISE_UPDATE_SUCCESS,
  EXERCISE_UPDATE_FAIL,
  EXERCISE_SAVE_DATA,
  EXERCISE_SAVE_INDICATIONS,
  EXERCISE_SAVE_RESULTS,
  EXERCISE_IMAGE_CREATE_REQUEST,
  EXERCISE_IMAGE_CREATE_SUCCESS,
  EXERCISE_IMAGE_CREATE_FAIL,
  EXERCISE_VIDEO_CREATE_REQUEST,
  EXERCISE_VIDEO_CREATE_SUCCESS,
  EXERCISE_VIDEO_CREATE_FAIL,
  EXERCISE_PATTER_LIST_REQUEST,
  EXERCISE_PATTER_SUCCESS,
  EXERCISE_PATTER_FAIL,
  EXERCISE_RESET,
} from "../constants/exerciseConstants";
import { FEEDBACK_CREATE_RESET } from "../constants/feedbackConstants";

export const listExercises =
  (page, keyword = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: EXERCISE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        params: {
          page: page,
          search: keyword,
        },
      };

      const { data } = await axios.get(`/api/exercises/readall/`, config);

      dispatch({
        type: EXERCISE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXERCISE_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const resetExerciseCreate = () => (dispatch) => {
  dispatch({ type: FEEDBACK_CREATE_RESET });
  dispatch({ type: EXERCISE_CREATE_RESET });
};

export const listMyExercises = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXERCISE_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/exercises/myexercises/`, config);

    dispatch({
      type: EXERCISE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listPatterExercises = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXERCISE_PATTER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/exercises/patter/`, config);

    dispatch({
      type: EXERCISE_PATTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_PATTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listExerciseDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EXERCISE_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/exercises/read/${id}`, config);

    const response = {
      ...data.exercise,
      feedback: { results: data.results, serie: data.serie },
    };

    if (data?.serie?.length && data.serie.length > 0) {
      response.resultFinal = { v2: data };
    }

    dispatch({
      type: EXERCISE_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteExercise = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXERCISE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/exercises/delete/${id}/`, config);

    dispatch({
      type: EXERCISE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createExercise = (info) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXERCISE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/exercises/create/`, info, config);
    dispatch({
      type: EXERCISE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateExercise = (exercise) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXERCISE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/exercises/edit/${exercise._id}/`,
      exercise,
      config
    );

    dispatch({
      type: EXERCISE_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: EXERCISE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const favoriteExercise = (exercise) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXERCISE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/exercises/edit/${exercise._id}/`,
      { isFavorite: exercise.isFavorite },
      config
    );

    dispatch({
      type: EXERCISE_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: EXERCISE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listMuscles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MUSCLE_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/exercises/muscles/`, config);

    dispatch({
      type: MUSCLE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MUSCLE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const saveExerciseData = (data) => (dispatch) => {
  dispatch({
    type: EXERCISE_SAVE_DATA,
    payload: data,
  });

  localStorage.setItem("ExerciseData", JSON.stringify(data));
};

export const saveExerciseIndications = (data) => (dispatch) => {
  dispatch({
    type: EXERCISE_SAVE_INDICATIONS,
    payload: data,
  });

  localStorage.setItem("ExerciseIndications", JSON.stringify(data));
};

export const saveExerciseResults = (data) => (dispatch) => {
  dispatch({
    type: EXERCISE_SAVE_RESULTS,
    payload: data,
  });

  localStorage.setItem("ExerciseResults", JSON.stringify(data));
};

export const saveExerciseImage = (imageFile) => (dispatch) => {
  dispatch({
    type: "LOAD_IMAGE",
    payload: imageFile,
  });

  localStorage.setItem("ExerciseImage", JSON.stringify(imageFile));
};

export const saveExerciseVideo = (imageFile) => (dispatch) => {
  dispatch({
    type: "LOAD_VIDEO",
    payload: imageFile,
  });

  localStorage.setItem("ExerciseVideo", JSON.stringify(imageFile));
};

export const createImageExercise = (info) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXERCISE_IMAGE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/exercises/upload/`, info, config);
    dispatch({
      type: EXERCISE_IMAGE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_IMAGE_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createExerciseVideo = (info) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXERCISE_VIDEO_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `/api/exercises/uploadvideo/`,
      info,
      config
    );
    dispatch({
      type: EXERCISE_VIDEO_CREATE_SUCCESS,
      payload: data,
    }).then((response) => {
      console.log(response);
    });
  } catch (error) {
    dispatch({
      type: EXERCISE_VIDEO_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const resetExercise = () => (dispatch) => {
  dispatch({ type: EXERCISE_CREATE_RESET });
};

export const resetNewExercise = () => (dispatch) => {
  dispatch({ type: EXERCISE_RESET });
};

export const resetDeleteExercise = () => (dispatch) => {
  dispatch({ type: EXERCISE_DELETE_RESET });
};
