import axios from '../services/axios';
import {
    WORKOUTSHEET_CREATE_REQUEST,
    WORKOUTSHEET_CREATE_SUCCESS,
    WORKOUTSHEET_CREATE_FAIL,

    WORKOUTSHEET_DETAILS_REQUEST,
    WORKOUTSHEET_DETAILS_SUCCESS,
    WORKOUTSHEET_DETAILS_FAIL,
    WORKOUTSHEET_DETAILS_RESET,

    WORKOUTSHEET_LIST_REQUEST,
    WORKOUTSHEET_LIST_SUCCESS,
    WORKOUTSHEET_LIST_FAIL,
    WORKOUTSHEET_LIST_RESET,

    WORKOUTSHEET_ITEM_DELETE_REQUEST,
    WORKOUTSHEET_ITEM_DELETE_FAIL,
    WORKOUTSHEET_ITEM_DELETE_SUCCESS,

    WORKOUTSHEET_UPDATE_REQUEST,
    WORKOUTSHEET_UPDATE_SUCCESS,
    WORKOUTSHEET_UPDATE_FAIL,

} from '../constants/workoutSheetConstants'


export const listWorkoutSheets = (page, keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: WORKOUTSHEET_LIST_REQUEST })
      
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params: {
                page: page
            }
        }

        const { data } = await axios.get(
            `/api/exercises/readall/`,
            config
        )

        dispatch({
            type: WORKOUTSHEET_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: WORKOUTSHEET_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listMyWorkoutSheets = (patientSheetId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: WORKOUTSHEET_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `api/workouts/pacient/read/${patientSheetId}/`,
            config
        )

        dispatch({
            type: WORKOUTSHEET_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: WORKOUTSHEET_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listWorksheetDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: WORKOUTSHEET_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/exercises/${id}`,
            config
        )

        dispatch({
            type: WORKOUTSHEET_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: WORKOUTSHEET_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteWorksheetItem = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: WORKOUTSHEET_ITEM_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/workouts/sheet/delete/${id}/`,

            config
        )

        dispatch({
            type: WORKOUTSHEET_ITEM_DELETE_SUCCESS,
            payload: id
        })


    } catch (error) {
        dispatch({
            type: WORKOUTSHEET_ITEM_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}





export const createWorksheet = (pacientId, sheet) => async (dispatch, getState) => {
    try {
        dispatch({
            type: WORKOUTSHEET_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/workouts/pacient/create/${pacientId}/${sheet.workoutID}/`,
            sheet,
            config
        )
        dispatch({
            type: WORKOUTSHEET_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: WORKOUTSHEET_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const updateWorksheet = (sendData, patientSheetId, workoutSheetId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: WORKOUTSHEET_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/workouts/pacient/edit/${patientSheetId}/${workoutSheetId}/`,
            sendData,
            config
        )
        dispatch({
            type: WORKOUTSHEET_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: WORKOUTSHEET_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: WORKOUTSHEET_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateWorksheetItem = (sheet, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: WORKOUTSHEET_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `api/workouts/sheet/edit/${id}/`,
            sheet,
            config
        )

        dispatch({
            type: WORKOUTSHEET_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: WORKOUTSHEET_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: WORKOUTSHEET_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}