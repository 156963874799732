import React, { useState } from "react";
import { Row, Col, Image, Modal, Spinner } from "react-bootstrap";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import trash from "../../../assets/v2/icons/trash.svg";
import { daysMap } from "../../../constants/days.consts";
import { useRoutineMutation } from "../../../mutations";
import { useQueryClient } from "@tanstack/react-query";
import { RegisterRoutineSheetItem } from "./RegisterRoutineSheetItem";

export function RoutineSheet({
  sheetRows,
  isPatient,
  canEdit = true,

  isInitialLoading = false,
  onEdit = () => {},
}) {
  const mediaValues = useMediaQueryValues();

  const { deleteRoutineWorkout } = useRoutineMutation();
  const queryClient = useQueryClient();

  const [sheetItem, setSheetItem] = useState();

  const isLoading = isInitialLoading || deleteRoutineWorkout?.isPending;

  const handleDelete = async (sheetItem) => {
    await deleteRoutineWorkout.mutateAsync({ id: sheetItem?._id });

    if (!isPatient) {
      await queryClient.refetchQueries({
        queryKey: [
          "read-routine-details-query",
          true,
          { id: String(sheetItem?.routine?._id) },
        ],
      });
    }

    onEdit();
  };

  return (
    <Row className="bg-body-secondary m-0 mt-4 p-4 rounded-3">
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <h2 className="h1 fw-bold ps-0 text-primary ps-md-3 pt-md-3 col-12 col-md-6">
          Treinos
        </h2>
      </div>

      {isLoading && (
        <div className="w-100 flex-center mb-3">
          <Spinner animation="border" role="status" variant="primary"></Spinner>
        </div>
      )}

      {!isLoading && (
        <Row className="m-0 p-0 p-md-3">
          <Col className="col-12 px-0">
            {sheetRows?.map((sheetItem, i) => {
              const { workout, days, sheetPatient } = sheetItem;
              const sheetItemDays = sheetPatient?.days ?? days ?? [];

              return (
                sheetItem && (
                  <div
                    key={i}
                    className="d-flex flex-direction-row fw-bold w-100"
                  >
                    <p className="pe-1 mb-0 border-end border-dark border-1">
                      {i + 1}
                    </p>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between w-100">
                        <p className={"ms-2 text-capitalize col-lg-4"}>
                          {workout?.name}
                        </p>

                        <p className="d-none text-center d-lg-flex">
                          {sheetItemDays.length > 1 &&
                            sheetItemDays
                              ?.slice(0, sheetItemDays.length - 1)
                              .map((d) => daysMap[d] + ", ")}
                          {daysMap[sheetItemDays[sheetItemDays.length - 1]]}
                        </p>

                        {canEdit && (
                          <div className="d-flex">
                            <p className="text-center mx-0 pointer">
                              <Image
                                variant="top"
                                src="assets/v2/icons/pen.svg"
                                onClick={() => setSheetItem(sheetItem)}
                              />
                            </p>
                            <p className="text-center ms-2 pointer">
                              <Image
                                variant="top"
                                src={trash}
                                onClick={() => handleDelete(sheetItem)}
                              />
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="d-flex d-lg-none justify-content-between mb-2">
                        <p className="text-center text-wrap ms-2">
                          {sheetItemDays.length > 1 &&
                            sheetItemDays
                              ?.slice(0, sheetItemDays.length - 1)
                              .map((d) => daysMap[d] + ", ")}
                          {daysMap[sheetItemDays[sheetItemDays.length - 1]]}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </Col>
        </Row>
      )}

      {sheetItem && (
        <RegisterRoutineSheetItem
          onClose={() => setSheetItem(undefined)}
          isPatient={isPatient}
          sheetItem={sheetItem}
          onEdit={() => onEdit()}
        ></RegisterRoutineSheetItem>
      )}
    </Row>
  );
}
