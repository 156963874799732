import React from "react";
import { Image, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import {
  useReadAllEvaluationModelQuery,
  useReadAllFilledEvaluationQuery,
} from "../../../../queries/evaluation.query";
import { useState } from "react";
import trash from "../../../../assets/v2/icons/trash.svg";

import { CreateFilledEvaluation } from "./CreateFilledEvaluation";
import { formatDate } from "../../../../utils/date";
import { ViewFilledEvaluation } from "./ViewFilledEvaluation";
import { useEvaluationMutation } from "../../../../mutations/evaluation.mutation";
import { useQueryClient } from "@tanstack/react-query";
import { useUserInfo } from "../../../../hooks/useUserInfo";
export function FilledEvaluationList({
  evaluationModel,
  patient,
  onClose = () => {},
}) {
  const { allFilledEvaluations, readAllFilledEvaluationQuery } =
    useReadAllFilledEvaluationQuery({
      enabled: true,
      params: {
        patientId: patient?.id,
        evaluationId: evaluationModel.data._id,
      },
    });

  const { deleteFilledEvaluationMutation } = useEvaluationMutation();

  const [showCreateFilledEv, setShowCreateFilledEv] = useState(false);

  const [filledEvaluation, setFilledEvaluation] = useState();

  const { isProfessional } = useUserInfo();

  const queryClient = useQueryClient();

  const handleDeleteClick = async (filledEv) => {
    await deleteFilledEvaluationMutation.mutateAsync({
      filledEvaluationId: filledEv._id,
    });

    await readAllFilledEvaluationQuery.refetch();

    await queryClient.refetchQueries({
      queryKey: [useReadAllEvaluationModelQuery.key, true],
    });
  };

  return (
    <>
      <Modal size="lg" show={true} onHide={onClose}>
        <ModalHeader
          onClose={onClose}
          title={evaluationModel.data.name}
        ></ModalHeader>

        <Modal.Body>
          <div className="flex-between align-items-center mb-3">
            <h6 className="mb-0">
              Avaliações Preenchidas por você para este Aluno
            </h6>

            {isProfessional && (
              <div
                className="bg-primary p-2 rounded pointer"
                onClick={() => setShowCreateFilledEv(true)}
              >
                <img alt="adicionar" src="assets/v2/icons/plus.svg" />
              </div>
            )}
          </div>

          <div className="d-flex flex-column">
            {allFilledEvaluations.map((filledEv) => (
              <div
                key={filledEv._id}
                className="flex-between my-3 align-items-center"
              >
                <p
                  className="m-0 flex-grow-1 pointer"
                  onClick={() => setFilledEvaluation(filledEv)}
                >
                  {filledEv.name ?? "-"}
                </p>
                <p className="m-0 me-3">{formatDate(filledEv.createdAt)}</p>

                {isProfessional && (
                  <div
                    className="d-flex align-items-center pointer"
                    onClick={() => handleDeleteClick(filledEv)}
                  >
                    <Image
                      width={30}
                      className="p-1 pointer"
                      src={trash}
                    ></Image>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      {showCreateFilledEv && (
        <CreateFilledEvaluation
          patientId={patient?.id}
          evaluationModel={evaluationModel}
          onClose={() => setShowCreateFilledEv(false)}
        ></CreateFilledEvaluation>
      )}

      {filledEvaluation && (
        <ViewFilledEvaluation
          patient={patient}
          onClose={() => setFilledEvaluation(undefined)}
          filledEvaluation={filledEvaluation}
        ></ViewFilledEvaluation>
      )}
    </>
  );
}
