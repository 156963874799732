import axios from "../services/axios";
import {
  FEEDBACK_LIST_REQUEST,
  FEEDBACK_LIST_SUCCESS,
  FEEDBACK_LIST_FAIL,
  FEEDBACK_DETAILS_REQUEST,
  FEEDBACK_DETAILS_SUCCESS,
  FEEDBACK_DETAILS_FAIL,
  FEEDBACK_DELETE_REQUEST,
  FEEDBACK_DELETE_SUCCESS,
  FEEDBACK_DELETE_FAIL,
  FEEDBACK_DELETE_RESET,
  FEEDBACK_CREATE_REQUEST,
  FEEDBACK_CREATE_SUCCESS,
  FEEDBACK_CREATE_FAIL,
  FEEDBACK_CREATE_RESET,
  FEEDBACK_UPDATE_REQUEST,
  FEEDBACK_UPDATE_SUCCESS,
  FEEDBACK_UPDATE_FAIL,
  FEEDBACK_SAVE_DATA,
  FEEDBACK_SAVE_INDICATIONS,
  FEEDBACK_SAVE_RESULTS,
  FEEDBACK_IMAGE_CREATE_REQUEST,
  FEEDBACK_IMAGE_CREATE_SUCCESS,
  FEEDBACK_IMAGE_CREATE_FAIL,
  FEEDBACK_VIDEO_CREATE_REQUEST,
  FEEDBACK_VIDEO_CREATE_SUCCESS,
  FEEDBACK_VIDEO_CREATE_FAIL,
  FEEDBACK_PATTER_LIST_REQUEST,
  FEEDBACK_PATTER_SUCCESS,
  FEEDBACK_PATTER_FAIL,
  FEEDBACK_RESET,
} from "../constants/feedbackConstants";

export const listFeedbacks =
  (page, keyword = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FEEDBACK_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        params: {
          page: page,
        },
      };

      const { data } = await axios.get(
        `/api/exercises_done/feed/readall/`,
        config
      );

      dispatch({
        type: FEEDBACK_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FEEDBACK_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listMyFeedbacks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/feedbacks/myfeedbacks/`, config);

    dispatch({
      type: FEEDBACK_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FEEDBACK_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listPatterFeedbacks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_PATTER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/feedbacks/patter/`, config);

    dispatch({
      type: FEEDBACK_PATTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FEEDBACK_PATTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listFeedbackDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: FEEDBACK_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/exercises_done/feed/read/${id}/`,
      config
    );

    dispatch({
      type: FEEDBACK_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FEEDBACK_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteFeedback = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/feedbacks/delete/${id}/`, config);

    dispatch({
      type: FEEDBACK_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: FEEDBACK_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createFeedback =
  (feedback, exerciseId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FEEDBACK_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/exercises/feedback/create/${exerciseId}/`,
        feedback,
        config
      );
      dispatch({
        type: FEEDBACK_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FEEDBACK_CREATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const resetCreateFeedback = () => async (dispatch, getState) => {
  dispatch({ type: FEEDBACK_CREATE_RESET });
};

export const updateFeedback = (feedback) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/feedbacks/edit/${feedback._id}/`,
      feedback,
      config
    );
    dispatch({
      type: FEEDBACK_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: FEEDBACK_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FEEDBACK_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateFeedbackItemComment =
  ({ comments, exercise_done_id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: FEEDBACK_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/exercises_done/comments/update/${exercise_done_id}/`,
        {
          comments,
        },
        config
      );

      dispatch({
        type: FEEDBACK_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FEEDBACK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const updateFeedbackComment =
  ({ comments, postId }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: FEEDBACK_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/exercises_done/feed/comments/update/${postId}/`,
        {
          commentsProfessional: comments,
        },
        config
      );

      dispatch({
        type: FEEDBACK_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FEEDBACK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
export const saveFeedbackData = (data) => (dispatch) => {
  dispatch({
    type: FEEDBACK_SAVE_DATA,
    payload: data,
  });

  localStorage.setItem("FeedbackData", JSON.stringify(data));
};

export const saveFeedbackIndications = (data) => (dispatch) => {
  dispatch({
    type: FEEDBACK_SAVE_INDICATIONS,
    payload: data,
  });

  localStorage.setItem("FeedbackIndications", JSON.stringify(data));
};

export const saveFeedbackResults = (data) => (dispatch) => {
  dispatch({
    type: FEEDBACK_SAVE_RESULTS,
    payload: data,
  });

  localStorage.setItem("FeedbackResults", JSON.stringify(data));
};

export const saveFeedbackImage = (imageFile) => (dispatch) => {
  dispatch({
    type: "LOAD_IMAGE",
    payload: imageFile,
  });

  localStorage.setItem("FeedbackImage", JSON.stringify(imageFile));
};

export const saveFeedbackVideo = (imageFile) => (dispatch) => {
  dispatch({
    type: "LOAD_VIDEO",
    payload: imageFile,
  });

  localStorage.setItem("FeedbackVideo", JSON.stringify(imageFile));
};

export const createImageFeedback = (info) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_IMAGE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/feedbacks/upload/`, info, config);
    dispatch({
      type: FEEDBACK_IMAGE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FEEDBACK_IMAGE_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createFeedbackVideo = (info) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_VIDEO_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `/api/feedbacks/uploadvideo/`,
      info,
      config
    );
    dispatch({
      type: FEEDBACK_VIDEO_CREATE_SUCCESS,
      payload: data,
    }).then((response) => {
      console.log(response);
    });
  } catch (error) {
    dispatch({
      type: FEEDBACK_VIDEO_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const resetNewFeedback = () => (dispatch) => {
  dispatch({ type: FEEDBACK_RESET });
};

export const resetDeleteFeedback = () => (dispatch) => {
  dispatch({ type: FEEDBACK_DELETE_RESET });
};
