export const SET_TODAY_EXERCISES = "SET_TODAY_EXERCISES";

export const todayExercisesReducer = (
  state = { todayExercises: [] },
  action
) => {
  switch (action.type) {
    case SET_TODAY_EXERCISES:
      return { ...action.payload };
    default:
      return state;
  }
};

export const setTodayExercises =
  ({
    todayExercises = [],
    pace = 0.1,
    currentTabIndex = new Date().getDay(),
  }) =>
  (dispatch) => {
    dispatch({
      type: SET_TODAY_EXERCISES,
      payload: { todayExercises, pace, currentTabIndex },
    });
  };
