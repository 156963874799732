export function formatToMoneyString(number) {
  if (number === null || number === undefined) {
    return null;
  }

  // Convert number to string and split it into integer and decimal parts
  const [integerPart, decimalPart] = Number(number).toFixed(2).split(".");

  // Add commas for thousands separator
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );

  // Combine integer and decimal parts with comma as decimal separator
  const formattedNumber = `R$ ${formattedIntegerPart},${decimalPart}`;

  return formattedNumber;
}

export const onMoneyInput = (e) => {
  const value = e.target.value.replace(/\D/g, "");

  const realValue = Number(value) / 100; // start form decimals

  return realValue;
};
