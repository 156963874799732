import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { resetPass } from "../../actions/userActions";
import { passwordIsInvalid } from "../../utils/validations";
import logo from '../../assets/v2/icons/logo.svg'
import resetPassword from '../../assets/v2/reset-pass-page.css'

function ResetPassScreenV2({ history }) {
  let { token } = useParams();

  const [successModalShow, setSuccessModalShow] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const { errorResetPass, loadingResetPass, resetPassData } = useSelector(
    (state) => state.resetPass
  );

  const closeSuccessModal = () => {
    setSuccessModalShow(false);
    history.replace("/login");
  };

  useEffect(() => {
    console.log("resetPassData", resetPassData);
    if (resetPassData) {
      setSuccessModalShow(true);
    }
  }, [resetPassData]);

  useEffect(() => {
    console.log(errorResetPass, loadingResetPass, resetPassData);
  }, [errorResetPass, loadingResetPass, resetPassData]);

  useEffect(() => {
    console.log("token", token);
  }, [token]);

  useEffect(() => {
    console.log("aaa");
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(resetPass(token, password));
  };

  return (
    <>
      <style src={resetPassword}></style>
      <div className="page-alert">
        {errorResetPass && <Alert variant="danger">{errorResetPass}</Alert>}
      </div>
      <div className="main-background">
        <form className="page-card" onSubmit={submitHandler}>
          <div className="page-logo">
            <img src={logo} alt="" />
          </div>
          <div className="page-title">Redefinição de senha</div>
          <div className="page-user-input mb">
            <label>Nova senha</label>
            <input
              type="password"
              placeholder="*********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="page-user-input mb">
            <label>Confirmar senha</label>
            <input
              type="password"
              placeholder="*********"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="page-footer">
            <button
              className="page-btn-login"
              type="submit"
              disabled={() => passwordIsInvalid(password, confirmPassword)}
            >
              Enviar
            </button>
            {/* {loading && <Spinner />} */}
          </div>
        </form>
      </div>

      <Modal
        show={successModalShow}
        onHide={() => closeSuccessModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h2>Nova senha cadastrada!</h2>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResetPassScreenV2;
