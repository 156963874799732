import React, { useState } from "react";
import { useReadAllPatientFormModelQuery } from "../../../../queries/patient-form.query";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";

import trash from "../../../../assets/v2/icons/trash.svg";
import eye from "../../../../assets/v2/icons/eye.svg";
import { FilledPatientFormList } from "./FilledPatientFormList";
import { CreatePatientFormModel } from "./CreatePatientFormModel";
import { ViewPatientFormModel } from "./ViewPatientFormModel";
import { usePatientFormMutation } from "../../../../mutations/patient-form.mutation";
import { useUserInfo } from "../../../../hooks/useUserInfo";

export function PatientFormModelList({ patient }) {
  const { allPatientFormsModel, readAllPatientFormModelQuery } =
    useReadAllPatientFormModelQuery({
      enabled: patient?.id !== undefined,
      params: { patientId: patient?.id },
    });

  const { deletePatientFormModelMutation } = usePatientFormMutation();

  const [selectedPatientFormModel, setSelectedPatientFormModel] = useState();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [showPatientFormModel, setShowPatientFormModel] = useState(undefined);

  const { isProfessional, isPatient } = useUserInfo();

  const tooltip = (
    <Tooltip id="tooltip" placement="top">
      <p className="mb-0">Ver Modelo</p>
    </Tooltip>
  );

  const handleDeleteClick = async (e, evModel) => {
    const response = await deletePatientFormModelMutation.mutateAsync({
      formModelId: evModel.data._id,
    });

    readAllPatientFormModelQuery.refetch();
  };

  return (
    <div className="pwl-root">
      <div className="pwl-header flex-between align-items-center">
        <h3 className="m-0">Formulários</h3>

        {isProfessional && (
          <div className="add-button" onClick={() => setOpenCreateModal(true)}>
            <img alt="adicionar" src="assets/v2/icons/plus.svg" />
          </div>
        )}
      </div>

      <div className="d-flex flex-column">
        {allPatientFormsModel.map((ev, i) => {
          if (isPatient && ev.count === 0) {
            return <></>;
          }

          return (
            <div key={i} className="d-flex p-1 align-items-center mb-3">
              <OverlayTrigger placement="top" overlay={tooltip}>
                <Image
                  width={30}
                  className="p-1 me-3 pointer"
                  onClick={() => setShowPatientFormModel(ev)}
                  src={eye}
                ></Image>
              </OverlayTrigger>

              <p
                className="mb-0 pointer"
                onClick={() => setSelectedPatientFormModel(ev)}
              >
                {ev.data.name}

                <span className="ms-2 mb-0 text-primary fw-bold">
                  ({ev.count})
                </span>
              </p>

              {!ev.data.isExercitium && isProfessional && (
                <div className="d-flex flex-grow-1 justify-content-end ">
                  <Image
                    width={30}
                    onClick={(e) => handleDeleteClick(e, ev)}
                    className="p-1 pointer"
                    src={trash}
                  ></Image>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {selectedPatientFormModel && (
        <FilledPatientFormList
          patient={patient}
          onClose={() => setSelectedPatientFormModel(undefined)}
          formModel={selectedPatientFormModel}
        ></FilledPatientFormList>
      )}

      {openCreateModal && (
        <CreatePatientFormModel
          onClose={() => setOpenCreateModal(false)}
        ></CreatePatientFormModel>
      )}

      {showPatientFormModel && (
        <ViewPatientFormModel
          formModel={showPatientFormModel}
          onClose={() => setShowPatientFormModel(undefined)}
        ></ViewPatientFormModel>
      )}
    </div>
  );
}
