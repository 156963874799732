import { useMutation } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";

export function usePlanMutation() {
  const config = useApiConfig();

  const editPlanMutation = useMutation({
    mutationFn: async (params) => {
      return api.put(
        `/api/users/plans/professional/update/${params.id}/`,
        params,
        config
      );
    },
  });

  return {
    editPlanMutation,
  };
}
