import { React, useState, forwardRef } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import eye from '../../../assets/v2/icons/Eye.svg';

function PasswordField({ name, value, placeholder }) {
    const [type, setType] = useState('password');

    const changeType = () => {
        (type == 'password') ? setType('text') : setType('password');
    }

    return (
        <div className='position-relative d-block'>
            <Form.Control
                type={type}
                placeholder={placeholder}
                name={name}
                // onChange={onChangeHandler}
                {...value}
            />
            <div className='position-absolute eye-btn'>
                <img 
                    src={eye}
                    onClick={changeType}
                />
            </div>
        </div>
        
    )
}

export default PasswordField;