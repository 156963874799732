import React from "react";
import { CloseButton, Modal } from "react-bootstrap";

export function ModalHeader({ title, onClose = () => {}, children }) {
  return (
    <Modal.Header className="justify-content-between align-items-center">
      <p className="mb-0 text-primary fw-500 fs-5">{title}</p>

      <div className="d-flex align-items-center">
        {children}
        <CloseButton className="btn text-danger fs-4" onClick={onClose} />
      </div>
    </Modal.Header>
  );
}
