import {
  BILLS_LIST_FAIL,
  BILLS_LIST_REQUEST,
  BILLS_LIST_RESET,
  BILLS_LIST_SUCCESS,
} from "../constants/paymentConstats";

export const listBillsReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLS_LIST_REQUEST:
      return { ...state, loading: true };

    case BILLS_LIST_SUCCESS:
      return { ...state, loading: false, payload: action.payload };

    case BILLS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case BILLS_LIST_RESET:
      return {};

    default:
      return state;
  }
};
