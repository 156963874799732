import React, { useEffect } from "react";
import Sidebar from "react-sidebar";
import { useController } from "./controller";
import { CloseButton, Image } from "react-bootstrap";
import checkSvg from "../../../assets/v2/icons/check.svg";
import weightPng from "../../../assets/v2/icons/weight.png";

export function QueueView({ show, onHide, postId }) {
  const controller = useController({ postId });

  const { allExercises, leftExercises, postQueue, postResume } = controller;

  const handleExerciseClick = async (queueItem, index) => {
    await controller.handleExerciseClick({
      currentExercise: index + 1,
      exerciseId: queueItem.exercise?._id.toString() ?? "-1",
      progress: postResume?.post?.progress ?? 0,
    });

    onHide();
  };

  useEffect(() => {
    controller.refetch();
  }, [show]);

  const content = (
    <section
      style={{ width: 300 }}
      className="d-flex h-100 bg-white p-3 flex-column "
    >
      <div className="flex-between align-items-center">
        <h5 className="text-primary m-0">Fila de Exercícios</h5>

        <CloseButton
          className="btn text-danger fs-5"
          onClick={() => onHide()}
        ></CloseButton>
      </div>

      <div className="d-flex flex-column mt-2 ">
        {allExercises.map((q, i) => {
          const isCurrentExercise =
            (postResume?.post?.currentExercise ?? 1) === i + 1;

          return (
            <div
              key={i}
              className="flex-between flex-row my-2 pointer align-items-center"
              onClick={() => handleExerciseClick(q, i)}
            >
              <p className={"m-0 " + (isCurrentExercise ? "text-primary" : "")}>
                {q.exercise?.name}
              </p>

              {!isCurrentExercise && !q.isComplete && (
                <Image src={weightPng} width={25} height={25}></Image>
              )}

              {isCurrentExercise && !q.isComplete && (
                <div
                  style={{ width: 15, height: 15, marginRight: 5 }}
                  className="bg-primary rounded-circle"
                ></div>
              )}

              {q.isComplete && (
                <Image src={checkSvg} width={25} height={25}></Image>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );

  return (
    <div style={{ zIndex: 900000 }} className={" " + (!show ? "pe-none" : "")}>
      <Sidebar
        className="position-fixed"
        sidebar={content}
        open={show}
        onSetOpen={(op) => {
          !op && onHide();
        }}
        styles={{
          sidebar: { background: "white" },
          root: { position: "fixed" },
        }}
        pullRight={true}
      >
        <></>
      </Sidebar>
    </div>
  );
}
