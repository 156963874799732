import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";

const keys1 = [
  "dashboard-schedule",
  "dashboard-patient-forms",
  "dashboard-routines",
  "dashboard-workouts",
  "dashboard-plans-expired",
  "dashboard-plans-delayed",
  "dashboard-workout-graph",
  "dashboard-finance-graph",
];

export const useReadDashboardQuery = ({ enabled = true, params }) => {
  const searchParams = new URLSearchParams();

  const config = useApiConfig();

  if (params.month) {
    searchParams.append("month", params.month);
  }

  const searchUrl = searchParams.toString()
    ? "/?" + searchParams.toString()
    : "/";

  const scheduleQuery = useQuery({
    queryKey: [keys1[0], params, enabled],
    queryFn: async () => {
      return api.get(`/api/dashboard/agenda` + searchUrl, config);
    },
    enabled,
  });

  const patientFormQuery = useQuery({
    queryKey: [keys1[1], params, enabled],
    queryFn: async () => {
      return api.get(`/api/dashboard/form/without-answer` + searchUrl, config);
    },
    enabled,
  });

  const routinesQuery = useQuery({
    queryKey: [keys1[2], params, enabled],
    queryFn: async () => {
      return api.get(`/api/dashboard/routines/expired` + searchUrl, config);
    },
    enabled,
  });

  const workoutsQuery = useQuery({
    queryKey: [keys1[3], params, enabled],
    queryFn: async () => {
      return api.get(`/api/dashboard/workouts/expired` + searchUrl, config);
    },
    enabled,
  });

  const plansDelayedQuery = useQuery({
    queryKey: [keys1[4], params, enabled],
    queryFn: async () => {
      return api.get(`/api/dashboard/plans/later` + searchUrl, config);
    },
    enabled,
  });

  const plansExpiredQuery = useQuery({
    queryKey: [keys1[5], params, enabled],
    queryFn: async () => {
      return api.get(`/api/dashboard/plans/expired` + searchUrl, config);
    },
    enabled,
  });

  const workoutGraphQuery = useQuery({
    queryKey: [keys1[6], params, enabled],
    queryFn: async () => {
      return api.get(`/api/dashboard/workouts/graph` + searchUrl, config);
    },
    enabled,
  });

  const financeGraphQuery = useQuery({
    queryKey: [keys1[7], params, enabled],
    queryFn: async () => {
      return api.get(`/api/dashboard/finance/graph` + searchUrl, config);
    },
    enabled,
  });

  const isComplete =
    scheduleQuery.data?.data &&
    patientFormQuery.data?.data &&
    routinesQuery.data?.data &&
    workoutsQuery.data?.data &&
    plansDelayedQuery.data?.data &&
    plansExpiredQuery.data?.data &&
    workoutGraphQuery.data?.data &&
    financeGraphQuery.data?.data;

  const dashboardData = useMemo(() => {
    if (!isComplete) return null;

    return {
      schedule: scheduleQuery.data.data,
      patientForm: patientFormQuery.data.data,
      routines: routinesQuery.data.data,
      workouts: workoutsQuery.data.data,
      plansDelayed: plansDelayedQuery.data.data,
      plansExpired: plansExpiredQuery.data.data,
      workoutGraph: workoutGraphQuery.data.data,
      financeGraph: financeGraphQuery.data.data,
    };
  }, [isComplete]);

  const queries = {
    scheduleQuery,
    patientFormQuery,
    routinesQuery,
    workoutsQuery,
    plansDelayedQuery,
    plansExpiredQuery,
  };

  const isFetching =
    scheduleQuery.isFetching ||
    patientFormQuery.isFetching ||
    routinesQuery.isFetching ||
    workoutsQuery.isFetching ||
    plansDelayedQuery.isFetching ||
    plansExpiredQuery.isFetching ||
    workoutGraphQuery.isFetching ||
    financeGraphQuery.isFetching;

  const refetchDashboard = async () => {
    await Promise.all([
      scheduleQuery.refetch(),
      patientFormQuery.refetch(),
      routinesQuery.refetch(),
      workoutsQuery.refetch(),
      plansDelayedQuery.refetch(),
      plansExpiredQuery.refetch(),
      workoutGraphQuery.refetch(),
      financeGraphQuery.refetch(),
    ]);
  };

  return { queries, dashboardData, refetchDashboard, isFetching };
};
