import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  
  return (
    <div>
      {
        (i18n.language == 'en') &&
        <a
          className="text-primary fw-bold text-decoration-none "
          href="#"
          onClick={() => changeLanguage('pt')}
        >
          PT
        </a>
      }
      {
        (i18n.language == 'pt') &&
        <a
          className="text-primary fw-bold text-decoration-none "
          href="#"
          onClick={() => changeLanguage('en')}
        >
          EN
        </a>
      }
    </div>
  );
}

export default LanguageSelector;