import { useMemo } from "react";
import api from "../services/axios";
import { useApiConfig } from "../hooks/useApiConfig";
import { useQuery } from "@tanstack/react-query";

export const useReadFeedbackDetailsQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readFeedbackDetailsQuery = useQuery({
    queryKey: ["readFeedbackDetails", enabled, params],
    queryFn: async () => {
      const { doneId } = params;

      return api.get(`/api/exercises_done/feed/read/${doneId}/`, config);
    },
    enabled,
  });

  const feedbackDetails = useMemo(() => {
    if (readFeedbackDetailsQuery.data?.data)
      return readFeedbackDetailsQuery.data.data[0];

    return undefined;
  }, [readFeedbackDetailsQuery.data?.data]);

  return { feedbackDetails, readFeedbackDetailsQuery };
};

export const useReadAllPatientFeedbacksQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readAllPatientFeedbackQuery = useQuery({
    queryKey: ["read-all-patient-feedbacks", enabled, params],
    queryFn: async () => {
      return api.get(`/api/exercises_done/feed/patient/readall/`, config);
    },
    enabled,
  });

  const patientFeedbacks = useMemo(() => {
    return readAllPatientFeedbackQuery.data?.data ?? [];
  }, [readAllPatientFeedbackQuery.data?.data]);

  return { patientFeedbacks, readAllPatientFeedbackQuery };
};

export const useReadAllFeedbacksQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readAllFeedbackQuery = useQuery({
    queryKey: ["read-all-feedbacks", enabled, params],
    queryFn: async () => {
      return api.get(`/api/exercises_done/feed/readall/`, config);
    },
    enabled,
  });

  const feedbacks = useMemo(() => {
    return readAllFeedbackQuery.data?.data ?? [];
  }, [readAllFeedbackQuery.data?.data]);

  return { feedbacks, readAllFeedbackQuery };
};
