import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import { SpinnerButton } from "../../../../components/v2/SpinnerButton";

import { usePatientFormMutation } from "../../../../mutations/patient-form.mutation";
import { useQueryClient } from "@tanstack/react-query";
import { useReadAllPatientFormModelQuery } from "../../../../queries/patient-form.query";

import { useQuestionFormController } from "../../../../controllers/question-form-controller";

export function CreatePatientFormModel({ onClose = () => {} }) {
  const [formData, setFormData] = useState({ name_form: "" });

  const constroller = useQuestionFormController();

  const {
    addQuestionButtonRender,
    questionFormRender,
    questions,
    questionsRender,
  } = constroller;

  const {
    createPatientFormModelMutation,
    createQuestionPatientFormMutation,
    createChoiceQuestionMutation,
  } = usePatientFormMutation();

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await createPatientFormModelMutation.mutateAsync(formData);

    const formModelId = response.data._id;

    await constroller.sendCreateQuestions(
      { modelId: formModelId },
      createQuestionPatientFormMutation,
      createChoiceQuestionMutation
    );

    await queryClient.refetchQueries({
      queryKey: [useReadAllPatientFormModelQuery.key],
    });

    onClose();
  };

  const isLoading =
    createPatientFormModelMutation.isPending ||
    createQuestionPatientFormMutation.isPending ||
    createChoiceQuestionMutation.isPending ||
    queryClient.isFetching({ queryKey: [useReadAllPatientFormModelQuery.key] });

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <ModalHeader title={"Criar Formulário"} onClose={onClose}></ModalHeader>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Título</Form.Label>
            <Form.Control
              name="name_form"
              onChange={updateFormData}
              type="text"
              placeholder="Digite o título"
              required
              value={formData.name_form}
            />
          </Form.Group>

          <div
            className="w-100 bg-black bg-opacity-10 mt-3 mb-5"
            style={{ height: 2 }}
          ></div>

          {questionsRender}

          {questionFormRender}

          <div className="mt-5"></div>

          {addQuestionButtonRender}
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isLoading}
          >
            <SpinnerButton isLoading={isLoading}></SpinnerButton>
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
