import { useState } from "react";

export function useFormInput(initialValue = null, getValue = false) {
  const [value, setValue] = useState(initialValue);

  function handleChange(e) {
    const value = e?.target ? e.target.value : e;
    setValue(value);
  }

  const inputProps = {
    value,
    onChange: handleChange,
  };

  if (getValue) {
    return value;
  }

  return inputProps;
}
