import React from "react";
import WebcamStreamCapture from "./WebcamStreamCapture";
import VideoPlayer from "./VideoPlayer";

export function WebcamPlayer({
  showWebcam,
  muscleName,
  onGetVideoHandler,
  setWebcamFeedbackResult,
  withFeedback,
  previewUrl,
  professionalFeedback = {},
}) {
  return showWebcam ? (
    <div className="mb-2">
      <WebcamStreamCapture
        muscle={muscleName}
        uploaderHandler={onGetVideoHandler}
        withFeedbackProcess={withFeedback}
        onResultFinal={setWebcamFeedbackResult}
        professionalFeedback={professionalFeedback}
      ></WebcamStreamCapture>
    </div>
  ) : (
    <div
      id="thumbnail"
      className={`bg-body-secondary d-block rounded-3 overflow-hidden`}
    >
      {previewUrl && <VideoPlayer source={previewUrl}></VideoPlayer>}
    </div>
  );
}
