import { React, useEffect, useState, useMemo } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listFeedbacks } from "../../../actions/feedbackActions";
import { listMuscles } from "../../../actions/exerciseActions";
import DateField from "../../../components/v2/Fields/DateField";
import VideoPlayer from "../../../components/v2/VideoPlayer";
import Modal from "react-bootstrap/Modal";
import { SearchPatients } from "../../../components/v2/SearchPatients";
import { FeedbackCard } from "../../../components/v2/FeedbackCard";

import { useFeedbackMutation } from "../../../mutations/feedback.mutation";
import {
  useReadAllFeedbacksQuery,
  useReadAllPatientFeedbacksQuery,
  useReadFeedbackDetailsQuery,
} from "../../../queries";

import { getFeedbackResults } from "../../../utils/getFeedbackResults";
import { stringToDate } from "../../../utils/date";
import { toast } from "react-toastify";
import { useUserInfo } from "../../../hooks/useUserInfo";

function FeedbackScreenV2({}) {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [video, setVideo] = useState("");
  const [currentMuscle, setCurrentMuscle] = useState(-1);
  const [currentPatient, setCurrentPatient] = useState();
  const [show, setShow] = useState(false);
  const [doneId, setDoneId] = useState(null);
  const [postId, setPostId] = useState(null);
  const { isPatient } = useUserInfo();

  const { feedbackDetails, readFeedbackDetailsQuery } =
    useReadFeedbackDetailsQuery({
      enabled: !!doneId,
      params: {
        doneId,
      },
    });

  const { patientFeedbacks, readAllPatientFeedbackQuery } =
    useReadAllPatientFeedbacksQuery({
      enabled: isPatient,
    });

  const { feedbacks: professionalFeedbacks } = useReadAllFeedbacksQuery({
    enabled: !isPatient,
  });

  const feedbacks = useMemo(
    () => (isPatient ? patientFeedbacks : professionalFeedbacks),
    [patientFeedbacks, professionalFeedbacks]
  );

  const muscleList = useSelector((state) => state.muscleList);
  const muscles = muscleList?.muscles ?? [];
  const { commentFeedbackItem } = useFeedbackMutation();
  const [comment, setComment] = useState(feedbackDetails?.comments ?? null);

  const handleClose = () => {
    setVideo("");
    setShow(false);
  };
  const handleShow = (dId, source, id) => {
    setVideo(source);

    setComment(null);
    setShow(true);
    setDoneId(String(dId));
    setPostId(id);
  };

  useEffect(() => {
    if (!muscles || muscles?.length === 0) {
      dispatch(listMuscles());
    }
  }, []);

  const filteredFeedbacks = useMemo(() => {
    let filtered = feedbacks ?? [];

    if (currentMuscle !== -1)
      filtered = filtered.filter(
        (f) =>
          f?.exercises_done &&
          !!f.exercises_done.find(
            (e) =>
              e?.exercise?.muscle?._id &&
              e.exercise.muscle._id === currentMuscle
          )
      );

    if (currentPatient)
      filtered = filtered.filter((f) => {
        const name = f.post?.pacient?.name ?? "";

        return name.includes(currentPatient.name);
      });

    if (startDate)
      filtered = filtered.filter((f) => {
        if (!f?.post?.date) return false;

        const date = new Date(stringToDate(f?.post?.date));

        return (
          startDate.getDay() == date.getDay() &&
          startDate.getMonth() == date.getMonth() &&
          startDate.getFullYear() == date.getFullYear()
        );
      });

    return filtered;
  }, [feedbacks, currentMuscle, currentPatient, startDate]);

  const handleItemSubmit = async (e) => {
    e.preventDefault();

    await commentFeedbackItem.mutateAsync({
      comments: comment,
      exercise_done_id: doneId,
    });

    await readFeedbackDetailsQuery.refetch();

    toast("Comentário atualizado!", {
      type: "success",
      position: "top-center",
    });
  };

  const results = feedbackDetails
    ? getFeedbackResults({ v2: feedbackDetails })
    : {};

  return (
    <Col className="col-12">
      <Row className="m-0">
        <Row className="m-0">
          <Col className="col-12 col-md-4 p-0">
            <Form.Group className="mt-2 pe-md-2" controlId="formBasicAddress">
              <DateField currentDate={startDate} setDate={setStartDate} />
            </Form.Group>
          </Col>

          <Col className="col-12 col-md-4 p-0 col-xl-4 m-0 pe-md-2">
            <Form.Control
              as="select"
              className="mt-2"
              value={currentMuscle}
              onChange={(e) => setCurrentMuscle(Number(e.target.value))}
            >
              <option value={-1}>Músculos</option>
              {muscles?.map((muscle) => (
                <option key={muscle?._id} value={muscle?._id}>
                  {muscle?.name}
                </option>
              ))}
            </Form.Control>
          </Col>

          {!isPatient && (
            <div className="col-12 col-md-4 col-xl-4  p-0 pt-2">
              <SearchPatients
                handleResult={(p) => setCurrentPatient(p)}
              ></SearchPatients>
            </div>
          )}
        </Row>
      </Row>

      <div className="row m-0">
        {filteredFeedbacks?.map((feedback, i) => {
          return (
            <FeedbackCard
              key={i}
              handleShow={handleShow}
              feedback={feedback}
            ></FeedbackCard>
          );
        })}
      </div>

      {feedbackDetails && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="border-0">
            <Modal.Title className="text-primary">
              {feedbackDetails?.exerciseName} - {feedbackDetails?.muscleName}
            </Modal.Title>
            <div className="title-action" onClick={handleClose}>
              <img alt="close icon" src="/assets/v2/icons/x-square.png" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form className="col col-12" onSubmit={handleItemSubmit}>
                <div
                  id="thumbnail"
                  className={`mx-auto bg-body-secondary d-block rounded-3`}
                  style={{ height: 300 }}
                >
                  <VideoPlayer source={video} />
                </div>

                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label className="fw-bold mt-4 fs-4">Alertas</Form.Label>
                </Form.Group>
                {feedbackDetails?.alerts.map((alert) => {
                  if (!alert || alert?.message === "") return;

                  return (
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label className="">{alert?.message}</Form.Label>
                    </Form.Group>
                  );
                })}
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label className="fw-bold fs-4">Dados Médios</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label className="fw-bold">Subida</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>
                    <span className="fw-bold">Tempo:</span>{" "}
                    {results?.timeUpMedia}s &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <span className="fw-bold">Ângulo:</span> Entre{" "}
                    {results?.mediaUp[0]}° e {results?.mediaUp[1]}°
                  </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label className="fw-bold">Descida</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>
                    <span className="fw-bold">Tempo:</span>{" "}
                    {results?.timeDownMedia}s{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <span className="fw-bold">Ângulo:</span> Entre{" "}
                    {results?.mediaDown[0]}° e {results?.mediaDown[1]}°
                  </Form.Label>
                </Form.Group>

                {
                  <>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label className="text-primary fw-bold">
                        Comentário {isPatient && " do Profissional:"}
                      </Form.Label>

                      {!isPatient ? (
                        <Form.Control
                          style={{ height: 100 }}
                          as="textarea"
                          rows={3}
                          value={comment ?? feedbackDetails?.comments}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      ) : (
                        <p className="p-2 bg-body-secondary rounded">
                          {comment ?? feedbackDetails?.comments}
                        </p>
                      )}
                    </Form.Group>

                    {!isPatient && (
                      <Button
                        variant="primary"
                        type="submit"
                        className="float-end"
                      >
                        Salvar
                      </Button>
                    )}
                  </>
                }
              </Form>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Col>
  );
}

export default FeedbackScreenV2;
