import { useState, React, useEffect, useMemo } from "react";
import { Row, Col, Image, Modal, CloseButton } from "react-bootstrap";
import RegisterSheetRowV2 from "./RegisterSheetRowV2";
import { useDispatch, useSelector } from "react-redux";
import trash from "../../assets/v2/icons/trash.svg";
import { deleteWorksheetItem } from "../../actions/workoutSheetActions";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { respStyle } from "../../utils/respStyle";
import { formatDate } from "../../utils/date";

function WorkoutSheetV2({
  sheetRows,
  startDate,
  finalDate,
  edit = true,
  onUpdate = () => {},
}) {
  const dispatch = useDispatch();

  const mediaValues = useMediaQueryValues();

  const { isMD, isLG } = mediaValues;

  const [sheetRowsState, setSheetRows] = useState(sheetRows);

  const workoutSheetUpdate = useSelector(
    (state) => state.workoutSheetItemUpdate
  );
  const workoutSheetDeleteItemReducer = useSelector(
    (state) => state.workoutSheetDeleteItemReducer
  );

  const handleDelete = async (sheetItem) => {
    dispatch(deleteWorksheetItem(sheetItem._id));
  };

  const [selectedSheetRow, setSelectedSheetRow] = useState();

  useEffect(() => {
    let result = sheetRowsState.map((s) => {
      if (s._id === workoutSheetUpdate.payload?._id) {
        return workoutSheetUpdate.payload;
      }

      return s;
    });

    setSheetRows(result);

    onUpdate(result);
  }, [workoutSheetUpdate]);

  useEffect(() => {
    setSheetRows(sheetRows);
  }, [sheetRows]);

  useEffect(() => {
    setSheetRows((prev) =>
      prev.filter((s) => s._id !== workoutSheetDeleteItemReducer.payload)
    );
  }, [workoutSheetDeleteItemReducer.payload]);

  const dates = useMemo(() => {
    if (!startDate) return "";
    if (!finalDate) return "";

    const start = formatDate(startDate);
    const end = formatDate(finalDate);

    return `${start} - ${end}`;
  }, [startDate, finalDate]);

  return (
    <Row className="bg-body-secondary m-0 mt-4 p-4 rounded-3">
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <h2 className="h1 fw-bold ps-0 text-primary ps-md-3 pt-md-3 col-12 col-md-6">
          Ficha de treino
        </h2>

        <p className="h7 text-primary">{dates}</p>
      </div>

      <Row className="m-0 p-0 p-md-3">
        <Col className="col-12 px-0">
          {sheetRowsState?.map(
            (sheet, i) =>
              sheet.exercise && (
                <div
                  key={i}
                  className="d-flex flex-direction-row fw-bold w-100"
                >
                  <p className="pe-1 mb-0 border-end border-dark border-1">
                    {i + 1}
                  </p>
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex justify-content-between w-100">
                      <p
                        className={
                          "ms-2 text-capitalize mb-0" +
                          respStyle({ lg: "col-4" }, mediaValues)
                        }
                      >
                        {sheet.exercise.name}
                      </p>

                      <p className="d-none text-center d-lg-flex mb-0">
                        {sheet.repetitions} rep - {sheet.serie} ser
                      </p>
                      <p className="d-none text-center d-lg-flex mb-0">
                        {sheet.restTime} seg
                      </p>
                      <p className="d-none text-center d-lg-flex mb-0">
                        {sheet.weight}kg
                      </p>

                      {edit && (
                        <div className="d-flex">
                          <div className="text-center mx-0 pointer">
                            <Image
                              variant="top"
                              src="assets/v2/icons/pen.svg"
                              onClick={() => setSelectedSheetRow(sheet)}
                            />
                          </div>
                          <div className="text-center ms-2 pointer">
                            <Image
                              variant="top"
                              src={trash}
                              onClick={() => handleDelete(sheet)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {sheet?.notes && (
                      <div className="d-flex justify-content-between w-100 ">
                        <p className={"ms-2 text-capitalize"}>
                          Observações:
                          <span className="fw-normal"> {sheet?.notes}</span>
                        </p>
                      </div>
                    )}

                    <div className="mb-3"></div>

                    <div className="d-flex d-lg-none justify-content-between mb-2">
                      <p className="text-center ms-2">
                        {sheet.repetitions} rep - {sheet.serie} ser
                      </p>
                      <p className="text-center">{sheet.restTime} seg</p>
                      <p className="text-center">{sheet.weight}kg</p>
                    </div>
                  </div>
                </div>
              )
          )}
        </Col>
      </Row>

      <Modal
        show={!!selectedSheetRow}
        onHide={() => setSelectedSheetRow(undefined)}
      >
        <Modal.Header>
          Editar Item da Ficha{" "}
          <CloseButton
            className="btn text-danger fs-4 my-0 py-0"
            onClick={() => setSelectedSheetRow(undefined)}
          />
        </Modal.Header>
        <Modal.Body>
          {selectedSheetRow && (
            <RegisterSheetRowV2
              id={selectedSheetRow._id}
              onDone={() => setSelectedSheetRow(undefined)}
              row={selectedSheetRow}
            />
          )}
        </Modal.Body>
      </Modal>
    </Row>
  );
}

export default WorkoutSheetV2;
