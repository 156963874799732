export function getFilenameFromURL(uri) {
  let filename = uri.split("/").pop();
  return filename.split("?")[0];
}

export function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export async function dataURLtoFile(dataURL, filename) {
  const response = await fetch(dataURL);
  const blob = await response.blob();
  return new File([blob], filename);
}

export async function getVideoDuration(file) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };

    video.onerror = () => {
      reject("Error loading video");
    };

    video.src = URL.createObjectURL(file);
  });
}
