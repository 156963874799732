import React from "react";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { useState } from "react";
import { respStyle } from "../../utils/respStyle";
import { Image } from "react-bootstrap";
import UserDropdownV2 from "../../components/v2/UserDropdownV2";
import NavSidebarPersonalV2 from "../../components/v2/NavSidebarPersonalV2";
import { useRouteMatch } from "react-router-dom";
import menuSvg from "../../assets/v2/icons/menu-mobile2.svg";
import { useEffect } from "react";

export function NavBar({
  getModalBody,
  unsetModalBody,
  changeVisbility,
  onChanged = () => {},
}) {
  const mediaValues = useMediaQueryValues();
  let { path, url } = useRouteMatch();

  const { isMD, isLG } = mediaValues;
  const [open, setIsOpen] = useState(isMD);

  useEffect(() => {
    setIsOpen(isMD);
  }, [isMD]);

  return (
    <>
      <div
        className={`col-12 p-3 d-flex flex-row justify-content-between  pb-2 position-fixed ${respStyle(
          { md: "pb-0", lg: "pe-5 pt-4", xl: "pt-5" },
          mediaValues
        )}`}
        style={{
          zIndex: 1000,
          width: "100vw",
          pointerEvents: "none",
        }}
      >
        <div
          className="btn btn-primary d-flex d-md-none justify-content-center align-items-center"
          style={{ width: "50px", pointerEvents: "all" }}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <Image src={menuSvg}></Image>
        </div>

        <div className="d-none d-md-flex"></div>
        <UserDropdownV2 path={`${path}`} />
      </div>
      <div className={`${open ? "d-flex" : "d-none"}`}>
        <NavSidebarPersonalV2
          handleModal={getModalBody}
          unsetModalBody={unsetModalBody}
          visibilityModal={changeVisbility}
          onChanged={onChanged}
        />
      </div>
    </>
  );
}
