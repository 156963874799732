import React, { useMemo, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import {
  getDashboardFinanceList,
  getDashboardPatientFormList,
  getDashboardRoutineList,
  getDashboardSchedulesToList,
  getDashboardWorkoutList,
} from "../../../../utils/dashboard.utils";
import RegisterEventV2 from "../../../../components/v2/RegisterEventV2";
import { CreatePatientRoutine } from "../../ProgramsV2/CreatePatientRoutine";
import { PatientFinanceModal } from "../../Finance/PatientFinanceModal";
import RegisterPacientWorkoutV2 from "../../../../components/v2/RegisterPacientWorkoutV2";
import { ViewFilledPatientForm } from "../../PacientDetailsV2/Forms/ViewFilledPatientForm";
import { stringToDate } from "../../../../utils/date";

export function DashboardList({ title, data, type, onClose = () => {} }) {
  const [dataView, setDataView] = useState(null);

  const dataList = useMemo(() => {
    if (type === "schedule") return getDashboardSchedulesToList(data);
    if (type === "routines") return getDashboardRoutineList(data);
    if (type === "plansDelayed") return getDashboardFinanceList(data);
    if (type === "patientForm") return getDashboardPatientFormList(data);
    if (type === "workouts") return getDashboardWorkoutList(data);
    if (type === "plansExpired") return getDashboardFinanceList(data);

    return [];
  }, [data]);

  return (
    <Modal show={true}>
      <ModalHeader onClose={onClose} title={title}></ModalHeader>

      <ModalBody>
        {dataList.map((item, index) => (
          <div
            key={index}
            className="flex-between mb-3 pointer"
            onClick={() => setDataView(data[index])}
          >
            <p className="m-0">{item.name}</p>
            <p className="m-0">{item.info}</p>
          </div>
        ))}
      </ModalBody>

      {type === "schedule" && dataView && (
        <Modal show={true} onHide={() => setDataView(null)} size="lg">
          <ModalHeader
            title={"Editar Evento"}
            onClose={() => setDataView(null)}
          ></ModalHeader>

          <Modal.Body>
            <RegisterEventV2
              date={new Date()}
              schedule={dataView}
              onFinish={() => setDataView(null)}
            />
          </Modal.Body>
        </Modal>
      )}

      {type === "routines" && dataView && (
        <CreatePatientRoutine
          registeredPatients={[]}
          routine={{ _id: dataView.routine }}
          routineSheet={[]}
          routineWorkouts={[]}
          initialRoutinePatient={dataView}
          onFinish={() => setDataView(null)}
        ></CreatePatientRoutine>
      )}

      {(type === "plansDelayed" || type === "plansExpired") && dataView && (
        <PatientFinanceModal
          vinculed={true}
          onClose={() => {
            setDataView(null);
          }}
          patientFinance={{ patient: dataView.pacient }}
        ></PatientFinanceModal>
      )}

      {type === "workouts" && dataView && (
        <RegisterPacientWorkoutV2
          onDone={() => setDataView(null)}
          workoutID={1}
          unsetModalBody={() => setDataView(null)}
          sheetRows={[]}
          registeredPacientes={[]}
          defaultPacient={{
            ...dataView.patient,
            ...dataView,
            initialDate: stringToDate(dataView.initialDate),
            finalDate: stringToDate(dataView.finalDate),
            sheet: dataView,
            sheetId: dataView._id,
          }}
        />
      )}

      {type === "patientForm" && dataView && (
        <ViewFilledPatientForm
          patient={dataView.patient}
          onClose={() => setDataView(null)}
          filledPatientForm={dataView}
        ></ViewFilledPatientForm>
      )}
    </Modal>
  );
}
