import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../actions/userActions";
import messageTranslation from "../../utils/messageTranslation";
import { Alert } from "react-bootstrap";
import logo from "../../assets/v2/icons/logo.svg";
import loginPage from "../../assets/v2/login-page.css";
import { useUserInfo } from "../../hooks/useUserInfo";
import {
  PATIENT_ROLE,
  PROFESSIONAL_ROLE,
} from "../../constants/rolesConstants";
import { Link, useLocation } from "react-router-dom";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { LangSwitcher } from "./LangSwitcher";
import { useTranslation } from "react-i18next";

function LoginScreenV2({ location, history }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const { isLowerHeight } = useMediaQueryValues();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const { userRole } = useUserInfo();

  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const isAlreadyLogged = !!userInfo;

  useEffect(() => {
    const all = Array.from(searchParams.entries()).map((e) => e[0]);

    if (
      all.includes("redirect") &&
      isAlreadyLogged &&
      // in case of be different users
      userInfo?.id.toString() !== searchParams.get("redirectId")
    ) {
      return dispatch(logout());
    }

    if (userRole) {
      const baseUrl =
        userRole === PROFESSIONAL_ROLE ? "/home/personal" : "/home/pacient";

      if (all.includes("redirect")) {
        return history.replace(baseUrl + "/" + searchParams.get("redirect"));
      }

      if (userRole === PROFESSIONAL_ROLE) {
        history.push(baseUrl + "/programs");
      } else if (userRole === PATIENT_ROLE) {
        history.push(baseUrl);
      } else {
        history.replace("/login");
      }
    }
  }, [userRole]);

  useEffect(() => {
    setMessage(messageTranslation(error));
  }, [error, loading, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <>
      <style src={loginPage}></style>
      <div className="login-alert">
        {error && <Alert variant="danger">{message}</Alert>}
      </div>
      <div
        className={
          "main-background login-container justify-content-end" +
          (isLowerHeight ? " overflow-scroll " : "")
        }
      >
        <form
          className="login-card rounded-0 me-md-5"
          onSubmit={submitHandler}
          style={isLowerHeight ? { transform: `scale(.7, .7)` } : {}}
        >
          <div className="d-flex flex-between aling-items-center">
            <div className="login-logo">
              <Link to={"/"}>
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className="d-flex justify-content-end mt-4">
              <LangSwitcher></LangSwitcher>
            </div>
          </div>

          <div className="login-title">{t("login.title")}</div>
          {/* <button className="login-with-google" type="button">
            <img src={google} alt="" />
            Fazer login com o Google
          </button> */}
          <div className="login-user-input mb">
            <label>E-mail</label>
            <input
              type="email"
              placeholder={t("field.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="login-user-input">
            <label>{t("field.password")}</label>
            <input
              type="password"
              placeholder="*********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <a href="/#/forget-pass" className="login-user-forgot-pass">
            {t("login.forgotPassword")}
          </a>
          <div className="login-footer">
            <div className="login-signin">
              {t("login.noAccount")}
              <a href="/#/sign-up"> {t("login.createAccount")}</a>
            </div>
            <button className="login-btn-login" type="submit">
              {t("button.login")}
            </button>
            {/* { loading && <Spinner />} */}
          </div>
        </form>
      </div>
    </>
  );
}

export default LoginScreenV2;
