import React from "react";
import { Image } from "react-bootstrap";
import printer from "../assets/v2/icons/printer-2.svg";
import download from "../assets/v2/icons/download2.png";

export function PrintButton({ onClick = () => {}, icon }) {
  return (
    <div className="d-flex justify-content-end text-primary ">
      <div className="pointer" onClick={onClick}>
        {/* Imprimir */}
        <Image height={24} src={icon ?? download} className="pointer "></Image>
      </div>
    </div>
  );
}
