export const WORKOUT_LIST_REQUEST = 'WORKOUT_LIST_REQUEST'
export const WORKOUT_LIST_SUCCESS = 'WORKOUT_LIST_SUCCESS'
export const WORKOUT_LIST_FAIL = 'WORKOUT_LIST_FAIL'
export const WORKOUT_LIST_RESET = 'WORKOUT_LIST_RESET'

export const WORKOUT_LIST_DETAILS_REQUEST = 'WORKOUT_LIST_DETAILS_REQUEST'
export const WORKOUT_LIST_DETAILS_SUCCESS = 'WORKOUT_LIST_DETAILS_SUCCESS'
export const WORKOUT_LIST_DETAILS_FAIL = 'WORKOUT_LIST_DETAILS_FAIL'
export const WORKOUT_LIST_DETAILS_RESET = 'WORKOUT_LIST_DETAILS_RESET'

export const WORKOUT_BODYBUILDER_LIST_REQUEST = 'WORKOUT_BODYBUILDER_LIST_REQUEST'
export const WORKOUT_BODYBUILDER_LIST_SUCCESS = 'WORKOUT_BODYBUILDER_LIST_SUCCESS'
export const WORKOUT_BODYBUILDER_LIST_FAIL = 'WORKOUT_BODYBUILDER_LIST_FAIL'
export const WORKOUT_BODYBUILDER_LIST_RESET = 'WORKOUT_BODYBUILDER_LIST_RESET'

export const WORKOUT_CREATE_REQUEST = 'WORKOUT_CREATE_REQUEST'
export const WORKOUT_CREATE_SUCCESS = 'WORKOUT_CREATE_SUCCESS'
export const WORKOUT_CREATE_FAIL = 'WORKOUT_CREATE_FAIL'
export const WORKOUT_CREATE_RESET = 'WORKOUT_CREATE_RESET'

export const WORKOUT_BODYBUILDER_CREATE_REQUEST = 'WORKOUT_BODYBUILDER_CREATE_REQUEST'
export const WORKOUT_BODYBUILDER_CREATE_SUCCESS = 'WORKOUT_BODYBUILDER_CREATE_SUCCESS'
export const WORKOUT_BODYBUILDER_CREATE_FAIL = 'WORKOUT_BODYBUILDER_CREATE_FAIL'
export const WORKOUT_BODYBUILDER_CREATE_RESET = 'WORKOUT_BODYBUILDER_CREATE_RESET'

export const WORKOUT_DETAILS_REQUEST = 'WORKOUT_DETAILS_REQUEST'
export const WORKOUT_DETAILS_SUCCESS = 'WORKOUT_DETAILS_SUCCESS'
export const WORKOUT_DETAILS_FAIL = 'WORKOUT_DETAILS_FAIL'
export const WORKOUT_DETAILS_RESET = 'WORKOUT_DETAILS_RESET'

export const WORKOUT_UPDATE_REQUEST = 'WORKOUT_UPDATE_REQUEST'
export const WORKOUT_UPDATE_SUCCESS = 'WORKOUT_UPDATE_SUCCESS'
export const WORKOUT_UPDATE_FAIL = 'WORKOUT_UPDATE_FAIL'
export const WORKOUT_UPDATE_RESET = 'WORKOUT_UPDATE_RESET'

export const WORKOUT_DELETE_REQUEST = 'WORKOUT_DELETE_REQUEST'
export const WORKOUT_DELETE_SUCCESS = 'WORKOUT_DELETE_SUCCESS'
export const WORKOUT_DELETE_FAIL = 'WORKOUT_DELETE_FAIL'
export const WORKOUT_DELETE_RESET = 'WORKOUT_DELETE_RESET'

export const WORKOUT_PATIENT_LIST_REQUEST = 'WORKOUT_PATIENT_LIST_REQUEST'
export const WORKOUT_PATIENT_LIST_SUCCESS = 'WORKOUT_PATIENT_LIST_SUCCESS'
export const WORKOUT_PATIENT_LIST_FAIL = 'WORKOUT_PATIENT_LIST_FAIL'
export const WORKOUT_PATIENT_LIST_RESET = 'WORKOUT_PATIENT_LIST_RESET'