export async function fetchImageWithHeaders(url) {
  const array = url?.split(".") ?? [];

  const contentType = "image/" + array[array.length - 1];

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": contentType,
  };

  const response = await fetch(url + "?cacheblock=true", {
    method: "GET",
    headers,
  });

  const blob = await response.blob();

  const imageUrl = URL.createObjectURL(blob);

  return imageUrl;
}

export async function extractImageFromVideo(videoFile, fileName) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    const reader = new FileReader();
    reader.onload = function (event) {
      video.onseeked = function (e) {
        var canvas = document.createElement("canvas");
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          resolve(new File([blob], fileName, { type: "image/png" }));
        }, "image/png");
      };
      video.onerror = function (e) {
        console.log({ e });
      };

      video.src = event.target.result;

      video.currentTime = 1;
    };

    reader.readAsDataURL(videoFile);
  });
}
