import { useSelector } from "react-redux";
import { useUserInfo } from "./useUserInfo";

export const useApiConfig = (contentType) => {
  const userInfo = useSelector((state) => state.userLogin?.userInfo);

  const config = {
    headers: {
      "Content-type": contentType ?? "application/json",
      Authorization: `Bearer ${userInfo?.token}`,
    },
  };

  return config;
};
