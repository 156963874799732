import React, { useMemo, useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from "recharts";
import { Form, Spinner } from "react-bootstrap";

export function Chart({
  tooltipLabel = {
    x: "Dia",
    y: "Repetição",
  },
  title = null,
  info = <></>,
  isLoading,
  data,
  chartType = "bar",

  customChartHeight = "80%",
  linesNames = [],
  formatYText = (y) => y,
}) {
  const Chart = chartType === "bar" ? BarChart : LineChart;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-3">
          <p className="label">
            {tooltipLabel.y + ": " + formatYText(payload[0].value)}
          </p>
          <p className="intro mb-0">
            {tooltipLabel.x + ": " + payload[0].payload.name}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="h-100 position-relative">
      {title && (
        <div className="d-flex flex-column px-3 mb-2 w-100 ">
          <h4
            className="h5 col-12 col-lg-auto mb-2 mb-lg-0 text-primary m-0"
            style={{ flex: 1 }}
          >
            {title}
          </h4>
        </div>
      )}

      <p className="m-0 p-0 px-3 text-primary">{tooltipLabel.y}</p>

      <ResponsiveContainer
        className={"position-relative"}
        width={"100%"}
        height={customChartHeight}
      >
        <Chart
          width={500}
          height={"100%"}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {data && (
            <>
              <XAxis dataKey="name" />
              <YAxis tickFormatter={formatYText} />
              <Tooltip content={<CustomTooltip></CustomTooltip>}></Tooltip>
              {chartType === "bar" && <Bar dataKey="pv" fill="#052c65" />}
              {chartType === "line" && (
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#068757"
                  activeDot={{ r: 8 }}
                  name={linesNames[0]}
                />
              )}
              {chartType === "line" && (
                <Line
                  type="monotone"
                  dataKey="pv2"
                  stroke="#de3445"
                  activeDot={{ r: 8 }}
                  name={linesNames[1]}
                />
              )}

              {linesNames.length > 0 && <Legend></Legend>}
            </>
          )}
        </Chart>

        {isLoading && (
          <div className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center ">
            <div
              className=""
              style={{ transform: `translate(${0}px, ${0}px)` }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{
                  height: 45,
                  width: 45,
                }}
              ></Spinner>
            </div>
          </div>
        )}

        {!isLoading && data.length === 0 && (
          <div className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
            <p>Sem dados</p>
          </div>
        )}
      </ResponsiveContainer>

      <p
        className="m-0 p-0 text-end text-primary mt-1"
        style={{ transform: `translate(${0}px, ${-25}px)` }}
      >
        {tooltipLabel.x}
      </p>
    </div>
  );
}
