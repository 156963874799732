import { React, useEffect, useRef, useState } from "react";
import { Nav, Image, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RegisterExerciseVideoV2 from "./RegisterExerciseVideoV2";

import logo from "../../assets/v2/icons/white-logo.png";
import logosmall from "../../assets/v2/icons/logosmall.svg";
import people from "../../assets/v2/icons/people.svg";
import person from "../../assets/v2/icons/person2.svg";

import folder from "../../assets/v2/icons/folder.svg";
import trendingUp from "../../assets/v2/icons/trending-up.svg";
import video from "../../assets/v2/icons/video.svg";
import hideLeft from "../../assets/v2/icons/hide-left.svg";
import weightlifting from "../../assets/v2/icons/Weightlifting.svg";
import feedback from "../../assets/v2/icons/feedback.png";
import dashboard from "../../assets/v2/icons/dashboard.svg";

import { useUserInfo } from "../../hooks/useUserInfo";

import { listExercises } from "../../actions/exerciseActions";
import { usePendentPostController } from "../../controllers";
import { toast } from "react-toastify";
function NavSidebarPersonalV2({
  handleModal,
  visibilityModal,
  unsetModalBody,
  onChanged = () => {},
}) {
  let { path, url } = useRouteMatch();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const [todayExercisesNow, setTodayExercisesNow] = useState([]);

  const {
    todayExercises: selectedDayExercises,
    pace,
    currentTabIndex,
  } = useSelector((state) => state.todayExercisesState);

  const splitPath = pathname?.split("/") ?? [];
  const lastPath = splitPath[splitPath.length - 1];

  const executeSelectedDaySheet = lastPath === "pacient";

  const { handleExecuteWorkoutClick, pendentPost, executePendentPost } =
    usePendentPostController({
      exercises: executeSelectedDaySheet
        ? selectedDayExercises
        : todayExercisesNow,
      pace,
    });

  const sideBarRef = useRef();
  const [full, setFull] = useState(false);

  const toggleWidth = () => {
    setFull((prevState) => !prevState);
  };

  const videoModal = () => {
    return (
      <RegisterExerciseVideoV2
        unsetModalBody={unsetModalBody}
        onFinish={() => {
          dispatch(listExercises(1));
          unsetModalBody();
        }}
      />
    );
  };

  useEffect(() => {
    onChanged(full);
  }, [full]);

  const width = full ? "full" : "small";

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const { isPatient } = useUserInfo();

  const baseUrl = url[url.length - 1] === "/" ? url.slice(0, -1) : url;

  useEffect(() => {
    if (currentTabIndex === new Date().getDay() - 1) {
      setTodayExercisesNow(selectedDayExercises);
    }
  }, [selectedDayExercises, currentTabIndex]);

  const personalNav = () => {
    return (
      <>
        <div className="mb-4">
          <LinkContainer to={`${baseUrl}/dashboard`}>
            <Nav.Link className="mb-3 text-white" eventKey="link-0">
              <img className="pe-2" src={dashboard} />
              <span>Dashboard</span>
            </Nav.Link>
          </LinkContainer>

          <LinkContainer to={`${baseUrl}/pacients`}>
            <Nav.Link className="mb-3 text-white" eventKey="link-1">
              <img className="pe-2" src={people} />
              <span>Alunos</span>
            </Nav.Link>
          </LinkContainer>

          <LinkContainer to={`${baseUrl}/programs`}>
            <Nav.Link className="mb-3 text-white" eventey="link-2">
              <img className="pe-2" src={folder} />
              <span>Programas</span>
            </Nav.Link>
          </LinkContainer>

          <LinkContainer to={`${baseUrl}/admin`}>
            <Nav.Link className="mb-3 text-white" eventKey="link-3">
              <img className="pe-2" src={trendingUp} />
              <span>Gestão</span>
            </Nav.Link>
          </LinkContainer>
        </div>

        <div className="bg-dark rounded-end mt-5">
          <LinkContainer to={`${baseUrl}/record`}>
            <Nav.Link
              className="text-white"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                handleModal(videoModal(), "Gravar Video");
              }}
            >
              <img className="pe-2" src={video} />
              <span>Grave seu exercício</span>
            </Nav.Link>
          </LinkContainer>
        </div>

        <Button
          variant="link position-absolute bottom-0 end-0"
          onClick={toggleWidth}
        >
          <img className="pe-2 size-btn" src={hideLeft} />
        </Button>
      </>
    );
  };

  const handleExecuteClick = (e) => {
    let exercises = executeSelectedDaySheet
      ? selectedDayExercises
      : todayExercisesNow;

    exercises = exercises ?? [];

    if (exercises.length === 0) {
      toast("Você não tem treino para neste dia da semana!", {
        type: "info",
        position: "top-center",
      });

      return;
    }

    handleExecuteWorkoutClick(e);
  };

  const pacientNav = () => {
    return (
      <>
        <div className="mb-4">
          <LinkContainer to={`${baseUrl}`} exact={true}>
            <Nav.Link className="mb-3 text-white" eventKey="link-0">
              <img className="" src={weightlifting} />
              <span>Treinos</span>
            </Nav.Link>
          </LinkContainer>

          <LinkContainer
            to={`${baseUrl}/perfil`}
            exact={true}
            className=""
            // style={{ transform: `translate(${-6}px, ${0}px)` }}
          >
            <Nav.Link className="mb-3 text-white" eventKey="link-1">
              <img className="" width={18} src={person} />
              <span>Perfil</span>
            </Nav.Link>
          </LinkContainer>

          {/* <LinkContainer to={`${url}/none-1`}>
            <Nav.Link className="mb-3 text-white" eventKey="link-1" disabled>
              <img className="pe-2" src={weightlifting} />
              <span>Treinos</span>
            </Nav.Link>
          </LinkContainer> */}

          <LinkContainer to={`${baseUrl}/feedback`} style={{}}>
            <Nav.Link className="mb-3 text-white" eventey="link-2">
              <img className="" width={13} src={feedback} />
              <span>Feedbacks</span>
            </Nav.Link>
          </LinkContainer>

          {/* <LinkContainer to={`${url}/none-3`}>
            <Nav.Link className="mb-3 text-white" eventKey="link-3" disabled>
              <img className="pe-2" src={chat} />
              <span>Chat</span>
            </Nav.Link>
          </LinkContainer> */}
        </div>

        <div className="bg-dark rounded-end mt-5" onClick={handleExecuteClick}>
          <Nav.Link className="text-white">
            <img className="" src={video} />
            <span>Executar treino</span>
          </Nav.Link>
        </div>

        <Button
          variant="link position-absolute bottom-0 end-0"
          onClick={toggleWidth}
        >
          <img className="pe-2 size-btn" src={hideLeft} />
        </Button>
      </>
    );
  };

  return (
    <>
      <Nav
        id="sidebar"
        defaultActiveKey={`${baseUrl}`}
        className={`flex-column bg-primary text-white position-fixed rounded-end ${width}`}
        ref={sideBarRef}
      >
        <div className="logo rounded-end mb-5">
          <LinkContainer
            to={isPatient ? `${baseUrl}` : "/home/personal/programs"}
            exact={true}
          >
            <Nav.Link id="home-img" className="border-0" eventKey="link-img">
              <Image
                className={`img-fluid d-block m-0 ${!full ? "invert" : ""}`}
                src={full ? logo : logosmall}
              />
            </Nav.Link>
          </LinkContainer>
        </div>
        {(userInfo?.isPersonal || userInfo?.isPhysio) && personalNav()}

        {!userInfo?.isPersonal && !userInfo?.isPhysio && pacientNav()}
        <Button
          variant="link position-absolute bottom-0 end-0"
          onClick={toggleWidth}
        >
          <img className="pe-2 size-btn" src={hideLeft} />
        </Button>
      </Nav>
    </>
  );
}

export default NavSidebarPersonalV2;
