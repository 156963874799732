import React from "react";
import { formatDate } from "../../utils/date";
import { Button, Image } from "react-bootstrap";
import imageSection from "../../assets/v2/img/image-section-1.png";
import { useHistory } from "react-router-dom";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { respStyle } from "../../utils/respStyle";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useReadUserDetailsQuery } from "../../queries";

import exercitiumLogo from "../../assets/v2/icons/logo.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/userActions";
import { useEffect } from "react";
import { ALLOWED_PLANS_IDS } from "../../constants/plan.consts";

export function Paywall() {
  const history = useHistory();
  const mediaValues = useMediaQueryValues();

  const { isMD, isMobile } = mediaValues;

  const dispatch = useDispatch();

  const { name, ...rest } = useUserInfo();

  const { userDetails } = useReadUserDetailsQuery({
    options: { refetchInterval: 3000 },
  });

  const date = new Date(userDetails?.createdAt);
  date.setDate(date.getDate() + 7);

  const handleLogout = () => {
    dispatch(logout());
    history.replace("/");
  };

  useEffect(() => {
    if (ALLOWED_PLANS_IDS.includes(userDetails?.plan?._id ?? -1)) {
      history.replace("/login");
    }
  }, [userDetails?.plan?._id]);

  return (
    <div className="bg-body-secondary h-100 d-flex justify-content-center align-items-center p-0 w-100 overflow-hidden overflow-y-scroll">
      <div
        className={
          "d-flex flex-column flex-md-row-reverse align-items-center p-0 p-md-0"
        }
      >
        <img
          className="col-12 col-md-5"
          style={{ maxHeight: isMobile ? 350 : "", objectFit: "contain" }}
          src={imageSection}
        ></img>

        <div
          className="d-flex flex-column bg-body-secondary p-5 pt-2"
          style={{ zIndex: 30 }}
        >
          <Image
            className="pointer mb-2"
            width={250}
            src={exercitiumLogo}
            onClick={() => handleLogout()}
          ></Image>
          <p className="h3 mb-3">
            {name?.split(" ")[0]}, Seu teste grátis terminou!
          </p>
          <p>Obrigado por experimentar a Exercitium!</p>
          <p>
            Inicio do Teste Grátis:{" "}
            {formatDate(new Date(userDetails?.createdAt))}
          </p>
          <p>Fim do Teste Grátis: {formatDate(date)}</p>

          <p className="mt-5">
            Para continuar usando a Exercitium e todas as suas ferramentas, é
            necessário pagar uma assinatura.
          </p>

          <div className="d-flex flex-wrap">
            <Button
              onClick={() => history.replace("/payment")}
              className="w-100"
            >
              Assinar Agora
            </Button>

            <Button
              onClick={() => handleLogout()}
              className="d-md-none w-100 px-5 mt-1"
            >
              Sair
            </Button>
          </div>
        </div>
      </div>

      <Button
        onClick={() => handleLogout()}
        className="d-none d-md-flex position-absolute bottom-0 px-5 mb-3"
      >
        Sair
      </Button>
    </div>
  );
}
