import React from "react";
import { useController } from "./controller";
import { Form } from "react-bootstrap";
import { DashboardList } from "../DashboardList";
import openSvg from "../../../../assets/v2/icons/open.svg";
import { Chart } from "../../../../components/v2/Chart";

export function DashboardView() {
  const controller = useController();

  const {
    currentMonth,
    dashboardList,
    dashboardData,
    isFetching,
    financesGraphData,
  } = controller;
  const workoutGraphData = dashboardData?.workoutGraph.data ?? [];

  const renderCardInfo = ({ text, data, type }) => {
    return (
      <div className="col-12 col-md-4 p-0 pe-2 pb-2">
        <div
          className="d-flex flex-row bg-body-secondary p-3 align-items-center rounded pointer"
          onClick={() =>
            controller.setDashboardList({ list: data?.data, type, title: text })
          }
        >
          <p className="m-0 me-3 fs-3 text-primary">{data?.total ?? 0}</p>
          <p className="m-0">{text}</p>
          <div className="flex-grow-1"></div>

          <img src={openSvg} alt="open" width={20} />
        </div>
      </div>
    );
  };

  return (
    <section>
      <div className="d-flex gap-5 align-items-center">
        <h1 className="text-primary m-0">Dashboard</h1>

        <Form.Group className="">
          <Form.Control
            as="select"
            style={{ maxWidth: 300 }}
            value={currentMonth}
            onChange={(e) => controller.setCurrentMonth(e.target.value)}
          >
            <option value={1}>Janeiro</option>
            <option value={2}>Fevereiro</option>
            <option value={3}>Março</option>
            <option value={4}>Abril</option>
            <option value={5}>Maio</option>
            <option value={6}>Junho</option>
            <option value={7}>Julho</option>
            <option value={8}>Agosto</option>
            <option value={9}>Setembro</option>
            <option value={10}>Outubro</option>
            <option value={11}>Novembro</option>
            <option value={12}>Dezembro</option>
          </Form.Control>
        </Form.Group>
      </div>

      <div className="row m-0 p-0 mt-2">
        {renderCardInfo({
          text: "Agendamentos",
          data: dashboardData?.schedule,
          type: "schedule",
        })}

        {renderCardInfo({
          text: "Rotinas se expiram",
          data: dashboardData?.routines,
          type: "routines",
        })}

        {renderCardInfo({
          text: "Planos estão atrasados",
          data: dashboardData?.plansDelayed,
          type: "plansDelayed",
        })}

        {renderCardInfo({
          text: "Formulários sem resposta",
          data: dashboardData?.patientForm,
          type: "patientForm",
        })}

        {renderCardInfo({
          text: "Treinos se expiram",
          data: dashboardData?.workouts,
          type: "workouts",
        })}

        {renderCardInfo({
          text: "Planos se expiram",
          data: dashboardData?.plansExpired,
          type: "plansExpired",
        })}
      </div>

      {dashboardList.list !== null && (
        <DashboardList
          data={dashboardList.list}
          title={dashboardList.title}
          type={dashboardList.type}
          onClose={() => controller.setDashboardList({ list: null })}
        ></DashboardList>
      )}

      <div
        style={{ height: 350 }}
        className="mt-3 bg-body-secondary pt-3 pe-2 rounded"
      >
        <Chart
          title="Treinos"
          data={workoutGraphData.map((item, i) => {
            return {
              name: item[0],
              uv: i,
              pv: item[1],
              amt: 0,
            };
          })}
          isLoading={isFetching}
          tooltipLabel={{ x: "Dia", y: "Quantidade" }}
        ></Chart>
      </div>

      <div className="bg-body-secondary mt-3 rounded pt d-flex flex-column pt-3">
        <div className="d-flex flex-between align-items-center">
          <h4
            className="h5 col-12 col-lg-auto mb-2 mb-lg-0 text-primary m-0 ms-3"
            style={{ flex: 1 }}
          >
            Finanças
          </h4>
          <div className="d-flex gap-2 me-4">
            <p className="mb-0 fs-5">
              <span className="text-primary  fw-500 ">Total Mês: </span>R${" "}
              {dashboardData?.financeGraph.totalMonth
                .toFixed(2)
                .toString()
                .replace(".", ",")}
            </p>

            <p className="mb-0 fs-5">
              <span className="text-primary  fw-500 ">Total Ano: </span>R${" "}
              {dashboardData?.financeGraph.totalYear
                .toFixed(2)
                .toString()
                .replace(".", ",")}
            </p>
          </div>
        </div>

        <div style={{ height: 300 }} className="pt-3 pe-2">
          <Chart
            data={financesGraphData}
            customChartHeight="88%"
            isLoading={isFetching}
            tooltipLabel={{ x: "Dia", y: "Valor" }}
            chartType="line"
            formatYText={(y) => "R$" + y}
            info={
              <div className="d-flex gap-5 mt-2 mb-1">
                <p className="mb-0">
                  <span className="text-primary  fw-500">Total Mês: </span>R${" "}
                  {dashboardData?.financeGraph.totalMonth
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")}
                </p>

                <p className="mb-0">
                  <span className="text-primary  fw-500">Total Ano: </span>R${" "}
                  {dashboardData?.financeGraph.totalYear
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")}
                </p>
              </div>
            }
            linesNames={["Entrada", "Saída"]}
          ></Chart>
        </div>
      </div>
    </section>
  );
}
