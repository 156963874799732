export function PrintBody({
  children,
  title,
  className = "",
  isFirst = false,
  titleComp = undefined,
}) {
  const titleItem = titleComp ? (
    titleComp
  ) : title ? (
    <p className="h6 text-primary mb-3 mt-2">{title}</p>
  ) : undefined;

  return (
    <div
      className={`row m-0 bg-body-secondary p-3 ${
        isFirst ? "rounded-top-3" : "pt-0"
      } ${className}`}
    >
      {/* {isFirst && <p>Primeiro</p>} */}

      {isFirst && !titleItem && <div className="mt-3"></div>}
      {titleItem}
      {children}
    </div>
  );
}
