import { EXERCISE_CREATE_RESET } from '../constants/exerciseConstants'
import {
    FEEDBACK_LIST_REQUEST,
    FEEDBACK_LIST_SUCCESS,
    FEEDBACK_LIST_FAIL,
    FEEDBACK_DETAILS_REQUEST,
    FEEDBACK_DETAILS_SUCCESS,
    FEEDBACK_DETAILS_FAIL,
    FEEDBACK_DELETE_REQUEST,
    FEEDBACK_DELETE_SUCCESS,
    FEEDBACK_DELETE_FAIL,
    FEEDBACK_DELETE_RESET,
    FEEDBACK_CREATE_REQUEST,
    FEEDBACK_CREATE_SUCCESS,
    FEEDBACK_CREATE_FAIL,
    FEEDBACK_CREATE_RESET,
    FEEDBACK_UPDATE_REQUEST,
    FEEDBACK_UPDATE_SUCCESS,
    FEEDBACK_UPDATE_FAIL,
    FEEDBACK_UPDATE_RESET,
    FEEDBACK_CREATE_REVIEW_REQUEST,
    FEEDBACK_CREATE_REVIEW_SUCCESS,
    FEEDBACK_CREATE_REVIEW_FAIL,
    FEEDBACK_CREATE_REVIEW_RESET,
    FEEDBACK_TOP_REQUEST,
    FEEDBACK_TOP_SUCCESS,
    FEEDBACK_TOP_FAIL,
    FEEDBACK_SAVE_DATA,
    FEEDBACK_SAVE_INDICATIONS,
    FEEDBACK_SAVE_RESULTS,
    FEEDBACK_IMAGE_CREATE_REQUEST,
    FEEDBACK_IMAGE_CREATE_SUCCESS,
    FEEDBACK_IMAGE_CREATE_FAIL,
    FEEDBACK_VIDEO_CREATE_REQUEST,
    FEEDBACK_VIDEO_CREATE_SUCCESS,
    FEEDBACK_VIDEO_CREATE_FAIL,
    FEEDBACK_PATTER_LIST_REQUEST,
    FEEDBACK_PATTER_SUCCESS,
    FEEDBACK_PATTER_FAIL,
    FEEDBACK_RESET,

} from '../constants/feedbackConstants'


export const feedbackListReducer = (state = { feedbacks: [] }, action) => {
    
    switch (action.type) {
        case FEEDBACK_LIST_REQUEST:
            return { loading: true, feedbacks: [] }
            
        case FEEDBACK_LIST_SUCCESS:
            return {
                loading: false,
                feedbacks: action.payload,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case FEEDBACK_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const feedbackDetailsReducer = (state = { feedback: { reviews: [] } }, action) => {
    switch (action.type) {
        case FEEDBACK_DETAILS_REQUEST:
            return { loading: true, ...state }

        case FEEDBACK_DETAILS_SUCCESS:
            return { loading: false, feedback: action.payload }

        case FEEDBACK_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const feedbackDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case FEEDBACK_DELETE_REQUEST:
            return { loading: true }

        case FEEDBACK_DELETE_SUCCESS:
            return { loading: false, deleteExerciseSuccess: true }

        case FEEDBACK_DELETE_FAIL:
            return { loading: false, error: action.payload }

        case FEEDBACK_DELETE_RESET:
            return []

        default:
            return state
    }
}


export const feedbackCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case FEEDBACK_CREATE_REQUEST:
            return { loadingExercise: true }

        case FEEDBACK_CREATE_SUCCESS:
            return { loadingExercise: false, createExerciseSucess: true, feedback: action.payload }

        case FEEDBACK_CREATE_FAIL:
            return { loadingExercise: false, error: action.payload }

        case EXERCISE_CREATE_RESET:
            return { }

        default:
            return state
    }
}

export const feedbackUpdateReducer = (state = { feedback: {} }, action) => {
    switch (action.type) {
        case FEEDBACK_UPDATE_REQUEST:
            return { loading: true }

        case FEEDBACK_UPDATE_SUCCESS:
            return { loading: false, success: true, feedback: action.payload }

        case FEEDBACK_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const feedbackReducer = (state = { feedbackData: {}, feedbackIndications: {} }, action) => {
    switch (action.type) {

        case FEEDBACK_SAVE_DATA:
            return {
                ...state,
                feedbackData: action.payload
            }

        case FEEDBACK_SAVE_INDICATIONS:
            return {
                ...state,
                feedbackIndications: action.payload
            }

        case FEEDBACK_SAVE_RESULTS:
            return {
                ...state,
                feedbackResults: action.payload
            }

        case "LOAD_IMAGE":
            return {
                ...state,
                feedbackImage: action.payload
            }

        case "LOAD_VIDEO":
            return {
                ...state,
                feedbackVideo: action.payload
            }

        case FEEDBACK_RESET:
            return {
                feedbackData: {},
                feedbackIndications: {},
                feedbackResults: {},
                feedbackImage: {},
                feedbackVideo: {}
            }

        default:
            return state
    }
}
