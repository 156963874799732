import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import { QuestionInput } from "./QuestionInput";
import {
  useReadAllAnswersEvaluationQuery,
  useReadAllFilledEvaluationQuery,
} from "../../../../queries/evaluation.query";
import { SpinnerButton } from "../../../../components/v2/SpinnerButton";
import { useEvaluationMutation } from "../../../../mutations/evaluation.mutation";
import { useQueryClient } from "@tanstack/react-query";
import { getQuestionsAnswers } from "../../../../utils/question.utils";
import download from "../../../../assets/v2/icons/download.png";
import { PrintButton } from "../../../../components/PrintButton";
import { usePrintPage } from "../../../../hooks/usePrintPage";

import { getEvaluationPrint } from "../../../../components/print/getEvaluationPrint";
import { useUserInfo } from "../../../../hooks/useUserInfo";

export function ViewFilledEvaluation({
  filledEvaluation,
  onClose = () => {},
  patient,
}) {
  const { allQuestionAnswers, readAllAnswerEvaluationQuery } =
    useReadAllAnswersEvaluationQuery({
      enabled: true,
      params: { filledEvaluationId: filledEvaluation._id },
    });

  const queryClient = useQueryClient();

  const {
    editAnswerEvaluationMutation,
    editFilledEvaluationMutation,
    createAnswerEvaluationMutation,
    uploadEvaluationImage,
  } = useEvaluationMutation();
  const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const [formData, setFormData] = useState({ name: filledEvaluation.name });

  const { isPatient, isProfessional, userDetails } = useUserInfo();

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnChange = (e, value) => {
    const question = e.question;

    setQuestionsAnswers(
      questionsAnswers.map((i) => {
        if (i.question.id === question.id) {
          i.newValue = value;
        }

        return i;
      })
    );
  };

  useEffect(() => {
    setQuestionsAnswers(allQuestionAnswers);
  }, [allQuestionAnswers]);

  const getAnswerValue = (answer, type) => {
    if (type === "0") {
      return answer?.data?.answerText;
    }

    if (type === "1") {
      return answer?.data?.answerChoice;
    }

    if (type === "2") {
      return answer?.images?.map((i) => i.image) ?? [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await editFilledEvaluationMutation.mutateAsync({
      patientEvaluationId: filledEvaluation._id,
      name: formData.name,
    });

    const allQuestions = questionsAnswers.map((qA) => ({
      id: qA.answer.data?.id,
      value: qA.newValue ?? getAnswerValue(qA.answer, qA.question.type),
      type: qA.question.type,
      question_id: qA.question.id,
    }));

    const questions = allQuestions.filter(
      (question) => question.id !== undefined
    );

    const questionsWithoutAnswerId = allQuestions
      .filter((question) => question.id === undefined)
      .map((q) => {
        q.id = q.question_id;
        return q;
      });

    const questionData = await getQuestionsAnswers({
      questions,
      uploadImageMutation: uploadEvaluationImage,
      key: "answer_id",
    });

    if (questionsWithoutAnswerId.length > 0) {
      const qAs = await getQuestionsAnswers({
        questions: questionsWithoutAnswerId,
        uploadImageMutation: uploadEvaluationImage,
      });

      await createAnswerEvaluationMutation.mutateAsync({
        patientEvaluationId: filledEvaluation._id,
        patientId: patient.id,
        name: formData.name,
        questionsAnswers: qAs, // [{question_id: 118, answerText: 'sdasd'}]
      });
    }

    await editAnswerEvaluationMutation.mutateAsync({
      questionsAnswers: questionData,
      patientEvaluationId: filledEvaluation._id,
    });

    await queryClient.refetchQueries({
      queryKey: [useReadAllFilledEvaluationQuery.key],
    });

    onClose();
  };

  const { document, printDocument } = usePrintPage({
    title: "Avaliação",
    filename: "avaliacao",
    withProfiles: true,
    patient: patient,
    bodyContent: getEvaluationPrint({
      name: filledEvaluation.name,
      questionsAnswers,
    }),
    professional: userDetails,
  });

  const isLoading =
    readAllAnswerEvaluationQuery.isFetching ||
    editFilledEvaluationMutation.isPending ||
    editAnswerEvaluationMutation.isPending ||
    uploadEvaluationImage.isPending ||
    createAnswerEvaluationMutation.isPending;

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <ModalHeader
        title={"Avaliação: " + (filledEvaluation.name ?? "-")}
        onClose={onClose}
      >
        <div className="me-2">
          <PrintButton
            icon={download}
            onClick={() => printDocument()}
          ></PrintButton>
        </div>
      </ModalHeader>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Título</Form.Label>
            <Form.Control
              name="name"
              onChange={updateFormData}
              type="text"
              placeholder="Digite o título"
              required
              disabled={isPatient}
              value={formData["name"]}
            />
          </Form.Group>

          <div
            className="w-100 bg-black bg-opacity-10 mt-3 mb-3"
            style={{ height: 2 }}
          ></div>

          <h6>Questões</h6>

          {questionsAnswers.map((qA) => {
            const { question, answer, choices, newValue } = qA;

            question.choices = choices;

            return (
              <QuestionInput
                key={question.id}
                name={question.question_name}
                question={question}
                value={newValue}
                className="my-4"
                answer={answer}
                onChange={handleOnChange}
                readOnly={isPatient}
              ></QuestionInput>
            );
          })}
        </Modal.Body>

        {isProfessional && (
          <Modal.Footer>
            <Button type="submit" disabled={isLoading}>
              <SpinnerButton isLoading={isLoading}></SpinnerButton>
            </Button>
          </Modal.Footer>
        )}
      </Form>
      {document}
    </Modal>
  );
}
