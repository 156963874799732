import { Col, Row } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import profile from "../../../assets/v2/pacient-profile-component.css";
import ProfilePhoto from "../../../components/v2/ProfilePhoto";
import RegisterCostumerV2 from "../../../components/v2/RegisterCostumerV2";
import trash from "../../../assets/v2/icons/trash2.svg";
import { useDispatch } from "react-redux";
import { deletePacient, updatePacient } from "../../../actions/pacientActions";
import { useHistory } from "react-router-dom";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { respStyle } from "../../../utils/respStyle";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { UserImageProfileV3 } from "../UserImageProfileV3";

function PacientProfileV2({
  pacient,
  handleModal,
  unsetModalBody,
  withActions = true,
  photoFullWidth = false,

  withStatus = true,
  withBirth = true,
  withGender = true,
  withAddress = true,
  withFixedSize = false,
}) {
  const mediaValues = useMediaQueryValues();

  const modal = useMemo(
    () => (
      <RegisterCostumerV2 unsetModalBody={unsetModalBody} pacient={pacient} />
    ),
    [pacient, unsetModalBody]
  );
  const history = useHistory();

  const dispatch = useDispatch();

  const [profileImage, setProfileImage] = useState({});

  const deleteHandler = (e) => {
    e.preventDefault();
    dispatch(deletePacient(pacient.id));
    history.replace("/home/personal/pacients/");
  };

  useEffect(() => {
    if (profileImage.id) {
      dispatch(updatePacient({ ...pacient, profileImage_id: profileImage.id }));
    }
  }, [profileImage]);

  return (
    <div className="row m-0">
      <style src={profile}></style>
      <Col
        className={`col-12 p-0 ${photoFullWidth ? "col-md-12" : "col-md-4"}`}
      >
        <div className={"h-100 me-1" + respStyle({ md: "" }, mediaValues)}>
          <UserImageProfileV3
            disabled={!withActions}
            handleInputImageChange={setProfileImage}
            userImage={profileImage?.url ?? pacient?.profileImage?.image}
          ></UserImageProfileV3>
        </div>
      </Col>
      <div
        className={`ppcv2-root col-12  ${
          photoFullWidth ? "col-md-12" : "col-md-8"
        }`}
      >
        <div className="d-flex justify-content-between align-items-center ppcv2-header">
          <h3>{pacient?.name}</h3>
          {withActions && (
            <div className="d-flex gap-2">
              <div
                className="add-button ml-2"
                onClick={(e) => handleModal(modal, "Editar")}
              >
                <img alt="preferências" src="assets/v2/icons/settings.png" />
              </div>
              <div
                className="add-button ml-2"
                onClick={(e) => deleteHandler(e)}
              >
                <img alt="preferências" src={trash} />
              </div>
            </div>
          )}
        </div>
        <div className="ppcv2-item-root">
          {withGender && (
            <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
              <img alt="pessoa" src="assets/v2/icons/person.svg" />
              <div>
                {pacient?.gender == "F" ? "Feminino" : ""}
                {pacient?.gender == "M" ? "Masculino" : ""}
                {pacient?.gender == "N" ? "Não declarado" : ""}
              </div>
            </div>
          )}
          {withBirth && (
            <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
              <img alt="pessoa" src="assets/v2/icons/cake.svg" />
              <div>{pacient?.birthDate?.split("-").reverse().join("/")}</div>
            </div>
          )}
          {withAddress && (
            <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
              <img alt="pessoa" src="assets/v2/icons/location.svg" />
              <div>{pacient?.address}</div>
            </div>
          )}
          <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
            <img alt="pessoa" src="assets/v2/icons/message.svg" />
            <div>{pacient?.email}</div>
          </div>
          <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
            <img alt="pessoa" src="assets/v2/icons/phone.svg" />
            <div>{pacient?.phone}</div>
          </div>

          {withStatus && (
            <div className="ppcv2-item d-flex flex-row justify-content-start align-items-center">
              <img alt="pessoa" src="assets/v2/icons/person.svg" />
              <div>
                {pacient?.user ? (
                  "Ativo"
                ) : (
                  <span className="text-secondary">
                    Não possui conta na plataforma
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PacientProfileV2;
