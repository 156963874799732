import {
    SCHEDULE_LIST_REQUEST,
    SCHEDULE_LIST_SUCCESS,
    SCHEDULE_LIST_FAIL,

    SCHEDULE_DETAILS_REQUEST,
    SCHEDULE_DETAILS_SUCCESS,
    SCHEDULE_DETAILS_FAIL,

    // SCHEDULE_DELETE_REQUEST,
    // SCHEDULE_DELETE_SUCCESS,
    // SCHEDULE_DELETE_FAIL,

    SCHEDULE_CREATE_REQUEST,
    SCHEDULE_CREATE_SUCCESS,
    SCHEDULE_CREATE_FAIL,
    SCHEDULE_CREATE_RESET,

    // SCHEDULE_UPDATE_REQUEST,
    // SCHEDULE_UPDATE_SUCCESS,
    // SCHEDULE_UPDATE_FAIL,
    // SCHEDULE_UPDATE_RESET,

    // SCHEDULE_SAVE_DATA,
    // SCHEDULE_SAVE_INDICATIONS,
    // SCHEDULE_SAVE_RESULTS,


    SCHEDULE_RESET
} from '../constants/scheduleConstats';

export const scheduleListReducer = (state = { schedules: [] }, action) => {
    switch (action.type) {
        case SCHEDULE_LIST_REQUEST:
            return { loading: true, schedules: [] }
            
        case SCHEDULE_LIST_SUCCESS:
           return {
                loading: false,
                schedules: action.payload,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case SCHEDULE_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const scheduleDetailsReducer = (state = { schedule: null }, action) => {
    switch (action.type) {
        case SCHEDULE_DETAILS_REQUEST:
            return { loading: true, schedule: null }

        case SCHEDULE_DETAILS_SUCCESS:
           return {
                loading: false,
                schedule: action.payload,
            }

        case SCHEDULE_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const scheduleCreateReducer = (state = { scheduleCreate: [] }, action) => {
    switch (action.type) {
        case SCHEDULE_CREATE_REQUEST:
            return { loadingPacientCreate: true }

        case SCHEDULE_CREATE_SUCCESS:
            return { loadingPacientCreate: false, scheduleCreate: action.payload }

        case SCHEDULE_CREATE_FAIL:
            return { loadingPacientCreate: false, errorPacientCreate: action.payload }

        case SCHEDULE_CREATE_RESET:
            return { scheduleCreate: [] }

        default:
            return state
    }
}