import stepbar from '../../../assets/v2/stepbar-component.css'

function Stepbar({ stepLength = 3, activeStep = 0 }) {
  const renderSteps = () => {
    const steps = [];
    let i = 0;
    while (i < stepLength) {
      steps.push(
        <div className={`step ${i <= activeStep ? "active-step" : ""}`}></div>
      );
      i++;
    }
    return steps;
  };
  return (
    <div className="step-bar-root">
      <style src={stepbar}></style>
      <div className="step-bar">
        <div className="step-line"></div>
        {renderSteps()}
      </div>
    </div>
  );
}

export default Stepbar;
