export function parseBrazilianPhoneNumber(phone) {
  // Remove any non-digit characters from the phone number
  const cleanedPhone = phone.replace(/\D/g, "");

  // Check if the cleaned phone number matches the Brazilian format
  const brazilianPhoneRegex = /^55(\d{2})(\d{4,5})(\d{4})$/;
  const match = cleanedPhone.match(brazilianPhoneRegex);

  if (match) {
    const countryCode = "55";
    const areaCode = match[1];
    const number = match[2] + match[3];

    return {
      country_code: countryCode,
      area_code: areaCode,
      number: number,
    };
  } else {
    // If the phone number doesn't match the Brazilian format
    throw "Invalid Brazilian phone number format";
  }
}
