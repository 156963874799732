import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useUserInfo } from "../../hooks/useUserInfo";
import DateField from "./Fields/DateField";
import { formatToMoneyString, onMoneyInput } from "../../utils/mask.utils";

export function ChargeForm({ charge = {}, setCharge = () => {} }) {
  const { userDetails } = useUserInfo();

  const onMoneyCharge = (e) => {
    const realValue = onMoneyInput(e);
    setCharge((prev) => ({ ...prev, value: realValue }));
  };

  return (
    <Card className="">
      <Card.Header>Cobrança</Card.Header>
      <Card.Body>
        <Form.Group className="">
          <Form.Check
            disabled={!userDetails?.recipient?.idPagueMe}
            onChange={(e) =>
              setCharge((prev) => ({ ...prev, enabled: e.target.checked }))
            }
            value={charge.enabled}
            checked={charge.enabled}
            label={<Form.Check.Label>Cobrar Aluno</Form.Check.Label>}
          ></Form.Check>

          {!userDetails?.recipient?.idPagueMe && (
            <Form.Control.Feedback className="d-flex" type="invalid">
              Registre uma conta bancária no perfil, para habilitar essa opção.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {charge?.enabled && (
          <>
            <div
              style={{ height: 2 }}
              className="w-100 bg-body-secondary mb-4 mt-2"
            ></div>

            <div className="d-flex flex-wrap">
              <div className="col-12 col-md-6">
                <Form.Label>Data Inicial</Form.Label>
                <DateField
                  currentDate={charge.initialDate}
                  setDate={(d) =>
                    setCharge((prev) => ({ ...prev, initialDate: d }))
                  }
                ></DateField>
              </div>

              <Form.Group className="ms-2 mb-2 flex-grow-1 col-12 col-md-5">
                <Form.Label>Valor a ser cobrado</Form.Label>

                <Form.Control
                  onChange={(e) => onMoneyCharge(e)}
                  type="text"
                  value={formatToMoneyString(charge.value)}
                  required
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Check
                  onChange={(e) =>
                    setCharge((prev) => ({
                      ...prev,
                      recorrency: e.target.checked,
                    }))
                  }
                  value={charge.recorrency}
                  checked={charge.recorrency}
                  label={<Form.Check.Label>Recorrência</Form.Check.Label>}
                ></Form.Check>

                {!userDetails?.recipient?.idPagueMe && (
                  <Form.Control.Feedback className="d-flex" type="invalid">
                    Registre uma conta bancária no perfil, para habilitar essa
                    opção.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
