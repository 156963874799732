import {
    EXERCISE_DONE_LIST_REQUEST,
    EXERCISE_DONE_LIST_SUCCESS,
    EXERCISE_DONE_LIST_FAIL,

    MUSCLE_LIST_REQUEST,
    MUSCLE_LIST_SUCCESS,
    MUSCLE_LIST_FAIL,

    EXERCISE_DONE_DETAILS_REQUEST,
    EXERCISE_DONE_DETAILS_SUCCESS,
    EXERCISE_DONE_DETAILS_FAIL,

    EXERCISE_DONE_DELETE_REQUEST,
    EXERCISE_DONE_DELETE_SUCCESS,
    EXERCISE_DONE_DELETE_FAIL,

    EXERCISE_DONE_CREATE_REQUEST,
    EXERCISE_DONE_CREATE_SUCCESS,
    EXERCISE_DONE_CREATE_FAIL,
    EXERCISE_DONE_CREATE_RESET,

    SERIE_CREATE_REQUEST,
    SERIE_CREATE_SUCCESS,
    SERIE_CREATE_FAIL,

    SERIE_DETAILS_REQUEST,
    SERIE_DETAILS_SUCCESS,
    SERIE_DETAILS_FAIL,

    SERIE_AMOUNT_REQUEST,
    SERIE_AMOUNT_SUCCESS,
    SERIE_AMOUNT_FAIL,

    ARTICULATION_RESULTS_DETAILS_REQUEST,
    ARTICULATION_RESULTS_DETAILS_SUCCESS,
    ARTICULATION_RESULTS_DETAILS_FAIL,

    ARTICULATION_CREATE_REQUEST,
    ARTICULATION_CREATE_SUCCESS,
    ARTICULATION_CREATE_FAIL,

    ALERTS_CREATE_REQUEST,
    ALERTS_CREATE_SUCCESS,
    ALERTS_CREATE_FAIL,

    ARTICULATION_DETAILS_REQUEST,
    ARTICULATION_DETAILS_SUCCESS,
    ARTICULATION_DETAILS_FAIL,

    EXERCISE_DONE_UPDATE_REQUEST,
    EXERCISE_DONE_UPDATE_SUCCESS,
    EXERCISE_DONE_UPDATE_FAIL,
    EXERCISE_DONE_UPDATE_RESET,

    EXERCISE_DONE_CREATE_REVIEW_REQUEST,
    EXERCISE_DONE_CREATE_REVIEW_SUCCESS,
    EXERCISE_DONE_CREATE_REVIEW_FAIL,
    EXERCISE_DONE_CREATE_REVIEW_RESET,

    EXERCISE_DONE_TOP_REQUEST,
    EXERCISE_DONE_TOP_SUCCESS,
    EXERCISE_DONE_TOP_FAIL,

    EXERCISE_DONE_SAVE_DATA,
    EXERCISE_DONE_SAVE_INDICATIONS,
    EXERCISE_DONE_SAVE_RESULTS,

    FEED_BODYBUILDER_SERIE_REQUEST,
    FEED_BODYBUILDER_SERIE_SUCCESS,
    FEED_BODYBUILDER_SERIE_FAIL,

    FEED_BODYBUILDER_ARTICULATION_REQUEST,
    FEED_BODYBUILDER_ARTICULATION_SUCCESS,
    FEED_BODYBUILDER_ARTICULATION_FAIL,

    FEED_PERSONAL_REQUEST,
    FEED_PERSONAL_SUCCESS,
    FEED_PERSONAL_FAIL,

    SERIE_CREATE_RESET,
    ARTICULATION_CREATE_RESET
} from '../constants/exerciseDoneConstants'


export const exerciseListReducer = (state = { exercises: [] }, action) => {
    switch (action.type) {
        case EXERCISE_DONE_LIST_REQUEST:
            return { loading: true, exercises: [] }

        case EXERCISE_DONE_LIST_SUCCESS:
            return {
                loading: false,
                exercises: action.payload.exercises,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case EXERCISE_DONE_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const exerciseDetailsReducer = (state = { exercise: { reviews: [] } }, action) => {
    switch (action.type) {
        case EXERCISE_DONE_DETAILS_REQUEST:
            return { loading: true, ...state }

        case EXERCISE_DONE_DETAILS_SUCCESS:
            return { loading: false, exercise: action.payload }

        case EXERCISE_DONE_DETAILS_FAIL:
            return { loading: false, error: action.payload }


        default:
            return state
    }
}


export const exerciseDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case EXERCISE_DONE_DELETE_REQUEST:
            return { loading: true }

        case EXERCISE_DONE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case EXERCISE_DONE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const exerciseExecutionReducer =  (state = {}, action) => {
    switch (action.type)
    {
        case "SET_EXECUTION":
            return { ...state, payload: action.payload }
            
        default: 
            return state;
    }
}


export const exerciseDoneCreateReducer = (state = { exerciseDone: null, serie: [], articulation: [], alerts: [] }, action) => {
    switch (action.type) {
        case EXERCISE_DONE_CREATE_REQUEST:
            return { loading: true }

        case EXERCISE_DONE_CREATE_SUCCESS:
            return {
                ...state,
                successExerciseDone: true,
                exerciseDone: action.payload, 
                loading: false
            }

        case SERIE_CREATE_SUCCESS:
            return {
                ...state,
                successSerie: true,
                serie: action.payload,
                loading: false
            }

        case ARTICULATION_CREATE_SUCCESS:
            return {
                ...state,
                successArticulation: true,
                articulation: action.payload,
                loading: false
            }

        case ALERTS_CREATE_SUCCESS:
            return {
                ...state,
                successAlerts: true,
                alerts: action.payload,
                loading: false
            }

        case EXERCISE_DONE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case EXERCISE_DONE_CREATE_RESET:
            return {
                successExerciseDone: false,
                exerciseDone: [],
                successSerie: false,
                serie: [],
                successArticulation: false,
                articulation: [],
                successAlerts: false,
                alerts: [],
                loading: false
            }

        default:
            return state
    }
}

export const articulationDetailsReducer = (state = { articulations: [] }, action) => {
    switch (action.type) {
        case ARTICULATION_DETAILS_REQUEST:
            return { loading: true, ...state }

        case ARTICULATION_DETAILS_SUCCESS:
            return {
                loading: false,
                articulations: action.payload
            }

        case ARTICULATION_DETAILS_FAIL:
            return { loading: false, error: action.payload }


        default:
            return state
    }
}

export const serieDetailsReducer = (state = { serieDetail: [] }, action) => {
    switch (action.type) {
        case SERIE_DETAILS_REQUEST:
            return { loadingSerie: true, ...state }

        case SERIE_DETAILS_SUCCESS:
            return { loadingSerie: false, serieDetail: action.payload }

        case SERIE_DETAILS_FAIL:
            return { loadingSerie: false, error: action.payload }

        case SERIE_CREATE_RESET:
            return {
                serieDetail: {}
            }

        default:
            return state
    }
}

export const articulationsDetailsReducer = (state = { articulations: [] }, action) => {
    switch (action.type) {
        case ARTICULATION_RESULTS_DETAILS_REQUEST:
            return { loadingArticulations: true, ...state }

        case ARTICULATION_RESULTS_DETAILS_SUCCESS:
            return { loadingArticulations: false, articulations: action.payload }

        case ARTICULATION_RESULTS_DETAILS_FAIL:
            return { loadingArticulations: false, errorArticulations: action.payload }

        case ARTICULATION_CREATE_RESET:
            return {
                articulations: {}
            }

        default:
            return state
    }
}

export const feedBodybuilderReducerSerie = (state = { feedSerie: [] }, action) => {
    switch (action.type) {
        case FEED_BODYBUILDER_SERIE_REQUEST:
            return {
                success: false,
                loadingSerie: true,
                ...state
            }

        case FEED_BODYBUILDER_SERIE_SUCCESS:
            return {
                ...state,
                success: true,
                feedSerie: action.payload
            }

        case FEED_BODYBUILDER_SERIE_FAIL:
            return {
                success: false,
                loadingSerie: false,
                errorSerie: action.payload
            }

        default:
            return state
    }
}

export const feedBodybuilderReducerArticulation = (state = { feedArticulation: [] }, action) => {
    switch (action.type) {
        case FEED_BODYBUILDER_ARTICULATION_REQUEST:
            return { loadingFeedArticulation: true, ...state }

        case FEED_BODYBUILDER_ARTICULATION_SUCCESS:
            return {
                ...state,
                feedArticulation: state.feedArticulation.concat(action.payload),
                page: action.payload.page,
                pages: action.payload.pages

            }

        case FEED_BODYBUILDER_ARTICULATION_FAIL:
            return { loadingFeedArticulation: false, errorArticulation: action.payload }


        default:
            return state
    }
}

export const feedPersonalReducer = (state = { feed: [] }, action) => {
    switch (action.type) {
        case FEED_PERSONAL_REQUEST:
            return { loading: true, feed: [] }

        case FEED_PERSONAL_SUCCESS:
            return {
                loading: false, feed: action.payload
            }

        case FEED_PERSONAL_FAIL:
            return { loading: false, error: action.payload }


        default:
            return state
    }
}

export const serieAmountReducer = (state = { amountSerie: [] }, action) => {
    switch (action.type) {
        case SERIE_AMOUNT_REQUEST:
            return { loadingAmountSerie: true, ...state }

        case SERIE_AMOUNT_SUCCESS:
            return { loadingAmountSerie: false, amountSerie: action.payload }

        case SERIE_AMOUNT_FAIL:
            return { loadingAmountSerie: false, errorAmountSerie: action.payload }


        default:
            return state
    }
}