import React, { useEffect, useState } from "react";
import { Card, Form, Image, Row } from "react-bootstrap";
import { useReadPaymentCoupomQuery } from "../../../queries";
import check from "../../../assets/v2/icons/check.svg";

export function CoupomInput({
  newPrice,
  planPrice,
  isMonthly,
  plan,
  planId,

  disabled = false,
  onCoupomFetch = () => {},
}) {
  const [coupomName, setCoupomName] = useState("");

  const { coupom, readCoupomQuery } = useReadPaymentCoupomQuery({
    enabled: coupomName !== "",
    params: { coupom: coupomName, id: planId },
  });

  useEffect(() => {
    onCoupomFetch(coupom);
  }, [coupom]);

  return (
    <section className="d-flex flex-column flex-md-row">
      <Form.Group className="mb-3 p-0 col-12 p-0" controlId="formContact">
        <Form.Label className="fw-bold">Aplicar cupom de desconto:</Form.Label>
        <div className="d-flex align-items-center">
          <Form.Control
            value={coupomName}
            onChange={(e) => setCoupomName(e.target.value)}
            type="text"
            placeholder="Digite aqui"
            name="coupom"
            disabled={disabled}
          />
          {coupom && coupom.is_active && (
            <Image className="ms-2" width={25} height={25} src={check}></Image>
          )}
        </div>

        {coupomName !== "" && !coupom && (
          <Form.Control.Feedback type="invalid" className="d-flex">
            Cupom Inválido
          </Form.Control.Feedback>
        )}
        {coupom && !coupom.is_active && (
          <Form.Control.Feedback type="invalid" className="d-flex">
            Cupom Expirado!
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </section>
  );
}

export const CoupomInputResume = ({
  plan,
  isMonthly,
  planPrice,
  newPrice,
  coupom,
  planType,
  status,
  paymentDate,
}) => (
  <Row className="m-0 p-0 w-100 h-100">
    <Card className="col-12">
      <Card.Header className="bg-white">
        <Card.Title className="h5 mb-0">Resumo</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex justify-content-between flex-wrap">
        <Card.Text>{plan.title}</Card.Text>
        <Card.Text>
          {planType ? planType : isMonthly ? "Mensal" : "Anual"}
        </Card.Text>
        <Card.Text>
          Valor Final:{" "}
          <span className="text-primary fw-bold">
            R$ {newPrice ?? planPrice}
          </span>
        </Card.Text>

        <Card.Text className="w-100">
          Cupom:{" "}
          <span className="text-primary fw-bold">
            {coupom && coupom.is_active
              ? `${coupom.code} (R\$ ${coupom.discount})`
              : "Nenhum"}
          </span>
        </Card.Text>
      </Card.Body>
    </Card>
  </Row>
);
