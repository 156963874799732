import { useMutation } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";
import { useUserInfo } from "../hooks/useUserInfo";

export function usePaymentMutation() {
  const config = useApiConfig();

  const { isPatient } = useUserInfo();

  const baseUrl = isPatient ? "/api/payment/patient" : "/api/payment";

  // {
  //   "plan_id" : 3,
  //   "customer": {
  //   "name": "Leandro de Souza Rodriggues",
  //   "email": "leandro.rn.souza@hotmail.com",
  //   "cpf" : "10018365450",
  //   "address": {
  //   "street_number": "Rua colina das palmeiras, 168",
  //   "zip_code": "59151130",
  //   "city": "Parnamirim",
  //   "state": "RN"
  //   },
  //   "phones": {
  //   "mobile_phone": {
  //   "country_code": "55",
  //   "area_code": 84,
  //   "number": 998419659
  //   }
  //   }
  //   },
  //   "items": {
  //   "price": 2,
  //   "description": "plano premium",
  //   "quantity": 1
  //   },
  //   "payments": {
  //   "payment_method": "credit_card",
  //   "number": "5581201224747337",
  //   "holder_name": "Leandro de Souza Rodrigues",
  //   "exp_month": 6,
  //   "exp_year": 32,
  //   "cvv": "041"
  //   }
  //   }
  const createPayment = useMutation({
    mutationFn: async (params) => {
      return api.post(`${baseUrl}/subscription/create/`, params, config);
    },
  });

  const cancelPlanMutation = useMutation({
    mutationFn: async (params) => {
      return api.delete(`${baseUrl}/subscription/cancel/`, config);
    },
  });

  // {
  //   "plan_id" : 3,
  //   "paymentsTypes_id" : 6,
  //   "customer": {
  //   "name": "Leandro de Souza Rodriggues",
  //   "email": "leandro.rn.souza@hotmail.com",
  //   "cpf" : "10018365450",
  //   "address": {
  //   "street_number": "Rua colina das palmeiras, 168",
  //   "zip_code": "59151130",
  //   "city": "Parnamirim",
  //   "state": "RN"
  //   }
  //   },
  //   "items": {
  //   "price": 2,
  //   "description": "plano premium",
  //   "quantity": 1
  //   }
  //   }

  const createPixPayment = useMutation({
    mutationFn: async (params) => {
      return api.post(`${baseUrl}/pix/create/`, params, config);
    },
  });

  // {
  // "plan_id" : 3,
  // "paymentsTypes_id" : 3,
  // "customer": {
  // "name": "Leandro de Souza Rodriggues",
  // "email": "leandro.rn.souza@hotmail.com",
  // "cpf" : "10018365450",
  // "address": {
  // "street_number": "Rua colina das palmeiras, 168",
  // "zip_code": "59151130",
  // "city": "Parnamirim",
  // "state": "RN"
  // }
  // },
  // "items": {
  // "price": 2,
  // "description": "plano basico",
  // "quantity": 1
  // }
  // }

  const createBoletoPayment = useMutation({
    mutationFn: async (params) => {
      return api.post(`${baseUrl}/boleto/create/`, params, config);
    },
  });

  // {
  //   "customer" : {
  //   "name": "Tony Stark",
  //   "email": "tstark@teste.com",
  //   "description": "Recebedor Tony Stark",
  //   "cpf": "26224451990"
  //   },
  //   "type": "individual",
  //   "default_bank_account": {
  //   "bank": "341", //Sempre informar o código do banco
  //   "branch_number": "1235",
  //   "branch_check_digit": "6",
  //   "account_number": "12345",
  //   "account_check_digit": "6",
  //   "type": "checking" //checking => Conta-corrente e savings => conta poupança
  //   }
  //   }

  const createPaymentRecipient = useMutation({
    mutationFn: async (params) => {
      return api.post(`${baseUrl}/recipient/create/`, params, config);
    },
  });

  return {
    createPayment,
    cancelPlanMutation,
    createPixPayment,
    createBoletoPayment,
    createPaymentRecipient,
  };
}
