import React from "react";
import { Spinner } from "react-bootstrap";

// import { Container } from './styles';

export function SpinnerButton({ isLoading = false, text = "Salvar" }) {
  return !isLoading ? (
    text
  ) : (
    <div className="" style={{ transform: `translate(${0}px, ${2}px)` }}>
      <Spinner
        animation="border"
        role="status"
        style={{
          height: 20,
          width: 20,
        }}
      ></Spinner>
    </div>
  );
}
