export const EXERCISE_DONE_LIST_REQUEST = 'EXERCISE_DONE_LIST_REQUEST'
export const EXERCISE_DONE_LIST_SUCCESS = 'EXERCISE_DONE_LIST_SUCCESS'
export const EXERCISE_DONE_LIST_FAIL = 'EXERCISE_DONE_LIST_FAIL'

export const EXERCISE_DONE_DETAILS_REQUEST = 'EXERCISE_DONE_DETAILS_REQUEST'
export const EXERCISE_DONE_DETAILS_SUCCESS = 'EXERCISE_DONE_DETAILS_SUCCESS'
export const EXERCISE_DONE_DETAILS_FAIL = 'EXERCISE_DONE_DETAILS_FAIL'

export const EXERCISE_DONE_DELETE_REQUEST = 'EXERCISE_DONE_DELETE_REQUEST'
export const EXERCISE_DONE_DELETE_SUCCESS = 'EXERCISE_DONE_DELETE_SUCCESS'
export const EXERCISE_DONE_DELETE_FAIL = 'EXERCISE_DONE_DELETE_FAIL'

export const EXERCISE_DONE_CREATE_REQUEST = 'EXERCISE_DONE_CREATE_REQUEST'
export const EXERCISE_DONE_CREATE_SUCCESS = 'EXERCISE_DONE_CREATE_SUCCESS'
export const EXERCISE_DONE_CREATE_FAIL = 'EXERCISE_DONE_CREATE_FAIL'
export const EXERCISE_DONE_CREATE_RESET = 'EXERCISE_DONE_CREATE_RESET'

export const SERIE_CREATE_REQUEST = 'SERIE_CREATE_REQUEST'
export const SERIE_CREATE_SUCCESS = 'SERIE_CREATE_SUCCESS'
export const SERIE_CREATE_FAIL = 'SERIE_CREATE_FAIL'
export const SERIE_CREATE_RESET = 'SERIE_CREATE_RESET'

export const SERIE_DETAILS_REQUEST = 'SERIE_DETAILS_REQUEST'
export const SERIE_DETAILS_SUCCESS = 'SERIE_DETAILS_SUCCESS'
export const SERIE_DETAILS_FAIL = 'SERIE_DETAILS_FAIL'

export const SERIE_AMOUNT_REQUEST = 'SERIE_AMOUNT_REQUEST'
export const SERIE_AMOUNT_SUCCESS = 'SERIE_AMOUNT_SUCCESS'
export const SERIE_AMOUNT_FAIL = 'SERIE_AMOUNT_FAIL'

export const ARTICULATION_CREATE_REQUEST = 'ARTICULATION_CREATE_REQUEST'
export const ARTICULATION_CREATE_SUCCESS = 'ARTICULATION_CREATE_SUCCESS'
export const ARTICULATION_CREATE_FAIL = 'ARTICULATION_CREATE_FAIL'
export const ARTICULATION_CREATE_RESET = 'ARTICULATION_CREATE_RESET'

export const ALERTS_CREATE_REQUEST = 'ALERTS_CREATE_REQUEST'
export const ALERTS_CREATE_SUCCESS = 'ALERTS_CREATE_SUCCESS'
export const ALERTS_CREATE_FAIL = 'ALERTS_CREATE_FAIL'

export const ARTICULATION_DETAILS_REQUEST = 'ARTICULATION_DETAILS_REQUEST'
export const ARTICULATION_DETAILS_SUCCESS = 'ARTICULATION_DETAILS_SUCCESS'
export const ARTICULATION_DETAILS_FAIL = 'ARTICULATION_DETAILS_FAIL'

export const ARTICULATION_RESULTS_DETAILS_REQUEST = 'ARTICULATION_RESULTS_DETAILS_REQUEST'
export const ARTICULATION_RESULTS_DETAILS_SUCCESS = 'ARTICULATION_RESULTS_DETAILS_SUCCESS'
export const ARTICULATION_RESULTS_DETAILS_FAIL = 'ARTICULATION_RESULTS_DETAILS_FAIL'

export const EXERCISE_DONE_UPDATE_REQUEST = 'EXERCISE_DONE_UPDATE_REQUEST'
export const EXERCISE_DONE_UPDATE_SUCCESS = 'EXERCISE_DONE_UPDATE_SUCCESS'
export const EXERCISE_DONE_UPDATE_FAIL = 'EXERCISE_DONE_UPDATE_FAIL'
export const EXERCISE_DONE_UPDATE_RESET = 'EXERCISE_DONE_UPDATE_RESET'

export const FEED_PERSONAL_REQUEST = 'FEED_PERSONAL_REQUEST'
export const FEED_PERSONAL_SUCCESS = 'FEED_PERSONAL_SUCCESS'
export const FEED_PERSONAL_FAIL = 'FEED_PERSONAL_FAIL'

export const FEED_BODYBUILDER_SERIE_REQUEST = 'FEED_BODYBUILDER_SERIE_REQUEST'
export const FEED_BODYBUILDER_SERIE_SUCCESS = 'FEED_BODYBUILDER_SERIE_SUCCESS'
export const FEED_BODYBUILDER_SERIE_FAIL = 'FEED_BODYBUILDER_SERIE_FAIL'

export const FEED_BODYBUILDER_ARTICULATION_REQUEST = 'FEED_BODYBUILDER_ARTICULATION_REQUEST'
export const FEED_BODYBUILDER_ARTICULATION_SUCCESS = 'FEED_BODYBUILDER_ARTICULATION_SUCCESS'
export const FEED_BODYBUILDER_ARTICULATION_FAIL = 'FEED_BODYBUILDER_ARTICULATION_FAIL'



