import React, { useRef, useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import trash from "../../../../assets/v2/icons/trash.svg";

export function QuestionForm({ id, onSubmit = () => {} }) {
  const [questionChoices, setQuestionChoices] = useState([]);

  const [choiceData, setChoiceData] = useState("");

  // a form cannot be a child of another form, so we need to check the inputs individually
  const inputRefs = useRef([]);

  const [formData, setFormData] = useState({
    question_name: "",
    type: "0",
  });

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addInputRef = (ref, type) => {
    if (!ref) return;

    if (!inputRefs.current.find((i) => i.ref.id === ref.id)) {
      inputRefs.current.push({ ref, type });
    }
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const results = inputRefs.current
      .filter((i) => i.type === "all" || i.type === formData.type)
      .map((i) => i.ref.reportValidity());

    if (results.includes(false)) return;

    onSubmit({
      ...formData,
      choices: questionChoices,
      id,
    });
  };

  return (
    <Card className="border-primary">
      <Card.Body>
        <Form.Group>
          <Form.Label>Pergunta</Form.Label>
          <Form.Control
            id="questionFormName"
            ref={(ref) => addInputRef(ref, "all")}
            type="text"
            placeholder="Digite a pergunta"
            name="question_name"
            required
            value={formData.question_name}
            onChange={updateFormData}
          />
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>Formato</Form.Label>
          <Form.Control
            as="select"
            ref={(ref) => addInputRef(ref, "all")}
            required
            name="type"
            value={formData.type}
            onChange={updateFormData}
          >
            <option value={"0"}>Texto</option>
            <option value={"1"}>Mútipla Escolha</option>
            <option value={"2"}>Imagem</option>
          </Form.Control>
        </Form.Group>

        {formData.type === "1" && (
          <div className="my-4 position-relative">
            <Form.Label className="text-primary">Escolhas</Form.Label>

            {/* Input to prevent send questionChoices with 0 length */}
            <input
              type="text"
              className="pe-none position-absolute start-0 opacity-0"
              required
              id="questionFormChoicesLength"
              onChange={() => {}}
              ref={(ref) => addInputRef(ref, "1")}
              value={questionChoices.length === 0 ? "" : questionChoices.length}
            ></input>

            {questionChoices.map((choice, i) => (
              <Form.Group key={i} className="flex-between align-items-center">
                <Form.Check
                  id={`questionFormChoice${choice.choice_name}`}
                  disabled={true}
                  label={choice.choice_name}
                ></Form.Check>
                <Image
                  width={30}
                  className="p-1 pointer"
                  src={trash}
                  onClick={() =>
                    setQuestionChoices(
                      questionChoices.filter((_, index) => index !== i)
                    )
                  }
                ></Image>
              </Form.Group>
            ))}

            <div className="mt-3">
              <Form.Group>
                <Form.Label>Opção {questionChoices.length + 1}</Form.Label>

                <div className="d-flex">
                  <Form.Control
                    type="text"
                    id="questionFormChoiceText"
                    ref={(ref) => addInputRef(ref, "1")}
                    placeholder="Texto para opção"
                    required={questionChoices.length === 0}
                    value={choiceData}
                    onChange={(e) => {
                      setChoiceData(e.target.value);
                    }}
                  />
                  <Button
                    className="ms-2"
                    onClick={() => {
                      if (choiceData.length > 0) {
                        setQuestionChoices([
                          ...questionChoices,
                          {
                            choice_name: choiceData,
                            value: questionChoices.length,
                            number_order: questionChoices.length + 1,
                          },
                        ]);
                        setChoiceData("");
                      }
                    }}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>
        )}
      </Card.Body>

      <Card.Footer className="d-flex justify-content-center">
        <Button onClick={handleSubmit} className="btn btn-primary">
          Confirmar
        </Button>
      </Card.Footer>
    </Card>
  );
}
