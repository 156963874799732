
import { WORKOUTSHEET_ITEM_DELETE_SUCCESS, WORKOUTSHEET_UPDATE_SUCCESS, WORKOUTSHEET_LIST_SUCCESS } from '../constants/workoutSheetConstants'





export const workoutSheetDeleteItemReducer = (state = {loading: false, payload: {}  }, action) => {
    switch (action.type) {
        case WORKOUTSHEET_ITEM_DELETE_SUCCESS:
            return { loading: false, payload: action.payload }

        default:
            return state
    }
}



export const workoutSheetUpdateItemReducer = (state = { loading: false, payload: {}  }, action) => {
    switch (action.type) {
        case WORKOUTSHEET_UPDATE_SUCCESS:
            return { loading: false, payload: action.payload }

        default:
            return state
    }
}


export const workoutSheetListReducer = (state = {  loading: false, payload: {} }, action) => {
    switch (action.type) {
        case WORKOUTSHEET_LIST_SUCCESS:
            return { loading: false, payload: action.payload }

        default:
            return state
    }
}