import { useMutation } from "@tanstack/react-query";
import api from "../services/api";
import { useApiConfig } from "../hooks/useApiConfig";

export function useEvaluationMutation() {
  const configFormData = useApiConfig("multipart/form-data");
  const config = useApiConfig();

  //   {
  //     "name_evaluation": "modelo personal"
  //     }
  const createEvaluationModelMutation = useMutation({
    mutationFn: async (params) => {
      return api.post("/api/profiles/evaluation/create/", params, config);
    },
  });

  //   {
  //  evaluationModelId: "1"
  //     "name_evaluation": "modelo personal"
  //     }
  const editEvaluationModelMutation = useMutation({
    mutationFn: async (params) => {
      return api.put(
        `/api/profiles/evaluation/edit/${params.evaluationModelId}/`,
        params,
        config
      );
    },
  });

  //   {
  //        evaluationModelId: "1"
  //     }
  const deleteEvaluationModelMutation = useMutation({
    mutationFn: async (params) => {
      return api.delete(
        `/api/profiles/evaluation/model/delete/${params.evaluationModelId}/`,
        config
      );
    },
  });

  //   {
  //   "modelId": "1",
  //     "question_name": "qual seu peso?",
  //     "type": "0"
  //     }
  const createQuestionEvaluationMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(
        `/api/profiles/evaluation/question/create/${params.modelId}/`,
        params,
        config
      );
    },
  });

  //   {
  //        questionId: "1"
  //     }
  const deleteQuestionEvaluationMutation = useMutation({
    mutationFn: async (params) => {
      return api.delete(
        `/api/profiles/evaluation/question/delete/${params.questionId}/`,
        config
      );
    },
  });

  //   {
  //     "number_order": 4,
  //     "choice_name": "abaixo de 18 anos"
  //     questionId: "1"
  //    }
  const createChoiceQuestionMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(
        `/api/profiles/evaluation/question/choice/create/${params.questionId}/`,
        params,
        config
      );
    },
  });

  //   {
  //     patientEvaluationId: "1"
  //     "name" : "primeira avaliação",
  //     "questionsAnswers" : [
  //     {
  //     "question_id" : 1,
  //     "answerText" : "Leandro"
  //     },
  //     {
  //     "question_id" : 2,
  //     "choice_id" : 2
  //     },
  //     {
  //     "question_id" : 3,
  //     "imageEvaluation_id" : 1
  //     }
  //     ]

  //     }

  const createAnswerEvaluationMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(
        `/api/profiles/evaluation/answer/create/${params.patientEvaluationId}/`,
        params,
        config
      );
    },
  });

  // patientId
  // evaluationModelId
  // name

  const createFilledEvaluationMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(
        `/api/profiles/evaluation/patient/create/${params.patientId}/${params.evaluationModelId}/`,
        params,
        config
      );
    },
  });

  //   {
  //     filledEvaluationId: "1"
  //    }
  const deleteFilledEvaluationMutation = useMutation({
    mutationFn: async (params) => {
      return api.delete(
        `/api/profiles/evaluation/answer/delete/${params.filledEvaluationId}/`,
        config
      );
    },
  });

  // patientEvaluationId
  // name
  const editFilledEvaluationMutation = useMutation({
    mutationFn: async (params) => {
      return api.put(
        `/api/profiles/evaluation/patient/edit/${params.patientEvaluationId}/`,
        params,
        config
      );
    },
  });

  //   {
  //     patientEvaluationId: "1"
  //     "name" : "primeira avaliação",
  //     "questionsAnswers" : [
  //     {
  //     "question_id" : 1,
  //     "answerText" : "Leandro"
  //     },
  //     {
  //     "question_id" : 2,
  //     "choice_id" : 2
  //     },
  //     {
  //     "question_id" : 3,
  //     "imageEvaluation_id" : 1
  //     }
  //     ]

  //     }
  const editAnswerEvaluationMutation = useMutation({
    mutationFn: async (params) => {
      return api.put(
        `/api/profiles/evaluation/answer/edit/${params.patientEvaluationId}/`,
        params,
        config
      );
    },
  });

  const uploadEvaluationImage = useMutation({
    mutationFn: async (params) => {
      const { file, name } = params;

      const formData = new FormData();

      formData.append("image", file);
      formData.append("caption", name);

      return api.post(
        "/api/profiles/evaluation/upload/",
        formData,
        configFormData
      );
    },
  });

  return {
    createEvaluationModelMutation,
    editEvaluationModelMutation,
    deleteEvaluationModelMutation,
    createQuestionEvaluationMutation,
    deleteQuestionEvaluationMutation,
    createChoiceQuestionMutation,
    createFilledEvaluationMutation,
    editAnswerEvaluationMutation,
    deleteFilledEvaluationMutation,
    uploadEvaluationImage,
    createAnswerEvaluationMutation,
    editFilledEvaluationMutation,
  };
}
