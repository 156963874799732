import React, { useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { formatDate } from "../utils/date";
import { PrintPage } from "../components/print/PrintPage";

export function usePrintPage({
  title,
  filename,
  patient,
  bodyContent = [],
  withProfiles = true,
  withDataUri = false,
  professional,
}) {
  const classIdentifier = `printPage${filename}`;

  const printDocument = async () => {
    const elements = window.document.getElementsByClassName(
      `printPage${filename}`
    );

    const pdf = new jsPDF();

    for (let index = 0; index < elements.length; index++) {
      const canvas = await html2canvas(elements[index], {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 1,
      });

      const imgData = canvas.toDataURL("image/png");

      index > 0 && pdf.addPage();

      pdf.addImage(imgData, "PNG", 0, 0);
    }

    const newFilename =
      filename + "-" + formatDate(new Date()).replace(/\//g, "-") + ".pdf";

    if (withDataUri) {
      const dataUriString = pdf.output("datauristring");
      const data = JSON.stringify({
        dataUriString,
        filename: newFilename,
        event: "print",
      });

      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(data);
      }
    } else {
      pdf.save(newFilename);
    }
  };

  if (bodyContent.length === 0) return { document: null, printDocument };

  const document = (
    <PrintPage
      bodyContent={bodyContent}
      withProfiles={withProfiles}
      title={title}
      patient={patient}
      classIdentifier={classIdentifier}
      professional={professional}
    ></PrintPage>
  );

  return { document, printDocument };
}
