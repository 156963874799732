import { useMemo } from "react";
import api from "../services/api";
import { useQuery } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";

const key1 = "read-all-evaluation-model";
export const useReadAllEvaluationModelQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readAllEvaluationModelQuery = useQuery({
    queryKey: [key1, enabled, params],
    queryFn: async () => {
      return api.get(
        `/api/profiles/evaluation/model/readall/${params.patientId}/`,
        config
      );
    },
    enabled,
  });

  const allEvaluationsModel = useMemo(() => {
    return readAllEvaluationModelQuery.data?.data ?? [];
  }, [readAllEvaluationModelQuery.data?.data]);

  return { allEvaluationsModel, readAllEvaluationModelQuery };
};
useReadAllEvaluationModelQuery.key = key1;

const key2 = "read-all-evaluation-model";
export const useReadAllFilledEvaluationQuery = ({ enabled = true, params }) => {
  const config = useApiConfig();

  const readAllFilledEvaluationQuery = useQuery({
    queryKey: [key2, params, enabled],
    queryFn: async () => {
      return api.get(
        `/api/profiles/evaluation/model/read/${params.patientId}/${params.evaluationId}/`,
        config
      );
    },
    enabled,
  });

  const allFilledEvaluations = useMemo(() => {
    return readAllFilledEvaluationQuery.data?.data ?? [];
  }, [readAllFilledEvaluationQuery.data?.data]);

  return { allFilledEvaluations, readAllFilledEvaluationQuery };
};
useReadAllFilledEvaluationQuery.key = key2;

const key3 = "read-all-questions-evaluation";
export const useReadAllQuestionsEvaluationQuery = ({
  enabled = true,
  params,
}) => {
  const config = useApiConfig();

  const readAllQuestionsEvaluationQuery = useQuery({
    queryKey: [key3, params, enabled],
    queryFn: async () => {
      return api.get(
        `/api/profiles/evaluation/question/readall/${params.evaluationModelId}/`,
        config
      );
    },
    enabled,
  });

  const allQuestions = useMemo(() => {
    return (
      readAllQuestionsEvaluationQuery.data?.data?.questions?.map((i) => ({
        ...i.question,
        choices: i.choices ?? [],
      })) ?? []
    );
  }, [readAllQuestionsEvaluationQuery.data?.data]);

  return { allQuestions, readAllQuestionsEvaluationQuery };
};
useReadAllQuestionsEvaluationQuery.key = key3;

const key4 = "read-all-answers-evaluation";
export const useReadAllAnswersEvaluationQuery = ({
  enabled = true,
  params,
}) => {
  const config = useApiConfig();

  const readAllAnswerEvaluationQuery = useQuery({
    queryKey: [key4, params, enabled],
    queryFn: async () => {
      return api.get(
        `/api/profiles/evaluation/answer/readall/${params.filledEvaluationId}/`,
        config
      );
    },
    enabled,
  });

  const allQuestionAnswers = useMemo(() => {
    return readAllAnswerEvaluationQuery.data?.data?.questions ?? [];
  }, [readAllAnswerEvaluationQuery.data?.data]);

  return { allQuestionAnswers, readAllAnswerEvaluationQuery };
};
useReadAllAnswersEvaluationQuery.key = key4;
