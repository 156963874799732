import React, { useRef } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/v2/icons/logo.svg";
import login from "../../assets/v2/icons/login.svg";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

function HeaderV2({ history }) {
  const { t } = useTranslation();

  const toggler = useRef(null);
  const navbar = useRef(null);

  const closeMenu = () => {
    toggler.current?.classList.add("collapsed");
    navbar.current?.classList.remove("show");
  };

  return (
    <header id="/#banner">
      <nav
        id="mobile-nav"
        class="d-block position-fixed d-lg-none navbar navbar-expand-lg bg-body-tertiary"
      >
        <div class="container">
          <a class="navbar-brand" href="#">
            <img src={logo} alt="" />
          </a>
          <button
            ref={toggler}
            class="navbar-toggler border-0 pe-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse"
            id="navbarNavAltMarkup"
            ref={navbar}
          >
            <div class="navbar-nav">
              <Link
                className="nav-link mt-4 mb-3"
                aria-current="page"
                to="#banner"
                onClick={closeMenu()}
              >
                {t("about")}
              </Link>
              <Link
                className="nav-link mb-3"
                to="#benefits"
                onClick={closeMenu()}
              >
                {t("benefits")}
              </Link>
              <Link className="nav-link mb-3" to="#users" onClick={closeMenu()}>
                {t("users")}
              </Link>
              <Link className="nav-link mb-3" to="#plans" onClick={closeMenu()}>
                {t("plans")}
              </Link>
              <LanguageSelector />
              <LinkContainer to="/login">
                <div className="btn btn-primary mb-3 mt-3">
                  <img src={login} alt="" />
                  &nbsp;{t("sign_in")}
                </div>
              </LinkContainer>
            </div>
          </div>
        </div>
      </nav>

      <nav className="d-none d-lg-block navbar navbar-expand-lg">
        <div className="container">
          <div className="logo">
            <a className="navbar-brand" href="#">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="login d-flex align-items-center">
            <LanguageSelector />
            <LinkContainer to="/login">
              <div className="ms-3 btn btn-primary">
                <img src={login} alt="" />
                &nbsp;{t("sign_in")}
              </div>
            </LinkContainer>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default HeaderV2;
