import { React, useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  Row,
  Col,
  Card,
  Image,
  Button,
  Form,
  Alert,
  Modal,
  CloseButton,
} from "react-bootstrap";
import CardFolderV2 from "../../../components/v2/Cards/CardFolderV2";
import TrainingCardV2 from "../../../components/v2/Cards/TrainingCardV2";
import RegisterWorkoutV2 from "../../../components/v2/RegisterWorkoutV2";
import RegisterExerciseVideoV2 from "../../../components/v2/RegisterExerciseVideoV2";
import { useDispatch, useSelector, connect } from "react-redux";
import { listMyWorkouts } from "../../../actions/workoutActions";
import {
  listExercises,
  listMuscles,
  resetExercise,
} from "../../../actions/exerciseActions";
import { updateWorkout } from "../../../actions/workoutActions";
import PaginationV2 from "../../../components/v2/PaginationV2";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { respStyle } from "../../../utils/respStyle";
import { resetCreateFeedback } from "../../../actions/feedbackActions";
import { RoutineList } from "./RoutineList";

import { CreateRoutineModal } from "./../ProgramsV2/CreateRoutineModal";

function ActiveProgramsV2({ handleModal, unsetModalBody, visibilityModal }) {
  const dispatch = useDispatch();

  const mediaValues = useMediaQueryValues();

  const { isMD, isLG, isMobile, isXL, isLowerHeight } = mediaValues;

  const workoutsSelector = useSelector((state) => state.workoutsList);
  const workoutsCreateSelector = useSelector((state) => state.workoutsCreate);
  const exercisesList = useSelector((state) => state.exerciseList);
  const exerciseCreateSelector = useSelector((state) => state.exerciseCreate);

  const { loadingWorkoutList, workoutList, page, pages } = workoutsSelector;
  const { loadingWorkoutCreate, workoutsCreate, createWorkoutSuccess } =
    workoutsCreateSelector;
  const { exercises, page: exerPage, pages: exerPages } = exercisesList;
  const { loadingExercise, createExerciseSucess } = exerciseCreateSelector;

  const muscleList = useSelector((state) => state.muscleList);
  const { errorM, loadingM, muscles } = muscleList;

  const [showNewExerciseModal, setShowNewExerciseModal] = useState(false);

  const [currentWorkoutPage, setCurrentWorkoutPage] = useState(1);

  const [currentExercisePage, setCurrentExercisePage] = useState(1);
  const [selectedExercises, setSelectedExercises] = useState([]);

  const [showCreateRoutineModal, setShowNewRoutineModal] = useState(false);

  const [currentMuscle, setMuscle] = useState("-1");

  const [hasSelectedExercises, hasMultipleSelectedExercises] = useMemo(
    () => [selectedExercises.length > 0, selectedExercises.length > 1],
    [selectedExercises]
  );

  const [search, setSearch] = useState("");

  const handleExercisesList = (exerciseId, isAdd) => {
    isAdd ? addExercise(exerciseId) : removeExercise(exerciseId);
  };

  const addExercise = (exerciseId) => {
    if (!selectedExercises.includes(exerciseId)) {
      setSelectedExercises([...selectedExercises, exerciseId]);
    }
  };

  const removeExercise = (exerciseId) => {
    const updatedList = selectedExercises.filter(
      (exercise) => exercise !== exerciseId
    );
    setSelectedExercises(updatedList);
  };

  const clearSelected = () => {
    setSelectedExercises([]);
  };

  const handleOnDropMultiplesExercises = (workout) => {
    dispatch(updateWorkout({ ...workout, exercises: selectedExercises }));
  };

  const [selectedWorkouts, setSelectedWorkouts] = useState([]);

  useEffect(() => {
    dispatch(listMyWorkouts(currentWorkoutPage));
    dispatch(listExercises(currentExercisePage));
    dispatch(listMuscles());
  }, [dispatch, createWorkoutSuccess, createExerciseSucess]);

  useEffect(() => {
    if (!showNewExerciseModal) dispatch(listExercises(1));
  }, [showNewExerciseModal]);
  useEffect(() => {
    dispatch(listExercises(currentExercisePage, search));
  }, [currentExercisePage, search, currentMuscle]);

  useEffect(() => {
    dispatch(listMyWorkouts(currentWorkoutPage));
  }, [currentWorkoutPage]);

  const filteredExercises = useMemo(() => {
    if (currentMuscle !== "-1" && exercises) {
      const result = exercises.filter(
        (exercise) => exercise?.muscle?._id === Number(currentMuscle)
      );

      return result;
    }
    return exercises;
  }, [currentMuscle, exercises]);

  const dropDown = (
    <Button
      onClick={() => setShowNewExerciseModal(true)}
      style={{ height: 40 }}
    >
      <p className="fw-500 m-0">{isLG && !isXL ? "Criar" : "Novo Exercício"}</p>
    </Button>
  );

  const inputs = (
    <>
      <input
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Procurar"
        type="text"
        style={{ height: 40 }}
        className={
          "ps-2" +
          respStyle({ base: "w-100 my-2", md: "w-70 me-2" }, mediaValues)
        }
      ></input>

      <Form.Control
        onChange={(e) => setMuscle(e.target.value)}
        as="select"
        style={{ height: 40, minWidth: 150 }}
        className={respStyle({ md: "w-30" }, mediaValues)}
      >
        <option value={-1}>Músculo</option>
        {muscles?.map((muscle) => (
          <option value={muscle._id}>{muscle.name}</option>
        ))}
      </Form.Control>
    </>
  );

  return (
    <div className="col-12">
      <RoutineList selectedWorkouts={selectedWorkouts}></RoutineList>
      <Row className="mb-2 m-0 p-0">
        <div className="row col-12 p-0 m-0">
          {(!workoutList || workoutList.length === 0) && (
            <p className="text-center my-5 py-5">Nenhum treino cadastrado.</p>
          )}

          {workoutList &&
            workoutList?.map((workout, i) => (
              <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 pe-3 mb-3 d-flex justify-content-center align-items-center">
                <CardFolderV2
                  onDrop={(workout) => handleOnDropMultiplesExercises(workout)}
                  handleDefaultDrop={!hasMultipleSelectedExercises}
                  key={i}
                  workout={workout}
                  selected={selectedWorkouts.includes(workout?._id)}
                  onSelectedChange={(selected) => {
                    if (selected === "true")
                      setSelectedWorkouts((prev) =>
                        prev.filter((id) => id !== workout?._id)
                      );
                    else setSelectedWorkouts((prev) => [...prev, workout?._id]);
                  }}
                />
              </div>
            ))}

          {selectedWorkouts.length > 0 && (
            <div className="col-12 position-sticky exercises-selector bg-info rounded-3 d-flex justify-content-between align-items-center py-3 mt-5">
              <p className="text-white fw-bold mb-0 ms-2">{`${selectedWorkouts.length} treinos selecionados`}</p>
              <Button
                className="m-0 w-25 py-2 fw-bold btn btn-primary"
                variant="primary"
                onClick={(e) => setShowNewRoutineModal(true)}
              >
                {"Nova Rotina"}
              </Button>
            </div>
          )}
        </div>
      </Row>
      <Row className="mx-0 mb-3 mt-5">
        <div className="d-flex col-12 flex-row justify-content-between align-items-center">
          <h1 className="text-primary mb-0">Exercícios</h1>

          <div className="d-flex align-items-center">
            <div className="d-none d-lg-flex me-2">{inputs}</div>
            {dropDown}
          </div>
        </div>

        <div className="col-12 d-flex flex-wrap flex-md-nowrap mt-md-2 d-lg-none">
          {inputs}
        </div>
      </Row>
      <Row className="col-12 mb-2 mx-0 position-relative">
        {!filteredExercises && "Nenhum exercício cadastrado."}

        {filteredExercises &&
          filteredExercises?.map((exercise, i) => (
            <div className="col-sm-6  col-xl-3 mb-4">
              <TrainingCardV2
                exercise={exercise}
                key={i}
                img={"assets/v2/img/programs/card-image.jpg"}
                handleSelectExercise={handleExercisesList}
                clear={clearSelected}
              />
            </div>
          ))}

        {hasSelectedExercises && (
          <div className="col-12 position-sticky exercises-selector bg-info rounded-3 d-flex justify-content-between align-items-center py-3 mt-5">
            <p className="text-white fw-bold mb-0 ms-2">{`${selectedExercises.length} exercícios selecionados`}</p>
            <Button
              className="m-0 w-25 py-2 fw-bold btn btn-primary"
              variant="primary"
              onClick={(e) =>
                handleModal(
                  <RegisterWorkoutV2
                    exercises={selectedExercises}
                    unsetModalBody={unsetModalBody}
                  />,
                  "Novo treino"
                )
              }
            >
              {"Novo treino"}
            </Button>
          </div>
        )}

        <PaginationV2
          currentPage={currentExercisePage}
          page={exerPage}
          pages={exerPages}
          setCurrentPage={setCurrentExercisePage}
        ></PaginationV2>
      </Row>

      <Modal
        show={showNewExerciseModal}
        onHide={() => setShowNewExerciseModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="text-primary">Novo Exercício</Modal.Title>
          <CloseButton
            className="btn text-danger fs-4 my-0 py-0"
            onClick={() => setShowNewExerciseModal(undefined)}
          />
        </Modal.Header>
        <Modal.Body>
          <RegisterExerciseVideoV2
            onFinish={() => setShowNewExerciseModal(false)}
          />
        </Modal.Body>
      </Modal>

      {showCreateRoutineModal && (
        <CreateRoutineModal
          intitalWorkouts={selectedWorkouts}
          onFinish={() => {
            setShowNewRoutineModal(false);
            setSelectedWorkouts([]);
          }}
        ></CreateRoutineModal>
      )}
    </div>
  );
}

export default ActiveProgramsV2;
