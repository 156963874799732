const stringToDate = (dateString) => {
  if (!dateString) return null;

  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [day, month, year] = dateString.split("-").reverse();

    const value = new Date(Number(year), Number(month - 1), Number(day));

    return value;
  } else if (
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/.test(dateString)
  ) {
    const [year, month, day] = dateString.split("T")[0].split("-");

    return new Date(Number(year), Number(month) - 1, Number(day));
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split("-");

    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  return new Date(dateString);
};

const dateFormat = (date, format) => {
  switch (format) {
    case "yyyy-mm-dd":
      date = date.toISOString().split("T")[0];
      break;
    default:
      return date;
  }

  return date;
};

export { stringToDate, dateFormat };

export const formatDate = (date) => {
  if (!date) return null;

  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return date.split("-").reverse().join("/");
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/.test(date)) {
    const [year, month, day] = date.split("T")[0].split("-");

    return `${day}/${month}/${year}`;
  }

  const value = new Date(date);

  var day = value.getDate();
  var month = value.getMonth() + 1;
  var year = value.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return day + "/" + month + "/" + year;
};

export const formatTime = (inputTime) => {
  var parts = inputTime?.split(":") ?? [];

  var hours = parseInt(parts[0] ?? 0);
  var minutes = parseInt(parts[1] ?? 0);
  var seconds = parseInt(parts[2] ?? 0);

  var formattedTime =
    (hours > 0 ? hours + "h" : "") +
    (minutes > 0 ? minutes + "m" : "") +
    seconds +
    "s";

  return formattedTime;
};

export const dateToStringApiFormat = (date) => {
  if (!date) return null;

  const dateJs = new Date(date);

  let month = dateJs.getMonth() + 1;
  let day = dateJs.getDate();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  return `${dateJs.getFullYear()}-${month}-${day}`;
};

export const getDaysLeft = (date) => {
  const differenceInTime = new Date(date).getTime() - new Date().getTime();

  return Math.ceil(differenceInTime / (1000 * 3600 * 24));
};

export const getDaysLeftText = (date) => {
  const daysLeft = getDaysLeft(date);

  if (daysLeft === 0) {
    return ["Vence:", " Hoje"];
  }

  if (daysLeft === -1) {
    return ["Vence: ", "Ontem"];
  }

  if (daysLeft < -1) {
    return [`Vencido: `, `${formatDate(date)}`];
  }

  return [`Vence em: `, `${daysLeft} dias`];
};

export function calculateAge(birthDate) {
  if (!birthDate) {
    return null;
  }

  const today = new Date();
  const birthDateObj = stringToDate(birthDate);

  if (isNaN(birthDateObj.getTime())) {
    return null;
  }

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDifference = today.getMonth() - birthDateObj.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
}
