import React from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updatePass, deleteUser } from "../../../actions/userActions";
import PasswordField from "../../../components/v2/Fields/PasswordField";
import { useFormInput } from "../../../hooks/useFormInput";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { useUserMutation } from "../../../mutations";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { UserImageProfileV3 } from "../UserImageProfileV3";
import { useQueryClient } from "@tanstack/react-query";
import { BankProfile } from "./BankProfile";
import { MyPlans } from "./Plans/MyPlans";

function UserProfileV2() {
  const userInfo = useUserInfo();
  const { userDetails } = userInfo;

  const dispatch = useDispatch();
  const [profileFileImage, setProfileFileImage] = useState();

  const queryClient = useQueryClient();

  const { updateProfile, uploadImage } = useUserMutation();

  const data = {
    name: useFormInput(userInfo?.name),
    phone: useFormInput(userInfo?.phone),
    password: useFormInput(""),
    newPassword: useFormInput(""),
    confirmPassword: useFormInput(""),
  };

  const { loadingUpdatePass, successUpdatePass } = useSelector(
    (state) => state.updatePass
  );

  const handleInputImageChange = (data) => {
    setProfileFileImage(data);
  };

  const userInfoSubmitHandler = async (e) => {
    e.preventDefault();
    const phone = data.phone?.value?.replace(/\D/g, "");
    const name = data.name.value;

    const userImageId = profileFileImage?.id ?? userDetails?.userImage?._id;

    const response = await updateProfile.mutateAsync({
      name: name,
      phone: phone,
      userImage_id: userImageId,
    });

    if (response.status === 200) {
      toast("Perfil atualizado com sucesso!", {
        position: "top-right",
        type: "success",
      });
    }

    await queryClient.refetchQueries({ queryKey: ["readUserDetails"] });
  };

  const passwordSubmitHandler = () => {
    dispatch(updatePass(data.password, data.newPassword));
  };

  const deletSubmitHandler = () => {
    dispatch(deleteUser(userInfo.id));
  };

  useEffect(() => {
    data.phone.onChange(userDetails?.phone);
    data.name.onChange(userDetails?.name);
  }, [userDetails]);

  return (
    <>
      <Row>
        <Form className="col row m-0 col-12" onSubmit={userInfoSubmitHandler}>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-between px-0">
            <Form.Group className="mb-3 col-12 p-0" controlId="formFullName">
              <Form.Label className="fw-bold">Nome completo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite seu nome completo"
                name="fullName"
                {...data.name}
                required
              />
            </Form.Group>

            <Form.Group className="pe-0 col-12" controlId="formContact">
              <Form.Label className="fw-bold">Contato</Form.Label>
              <Form.Control
                as={InputMask}
                type="text"
                placeholder="Digite seu número de contato"
                name="contact"
                {...data.phone}
                required
                mask="(99) 99999-9999"
                maskChar="_"
              />
            </Form.Group>
          </div>

          <div className="col-md-2 col-lg-3"></div>

          <div className="col-md-4 col-lg-3 mt-3 mt-md-0">
            <UserImageProfileV3
              userImage={profileFileImage?.url ?? userDetails?.userImage?.image}
              handleInputImageChange={handleInputImageChange}
            ></UserImageProfileV3>
          </div>

          <Button type="submit" className="p-2 mt-3 fw-bold col-6 col-lg-2">
            Salvar alterações
          </Button>
        </Form>
      </Row>
      <Row className="">
        <Col className="col-12">
          <p className="fs-3 fw-bold mt-4">Senha</p>
          <Form.Group
            className="mb-3 col-12 col-md-6 pe-md-3"
            controlId="formFullName"
          >
            <Form.Label className="fw-bold">Senha Atual</Form.Label>
            <PasswordField
              name={"password"}
              placeholder={"Digite a senha atual"}
              value={data.password}
            />
          </Form.Group>

          <Row>
            <Form.Group className="col-12 col-md-6 mb-3" controlId="formEmail">
              <Form.Label className="fw-bold">Nova Senha</Form.Label>
              <PasswordField
                name={"newpassword"}
                placeholder={"Digite a nova senha"}
                value={data.newPassword}
              />
            </Form.Group>

            <Form.Group
              className="col-12 col-md-6 mb-3"
              controlId="formContact"
            >
              <Form.Label className="fw-bold">Confirme a Senha</Form.Label>
              <PasswordField
                name={"newpasswordconfirm"}
                placeholder={"Confirme a nova senha"}
                value={data.confirmPassword}
              />
              {data.newPassword.value !== data.confirmPassword.value && (
                <span className="text-danger">As senhas não correspodem.</span>
              )}
              {/* {
                            (data.password.value === data.newPassword.value) &&
                            <span className="text-danger">A senha não pode ser igual a anterior</span>
                        } */}
              {successUpdatePass && (
                <span className="text-success">
                  Senha alterada com sucesso.
                </span>
              )}
            </Form.Group>
          </Row>

          <Button
            type="submit"
            className="p-2 fw-bold  col-6 col-lg-2"
            onClick={() => passwordSubmitHandler()}
          >
            Salvar alterações
          </Button>
        </Col>
      </Row>

      <BankProfile></BankProfile>

      <Row className="">
        <Col className="col-12">
          <p className="fs-3 fw-bold mt-4">Meus Planos</p>
        </Col>

        <div className="col-12 col-md-6">
          <MyPlans></MyPlans>
        </div>
      </Row>

      <Row>
        <Col className="col-12 col-lg-6 mb-5">
          <p className="fs-3 fw-bold mt-4">Conta</p>
          <p className="text-secondary">
            Se decidir excluir sua conta, perderá imediatamente os benefícios
            associados. A exclusão não é instantânea e ocorrerá 30 dias após a
            solicitação, dando-lhe tempo para reconsiderar. Durante esse
            período, é possível reativar a conta. Para mais informações ou
            assistência, entre em contato conosco.
          </p>
          <Button
            variant="danger"
            className="p-2 fw-bold  col-6 col-lg-4"
            onClick={() => deletSubmitHandler()}
          >
            Excluir Conta
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default UserProfileV2;
