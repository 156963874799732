import { INTENSITIES } from "../constants/post.consts";
import { formatTime } from "./date";

export function getItemsFromPost(postResume) {
  return [
    { title: "Tempos", value: formatTime(postResume?.totalTime) },
    {
      title: "Intensidade",
      value: INTENSITIES[postResume?.intensity - 1] ?? "-",
    },
    {
      value: ((postResume?.progress ?? 0) * 100).toFixed(0) + "%",
      title: "Progressão",
    },
    { title: "Exercícios", value: postResume?.currentExercise ?? 0 },
    { title: "Repetições", value: postResume?.totalRepetitions ?? 0 },
    { title: "Total Peso", value: (postResume?.totalWeight ?? 0) + "   kg" },
    {
      title: "Comentários do Aluno:",
      value: postResume?.commentsPatient ?? "",
    },
  ];
}
