import React, { useEffect } from "react";
import { usePaymentMutation } from "../../../mutations";
import pixPng from "../../../assets/v2/icons/pix.png";
import { Button, Image, Spinner } from "react-bootstrap";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";

export function PixPurchase({
  planId,
  price,
  description,
  customer,
  onError,
  goBack,
  isMonthly,
}) {
  const { createPixPayment } = usePaymentMutation();

  const { isMD } = useMediaQueryValues();

  const isLoading = createPixPayment.isPending;
  const sectionRef = useRef();

  const qrCodeImageUrl =
    createPixPayment.data?.data?.charges[0]?.last_transaction?.qr_code_url;

  const pixCode =
    createPixPayment.data?.data?.charges[0]?.last_transaction?.qr_code;

  //     (5, 'Pix - Pagamento mensal'),
  // (6, 'Pix - Pagamento anual')
  const createPixCode = async () => {
    try {
      await createPixPayment.mutateAsync({
        plan_id: planId,
        paymentsTypes_id: isMonthly ? 5 : 6,
        customer: customer,
        items: {
          price: price,
          description,
          quantity: 1,
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    createPixCode();

    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const renderPixInfo = (
    <div className="d-flex flex-column flex-sm-row justify-content-around col-12 my-4 align-self-center">
      <div className="small col-md-3  d-flex mb-2">
        <div
          className="border-bold rounded-circle flex-center me-2"
          style={{ height: 30, minWidth: 30 }}
        >
          1
        </div>
        <div style={{ textAlign: "justify" }}>
          Abra o app ou banco de sua prefeência. Escolha a opção pagar com
          código Pix "copia e cola", ou código QR.
        </div>
      </div>
      <div className="small col-md-3  d-flex mb-2">
        <div
          className="border-bold rounded-circle flex-center me-2 "
          style={{ height: 30, minWidth: 30 }}
        >
          2
        </div>
        <div style={{ textAlign: "justify" }}>
          Copie e cole o código, ou escaneie o QR Code com a câmera do seu
          celuar. Confira toas as informações e autorize o pagamento.
        </div>
      </div>
      <div className="small col-md-3  d-flex mb-2">
        <div
          className="border-bold rounded-circle flex-center me-2"
          style={{ height: 30, minWidth: 30 }}
        >
          3
        </div>
        <div style={{ textAlign: "justify" }}>
          Você vai receber a confirmação do pagamento no seu e-mail e através
          dos nossos canais. E pronto!
        </div>
      </div>
    </div>
  );

  return (
    <section
      ref={sectionRef}
      className="bg-body-secondary p-3 rounded d-flex flex-column"
    >
      <div className="flex-center justify-content-between">
        <div className="d-flex">
          <Image
            width={30}
            style={{ objectFit: "contain" }}
            src={pixPng}
          ></Image>
          <p className="fw-500 m-0 ms-3">Pagamento Via Pix</p>
        </div>

        <Button className="d-none d-md-flex" onClick={goBack}>
          Cancelar
        </Button>
      </div>

      {isMD && renderPixInfo}

      {isLoading && (
        <div className="flex-center flex-column">
          <p className="m-0">Gerando Código PIX</p>
          <Spinner
            className="ms-2 text-primary"
            animation="border"
            role="status"
            style={{
              height: 30,
              width: 30,
            }}
          ></Spinner>
        </div>
      )}

      {qrCodeImageUrl && (
        <Image className="align-self-center" src={qrCodeImageUrl}></Image>
      )}

      {pixCode && (
        <div className="flex-center flex-column mb-2">
          <Button
            className="my-3"
            onClick={async () => {
              await navigator.clipboard.writeText(pixCode);

              toast("PIX copiado com sucesso!", {
                type: "success",
                position: "top-center",
              });
            }}
          >
            Copiar código PIX
          </Button>

          <Button className="d-flex d-md-none" onClick={goBack}>
            Cancelar
          </Button>
        </div>
      )}

      {!isMD && renderPixInfo}

      <p className="m-0 text-center">
        Se o pagamento não for confirmado, não se preocupe. O pedido será
        cancelado automaticamente.
      </p>
    </section>
  );
}
