import React, { useState } from "react";
import {
  DropdownButton,
  Dropdown,
  Image,
  Modal,
  Button,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import { useUserInfo } from "../../hooks/useUserInfo";

import { usePatientMutation } from "../../mutations";
import { useReadUserDetailsQuery } from "../../queries";

import { LangSwitcher } from "../../screens/v2/LangSwitcher";

function UserDropdownV2({ path }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { isPatient } = useUserInfo();
  const { unlikeProfessional } = usePatientMutation();
  const { userDetails, readUserDetailsQuery } = useReadUserDetailsQuery({});
  const [showModal, setShowModal] = useState(false);

  const userImage = userDetails?.userImage?.image;

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  const getUserType = () => {
    return isPatient ? "Aluno" : "Profissional";
  };

  const handleUnlikeClick = async () => {
    await unlikeProfessional.mutateAsync({});
    readUserDetailsQuery.refetch();
    setShowModal(false);
  };

  return (
    <>
      {userInfo && (
        <DropdownButton
          id="dropdown-user"
          className="bg-white user-dropdown-container "
          style={{ pointerEvents: "all" }}
          title={
            <Image
              style={{ aspectRatio: "1/1", objectFit: "contain" }}
              src={
                userImage ??
                "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              }
              className="w-100 rounded"
            />
          }
        >
          <div className="d-flex flex-column px-3">
            <Dropdown.ItemText className="text-primary text-center text-capitalize fw-500 m-0 pb-0">
              {userInfo.name}
            </Dropdown.ItemText>
            <Dropdown.ItemText className="text-center text-secondary fw-500 p-0">
              {getUserType()}
            </Dropdown.ItemText>
            {/* <LinkContainer to="/home/personal/profile"> */}
            <LinkContainer to={`${path}/profile`} className="mt-1">
              <div
                as="button"
                className="text-center btn btn-outline-primary fw-500 align-self-center"
              >
                Editar
              </div>
            </LinkContainer>
            {/* 
          <Dropdown.Item
            as="button"
            className="text-primary border-0 border-top border-bottom text-center py-2 my-3 fw-500"
          >
            Updgrade do plano
          </Dropdown.Item>
          <Dropdown.Item as="button" className="text-secondary text-center fw-500 mb-3">
            Conta & preferências
          </Dropdown.Item> */}

            {isPatient && userDetails?.professional && (
              <>
                <Dropdown.Divider></Dropdown.Divider>

                <Dropdown.ItemText className="text-center text-secondary fw-500">
                  {userDetails?.professional?.name}
                </Dropdown.ItemText>
                <Dropdown.Item
                  as="button"
                  className="text-primary text-center fw-500"
                  onClick={() => setShowModal(true)}
                >
                  Desvincular
                </Dropdown.Item>
              </>
            )}

            <Dropdown.Divider></Dropdown.Divider>

            <div className="d-flex justify-content-center">
              <LangSwitcher></LangSwitcher>
            </div>

            <Dropdown.Divider></Dropdown.Divider>

            <LinkContainer onClick={logoutHandler} to="/login" className="">
              <Dropdown.Item
                as="button"
                className="text-primary text-center fw-500"
              >
                Encerrar Sessão
              </Dropdown.Item>
            </LinkContainer>
          </div>
        </DropdownButton>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered={true}
      >
        <Modal.Header>Tem certeza de desvincular profissional?</Modal.Header>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Cancelar</Button>
          <Button onClick={() => handleUnlikeClick()}>Sim</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserDropdownV2;
