import { React, useEffect, useState } from "react";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import { useFormInput } from "../../hooks/useFormInput";
import DateField from "./Fields/DateField";
import {
  dateFormat,
  dateToStringApiFormat,
  stringToDate,
} from "../../utils/date";
import { SearchPatients } from "./SearchPatients";
import { useScheduleMutation } from "../../mutations";
import { useQueryClient } from "@tanstack/react-query";
import { useReadPatientDetailsQuery } from "../../queries";
import { toast } from "react-toastify";

import trash from "../../assets/v2/icons/trash2.svg";

function RegisterEventV2({ onFinish = () => {}, schedule, date = new Date() }) {
  const [returnDataValue, setReturnDataValue] = useState(false);
  const { createEventMutation, updateEventMutation, deleteEventMutation } =
    useScheduleMutation();
  const queryClient = useQueryClient();

  const { patientDetails } = useReadPatientDetailsQuery({
    enabled: !!schedule?.pacient,
    params: { id: schedule?.pacient },
  });

  const { paciente } = patientDetails ?? {};

  const isEdit = !!schedule;

  const [currentPacient, setPacient] = useState(null);

  const handleResult = (p) => {
    setPacient(p);
  };

  const [startDate, setDate] = useState(
    schedule?.date ? stringToDate(schedule?.date) : date
  );

  const data = {
    nameEvent: useFormInput(schedule?.nameEvent ?? "", returnDataValue),
    note: useFormInput(schedule?.note ?? "", returnDataValue),
    date: startDate,
    hourInitial: useFormInput(schedule?.hourInitial ?? "", returnDataValue),
    hourFinal: useFormInput(schedule?.hourFinal ?? "", returnDataValue),
    isOnline: useFormInput(schedule?.isOnline ?? true, returnDataValue),
    patient_id: currentPacient?.id,
    isInPerson: useFormInput(schedule?.isInPerson ?? null, returnDataValue),
    procedure_id: useFormInput(schedule?.procedure ?? null, returnDataValue),
  };

  const handleCreateOrUpdateEvent = async () => {
    console.log({ d: data.date });

    data.date = dateToStringApiFormat(data.date);
    data.procedure_id = Number(data.procedure_id);

    const params = { ...data };

    if (params.hourInitial === "") delete params.hourInitial;
    if (params.hourFinal === "") delete params.hourFinal;

    if (isEdit) {
      await updateEventMutation.mutateAsync({
        id: schedule?._id,
        ...params,
        patient_id: currentPacient?.id,
        procedure_id: data?.procedure_id,
      });

      await queryClient.refetchQueries(["read-all-schedules"]);
      toast("Evento Atualizado!", { type: "success", position: "top-center" });
    } else {
      console.log({ params });
      await createEventMutation.mutateAsync(params);
      await queryClient.refetchQueries(["read-all-schedules"]);
    }

    setReturnDataValue(false);
    onFinish();
  };

  const handleDeleteEvent = async () => {
    await deleteEventMutation.mutateAsync({ id: schedule?._id });
    queryClient.refetchQueries(["read-all-schedules"]);

    onFinish();
  };

  useEffect(() => {
    if (returnDataValue) {
      handleCreateOrUpdateEvent();
    }
  }, [returnDataValue]);

  useEffect(() => {
    if (paciente) {
      setPacient(paciente);
    }
  }, [paciente]);

  const handlerSubmit = (e) => {
    e.preventDefault();
    setReturnDataValue(true);
  };

  return (
    <Form onSubmit={handlerSubmit}>
      <Row>
        <Col className="col-12">
          <Form.Group className="mb-3" controlId="formBasicEmailName">
            <Form.Label className="fw-500 required">
              Título do evento
            </Form.Label>
            <Form.Control
              type="text"
              required
              placeholder=""
              {...data.nameEvent}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicAddress">
            <Form.Label className="fw-500">Buscar Paciente/Aluno</Form.Label>
            <SearchPatients
              defaultSelectedName={currentPacient?.name}
              handleResult={handleResult}
            ></SearchPatients>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicGender">
            <Form.Label className="fw-500">Procedimento</Form.Label>
            <Form.Control required as="select" {...data.procedure_id}>
              <option value={""}>Selecionar</option>
              <option value={1}>Atendimento</option>
              <option value={2}>Aula</option>
              <option value={3}>Aula Experimental</option>
              <option value={4}>Avaliação</option>
              <option value={5}>Consulta</option>
              <option value={6}>Outro</option>
            </Form.Control>
          </Form.Group>
          <Row>
            <Col className="col-4">
              <Form.Group className="mb-3" controlId="formBasicGender">
                <Form.Label className="fw-500">Data</Form.Label>
                <DateField currentDate={startDate} setDate={setDate} />
              </Form.Group>
            </Col>
            <Col className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmailName">
                <Form.Label className="fw-500">Horário de início</Form.Label>
                <Form.Control
                  type="time"
                  placeholder=""
                  {...data.hourInitial}
                />
              </Form.Group>
            </Col>
            <Col className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmailName">
                <Form.Label className="fw-500">Horário de fim</Form.Label>
                <Form.Control type="time" placeholder="" {...data.hourFinal} />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Observações</Form.Label>
            <Form.Control as="textarea" rows={3} {...data.note} />
          </Form.Group>
        </Col>
        <Col className="col-12 d-flex justify-content-center align-items-center mt-3">
          {isEdit && (
            <Button
              className="fw-500 me-2"
              onClick={() => handleDeleteEvent()}
              variant="primary"
            >
              <Image src={trash}></Image>
            </Button>
          )}
          <Button className="fw-500" variant="primary" type="submit">
            {isEdit ? "Atualizar" : "Salvar"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default RegisterEventV2;
