import { React, useState } from "react";
import { Card, Row, Image, InputGroup, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { updateWorkout } from "../../../actions/workoutActions";
import { useDispatch } from "react-redux";

function CardFolderV2({
  workout,
  link,
  handleDefaultDrop = true,
  onDrop = () => {},
  onSelectedChange = () => {},

  canSelect = true,

  selected = false,
}) {
  const dispatch = useDispatch();

  const [droppedItems, setDroppedItems] = useState([]);
  const [isDraging, setIsDraging] = useState(false);

  const history = useHistory();

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraging(true);
  };

  const handleLeave = (e) => {
    e.preventDefault();
    setIsDraging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraging(false);

    if (!handleDefaultDrop) {
      onDrop(workout);
      return;
    }

    const exercise = JSON.parse(e.dataTransfer.getData("text/plain"));

    if (exercise.dragType === "exercise") {
      dispatch(updateWorkout({ ...workout, exercises: [exercise._id] }));
    }

    return;
  };

  const handleDragStart = (e) => {
    workout.dragType = "workout";
    e.dataTransfer.setData("text/plain", JSON.stringify(workout));
  };

  const onCardClick = () => {
    history.push(`/home/personal/programs/${workout?._id}`);
  };

  return (
    <div className="w-100 h-100 position-relative">
      <Card
        className={`pacient-card border-0 w-100 mb-2 rounded-4 pointer h-100 ${
          isDraging ? "bg-info" : ""
        }`}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={handleLeave}
        draggable={true}
        onClick={onCardClick}
      >
        <Card.Body className="d-flex align-items-center px-4">
          <Image
            variant="top"
            src="assets/v2/icons/folder-primary.svg"
            className="me-2"
            draggable={false}
          />
          <div className="d-flex flex-column ms-3">
            <h3 className="text-dark text-decoration-none fs-6 mb-0">Treino</h3>
            <p className="text-secondary mb-0">{workout?.name}</p>
            <p className="text-secondary mb-0">
              {workout?.createdAt.substr(0, 10).split("-").reverse().join("/")}
            </p>
          </div>
        </Card.Body>
      </Card>

      {canSelect && (
        <div className="position-absolute end-0 bottom-0 me-2 mb-2 mb-md-3 me-md-3 d-flex justify-content-end align-self-end">
          <input
            type="checkbox"
            className=""
            style={{ height: 25, width: 25 }}
            onChange={(e) => onSelectedChange(e.target.value)}
            value={selected}
          ></input>
        </div>
      )}
    </div>
  );
}

export default CardFolderV2;
