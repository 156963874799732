import React from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { usePaymentMutation } from "../../../mutations";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

export function BankProfile() {
  const userInfo = useUserInfo();

  const { createPaymentRecipient } = usePaymentMutation();

  const recipient = userInfo?.userDetails?.recipient;

  const formatBankNumber = (value = "") => {
    let data = String(value).replace(/\D/g, "");
    if (data.length > 1) {
      const lastChar = data.slice(-1);
      const restOfString = data.slice(0, -1);

      data = `${restOfString}-${lastChar}`;
    }

    return data;
  };

  const getFromRecipient = () => {
    return {
      bank: recipient?.bank,
      branch_number: formatBankNumber(
        recipient?.branch_number.toString() +
          recipient?.branch_check_digit?.toString()
      ),
      account_number: formatBankNumber(
        recipient?.account_number?.toString() +
          recipient?.account_check_digit?.toString()
      ),
      type: recipient?.type,
      cpf: recipient?.document,
    };
  };

  const [bankFormData, setBankFormData] = useState(getFromRecipient());

  const queryClient = useQueryClient();

  const updateBankFormData = (e) => {
    const name = e.target.name;

    let value = e.target.value;

    if (name === "branch_number" || name === "account_number") {
      value = formatBankNumber(value);
    }

    setBankFormData({ ...bankFormData, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const default_bank_account = { ...bankFormData };

    const customer = {
      name: userInfo?.name,
      email: userInfo?.email,
      description: "Recebedor " + userInfo.name,
      cpf: bankFormData.cpf.replace(/\D/g, ""),
    };

    const branch_number = bankFormData.branch_number
      .toString()
      .replace(/\D/g, "");
    const account_number = bankFormData.account_number
      .toString()
      .replace(/\D/g, "");

    const branch_check_digit = branch_number[branch_number.length - 1];
    const account_check_digit = account_number[account_number.length - 1];

    default_bank_account.account_number = account_number.slice(0, -1);
    default_bank_account.branch_number = branch_number.slice(0, -1);
    default_bank_account.branch_check_digit = branch_check_digit;
    default_bank_account.account_check_digit = account_check_digit;

    delete default_bank_account.cpf;

    const data = {
      customer,
      default_bank_account,
      type: "individual",
    };

    try {
      await createPaymentRecipient.mutateAsync(data);

      await queryClient.refetchQueries({ queryKey: ["readUserDetails"] });

      toast("Dados salvos com sucesso!", {
        type: "success",
        position: "top-center",
      });
    } catch (error) {
      toast(
        "Falha ao processar requisição! Verifique se os dados estão corretos.",
        {
          type: "error",
          position: "top-center",
        }
      );
    }
  };

  useEffect(() => {
    if (recipient) {
      setBankFormData(getFromRecipient());
    }
  }, [recipient]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="">
        <Col className="row m-0 p-0 col-12">
          <p className="fs-3 fw-bold mt-4">Dados Bancários</p>
          <Form.Group className="mb-3 col-12 col-md-6 pe-md-3">
            <Form.Label className="fw-bold">CPF</Form.Label>
            <Form.Control
              as={InputMask}
              type="text"
              placeholder="000.000.000-00"
              name="cpf"
              mask={"999.999.999-99"}
              required={true}
              value={bankFormData.cpf}
              onChange={updateBankFormData}
            />
          </Form.Group>
          <Row></Row>
          <Form.Group className="mb-3 col-12 col-md-3 pe-md-3">
            <Form.Label className="fw-bold">Código do Banco</Form.Label>
            <Form.Control
              as={InputMask}
              type="text"
              name={"bank"}
              mask={"999"}
              placeholder={"Código do Banco"}
              value={bankFormData.bank}
              onChange={updateBankFormData}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3 col-12 col-md-3 pe-md-3">
            <Form.Label className="fw-bold">Agência</Form.Label>
            <Form.Control
              type="text"
              name={"branch_number"}
              placeholder={"Número com Dígito"}
              value={bankFormData.branch_number}
              onChange={updateBankFormData}
              required
            />
          </Form.Group>
          <Row></Row>

          <Form.Group className="mb-3 col-12 col-md-3 pe-md-3">
            <Form.Label className="fw-bold">Número da Conta</Form.Label>
            <Form.Control
              type="text"
              placeholder={"Número com Dígito"}
              value={bankFormData.account_number}
              name={"account_number"}
              onChange={updateBankFormData}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3 col-12 col-md-3 pe-md-3">
            <Form.Label className="fw-bold">Tipo de Conta</Form.Label>
            <Form.Control
              as={"select"}
              name={"type"}
              placeholder={"Número com Dígito"}
              required
              onChange={updateBankFormData}
              value={bankFormData.type}
            >
              <option value={""}></option>
              <option value={"checking"}>Corrente</option>
              <option value={"savings"}>Poupança</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Button type="submit" className="p-2 ms-2 fw-bold  col-6 col-lg-2">
          Salvar alterações
        </Button>
      </Row>
    </Form>
  );
}
