import React, { useMemo } from "react";
import { useReadGraphPatientWorkoutsQuery } from "../queries";
import { Chart } from "./v2/Chart";

export function PatientWorkoutGraph({
  patientId,
  valueKey = "RepetitionsDay",
  tooltipLabel = {
    x: "Dia",
    y: "Repetição",
  },
  title = "Repetições por Mês",
  workoutId,
  currentMonth,
  isFilterLoading,
}) {
  const { graphPatientWorkout, readGraphPatientWorkoutsQuery } =
    useReadGraphPatientWorkoutsQuery({
      enabled: !!patientId && !!workoutId,
      params: { patientId, workoutId: workoutId, month: currentMonth },
    });

  const isLoading = isFilterLoading || readGraphPatientWorkoutsQuery.isLoading;

  const graphData = graphPatientWorkout[valueKey] ?? [];

  const data = useMemo(() => {
    return graphData.map((item, index) => ({
      name: Number(item[0]),
      uv: index,
      pv: item[1],
      amt: 0,
    }));
  }, [graphData]);

  return (
    <Chart
      isLoading={isLoading}
      title={title}
      tooltipLabel={tooltipLabel}
      data={data}
    ></Chart>
  );
}
