import { subdivideArray } from "../../utils/page.utils";
import { PrintBody } from "./PrintBody";
import React, { useEffect, useState } from "react";
import { WithPageSection } from "./WithPageSection";
import { fetchImageWithHeaders } from "../../utils/image.utils";
import { Image } from "react-bootstrap";
export function getEvaluationPrint({ name, questionsAnswers, isForm = false }) {
  // separate in three parts, explanation in getWorkoutSheetPrint
  const [firstContent, ...restGroup] = questionsAnswers;

  const QuestionImage = ({ url }) => {
    const [imageUrl, setImageUrl] = useState();

    useEffect(() => {
      fetchImageWithHeaders(url).then((value) => setImageUrl(value));
    }, [url]);

    return (
      <Image
        style={{ minHeight: 250, maxWidth: 600, maxHeight: 400 }}
        src={imageUrl}
        className="me-2 rounded-2"
      ></Image>
    );
  };

  const AnswerBody = ({ questionAnswer }) => {
    if (questionAnswer?.question?.type === "0") {
      return (
        <p className="extra-small m-0">{questionAnswer?.answer?.answerText}</p>
      );
    }

    if (questionAnswer?.question?.type === "1") {
      const id = questionAnswer?.answer?.answerChoice;
      const choice = questionAnswer.choices.find((c) => c.id === id);
      return <p className="extra-small m-0">{choice?.choice_name}</p>;
    }

    if (questionAnswer?.question?.type === "2") {
      return <QuestionImage url={questionAnswer?.answer?.answerImage?.image} />;
    }

    return "-";
  };

  return [
    WithPageSection(
      <PrintBody title={(isForm ? "Formulário - " : "Avaliação - ") + name}>
        <div className="col-12 mb-4">
          <p className="text-primary fw-500 mb-0 small">
            {firstContent?.question?.question_name}
          </p>

          <AnswerBody questionAnswer={firstContent}></AnswerBody>
        </div>
      </PrintBody>
    ),

    ...(restGroup ?? []).map((item, index) =>
      WithPageSection(
        <PrintBody key={index} className="py-3">
          <div className="col-12 mb-4">
            <p className="text-primary fw-500 mb-0 small">
              {item?.question?.question_name}
            </p>
            <AnswerBody questionAnswer={item}></AnswerBody>
          </div>
        </PrintBody>
      )
    ),
  ];
}
