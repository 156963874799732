import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { ModalHeader } from "../../../components/ModalHeader";
import { daysMap } from "../../../constants/days.consts";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { respStyle } from "../../../utils/respStyle";
import { useRoutineMutation } from "../../../mutations";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { SpinnerButton } from "../../../components/v2/SpinnerButton";
import { getSheetPatientDays } from "../../../queries/routine.query";
import { useApiConfig } from "../../../hooks/useApiConfig";
export function RegisterRoutineSheetItem({
  onClose = () => {},
  isPatient,
  sheetItem,
  onEdit = () => {},
}) {
  const isEditMode = !!sheetItem;
  const mediaValues = useMediaQueryValues();

  const config = useApiConfig();

  const queryClient = useQueryClient();
  const { updateRoutineWorkout, updateRoutinePatientWorkoutMutation } =
    useRoutineMutation();

  const [days, setDays] = useState(
    sheetItem.sheetPatient?.days ?? sheetItem?.days
  );

  const [messages, setMessages] = useState([]);

  const submitHandler = async (e) => {
    e.preventDefault();

    let hasMessages = false;

    if (isEditMode) {
      await updateRoutineWorkout.mutateAsync({ id: sheetItem?._id, days });

      if (isPatient) {
        const [sheetPatientDays, data] = await getSheetPatientDays(
          sheetItem.routinePatient?.toString() ?? "-1",
          config
        );

        const newDays = sheetPatientDays.map((sP) => ({
          ...sP,
          days:
            sP.routineWorkoutId.toString() === sheetItem._id.toString()
              ? days
              : sP.days,
        }));

        const response2 = await updateRoutinePatientWorkoutMutation.mutateAsync(
          {
            sheetPatientDays: newDays,
            patientId: data.Rotina.patient?.id.toString() ?? "-1",
            finalDate: data.Rotina.finalDate,
            initialDate: data.Rotina.initialDate,
          }
        );

        const msgs =
          response2.data?.messages
            ?.filter((m) => Array.isArray(m))
            ?.map((m) => m.length > 0 && m[0]) ?? [];

        hasMessages = msgs.length > 0;

        setMessages(msgs);
      }

      if (!isPatient) {
        await queryClient.refetchQueries({
          queryKey: [
            "read-routine-details-query",
            true,
            { id: String(sheetItem?.routine?._id) },
          ],
        });
      }

      if (!hasMessages) onEdit();

      if (!hasMessages) onClose();
    }
  };

  const onCheckboxChange = (value) => {
    if (days.includes(value)) {
      setDays(days.filter((day) => day !== value));
    } else {
      setDays([...days, value]);
    }
  };

  return (
    <Modal show={!!sheetItem} onHide={onClose} size="lg">
      <ModalHeader title={"Editar Item Ficha"} onClose={onClose}></ModalHeader>
      <Modal.Body>
        {messages.map((m, i) => (
          <div key={i} class="alert alert-primary" role="alert">
            {m}
          </div>
        ))}

        <Form.Group className="mb-3" controlId="formBasicDay">
          <Form.Label className="fw-500">Dias do Treino</Form.Label>
          <Form.Control
            required
            as="fieldset"
            onChange={(e) => onCheckboxChange(Number(e.target.value))}
            className="h-100"
          >
            <div className="d-flex flex-row flex-wrap mt-2">
              {Object.keys(daysMap).map((k) => {
                return (
                  <div
                    key={k}
                    className={
                      "w-50 mb-2" + respStyle({ lg: "w-auto" }, mediaValues)
                    }
                  >
                    <input
                      id={k}
                      type="checkbox"
                      value={Number(k)}
                      checked={days.includes(Number(k))}
                      className="ms-3 me-1"
                      name="days"
                      readOnly
                    />
                    {daysMap[k]}
                  </div>
                );
              })}
            </div>
          </Form.Control>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={(e) => submitHandler(e)}
          variant="primary"
          type="submit"
          disabled={updateRoutineWorkout.isPending}
        >
          {updateRoutineWorkout.isPending ? (
            <SpinnerButton></SpinnerButton>
          ) : (
            "Salvar"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
