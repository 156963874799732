import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { forgetPass } from "../../actions/userActions";
import logo from "../../assets/v2/icons/logo.svg";
import forget from "../../assets/v2/user-profile-update-component.css";
import { Link } from "react-router-dom";
import { t } from "i18next";

function ForgetPassScreenV2({ location, history }) {
  const [email, setEmail] = useState("");
  const [successModalShow, setSuccessModalShow] = useState(false);

  const dispatch = useDispatch();

  const { errorForgetPass, loadingForgetPass, forgetPassData } = useSelector(
    (state) => state.forgetPass
  );

  const closeSuccessModal = () => {
    setSuccessModalShow(false);
    history.replace("/");
  };

  useEffect(() => {
    console.log("forgetPassData", forgetPassData);
    if (forgetPassData) {
      setSuccessModalShow(true);
    }
  }, [forgetPassData]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(forgetPass(email));
  };

  return (
    <div className="overflow-hidden">
      <style src={forget}></style>
      <div className="page-alert">
        {errorForgetPass && <Alert variant="danger">{errorForgetPass}</Alert>}
      </div>
      <div className="main-background">
        <form
          className="page-card h-100 d-flex flex-column justify-content-center pt-0 forget-page-card"
          onSubmit={submitHandler}
        >
          <div className="page-logo">
            <Link to={"/"}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="page-title">{t("passwordReset.title")}</div>
          <div className="page-subtitle">{t("passwordReset.description")}</div>
          <div className="page-user-input mb">
            <label>E-mail</label>
            <input
              type="email"
              placeholder={t("field.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="page-footer">
            <button className="page-btn-login" type="submit">
              {t("button.submit")}
            </button>
          </div>
        </form>
      </div>

      <Modal
        show={successModalShow}
        onHide={() => closeSuccessModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h2>{t("forgetPassword.text1")}</h2>
          {t("forgetPassword.text2")} <b>{email}</b> {t("forgetPassword.text3")}
          <br />
          <b>{t("forgetPassword.text4")}</b> {t("forgetPassword.text5")}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ForgetPassScreenV2;
