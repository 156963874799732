import React from "react";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";

// import { Container } from './styles';

function Left({ isOpen }) {
  const mediaValues = useMediaQueryValues();
  const { isMD, isLG } = mediaValues;

  return (
    <div
      className={"d-none d-md-flex " + (isOpen ? "col-md-2" : "col-md-1")}
    ></div>
  );
}

function Top() {
  const mediaValues = useMediaQueryValues();
  const { isMD, isLG } = mediaValues;
  return <div className="d-md-flex" style={{ height: "70px" }}></div>;
}

export const NavBarSpacer = { Top, Left };
