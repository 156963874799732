import React, { useState } from "react";
import { useReadAllPatientWorkoutsQuery } from "../queries";
import { Form } from "react-bootstrap";
import { monthsMap } from "../constants/days.consts";

export function useGraphFilters({ patientId }) {
  const { workouts: remoteWorkouts, readAllPatientWorkoutsQuery } =
    useReadAllPatientWorkoutsQuery({
      enabled: !!patientId,
      params: { patientId },
    });

  // remove duplicated workouts
  const workouts = remoteWorkouts.reduce((acc, current) => {
    if (!acc.find((i) => i._id === current._id)) {
      acc.push(current);
    }

    return acc;
  }, []);

  const [workoutId, setWorkoutId] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const currentWorkoutId = workoutId ?? workouts[0]?._id;

  const isLoading = readAllPatientWorkoutsQuery.isLoading;

  const workoutFilter = currentWorkoutId && (
    <Form.Group
      className="d-flex align-items-center me-2"
      style={{ width: 170 }}
    >
      <Form.Label className="mb-0 me-2 text-primary fw-500">Treino:</Form.Label>
      <Form.Control
        as={"select"}
        value={currentWorkoutId}
        disabled={isLoading || !currentWorkoutId}
        onChange={(e) => setWorkoutId(e.target.value)}
      >
        {workouts.map((item) => (
          <option
            key={item?._id}
            value={String(item?._id)}
            label={item?.name}
          ></option>
        ))}
      </Form.Control>
    </Form.Group>
  );

  const monthFilter = (
    <Form.Group
      className="d-flex align-items-center mt-2 mt-md-0"
      style={{ width: 170 }}
    >
      <Form.Label className="mb-0 me-2 text-primary fw-500 me-4">
        Mês:
      </Form.Label>
      <Form.Control
        as={"select"}
        value={currentMonth}
        disabled={isLoading}
        onChange={(e) => setCurrentMonth(e.target.value)}
      >
        {Object.keys(monthsMap).map((key) => (
          <option key={key} value={key} label={monthsMap[key]}></option>
        ))}
      </Form.Control>
    </Form.Group>
  );
  return {
    isFilterLoading: isLoading,
    monthFilter,
    workoutFilter,
    currentWorkoutId,
    currentMonth,
  };
}
