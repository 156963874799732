import React from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import check from "../../../assets/v2/icons/check.svg";
import checkWhite from "../../../assets/v2/icons/check-white.svg";
import PurchaseV2 from "./PurchaseV2";
import { listBills } from "../../../actions/paymentActions";
import { useMemo } from "react";
import PaginationV2 from "../../../components/v2/PaginationV2";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { PLANS_IDS, PAID_PLANS_IDS } from "../../../constants/plan.consts";
import { useReadBillsQuery, useReadUserDetailsQuery } from "../../../queries";
import { updateUserInfo } from "../../../actions/userActions";
import { usePaymentMutation } from "../../../mutations";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { Badge } from "antd";
import { BillsHistory } from "../../../components/BillsHistory";

function PlansV2({ path, onPayFinish = () => {} }) {
  const userLogin = useSelector((state) => state.userLogin);

  const { bills, pages, readBillsQuery } = useReadBillsQuery({
    params: { page: 1 },
  });

  const [lastBill, setLastBill] = useState();

  const queryClient = useQueryClient();
  const { isMD, isLG } = useMediaQueryValues();
  const { cancelPlanMutation } = usePaymentMutation();

  const { userInfo } = userLogin;
  const { userDetails, readUserDetailsQuery } = useReadUserDetailsQuery({});

  const userPlanId = userDetails?.plan?._id;

  const [showPlans, setShowPlans] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(0);

  const isLoading =
    cancelPlanMutation.isPending ||
    readUserDetailsQuery.isLoading ||
    readBillsQuery.isLoading;

  const isCanceled = !!(
    lastBill &&
    lastBill.plan?._id === userPlanId &&
    !lastBill.status
  );

  const managePlans = (key) => {
    setShowPlans(!showPlans);
    setSelectedPlan(key);
    readUserDetailsQuery.refetch();
  };

  const BASIC_IDS = [PLANS_IDS.BASIC_MONTH, PLANS_IDS.BASIC_YEAR];

  const refetchData = async () => {
    await queryClient.refetchQueries({
      queryKey: ["read-bills-query"],
    });
    await queryClient.refetchQueries({
      queryKey: ["readUserDetails"],
    });
  };

  const onPay = async () => {
    await refetchData();
    onPayFinish();
  };

  const handleCancelPlanClick = async () => {
    try {
      await cancelPlanMutation.mutateAsync();
      await refetchData();
      toast("Plano cancelado com sucesso!", {
        position: "top-center",
        type: "success",
      });
    } catch (error) {
      toast("Falha ao cancelar plano!", {
        position: "top-center",
        type: "error",
      });
    }
  };

  let plans = [
    {
      ids: BASIC_IDS,
      title: "Plano Básico",
      value: 25,
      valueYear: 225,
      features: [
        "Grátis por 7 dias, sem cadastrar cartão de crédito",
        "Quantidade de alunos/pacientes ilimitados",
        "Exercícios e Treinos personalizados para seu aluno/paciente",
        "Avaliações e Prontuários",
        "Site disponível para aluno/paciente",
        "Correção de movimento por Inteligência Artificial (bônus)",
        "Videos executados por aluno/paciente, assim como os feedbacks de execução, disponíveis para o profissional, (bônus)",
        "Gestão de agenda",
      ],
    },
    {
      ids: [PLANS_IDS.PREMIUM_MONTH, PLANS_IDS.PREMIUM_YEAR],
      title: "Plano Premium",
      value: 45,
      valueYear: 495,
      features: [
        "Grátis por 7 dias, sem cadastrar cartão de crédito",
        "Quantidade de alunos/pacientes ilimitados",
        "Exercícios e Treinos personalizados para seu aluno/paciente",
        "Avaliações e Prontuários",
        "Site disponível para aluno/paciente",
        "Correção de movimento por Inteligência Artificial (bônus)",
        "Videos executados por aluno/paciente, assim como os feedbacks de execução, disponíveis para o profissional, (bônus)",
        "Gestão de agenda",
        "Registro Financeiro",
        "Chat com o aluno",
        "Dashboard de evoluções do aluno/paciente",
        "Formulários de satisfação para seus alunos/pacientes",
      ],
    },
  ];

  plans = plans.map((plan) => {
    plan.features = plan.features.map((feature) => {
      return (
        <li
          className={`${
            userInfo?.plan != plan.title ? "text-white" : ""
          } mb-3 align-items-start col-12`}
        >
          <img src={userInfo?.plan == plan.title ? check : checkWhite} alt="" />
          &nbsp;{feature}
        </li>
      );
    });

    return plan;
  });

  useEffect(() => {
    if (bills && bills.length > 0) {
      setLastBill(bills[0]);
    }
  }, [bills]);

  return (
    <div className="col-12 w-100">
      <div className={`${showPlans ? "w-100" : "d-none"}`}>
        <div className="row mb-0 pb-0 m-0 p-0">
          {plans.map((plan, key) => {
            const isUserPlan = plan.ids.includes(userPlanId);

            const canUpgrade = isUserPlan && !isCanceled && lastBill;

            return (
              <div
                key={key}
                className="col-12 col-md-6 mb-2 p-0 m-0 px-1 col-xl-6"
              >
                <div className="card benefits-card border-primary overflow-hidden border-2 w-100 p-0 h-100">
                  <div className="card-header bg-primary">
                    <h4 className="text-center text-white">
                      {plan.title} <br /> R$ {plan.value},00
                    </h4>
                  </div>
                  <div
                    className={`${
                      userInfo?.plan != plan.title
                        ? "bg-primary"
                        : "bg-body-secondary"
                    } card-body text-primary d-flex flex-column justify-content-between`}
                  >
                    <ul className="list-unstyled">{plan.features}</ul>
                    {PAID_PLANS_IDS.includes(plan.ids[0]) && (
                      // {true && (
                      <div className="w-100">
                        {canUpgrade && (
                          <Button
                            className={`py-3 w-50 fw-bold bg-white text-primary`}
                            onClick={handleCancelPlanClick}
                          >
                            Cancelar
                          </Button>
                        )}
                        <Button
                          className={`py-3 fw-bold ${
                            userInfo?.plan == plan.title
                              ? ""
                              : "bg-secondary bg-white text-primary disable-opacity-1"
                          } ${canUpgrade ? "w-50" : "w-100"}`}
                          onClick={() => managePlans(key)}
                          disabled={canUpgrade || isLoading}
                          // disabled={false}
                        >
                          {canUpgrade ? "Plano Atual" : "Fazer Upgrade"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <BillsHistory></BillsHistory>
      </div>
      <div className={`${showPlans ? "d-none" : "d-block"}`}>
        <PurchaseV2
          onPayFinish={onPay}
          plan={plans[selectedPlan]}
          managePlans={managePlans}
        />
      </div>
    </div>
  );
}

export default PlansV2;
