export const FEEDBACK_LIST_REQUEST = 'FEEDBACK_LIST_REQUEST'
export const FEEDBACK_LIST_SUCCESS = 'FEEDBACK_LIST_SUCCESS'
export const FEEDBACK_LIST_FAIL = 'FEEDBACK_LIST_FAIL'

export const MUSCLE_LIST_REQUEST = 'MUSCLE_LIST_REQUEST'
export const MUSCLE_LIST_SUCCESS = 'MUSCLE_LIST_SUCCESS'
export const MUSCLE_LIST_FAIL = 'MUSCLE_LIST_FAIL'

export const FEEDBACK_DETAILS_REQUEST = 'FEEDBACK_DETAILS_REQUEST'
export const FEEDBACK_DETAILS_SUCCESS = 'FEEDBACK_DETAILS_SUCCESS'
export const FEEDBACK_DETAILS_FAIL = 'FEEDBACK_DETAILS_FAIL'

export const FEEDBACK_DELETE_REQUEST = 'FEEDBACK_DELETE_REQUEST'
export const FEEDBACK_DELETE_SUCCESS = 'FEEDBACK_DELETE_SUCCESS'
export const FEEDBACK_DELETE_FAIL = 'FEEDBACK_DELETE_FAIL'
export const FEEDBACK_DELETE_RESET = 'FEEDBACK_DELETE_RESET'

export const FEEDBACK_CREATE_REQUEST = 'FEEDBACK_CREATE_REQUEST'
export const FEEDBACK_CREATE_SUCCESS = 'FEEDBACK_CREATE_SUCCESS'
export const FEEDBACK_CREATE_FAIL = 'FEEDBACK_CREATE_FAIL'
export const FEEDBACK_CREATE_RESET = 'FEEDBACK_CREATE_RESET'

export const FEEDBACK_UPDATE_REQUEST = 'FEEDBACK_UPDATE_REQUEST'
export const FEEDBACK_UPDATE_SUCCESS = 'FEEDBACK_UPDATE_SUCCESS'
export const FEEDBACK_UPDATE_FAIL = 'FEEDBACK_UPDATE_FAIL'
export const FEEDBACK_UPDATE_RESET = 'FEEDBACK_UPDATE_RESET'

export const FEEDBACK_CREATE_REVIEW_REQUEST = 'FEEDBACK_CREATE_REVIEW_REQUEST'
export const FEEDBACK_CREATE_REVIEW_SUCCESS = 'FEEDBACK_CREATE_REVIEW_SUCCESS'
export const FEEDBACK_CREATE_REVIEW_FAIL = 'FEEDBACK_CREATE_REVIEW_FAIL'
export const FEEDBACK_CREATE_REVIEW_RESET = 'FEEDBACK_CREATE_REVIEW_RESET'

export const FEEDBACK_TOP_REQUEST = 'FEEDBACK_TOP_REQUEST'
export const FEEDBACK_TOP_SUCCESS = 'FEEDBACK_TOP_SUCCESS'
export const FEEDBACK_TOP_FAIL = 'FEEDBACK_TOP_FAIL'

export const FEEDBACK_SAVE_DATA = 'FEEDBACK_SAVE_DATA'

export const FEEDBACK_SAVE_INDICATIONS = 'FEEDBACK_SAVE_INDICATIONS'

export const FEEDBACK_SAVE_RESULTS = 'FEEDBACK_SAVE_RESULTS'

export const FEEDBACK_IMAGE_CREATE_REQUEST = 'FEEDBACK_IMAGE_CREATE_REQUEST'
export const FEEDBACK_IMAGE_CREATE_SUCCESS = 'FEEDBACK_IMAGE_CREATE_SUCCESS'
export const FEEDBACK_IMAGE_CREATE_FAIL = 'FEEDBACK_IMAGE_CREATE_FAIL'

export const FEEDBACK_VIDEO_CREATE_REQUEST = 'FEEDBACK_VIDEO_CREATE_REQUEST'
export const FEEDBACK_VIDEO_CREATE_SUCCESS = 'FEEDBACK_VIDEO_CREATE_SUCCESS'
export const FEEDBACK_VIDEO_CREATE_FAIL = 'FEEDBACK_VIDEO_CREATE_FAIL'

export const FEEDBACK_PATTER_LIST_REQUEST = 'FEEDBACK_PATTER_LIST_REQUEST'
export const FEEDBACK_PATTER_SUCCESS = 'FEEDBACK_PATTER_SUCCESS'
export const FEEDBACK_PATTER_FAIL = 'FEEDBACK_PATTER_FAIL'

export const FEEDBACK_RESET = 'FEEDBACK_RESET'




