import { useMutation } from "@tanstack/react-query";
import api from "../services/api";
import { useApiConfig } from "../hooks/useApiConfig";

export function useExerciseMutation() {
  const configFormData = useApiConfig("multipart/form-data");
  const config = useApiConfig();

  const uploadExerciseVideo = useMutation({
    mutationFn: async ({ file }) => {
      const localUrl = window?.URL?.createObjectURL(file);

      const formData = new FormData();
      formData.append("video", file);
      formData.append("localURL", localUrl);
      formData.append("caption", null);

      const response = await api.post(
        `/api/exercises/uploadvideo/`,
        formData,
        configFormData
      );

      return response;
    },
  });

  const createExerciseMutation = useMutation({
    mutationFn: async (params) => {
      return api.post("/api/exercises/create/", params, config);
    },
  });

  const editExerciseMutation = useMutation({
    mutationFn: async (params) => {
      return api.put(`/api/exercises/edit/${params._id}/`, params, config);
    },
  });

  const uploadImage = useMutation({
    mutationFn: async (params) => {
      const { file, name } = params;

      const formData = new FormData();

      formData.append("image", file);
      formData.append("caption", name);

      return api.post("/api/exercises/upload/", formData, configFormData);
    },
  });

  return {
    uploadExerciseVideo,
    createExerciseMutation,
    editExerciseMutation,
    uploadImage,
  };
}
