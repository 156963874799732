import { React, useEffect, useState, useMemo } from "react";
import { Row, Col, Tabs, Tab, Button, Image } from "react-bootstrap";
import PacinentCardV2 from "./PacientCardV2";
import RegisterCostumerV2 from "./RegisterCostumerV2";
import { useDispatch, useSelector } from "react-redux";
import { listPacients } from "../../actions/pacientActions";
import PaginationV2 from "./PaginationV2";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import messageTranslation from "../../utils/messageTranslation";

import plusSvg from "../../assets/v2/icons/plus.svg";

import weightSvg from "../../assets/v2/icons/Weightlifting.svg";

function NavTabsV2({
  handleModal,
  tabs,
  defaultKey,
  actionButton,
  actionButtonMobileIcon,
  modal,
  classNames = "",
  secondActionButton,
  SecondActionContent,
  CustomActionButtonModal,
  onChangeTab = () => {},
}) {
  let { path, url } = useRouteMatch();

  const dispatch = useDispatch();

  const history = useHistory();
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const pacientList = useSelector((state) => state.pacientList);
  const { loading, pacients, page, pages } = pacientList;

  const [currentPage, setCurrentPage] = useState(1);
  const [activeKey, setActiveKey] = useState(defaultKey);
  const [showCustomModal, setShowCustomModal] = useState(false);

  const [showSecondContent, setShowSecondContent] = useState(false);

  const changeActiveTab = (k) => {
    const tab = tabs.find((t) => t.name === k);

    if (tab && tab.params) {
      if (!searchParams.get(tab.params)) {
        history.push("?" + tab.params);
      }
    } else {
      history.replace(pathname);
    }

    setActiveKey(k);
    onChangeTab(tabs.findIndex((t) => t.name === k));
  };

  useEffect(() => {
    dispatch(listPacients(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    setActiveKey(defaultKey);
    onChangeTab(tabs.findIndex((t) => t.name === defaultKey));
  }, [defaultKey]);

  return (
    <>
      <div className="nav-tabs-dashboard position-relative pb-2">
        <Col className="col-12 pe-0">
          <Tabs
            defaultActiveKey={defaultKey}
            activeKey={activeKey}
            transition={false}
            id="noanim-tab-example"
            className={`mb-3 ${classNames}`}
            onSelect={(k) => changeActiveTab(k)}
          >
            {tabs.map((tab, i) => (
              <Tab
                key={i}
                eventKey={tab.name}
                title={messageTranslation(tab.name)}
              >
                {tab.body}
              </Tab>
            ))}
          </Tabs>
        </Col>

        {showSecondContent && (
          <SecondActionContent
            onFinish={() => setShowSecondContent(false)}
          ></SecondActionContent>
        )}

        <div className="d-flex position-absolute top-0 end-0 me-2 w-auto">
          {secondActionButton && (
            <Button
              variant="primary"
              className="fw-bold me-2"
              style={{ transform: `translate(${0}px, ${-2}px)` }}
              onClick={() => setShowSecondContent(true)}
            >
              <div className="d-none d-sm-flex">{secondActionButton}</div>

              <Image className="d-sm-none" src={weightSvg}></Image>
              <Image className="d-sm-none" src={plusSvg}></Image>
            </Button>
          )}

          {actionButton && (
            <Button
              variant="primary"
              className="fw-bold"
              onClick={(e) => {
                if (CustomActionButtonModal) {
                  setShowCustomModal(true);
                  return;
                }

                handleModal(modal, actionButton);
              }}
              style={{ transform: `translate(${0}px, ${-2}px)` }}
            >
              <div className="d-none d-sm-flex">{actionButton}</div>

              {actionButtonMobileIcon && (
                <Image
                  width={20}
                  className="d-sm-none"
                  src={actionButtonMobileIcon}
                ></Image>
              )}

              <Image className="d-sm-none" src={plusSvg}></Image>
            </Button>
          )}
        </div>
      </div>

      {showCustomModal && (
        <CustomActionButtonModal
          onClose={() => setShowCustomModal(false)}
        ></CustomActionButtonModal>
      )}
    </>
  );
}

export default NavTabsV2;
