import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useFormInput } from "../../hooks/useFormInput";
import { useDispatch } from "react-redux";

import { updateWorksheetItem } from "../../actions/workoutSheetActions";

function RegisterSheetRowV2({ row, id = null, onDone = () => {} }) {
  const dispatch = useDispatch();

  const editMode = !!id;

  const rowData = {
    serie: useFormInput(row.serie),
    repetitions: useFormInput(row.repetitions),
    restTime: useFormInput(row.restTime),
    weight: useFormInput(row.weight),
  };

  const [notes, setNotes] = useState(row.notes);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (editMode) {
      dispatch(
        updateWorksheetItem(
          {
            serie: rowData.serie.value,
            repetitions: rowData.repetitions.value,
            restTime: rowData.restTime.value,
            weight: rowData.weight.value,
            notes,
          },
          id
        )
      );
    }

    onDone();
  };

  return (
    <Form>
      <Row>
        <Col className="col-12">
          <Form.Group className="mb-3" controlId="formBasicEmailName">
            <Form.Label className="fw-500">{row?.exercise?.name}</Form.Label>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="fw-500">Série</Form.Label>
            <Form.Control
              type="number"
              placeholder="Ex.: 3"
              {...rowData.serie}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fw-500">Repetições</Form.Label>
            <Form.Control
              type="number"
              placeholder="Ex.: 3"
              {...rowData.repetitions}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fw-500">
              Tempo de descanso (em segundos)
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Ex.: 2"
              {...rowData.restTime}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fw-500">Peso</Form.Label>
            <Form.Control
              type="number"
              placeholder="Ex.: 25 kg"
              {...rowData.weight}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fw-500">Observações</Form.Label>
            <Form.Control
              type="text"
              as={"textarea"}
              placeholder=""
              value={notes}
              style={{ height: "70px" }}
              onChange={(e) => setNotes(e.target.value.substring(0, 100))}
            />
            <Form.Control.Feedback className="d-flex text-primary" type="valid">
              ({notes?.length}) Máximo de 100 caracteres.
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            onClick={submitHandler}
            className="fw-500"
            variant="primary"
            type="submit"
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default RegisterSheetRowV2;
