import { useMemo } from "react";
import { useExerciseDoneMutation } from "../../../mutations";
import {
  usePatientWorkoutQuery,
  useReadPostResumeQuery,
} from "../../../queries";
import { useHistory } from "react-router-dom";
import { useApiConfig } from "../../../hooks/useApiConfig";
import api from "../../../services/api";

export function useController({ postId }) {
  const history = useHistory();

  const config = useApiConfig();

  const { postResume, postQueue, readPostResumeQuery } = useReadPostResumeQuery(
    {
      enabled: true,
      params: { postId: postId },
    }
  );

  const { patientWorkouts, patientWorkoutQuery } = usePatientWorkoutQuery({
    enabled: true,
    params: null,
  });

  const { editExerciseDonePostMut } = useExerciseDoneMutation();

  const workoutExercises = useMemo(() => {
    const result = patientWorkouts.filter((pW) =>
      pW?.ExerciciosFicha[0]?.find(
        (e) => e?.workoutSheet?.workout?._id === postResume?.post?.workout?._id
      )
    );

    if (result && result[0])
      return (
        result[0].ExerciciosFicha[0]
          ?.filter((e) => !!e.exercise)
          .map((e) => {
            e.exercise._localSerie = e.serie;

            return e.exercise;
          }) ?? []
      );

    return [];
  }, [patientWorkouts, postResume]);

  // eu posso ter o mesmo exercicio repetido em um treino?
  const leftExercises = useMemo(() => {
    return workoutExercises.filter(
      (e) => !postQueue.find((q) => q.exercise?._id === e?._id)
    );
  }, [workoutExercises, postResume]);

  const allExercises = useMemo(() => {
    return [
      ...postQueue,
      ...leftExercises.map((l) => ({
        exercise: l,
        _id: l._id,
        createdAt: new Date().toISOString(),
        currentSerie: 1,
        order: 1,
        isComplete: false,
        isLocal: true,
      })),
    ];
  }, [leftExercises, postQueue]);

  const handleExerciseClick = async ({
    currentExercise,
    exerciseId,
    progress,
  }) => {
    const response = await api.get(
      `api/exercises_done/post/read/${postId}/${exerciseId}/`,
      config
    );

    const eDs = response.data.exercises_done ?? [];

    const totalSerie = workoutExercises.find(
      (e) => e?._id?.toString() === exerciseId
    )?._localSerie;

    await editExerciseDonePostMut.mutateAsync({
      postId: postId,
      exercise_id: exerciseId,
      currentExercise: currentExercise,
      totalExercise: postResume?.post?.totalExercise ?? -1,
      currentSerie: eDs.length,
      totalSerie: totalSerie,
      currentProgress: progress,
      pace: postResume?.post?.pace ?? -1,
      skipProgress: true,
    });

    history.replace(
      `/home/pacient/workoutsheet/execution/${exerciseId}/${postId}`
    );
  };

  const refetch = () => {
    readPostResumeQuery.refetch();
  };

  return {
    postQueue,
    allExercises,
    leftExercises,
    handleExerciseClick,
    postResume,
    refetch,
  };
}
