import { useQuery } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";
import { useMemo } from "react";

export const useReadExerciseDetailsQuery = ({ params, enabled }) => {
  const config = useApiConfig();

  const readExerciseQuery = useQuery({
    queryKey: ["read-exercise-details", enabled, params],
    queryFn: async () => {
      const { id } = params;

      return api.get(`/api/exercises/read/${id}`, config);
    },
    enabled,
  });

  const exercise = useMemo(() => {
    if (readExerciseQuery.data?.data) {
      const { exercise, ...feedback } = readExerciseQuery.data?.data;
      const { serie, results, ...socketFeedback } = feedback;
      exercise.feedback = { serie, results, socketFeedback };

      return exercise;
    }

    return undefined;
  }, [readExerciseQuery.data?.data]);

  return { exercise, readExerciseQuery };
};
