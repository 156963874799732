import React from "react";

import { FinancePlanSection } from "./FinancePlanSection";
import { FINANCE_PLANS } from "../../../constants/plan.consts";
import {
  useReadAllFinancesQuery,
  useReadAllVinculedFinanceQuery,
} from "../../../queries";

import { FinanceLog } from "./FinanceLog";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import "../../../assets/v2/financeScreen.css";

export function FinanceScreen() {
  const { allVinculedFinances } = useReadAllVinculedFinanceQuery({});

  const { isLG } = useMediaQueryValues();

  const maxHeight = isLG ? window.innerHeight - 180 : window.innerHeight - 300;

  const onMouseWheel = (e) => {
    if (!isLG) return;

    e.currentTarget.scrollBy({ left: e.deltaY });
    e.preventDefault();
  };

  const [last, ...rest] = Object.keys(FINANCE_PLANS).map((planId, i) => (
    <FinancePlanSection
      key={planId}
      name={FINANCE_PLANS[planId].name}
      id={planId}
      monthCount={FINANCE_PLANS[planId].monthCount}
      finances={allVinculedFinances[i]}
    ></FinancePlanSection>
  ));

  return (
    <div className="row m-0 p-0 col-12 ">
      <div
        style={{
          height: maxHeight,
          overflowX: "scroll",
          scrollSnapType: "x mandatory",
        }}
        onWheel={(e) => onMouseWheel(e)}
        className="p-0 col-12 col-lg-7 col-xl-8 d-flex mb-3 rounded finance-custom-scrollbar "
      >
        {/* <h2 className="text-primary fw-500">Finanças</h2> */}

        {rest}
        {last}
      </div>

      <FinanceLog
        style={{ height: maxHeight }}
        className="col-lg-5 col-xl-4"
      ></FinanceLog>
    </div>
  );
}
