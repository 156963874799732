import React from "react";

export const WithPageSection =
  (children) =>
  ({ isFirst = false }) => {
    return React.cloneElement(children, {
      ...children.props,
      isFirst,
    });
  };
