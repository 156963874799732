//import axios from 'axios'
import axios from '../services/axios';
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,

    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_RESET,

    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,

    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,

    USER_LIST_BODYBUILDERS_REQUEST,
    USER_LIST_BODYBUILDERS_SUCCESS,
    USER_LIST_BODYBUILDERS_FAIL,
    USER_LIST_BODYBUILDERS_RESET,

    USER_LIST_BODYBUILDERS_AVAILABLE_REQUEST,
    USER_LIST_BODYBUILDERS_AVAILABLE_SUCCESS,
    USER_LIST_BODYBUILDERS_AVAILABLE_FAIL,
    USER_LIST_BODYBUILDERS_AVAILABLE_RESET,

    USER_LIST_PERSONAL_REQUEST,
    USER_LIST_PERSONAL_SUCCESS,
    USER_LIST_PERSONAL_FAIL,
    USER_LIST_PERSONAL_RESET,

    USER_INVITATION_LIST_REQUEST,
    USER_INVITATION_LIST_SUCCESS,
    USER_INVITATION_LIST_FAIL,
    USER_INVITATION_LIST_RESET,

    USER_INVITATION_SEND_REQUEST,
    USER_INVITATION_SEND_SUCCESS,
    USER_INVITATION_SEND_FAIL,
    USER_INVITATION_SEND_RESET,

    USER_INVITATION_REPLY_REQUEST,
    USER_INVITATION_REPLY_SUCCESS,
    USER_INVITATION_REPLY_FAIL,
    USER_INVITATION_REPLY_RESET,
    USER_FORGET_PASS_REQUEST,
    USER_FORGET_PASS_SUCCESS,
    USER_FORGET_PASS_FAIL,
    USER_RESET_PASS_REQUEST,
    USER_RESET_PASS_SUCCESS,
    USER_RESET_PASS_FAIL,

    USER_IMAGE_CREATE_REQUEST,
    USER_IMAGE_CREATE_SUCCESS,
    USER_IMAGE_CREATE_FAIL,

    USER_UPDATE_PASS_REQUEST,
    USER_UPDATE_PASS_SUCCESS,
    USER_UPDATE_PASS_FAIL,
    USER_UPDATE_PASS,
    USER_LOGIN_UPDATE,

} from '../constants/userConstants'


export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/api/users/login/',
            { 'username': email, 'password': password },
            config
        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET })
    dispatch({ type: USER_LIST_RESET })
}


export const register = (name, email, password, rePassword, isPersonal, isPhysio, isPacient) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const { data } = await axios.post(
            "/api/users/register/",
            {
                name: name,
                email: email,
                password: password,
                re_password: rePassword,
                isPersonal: isPersonal,
                isPhysio: isPhysio,
                isPacient: isPacient
            },
            config
        );

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        // dispatch({
        //     type: USER_LOGIN_SUCCESS,
        //     payload: data
        // })

        // localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createImageUser = (info) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_IMAGE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/profiles/pacient/upload/`,
            info,
            config
        )
        dispatch({
            type: USER_IMAGE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: USER_IMAGE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const saveUserImage = (imageFile) => (dispatch) => {
    dispatch({
        type: "LOAD_IMAGE",
        payload: imageFile,
    })

    localStorage.setItem('UserImage', JSON.stringify(imageFile))
}

export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/users/${id}/`,
            config
        )

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_PROFILE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/users/update/user/`,
            
            user,
            config
        )

        dispatch({
            type: USER_UPDATE_PROFILE_SUCCESS,
            payload: data
        })

        // const updatedUser = JSON.parse(localStorage.getItem('userInfo'));
        
        // updatedUser.name = data.name;
        // updatedUser.first_name = data.name;
        // updatedUser.email = data.email;
        // falta o numero do telefone nas infos

        // dispatch({
        //     type: USER_LOGIN_SUCCESS,
        //     payload: data
        // })

        // const updatedUser = {...data, ...userInfo}

        // localStorage.setItem('userInfo', JSON.stringify(updatedUser))
    } catch (error) {
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listUsers = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/users/mybodybuilders`,
            config
        )

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/users/delete/user/${id}/`,
            config
        )

        dispatch({
            type: USER_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateUser = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/users/update/${user._id}/`,
            user,
            config
        )

        dispatch({
            type: USER_UPDATE_SUCCESS,
        })

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listMyBodybuilders = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_BODYBUILDERS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/users/mybodybuilders/`,
            config
        )

        dispatch({
            type: USER_LIST_BODYBUILDERS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_LIST_BODYBUILDERS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listBodybuildersAvailable = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_BODYBUILDERS_AVAILABLE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/users/invitation/bbavailable/`,
            config
        )

        dispatch({
            type: USER_LIST_BODYBUILDERS_AVAILABLE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_LIST_BODYBUILDERS_AVAILABLE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listMyPersonal = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_PERSONAL_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/users/mypersonal/`,
            config
        )

        dispatch({
            type: USER_LIST_PERSONAL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_LIST_PERSONAL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listInvitations = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_INVITATION_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/users/invitation/`,
            config
        )

        dispatch({
            type: USER_INVITATION_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_INVITATION_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const sendInvitationPersonal = (info) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_INVITATION_SEND_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            '/api/users/invitation/send/',
            info,
            config
        )

        dispatch({
            type: USER_INVITATION_SEND_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_INVITATION_SEND_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const replyInvitationPersonal = (id, info) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_INVITATION_REPLY_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/users/invitation/reply/${id}/`,
            info,
            config
        )

        dispatch({
            type: USER_INVITATION_REPLY_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: USER_INVITATION_REPLY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const forgetPass = (email) => async (dispatch) => {
    try {
        dispatch({
            type: USER_FORGET_PASS_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/api/users/confirm/reset/',
            { 'email': email },
            config
        )

        dispatch({
            type: USER_FORGET_PASS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_FORGET_PASS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateUserInfo = (data, userInfo) => async(dispatch) => {

    try {
        dispatch({
            type: USER_LOGIN_UPDATE,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, ...data }))

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
    
}

export const resetPass = (token, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_RESET_PASS_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/api/users/new/password/',
            { 'token': token, 'password': password },
            config
        )

        dispatch({
            type: USER_RESET_PASS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_RESET_PASS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updatePass = (password, newPassword) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_RESET_PASS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            '/api/users/update/password/',
            { 
                'password_before': password.value, 
                'password_new': newPassword.value 
            },
            config
        )

        dispatch({
            type: USER_UPDATE_PASS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_UPDATE_PASS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
};