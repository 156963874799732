import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
    exerciseListReducer,
    exercisePatterReducer,
    exerciseDetailsReducer,
    exerciseDeleteReducer,
    exerciseCreateReducer,
    exerciseImageCreateReducer,
    exerciseVideoCreateReducer,
    exerciseUpdateReducer,
    exerciseReducer,
    muscleListReducer,
} from './reducers/exerciseReducers'

import {
    exerciseExecutionReducer,
    exerciseDoneCreateReducer,
    articulationDetailsReducer,
    serieDetailsReducer,
    articulationsDetailsReducer,
    feedBodybuilderReducerSerie,
    feedBodybuilderReducerArticulation,
    feedPersonalReducer,
    serieAmountReducer
} from './reducers/exerciseDoneReducers'

import {
    videoInicializeBodybuilderReducer,
    videoInicializePersonalReducer,
    videoHumanPointsBodybuilderReducer,
    videoHumanPointsPersonalReducer,
    videoStopBodybuilderReducer,
    videoStopPersonalReducer,
    videoUploadPersonalReducer,
    videoUploadBodybuilderReducer
} from './reducers/videoReducers'

import {
    workoutListReducer,
    workoutListDetailsReducer,
    workoutBodybuilderListReducer,
    workoutCreateReducer,
    workoutDeleteReducer,
    workoutCreateBodybuilderReducer,
    workoutPatientListReducer,
    workoutUpdateReducer

} from './reducers/workoutReducers'

import { workoutSheetDeleteItemReducer, workoutSheetUpdateItemReducer, workoutSheetListReducer } from './reducers/workoutSheetReducers'

import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userListReducer,
    userDeleteReducer,
    userUpdateReducer,
    userListMyBodybuildersReducer,
    userListBodybuildersAvailableReducer,
    userListMyPersonalReducer,
    userListInvitationReducer,
    userSendInvitationReducer,
    userReplyInvitationReducer,
    forgetPassReducer,
    resetPassReducer,
    userImageCreateReducer,
    updatePassReducer,
} from './reducers/userReducers'

import {
    pacientDetailsReducer,
    pacientListReducer,
    pacientCreateReducer,
    pacientImageCreateReducer,
} from './reducers/pacientReducers'

import {
        feedbackListReducer,
        feedbackDetailsReducer,
        feedbackDeleteReducer,
        feedbackCreateReducer,
        feedbackUpdateReducer,
        feedbackReducer,
} from './reducers/feedbackReducers'

import { physioEvaluationDetailsReducer, physioEvaluationListReducer, physioEvaluationDeleteReducer, physioEvaluationRegisterReducer, physioEvaluationUpdateReducer } from './reducers/physioEvaluationReducers'
import { medicalRecordDeleteReducer, medicalRecordDetailsReducer, medicalRecordListReducer, medicalRecordRegisterReducer, medicalRecordUpdateReducer } from './reducers/medicalRecordsReducers'

import {
    scheduleListReducer,
    scheduleDetailsReducer,
    scheduleCreateReducer,
} from './reducers/scheduleReducers'
import { listBillsReducer } from './reducers/paymentReducers'

import { todayExercisesReducer } from './reducers/todayExercisesReducer'

const reducer = combineReducers({
    exerciseList: exerciseListReducer,
    exercisePatter: exercisePatterReducer,
    exerciseDetails: exerciseDetailsReducer,
    exerciseDelete: exerciseDeleteReducer,
    exerciseCreate: exerciseCreateReducer,
    exerciseImageCreate: exerciseImageCreateReducer,
    exerciseVideoCreate: exerciseVideoCreateReducer,
    exerciseUpdate: exerciseUpdateReducer,
    muscleList: muscleListReducer,
    
    
    todayExercisesState: todayExercisesReducer,


    exerciseExecution: exerciseExecutionReducer,
    exerciseDoneCreate: exerciseDoneCreateReducer,
    articulationDetails: articulationDetailsReducer,
    serieDetails: serieDetailsReducer,
    articulationsDetails: articulationsDetailsReducer,
    feedBodybuilderSerie: feedBodybuilderReducerSerie,
    feedBodybuilderArticulation: feedBodybuilderReducerArticulation,
    feedPersonal: feedPersonalReducer,
    serieAmount: serieAmountReducer,

    videoInicializeBodybuilder: videoInicializeBodybuilderReducer,
    videoInicializePersonal: videoInicializePersonalReducer,
    videoHumanPointsBodybuilder: videoHumanPointsBodybuilderReducer,
    videoHumanPointsPersonal: videoHumanPointsPersonalReducer,
    videoStopBodybuilder: videoStopBodybuilderReducer,
    videoStopPersonal: videoStopPersonalReducer,
    videoUploadPersonal: videoUploadPersonalReducer,
    videoUploadBodybuilder: videoUploadBodybuilderReducer,

    workoutsList: workoutListReducer,
    workoutsListDetails: workoutListDetailsReducer,
    workoutsBodybuilderList: workoutBodybuilderListReducer,
    workoutsCreate: workoutCreateReducer,
    workoutsDelete: workoutDeleteReducer,
    workoutsCreateBodybuilder: workoutCreateBodybuilderReducer,
    workoutPatientList: workoutPatientListReducer,
    workoutSheetDeleteItemReducer: workoutSheetDeleteItemReducer,
    workoutUpdate: workoutUpdateReducer,
    workoutSheetItemUpdate: workoutSheetUpdateItemReducer,
    workoutSheetList: workoutSheetListReducer,

    exercise: exerciseReducer,
    exerciseList: exerciseListReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userListMyBodybuilders: userListMyBodybuildersReducer,
    userListBodybuildersAvailable: userListBodybuildersAvailableReducer,
    userListMyPersonal: userListMyPersonalReducer,
    userListInvitation: userListInvitationReducer,
    userSendInvitation: userSendInvitationReducer,
    userReplyInvitation: userReplyInvitationReducer,
    userImage:userImageCreateReducer,

    pacientList: pacientListReducer,
    pacientDetails: pacientDetailsReducer,
    pacientCreate: pacientCreateReducer,
    pacientImage: pacientImageCreateReducer,

    physioEvaluationList: physioEvaluationListReducer,
    physioEvaluationRegister: physioEvaluationRegisterReducer,
    physioEvaluationDetails: physioEvaluationDetailsReducer,
    physioEvaluationUpdate: physioEvaluationUpdateReducer,
    physioEvaluationDelete: physioEvaluationDeleteReducer,

    medicalRecordList: medicalRecordListReducer,
    medicalRecordRegister: medicalRecordRegisterReducer,
    medicalRecordDetails: medicalRecordDetailsReducer,
    medicalRecordUpdate: medicalRecordUpdateReducer,
    medicalRecordDelete: medicalRecordDeleteReducer,

    feedbackList: feedbackListReducer,
    feedbackDetails: feedbackDetailsReducer,
    feedbackDelete: feedbackDeleteReducer,
    feedbackCreate: feedbackCreateReducer,
    feedbackUpdate: feedbackUpdateReducer,
    feedback: feedbackReducer,

    listBills: listBillsReducer,

    forgetPass: forgetPassReducer,
    resetPass: resetPassReducer,
    updatePass: updatePassReducer,

    scheduleList: scheduleListReducer,
    scheduleDetails: scheduleDetailsReducer,
    scheduleCreate: scheduleCreateReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

const exerciseDataFromStorage = localStorage.getItem('exerciseData') ?
    JSON.parse(localStorage.getItem('exerciseData')) : {}

const exerciseIndicationsFromStorage = localStorage.getItem('exerciseIndications') ?
    JSON.parse(localStorage.getItem('exerciseIndications')) : {}

const exerciseImageFromStorage = localStorage.getItem('exerciseImage') ?
    JSON.parse(localStorage.getItem('exerciseImage')) : {}

const exerciseVideoFromStorage = localStorage.getItem('exerciseVideo') ?
    JSON.parse(localStorage.getItem('exerciseVideo')) : {}

const video0FromStorage = localStorage.getItem('video0') ?
    JSON.parse(localStorage.getItem('video0')) : {}

const feedArticulationFromStorage = localStorage.getItem('feedArticulation') ?
    JSON.parse(localStorage.getItem('feedArticulation')) : {}

const initialState = {
    exercise: {
        exerciseData: exerciseDataFromStorage,
        exerciseIndications: exerciseIndicationsFromStorage,
        exerciseImage: exerciseImageFromStorage,
        exerciseVideo: exerciseVideoFromStorage
    },
    userLogin: { userInfo: userInfoFromStorage },
    videoStopPersonal: { video0: video0FromStorage },
    feedArticulation: { feedArticulation: feedArticulationFromStorage }
}

const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store