import { useMutation } from "@tanstack/react-query";
import api from "../services/api";
import { useApiConfig } from "../hooks/useApiConfig";

export function usePatientFormMutation() {
  const configFormData = useApiConfig("multipart/form-data");
  const config = useApiConfig();

  //   {
  //     "name_form": "modelo personal"
  //     }
  const createPatientFormModelMutation = useMutation({
    mutationFn: async (params) => {
      return api.post("/api/profiles/form/create/", params, config);
    },
  });

  //   {
  //  formModelId: "1"
  //     "name_form": "modelo personal"
  //     }
  const editPatientFormModelMutation = useMutation({
    mutationFn: async (params) => {
      return api.put(
        `/api/profiles/form/edit/${params.formModelId}/`,
        params,
        config
      );
    },
  });

  //   {
  //        formModelId: "1"
  //     }
  const deletePatientFormModelMutation = useMutation({
    mutationFn: async (params) => {
      return api.delete(
        `/api/profiles/form/model/delete/${params.formModelId}/`,
        config
      );
    },
  });

  //   {
  //   "modelId": "1",
  //     "question_name": "qual seu peso?",
  //     "type": "0"
  //     }
  const createQuestionPatientFormMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(
        `/api/profiles/form/question/create/${params.modelId}/`,
        params,
        config
      );
    },
  });

  //   {
  //        questionId: "1"
  //     }
  const deleteQuestionPatientFormMutation = useMutation({
    mutationFn: async (params) => {
      return api.delete(
        `/api/profiles/form/question/delete/${params.questionId}/`,
        config
      );
    },
  });

  //   {
  //     "number_order": 4,
  //     "choice_name": "abaixo de 18 anos"
  //     questionId: "1"
  //    }
  const createChoiceQuestionMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(
        `/api/profiles/form/question/choice/create/${params.questionId}/`,
        params,
        config
      );
    },
  });

  //   {
  //     patientPatientFormId: "1"
  //     "name" : "primeira avaliação",
  //     "questionsAnswers" : [
  //     {
  //     "question_id" : 1,
  //     "answerText" : "Leandro"
  //     },
  //     {
  //     "question_id" : 2,
  //     "choice_id" : 2
  //     },
  //     {
  //     "question_id" : 3,
  //     "imagePatientForm_id" : 1
  //     }
  //     ]

  //     }

  const createAnswerPatientFormMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(
        `/api/profiles/form/answer/create/${params.patientPatientFormId}/`,
        params,
        config
      );
    },
  });

  // patientId
  // formModelId
  // name

  const createFilledPatientFormMutation = useMutation({
    mutationFn: async (params) => {
      return api.post(
        `/api/profiles/form/patient/create/${params.patientId}/${params.formModelId}/`,
        params,
        config
      );
    },
  });

  //   {
  //     filledPatientFormId: "1"
  //    }
  const deleteFilledPatientFormMutation = useMutation({
    mutationFn: async (params) => {
      return api.delete(
        `/api/profiles/form/answer/delete/${params.filledPatientFormId}/`,
        config
      );
    },
  });

  // patientPatientFormId
  // name
  const editFilledPatientFormMutation = useMutation({
    mutationFn: async (params) => {
      return api.put(
        `/api/profiles/form/patient/edit/${params.patientPatientFormId}/`,
        params,
        config
      );
    },
  });

  //   {
  //     patientPatientFormId: "1"
  //     "name" : "primeira avaliação",
  //     "questionsAnswers" : [
  //     {
  //     "question_id" : 1,
  //     "answerText" : "Leandro"
  //     },
  //     {
  //     "question_id" : 2,
  //     "choice_id" : 2
  //     },
  //     {
  //     "question_id" : 3,
  //     "imagePatientForm_id" : 1
  //     }
  //     ]

  //     }
  const editAnswerPatientFormMutation = useMutation({
    mutationFn: async (params) => {
      return api.put(
        `/api/profiles/form/answer/edit/${params.patientPatientFormId}/`,
        params,
        config
      );
    },
  });

  const uploadPatientFormImage = useMutation({
    mutationFn: async (params) => {
      const { file, name } = params;

      const formData = new FormData();

      formData.append("image", file);
      formData.append("caption", name);

      return api.post("/api/profiles/form/upload/", formData, configFormData);
    },
  });

  return {
    createPatientFormModelMutation,
    editPatientFormModelMutation,
    deletePatientFormModelMutation,
    createQuestionPatientFormMutation,
    deleteQuestionPatientFormMutation,
    createChoiceQuestionMutation,
    createFilledPatientFormMutation,
    editAnswerPatientFormMutation,
    deleteFilledPatientFormMutation,
    uploadPatientFormImage,
    createAnswerPatientFormMutation,
    editFilledPatientFormMutation,
  };
}
