import React from "react";
import { formatDate } from "../../../utils/date";
import trash from "../../../assets/v2/icons/trash.svg";
import { Image } from "react-bootstrap";
import { useFinanceMutation } from "../../../mutations";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import transferSvg from "../../../assets/v2/icons/transfer.svg";

export function FinanceLogItem({ finance, onClick = () => {} }) {
  const { deleteFinance } = useFinanceMutation();

  const queryClient = useQueryClient();

  const handleDeleteClick = async (e) => {
    e?.stopPropagation();

    await deleteFinance.mutateAsync({ id: finance?._id });

    toast("Pagamento deletado com sucesso!", {
      type: "success",
      position: "top-center",
    });

    await queryClient.refetchQueries({
      queryKey: ["read-all-finances-query"],
      exact: false,
    });

    await queryClient.refetchQueries({
      queryKey: ["read-all-vinculed-finance-query"],
    });
  };

  const pacientName = finance?.pacient?.name;

  return (
    <div
      className="col-12 p-0 d-flex  p-3 rounded mb-2 pe-2 align-items-start pointer"
      onClick={onClick}
    >
      <Image width={20} className="mt-1" src={transferSvg}></Image>

      <div className="d-flex flex-column ms-2">
        <p className="mb-0">{finance?.name}</p>
        {pacientName && (
          <p className="mb-0 text-primary text-small fw-bold">{pacientName}</p>
        )}
        <p className="mb-0 text-small">{formatDate(finance?.date)}</p>
      </div>

      <div
        style={{ flex: 1 }}
        className="d-flex justify-content-end text-nowrap"
      >
        <p
          className={
            "mb-0 " + (!finance?.category ? " text-success " : " text-danger ")
          }
        >
          {!finance?.category ? "+" : "-"} R$
          {Math.abs(finance.value).toFixed(2)}
        </p>
      </div>

      <Image
        className="align-self-center ms-3 pointer"
        src={trash}
        onClick={handleDeleteClick}
      ></Image>
    </div>
  );
}
