import { React, useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useFormInput } from "../../hooks/useFormInput";
import { useDispatch, useSelector } from "react-redux";
import { updatePacient } from "../../actions/pacientActions";
import DateField from "./Fields/DateField";
import {
  dateFormat,
  dateToStringApiFormat,
  stringToDate,
} from "../../utils/date";
import { useUserMutation } from "../../mutations";
import { toast } from "react-toastify";
import axios from "axios";
import { FINANCE_PLANS } from "../../constants/plan.consts";
import InputMask from "react-input-mask";
import { ChargeForm } from "./ChargeForm";
import { useUserInfo } from "../../hooks/useUserInfo";
import { UserImageProfileV3 } from "../../screens/v2/UserImageProfileV3";

function RegisterCostumerV2({ props, pacient = null, unsetModalBody }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState();

  const { userDetails } = useUserInfo();

  const [planId, setPlanId] = useState(pacient?.plansPatient?._id ?? "1");

  const [profileImage, setProfileImage] = useState({
    id: pacient?.profileImage?._id,
    url: pacient?.profileImage?.image,
  });

  const { createPatient } = useUserMutation();

  const myPlans = userDetails.myPlans ?? [];

  const [formData, setFormData] = useState({
    is_warm: !pacient,
  });

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const data = {
    name: useFormInput(pacient?.name),
    address: useFormInput(pacient?.address),
    gender: useFormInput(pacient?.gender),
    birthDate: useFormInput(
      pacient?.birthDate
        ? new Date(`${pacient.birthDate}T12:00:00Z`)
        : undefined
    ),
    email: useFormInput(pacient?.email),
    phone: useFormInput(pacient?.phone),
    image: useFormInput(pacient?.image),
  };

  const [charge, setCharge] = useState({
    initialDate: stringToDate(pacient?.charge?.initialDate),
    recorrency: !!pacient?.charge?.recorrency,
    enabled: !!pacient?.charge?.initialDate,
    value: pacient?.charge?.value ?? 0,
  });

  const handleInputImageChange = ({ id, url }) => {
    setProfileImage({ id, url });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setError(undefined);

    const formattedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (value.value instanceof Date)
          return [key, dateFormat(value.value, "yyyy-mm-dd")];
        return [key, value.value];
      })
    );

    formattedData.profileImage_id =
      pacient?.profileImage?._id ?? profileImage?.id;

    formattedData.plansPatient_id = planId;
    formattedData.charge = charge;
    formattedData.charge.initialDate = dateToStringApiFormat(
      charge.initialDate
    );
    formattedData.charge.recipient_id = userDetails?.recipient?._id ?? null;
    delete formattedData.image;

    if (planId === "1" || !charge.enabled) {
      delete formattedData.charge;
    }

    if (formattedData.profileImage_id === undefined) {
      formattedData.profileImage_id = null;
    }

    if (pacient) {
      dispatch(
        updatePacient({ ...formattedData, id: pacient.id, ...formData })
      );
      unsetModalBody();
    } else {
      try {
        formattedData.birthDate = formattedData.birthDate ?? null;
        formattedData.phone = formattedData.phone.replace(/\D/g, "");

        const response = await createPatient.mutateAsync({
          ...formattedData,
          ...formData,
        });

        const { data, status } = response;

        if (status === 200 && data?.data?.id) {
          const message = data?.message?.detail;

          message &&
            message !== "" &&
            toast(message, {
              type: "success",
              position: "top-center",
              autoClose: false,
            });

          history.push("/home/personal/pacients/" + data?.data?.id);
        }

        unsetModalBody();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const { data } = error.response;

          setError(data?.detail);
        }
      }
    }
  };

  useEffect(() => {
    const plan = myPlans.find(
      (p) => p?.plansPatient?._id?.toString() === planId
    );

    setCharge((prev) => {
      return {
        ...prev,
        value: pacient?.charge?.value ?? plan?.value ?? 0,
      };
    });
  }, [planId]);

  return (
    <Form onSubmit={submitHandler}>
      {error && (
        <div class="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <Row className="mb-3 d-flex justify-content-center">
        <Col className="col-12 col-md-4">
          <UserImageProfileV3
            handleInputImageChange={handleInputImageChange}
            userImage={profileImage.url}
          ></UserImageProfileV3>
        </Col>
      </Row>
      <Row>
        <Form.Group
          className="mb-3 col-12 col-lg-6"
          controlId="formBasicEmailName"
        >
          <Form.Label className="fw-500">Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nome Completo"
            {...data.name}
            required
          />
        </Form.Group>
        <Form.Group
          className="mb-3 col-12 col-lg-6"
          controlId="formBasicAddress"
        >
          <Form.Label className="fw-500">Endereço</Form.Label>
          <Form.Control
            type="text"
            placeholder="Endereço Completo"
            {...data.address}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-lg-6" controlId="formBasicGender">
          <Form.Label className="fw-500">Identidade de Gênero</Form.Label>
          <Form.Control required as="select" {...data.gender}>
            <option value={""}>Selecionar</option>
            <option value={"M"}>Masculino</option>
            <option value={"F"}>Feminino</option>
            <option value={"N"}>Outro</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-lg-6" controlId="formBasicBirth">
          <Form.Label className="fw-500">Aniversário</Form.Label>
          <DateField
            currentDate={data.birthDate.value}
            setDate={data.birthDate.onChange}
            required={false}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
          <Form.Label className="fw-500">E-mail</Form.Label>
          <Form.Control
            type="email"
            placeholder="email@exemplo.com"
            {...data.email}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 col-lg-6" controlId="formBasicPhone">
          <Form.Label className="fw-500">Contato</Form.Label>
          <Form.Control
            type="text"
            placeholder="(xx) 9xxxx-xxxx"
            {...data.phone}
            required
            as={InputMask}
            mask="(99) 99999-9999"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPhone">
          <Form.Label className="fw-500">Plano</Form.Label>
          <Form.Control
            as="select"
            required
            onChange={(e) => setPlanId(e.target.value)}
            value={planId}
          >
            {Object.keys(FINANCE_PLANS).map((planId) => (
              <option key={planId} value={planId}>
                {FINANCE_PLANS[planId].name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {!pacient && (
          <Form.Group className="mb-2">
            <Form.Check
              onChange={(e) => {
                updateFormData({
                  target: { name: e.target.name, value: e.target.checked },
                });
              }}
              checked={formData.is_warm}
              name="is_warm"
              label={
                <Form.Check.Label>
                  Convite por email para se cadastrar na Exercitium
                </Form.Check.Label>
              }
            ></Form.Check>

            {!userDetails?.recipient?.idPagueMe && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Registre uma conta bancária no perfil, para habilitar essa
                opção.
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )}

        {planId !== "1" && (
          <div className="px-3">
            <ChargeForm charge={charge} setCharge={setCharge}></ChargeForm>
          </div>
        )}

        <Col className="col-12 d-flex justify-content-center align-items-center mt-3">
          <Button className="fw-500" variant="primary" type="submit">
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default RegisterCostumerV2;
