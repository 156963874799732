export const PLANS_IDS = {
  FREE: 1,
  BASIC_MONTH: 2,
  BASIC_YEAR: 3,
  PREMIUM_MONTH: 4,
  PREMIUM_YEAR: 5,
  BLOCKED: 6,
};

export const FINANCE_PLANS = {
  1: {
    name: "Nenhum Plano",
    monthCount: 0,
  },
  2: {
    name: "Plano Mensal",
    monthCount: 1,
  },
  3: {
    name: "Plano Trimestral",
    monthCount: 3,
  },
  4: {
    name: "Plano Semestral",
    monthCount: 6,
  },
  5: {
    name: "Plano Anual",
    monthCount: 12,
  },
};

export const BASIC_PLANS_IDS = [PLANS_IDS.BASIC_MONTH, PLANS_IDS.BASIC_YEAR];

export const PREMIUM_PLANS_IDS = [
  PLANS_IDS.PREMIUM_MONTH,
  PLANS_IDS.PREMIUM_YEAR,
];

export const PAID_PLANS_IDS = [
  PLANS_IDS.BASIC_MONTH,
  PLANS_IDS.BASIC_YEAR,
  PLANS_IDS.PREMIUM_MONTH,
  PLANS_IDS.PREMIUM_YEAR,
];

export const ALLOWED_PLANS_IDS = [
  PLANS_IDS.BASIC_MONTH,
  PLANS_IDS.BASIC_YEAR,
  PLANS_IDS.FREE,
  PLANS_IDS.PREMIUM_MONTH,
  PLANS_IDS.PREMIUM_YEAR,
];
