import React from "react";

import { Card, Row, Col } from "react-bootstrap";
import { useMediaQueryValues } from "../hooks/useMediaQueryValues";
import { useMemo } from "react";
import { getFeedbackResults } from "../utils/getFeedbackResults";
import { Badge } from "antd";

export const ResultFinalCard = ({ resultFinal }) => {
  const {
    count,
    mediaTimeUp,
    mediaTimeDown,
    mediaUp,
    mediaDown,
    timeUpMedia,
    timeDownMedia,
  } = useMemo(() => getFeedbackResults(resultFinal), [resultFinal]);

  const { isMD } = useMediaQueryValues();

  const alerts = (resultFinal?.alertMessagens ?? []).filter((a) => a !== "");

  return (
    <Card className="bg-primary">
      <Card.Header className="text-white">
        <Card.Title className="mb-0">Análise de Movimento (Beta)</Card.Title>
      </Card.Header>
      <Card.Body className="text-white">
        <Row>
          <Col className="col-6 col-md-5">
            <Card.Text>Contagem: {count}</Card.Text>
          </Col>

          <Col className="col-6 col-md-5">
            <Card.Text>
              Tempo: Entre {Number(mediaTimeUp ?? timeUpMedia).toFixed(2)}s e{" "}
              {Number(mediaTimeDown ?? timeDownMedia).toFixed(2)}s
            </Card.Text>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col className="col-6 col-md-5">
            <Card.Text>
              Ang Principal: Entre{" "}
              {mediaUp && mediaUp.length > 0
                ? Number(mediaUp[0]).toFixed(2)
                : 0}
              º e{" "}
              {mediaDown && mediaDown.length > 0
                ? Number(mediaDown[0]).toFixed(2)
                : 0}
              º
            </Card.Text>
          </Col>

          <Col className="col-6 col-md-5">
            <Card.Text>
              Ang Secundário: Entre{" "}
              {mediaUp && mediaUp.length > 1
                ? Number(mediaUp[1]).toFixed(2)
                : 0}
              º e{" "}
              {mediaDown && mediaDown.length > 1
                ? Number(mediaDown[1]).toFixed(2)
                : 0}
              º
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
      {alerts.length > 0 && (
        <Card.Footer className="text-white">
          <Row className="mb-2">
            <Card.Text className="col-12 m-0">Alertas:</Card.Text>
            {alerts.map((a, i) => (
              <Card.Text key={i} className="col-12 m-0">
                <Badge status="warning" className="me-1"></Badge> {a}
              </Card.Text>
            ))}
          </Row>
        </Card.Footer>
      )}
    </Card>
  );
};
