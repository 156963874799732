import { useEffect, useMemo, useState } from "react";
import { Col, Row, Modal, Button, Image } from "react-bootstrap";
import React from "react";
import VideoPlayer from "../v2/VideoPlayer";

import { ResultFinalCard } from "../ResultFinalCard";
import { respStyle } from "../../utils/respStyle";
import SheetItemExecution from "../SheetItemExecution";

import { FinishWorkoutCard } from "../../screens/v2/FinishWorkout";

import timerSvg from "../../assets/v2/icons/timer.svg";
import executeSvg from "../../assets/v2/icons/Weightlifting.svg";
import check from "../../assets/v2/icons/check.svg";
import skip from "../../assets/v2/icons/skip.svg";
import menu from "../../assets/v2/icons/menu-mobile2.svg";

import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";

import { TimerModal } from "../TimerModal";
import { useController } from "./controller";
import { useHistory, useParams } from "react-router-dom";
import { QueueView } from "./QueueView";

export function WorkoutSheetExecution() {
  const [showFinishWorkout, setShowFinishWorkout] = useState(false);
  const [showQueue, setShowQueue] = useState(false);

  const mediaValues = useMediaQueryValues();
  const history = useHistory();

  const controller = useController();

  const {
    exercise,
    exercises_done,
    serieArray,
    showExecuteModal,
    post,
    nextSheetItem,
    currentSheetItem,
    exercisePost,
    currentPatientWorkout,
    workout,
    restTimerCount,
  } = controller;

  useEffect(() => {
    if (!showExecuteModal) controller.readExercisePostQuery.refetch();
  }, [showExecuteModal]);

  const renderButtons = (
    <>
      <Button
        className="text-center pointer col-auto d-flex align-items-center"
        onClick={controller.handleExecuteClick}
      >
        <span className="d-none d-md-flex me-2">Executar</span>

        <Image
          className=""
          style={{ width: 30, height: 30 }}
          src={executeSvg}
        ></Image>
      </Button>
      <Button
        className="text-center pointer col-auto d-flex align-items-center"
        onClick={(e) =>
          controller.handleRestClick(e, currentSheetItem?.restTime)
        }
      >
        <span className="d-none d-md-flex me-2">Descanço</span>
        <Image style={{ width: 30, height: 30 }} src={timerSvg}></Image>
      </Button>
      <Button
        className="text-center pointer col-auto d-flex align-items-center"
        onClick={(e) => controller.handleSkipSerie(e)}
      >
        <span className="d-none d-md-flex me-2">Pular</span>
        <Image
          className=""
          style={{ width: 30, height: 30 }}
          src={skip}
        ></Image>
      </Button>
    </>
  );

  console.log({ currentSheetItem, post });

  return (
    <div className="h-100" style={{}}>
      <Col className="col-12">
        <div className="row m-0">
          <div className="col-12 col-md-7">
            <h3 className="">
              <button onClick={() => history.goBack()}>{"<"}</button>{" "}
              {workout?.name} - {post?.lastExercise?.name}
            </h3>
          </div>
          <Col className="col-12 col-md-5 d-flex justify-content-end align-items-center p-0">
            <Button
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                history.goBack();
              }}
            >
              Sair do Treino
            </Button>

            <Button
              className="ms-2"
              style={{ cursor: "pointer" }}
              onClick={(e) => setShowFinishWorkout(true)}
            >
              Finalizar
            </Button>
          </Col>
        </div>

        <Row
          className="position-relative mx-auto mt-2 d-flex overflow-hidden"
          style={{ height: "50vh" }}
        >
          <VideoPlayer source={post?.lastExercise?.exerciseVideo?.video} />
        </Row>

        <div
          className="w-100 mt-4 bg-primary rounded-top rounded-bottom"
          style={{ height: 3 }}
        ></div>

        <p className="mt-2 text-break word-break">
          {post?.lastExercise?.description}
        </p>

        <Row className="bg-body-secondary m-0 mt-0 p-4 rounded-3">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <h2 className="h2 fw-bold ps-0 text-primary ps-md-3 col-12 col-md-7">
              Progressão de Treino
            </h2>

            <div className="d-flex align-items-center">
              <p className="h4 text-primary m-0 me-2">
                {((post?.progress ?? 0) * 100).toFixed(0)}%
              </p>

              <Button className="me-2" onClick={() => setShowQueue(true)}>
                <Image src={menu}></Image>
              </Button>

              {post?._id && (
                <QueueView
                  postId={post._id}
                  show={showQueue}
                  onHide={() => setShowQueue(false)}
                ></QueueView>
              )}

              {post?.currentExercise < post?.totalExercise && (
                <Button onClick={controller.handleNextExerciseClick}>
                  Próximo Exercício
                </Button>
              )}

              {!nextSheetItem && (
                <Button onClick={(e) => setShowFinishWorkout(true)}>
                  Finalizar Treino
                </Button>
              )}
            </div>
          </div>

          <Row className="m-0 p-0 p-md-3">
            <Col className="col-12 px-0">
              {currentSheetItem &&
                serieArray.map((i, index) => {
                  const exerciseDoneItem = exercises_done[index];

                  const renderData = (
                    <>
                      <p className="text-center d-xl-flex m-0 col-auto">
                        {exerciseDoneItem?.serie[0]?.count ??
                          currentSheetItem?.repetitions}{" "}
                        rep
                      </p>
                      <p className="text-center d-xl-flex m-0 col-auto">
                        {currentSheetItem?.restTime} seg
                      </p>
                      <p className="text-center d-xl-flex m-0 col-auto">
                        {exerciseDoneItem?.serie[0]?.weight ??
                          currentSheetItem?.weight}
                        kg
                      </p>
                    </>
                  );

                  return (
                    <div
                      key={i}
                      className={
                        "d-flex flex-direction-row fw-bold w-100 " +
                        (exerciseDoneItem ? "opacity-50" : "")
                      }
                    >
                      <p className="pe-1 mb-0 border-end border-dark border-1">
                        {i + 1}º
                      </p>
                      <div className="row m-0 w-100 mb-4">
                        <div
                          className={
                            "d-flex m-0 p-0 ps-2 justify-content-between"
                          }
                        >
                          <p
                            className={
                              "text-capitalize m-0 ms-2 p-0" +
                              respStyle({ lg: "" }, mediaValues)
                            }
                          >
                            Série
                          </p>

                          <div className="d-none d-xl-flex gap-7">
                            {renderData}
                          </div>

                          <div
                            className={
                              "ms-2 p-0 d-flex m-0 justify-content-end gap-2 " +
                              respStyle(
                                {
                                  base: "flex-fill",
                                  xl: "(replace)",
                                },
                                mediaValues
                              ) +
                              (post?.currentSerie === index
                                ? " "
                                : " d-none opacity-0 pe-none ")
                            }
                          >
                            {renderButtons}
                          </div>

                          {exerciseDoneItem && (
                            <Image
                              className="ms-2"
                              style={{ width: 30, height: 30 }}
                              src={check}
                            ></Image>
                          )}
                        </div>

                        <div className="d-flex justify-content-between ms-1 mt-1 d-xl-none">
                          {renderData}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </Row>

        <div className="mt-2"></div>

        {exercise?.hasFeedback && exercise?.feedback && (
          <ResultFinalCard
            resultFinal={{ v2: exercise?.feedback }}
          ></ResultFinalCard>
        )}

        {restTimerCount && (
          <TimerModal
            timeSeconds={restTimerCount}
            onFinish={() => controller.setRestTimerCount(undefined)}
          ></TimerModal>
        )}

        {exercisePost && currentSheetItem && (
          <Modal
            show={showExecuteModal}
            onHide={() => controller.setShowExecuteModal(false)}
          >
            <SheetItemExecution
              repetitionsValue={currentSheetItem?.repetitions}
              weigthValue={currentSheetItem?.weight}
              exercisePost={exercisePost}
              onFinish={() => controller.setShowExecuteModal(false)}
            ></SheetItemExecution>
          </Modal>
        )}
        {post && currentPatientWorkout && (
          <Modal
            show={showFinishWorkout}
            size="lg"
            onHide={() => setShowFinishWorkout(false)}
          >
            <FinishWorkoutCard
              patientWorkout={currentPatientWorkout}
              workout={workout}
              post={post}
              onClose={() => setShowFinishWorkout(false)}
            ></FinishWorkoutCard>
          </Modal>
        )}
      </Col>
    </div>
  );
}
