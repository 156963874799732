const validationRegex = {
  name: /^[A-Za-zÀ-ÖØ-öø-ÿ-\s]{2,}$/,
  /* Nome:
    Aceita letras maiúsculas e minúsculas, espaços e hífens.
    Deve ter no mínimo 2 caracteres.
     */
  phone: /^(\(\d{2}\)\s?)?\d{4,5}-\d{4}$/,
  /*
    Telefone: Aceita números com ou sem DDD.
    Exemplos válidos: (11) 99999-9999, 9999-9999, 999999999.
    */

  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  // Email: Segue o padrão básico de validação de email.

  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
  /* Senha:
    Pelo menos 8 caracteres, com pelo menos uma letra maiúscula,
    uma letra minúscula e um número.
     */
};

const passwordIsInvalid = (pass, confirmPass) => {
  if (!pass && !confirmPass) return false;
  if (!pass && confirmPass) return true;
  if (pass !== confirmPass) return true;
  if (pass === confirmPass) return false;
};

export default validationRegex;

export {passwordIsInvalid};