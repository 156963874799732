import React, { useEffect, useState } from "react";
import { Modal, Form, Image, Alert } from "react-bootstrap";
import { ModalHeader } from "../../../components/ModalHeader";
import { SearchPatients } from "../../../components/v2/SearchPatients";
import { FINANCE_PLANS } from "../../../constants/plan.consts";

import DateField from "../../../components/v2/Fields/DateField";
import { formatToMoneyString, onMoneyInput } from "../../../utils/mask.utils";
import { useFinanceMutation } from "../../../mutations/finance.mutation";
import { dateToStringApiFormat, stringToDate } from "../../../utils/date";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useReadPatientDetailsQuery } from "../../../queries";
import upSvg from "../../../assets/v2/icons/up.svg";
import downSvg from "../../../assets/v2/icons/down.svg";

export function CreateFinanceModal({
  onClose = () => {},
  initialFinance,
  initialVinculed,
}) {
  const isEditMode = !!initialFinance;

  const [formData, setFormData] = useState({
    category: initialFinance?.category ?? "0",
    vinculed: initialVinculed ?? false,
    name: initialFinance?.name ?? "",
    plansPatient_id: initialFinance?.plansPatient?._id ?? undefined,
    value: initialFinance?.value ? Math.abs(initialFinance.value) : null,
    date: initialFinance?.date
      ? new Date(stringToDate(initialFinance.date))
      : new Date(),
    dateInitial: initialFinance?.dateInitial
      ? new Date(stringToDate(initialFinance.dateInitial))
      : undefined,
    note: initialFinance?.note ?? "",
  });

  const monthCount = FINANCE_PLANS[formData?.plansPatient_id]?.monthCount ?? 0;

  const [patient, setPatient] = useState();

  const { patientDetails } = useReadPatientDetailsQuery({
    params: { id: initialFinance?.pacient?.id },
    enabled: !!initialFinance?.pacient?.id,
  });

  const queryClient = useQueryClient();

  const { createFinance, updateFinance } = useFinanceMutation();

  const setInputData = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const onMoneyFinanceInput = (e) => {
    const realValue = onMoneyInput(e);

    setInputData("value", realValue);
  };

  const getFinalDate = (date, monthCount) => {
    let dateFinal = new Date(date);

    dateFinal.setMonth(dateFinal.getMonth() + monthCount);

    return dateFinal;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.vinculed && !patient?.id) {
      return toast("Selecione um aluno!", {
        type: "error",
        position: "top-center",
      });
    }

    if (formData.value <= 0) {
      return;
    }

    const { vinculed, ...data } = formData;

    data.dateFinal = data.dateInitial
      ? getFinalDate(data.dateInitial, monthCount)
      : undefined;

    data.patient_id = patient?.id;
    data.plansPatient_id = data.plansPatient_id;

    data.dateFinal = data.dateFinal
      ? dateToStringApiFormat(data.dateFinal)
      : undefined;

    data.dateInitial = data.dateInitial
      ? dateToStringApiFormat(data.dateInitial)
      : undefined;

    data.date = dateToStringApiFormat(data.date);

    data.category = vinculed ? 0 : Number(data.category);

    if (isEditMode) {
      data.id = initialFinance?._id;
      await updateFinance.mutateAsync(data);

      await queryClient.refetchQueries({
        queryKey: ["read-patient-finances-details-query"],
      });
    } else {
      await createFinance.mutateAsync(data);
    }
    await queryClient.refetchQueries({
      queryKey: ["read-all-vinculed-finance-query"],
    });
    await queryClient.refetchQueries({
      queryKey: ["read-all-finances-query"],
    });

    toast(`Lançamento ${isEditMode ? "atualizado" : "criado"} com sucesso`, {
      position: "top-center",
      type: "success",
    });

    onClose();
  };

  useEffect(() => {
    if (patientDetails) {
      const { paciente } = patientDetails;
      setPatient(paciente);
    }
  }, [patientDetails]);

  useEffect(() => {
    if (!formData?.vinculed) {
      setFormData((prev) => ({
        ...prev,
        plansPatient_id: undefined,
        dateInitial: undefined,
      }));
    }
  }, [formData?.vinculed]);

  useEffect(() => {
    if (patient?.plansPatient) {
      setFormData((prev) => ({
        ...prev,
        plansPatient_id: isEditMode
          ? prev.plansPatient_id
          : patient?.plansPatient?._id,
        vinculed: isEditMode ? prev.vinculed : true,
      }));
    }

    if (patient?.charge?.initialDate) {
      setFormData((prev) => ({ ...prev, vinculed: false }));
    }
  }, [patient]);

  return (
    <Modal show={true} onHide={onClose}>
      <ModalHeader
        title={(isEditMode ? "Editar" : "Novo") + " Lançamento"}
        onClose={onClose}
      ></ModalHeader>

      <Form onSubmit={handleSubmit}>
        <Modal.Body className="d-flex flex-column">
          {patient?.charge?.initialDate && (
            <Alert variant="primary">
              Modo cobrança de aluno está ativa, permitindo lançamento de planos
              apenas pelo aluno.
            </Alert>
          )}

          <SearchPatients
            disabled={isEditMode && patient}
            customRequiredText={"Obrigatório no modo vinculado a plano"}
            required={formData?.vinculed && !isEditMode}
            handleResult={(p) => {
              setPatient(p);

              if (!isEditMode) {
                if (p) setInputData("category", "0");
                else setInputData("category", "1");
              }
            }}
            defaultSelectedName={patientDetails?.paciente?.name}
          ></SearchPatients>

          <Form.Group className="mt-2">
            <Form.Label className="m-0 ms-1">Modo</Form.Label>
            <Form.Control
              as="select"
              className="h-100 w-100"
              required
              disabled={isEditMode || !!patient?.charge?.initialDate}
              onChange={(e) => setInputData("vinculed", e.target.value === "0")}
              value={formData.vinculed ? "0" : "1"}
            >
              <option value={1}>Não vinculados a plano</option>
              <option value={0}>Vinculado a plano</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="m-0 ms-1">Entrada ou Saída</Form.Label>

            <div className="flex-center w-100">
              <Form.Check
                type={"radio"}
                checked={formData.category === "0" || formData.vinculed}
                onChange={(e) => setInputData("category", "0")}
                label={<Image className="m-2" src={upSvg} width={40}></Image>}
                style={{ display: "flex", alignItems: "center" }}
              />

              <div className="mx-5"></div>
              <Form.Check
                type={"radio"}
                checked={formData.category === "1" && !formData.vinculed}
                disabled={formData.vinculed}
                onChange={(e) => setInputData("category", "1")}
                label={<Image className="m-2" src={downSvg} width={40}></Image>}
                style={{ display: "flex", alignItems: "center" }}
              />
            </div>
          </Form.Group>

          {formData.vinculed && (
            <Form.Group className="mt-2">
              <Form.Label className="m-0 ms-1">Tipo de Plano</Form.Label>
              <Form.Control
                as="select"
                className="h-100 w-100"
                required
                onChange={(e) =>
                  setInputData("plansPatient_id", e.target.value)
                }
                value={formData.plansPatient_id}
              >
                {Object.keys(FINANCE_PLANS).map((planId) => (
                  <option key={planId} value={planId}>
                    {FINANCE_PLANS[planId].name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          {!formData.vinculed && (
            <Form.Group className="mt-2">
              <Form.Label className="m-0 ms-1">Nome</Form.Label>
              <Form.Control
                type="text"
                className="h-100 w-100"
                required
                onChange={(e) => setInputData("name", e.target.value)}
                value={formData.name}
              ></Form.Control>
            </Form.Group>
          )}

          <Form.Group className="mt-2">
            <Form.Label className="m-0 ms-1">Valor</Form.Label>
            <Form.Control
              type="text"
              className="h-100 w-100"
              required
              onChange={onMoneyFinanceInput}
              value={formatToMoneyString(formData.value)}
              placeholder="R$ 0,00"
            ></Form.Control>

            {formData.value === 0 && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                O valor não pode ser zero.
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <div className="mt-2">
            <Form.Label className="m-0 ms-1">Data de Pagamento</Form.Label>
            <DateField
              setDate={(d) => setInputData("date", d)}
              currentDate={formData.date}
            ></DateField>
          </div>

          {formData.vinculed && (
            <div className="mt-2">
              <Form.Label className="m-0 ms-1">Data Inicial</Form.Label>
              <DateField
                setDate={(d) => setInputData("dateInitial", d)}
                currentDate={formData.dateInitial}
              ></DateField>
            </div>
          )}

          {formData.plansPatient_id !== "1" && formData.dateInitial && (
            <div className="mt-2">
              <Form.Label className="m-0 ms-1">Data Final</Form.Label>
              <DateField
                disabled={true}
                setDate={(d) => {}}
                currentDate={getFinalDate(formData.dateInitial, monthCount)}
              ></DateField>
            </div>
          )}

          <Form.Group className="mt-2">
            <Form.Label className="m-0 ms-1">Descrição</Form.Label>
            <Form.Control
              as={"textarea"}
              className="h-100 w-100"
              onChange={(e) => setInputData("note", e.target.value)}
              value={formData.note}
            ></Form.Control>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary" type="submit">
            Salvar
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
