import { useState } from "react";
import { QuestionForm } from "../screens/v2/PacientDetailsV2/Evaluations/QuestionForm";
import { QuestionInput } from "../screens/v2/PacientDetailsV2/Evaluations/QuestionInput";
import { Button, Image } from "react-bootstrap";
import trash from "../assets/v2/icons/trash.svg";
import { useEvaluationMutation } from "../mutations/evaluation.mutation";

export function useQuestionFormController() {
  const [questionForm, setQuestionForm] = useState();
  const [questions, setQuestions] = useState([]);

  const addQuestion = ({ question_name, type, id, choices }) => {
    setQuestions([...questions, { question_name, type, id, choices }]);
  };

  const handleDeleteClick = (question) => {
    setQuestions(questions.filter((q) => q.id !== question.id));
  };

  const sendCreateQuestions = async (
    { modelId },
    createQuestionMutation,
    createChoiceMutation
  ) => {
    for (let i = 0; i < questions.length; i++) {
      const questionReponse = await createQuestionMutation.mutateAsync({
        modelId,
        ...questions[i],
      });

      for (let j = 0; j < questions[i].choices.length; j++) {
        const choice = questions[i].choices[j];

        await createChoiceMutation.mutateAsync({
          number_order: choice.number_order,
          choice_name: choice.choice_name,
          questionId: questionReponse.data.id,
        });
      }
    }
  };

  const questionsRender = questions.map((q) => (
    <div key={q.id} className="flex-between align-items-end my-4">
      <QuestionInput
        name={q.question_name}
        question={q}
        value={undefined}
        className="flex-grow-1"
        readOnly={true}
      ></QuestionInput>

      <Image
        onClick={(e) => handleDeleteClick(q)}
        width={35}
        className="p-1 pointer ms-3"
        src={trash}
      ></Image>
    </div>
  ));

  const questionFormRender = questionForm && (
    <div className="mt-4">
      <QuestionForm
        onSubmit={(d) => {
          addQuestion(d);
          setQuestionForm(undefined);
        }}
        id={questionForm.id}
      ></QuestionForm>
    </div>
  );

  const addQuestionButtonRender = !questionForm && (
    <div className="flex-center flex-column mt-3">
      {/* <p className="mb-1 text-primary">Adicionar Questão</p> */}
      <Button
        className="rounded-circle flex-center "
        style={{ width: 45, height: 45 }}
        onClick={() => setQuestionForm({ id: questions.length })}
      >
        <img alt="adicionar" src="assets/v2/icons/plus.svg" />
      </Button>
    </div>
  );

  return {
    questionFormRender,
    addQuestionButtonRender,
    questions,
    questionsRender,
    sendCreateQuestions,
  };
}
