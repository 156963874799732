import React from "react";
import { Card, Row, Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import chevrons from "../../assets/v2/icons/chevrons-double-down.svg";
import trash from "../../assets/v2/icons/trash.svg";
import { useSelector } from "react-redux";
import axios from "../../services/axios";
import send from "../../assets/v2/icons/send.svg";

import "../../assets/v2/box-shadow.css";

import { calculateAge, formatDate } from "../../utils/date";

function PacientCardV2({
  user,
  isLoading,
  link,
  hasDate,
  hasDelete,
  hasMsg,
  fullName = false,
  defaultDelete = true,
  onDelete = () => {},
  onClick = () => {},
  variant = "primary",
}) {
  const userToken = useSelector((state) => state.userLogin.userInfo.token);

  const handleDeleteClick = async () => {
    if (defaultDelete) {
      const { data } = await axios.delete(
        `/api/workouts/pacient/delete/${user.sheetId}/`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
    }

    onDelete();
  };

  const handleClick = (e) => {
    if (!link) e.preventDefault();
    onClick(user);
  };

  return (
    <div
      style={{}}
      className="d-flex flex-row ms-0 me-0 pe-3 align-items-center boxShadow1 position-relative"
    >
      <Link to={link} onClick={handleClick}>
        <Image
          src={
            user?.profileImage?.image ??
            "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
          }
          style={{
            width: variant === "primary" ? 200 : 100,
            height: variant === "primary" ? 200 : 100,
            marginRight: 5,
            objectFit: "cover",
          }}
        ></Image>
      </Link>

      <div className="d-flex flex-column ms-2 w-100">
        {variant === "primary" ? (
          <Link
            to={link}
            onClick={handleClick}
            className="text-dark text-decoration-none"
          >
            <div className="d-flex flex-column ">
              <div className="row d-flex flex-row mb-3 align-items-center">
                <p className="h3 m-0 col-5 text-break">
                  {fullName ? user?.name : user?.name?.substring(0, 10)}
                </p>

                <p className="m-0 h5 col-2">
                  {user.birthDate ? calculateAge(user.birthDate) + " anos" : ""}
                </p>
              </div>

              <div className="row d-flex flex-row mb-3 align-items-center">
                <p className="m-0 col-5 h5 text-break">{user.email}</p>

                <p className="m-0 h5 col-2">
                  {user?.plansPatient?.name_type ?? "nenhum plano"}
                </p>

                <p className="m-0 h7 col-5 d-flex justify-content-end">
                  criado em: {formatDate(user.createdAt)}
                </p>
              </div>
            </div>
          </Link>
        ) : (
          <Link
            to={link}
            onClick={handleClick}
            className="text-dark text-decoration-none"
          >
            <Card.Title className="">
              {fullName ? user?.name : user?.name?.substring(0, 10)}
            </Card.Title>
          </Link>
        )}

        {user.bio && <p>{user.bio}</p>}

        {hasDate && (
          <p>{`${format(user.initialDate, "dd/MM")} - ${format(
            user.finalDate,
            "dd/MM"
          )}`}</p>
        )}
      </div>

      {/* {hasMsg && (
        <Card.Link className="btn btn-primary fw-bold ms-auto" href="">
          <div className="d-none d-md-flex">Enviar Mensagem</div>
          <div className="d-md-none">
            <Image
              style={{ width: 20, height: 20 }}
              src={send}
              onClick={() => {}}
            ></Image>
          </div>
        </Card.Link>
      )} */}

      {hasDelete && (
        <Image
          className="ms-4 pointer ms-auto"
          src={trash}
          onClick={() => handleDeleteClick()}
        />
      )}

      {isLoading && (
        <div className="position-absolute w-100 h-100 bg-black bg-opacity-75 flex-center">
          <Spinner animation="border" role="status" variant="white"></Spinner>
        </div>
      )}
    </div>
  );
}

export default PacientCardV2;
