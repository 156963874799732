import { useReducer } from "react";
import validationRegex from "../../../utils/validations";

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_NAME":
      return {
        ...state,
        name: action.payload,
        errors: {
          ...state.errors,
        },
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
        errors: {
          ...state.errors,
        },
      };
    case "SET_ROLE":
      return {
        ...state,
        role: action.payload,
        errors: {
          ...state.errors,
        },
      };
    case "SET_PASSWORD":
      return {
        ...state,
        password: action.payload,
        errors: {
          ...state.errors,
        },
      };
    case "SET_CONFIRM_PASSWORD":
      return {
        ...state,
        re_password: action.payload,
        errors: {
          ...state.errors,
          re_password: passwordIsInvalid(state.password, action.payload),
        },
      };
    default:
      return state;
  }
};

const runRegexTest = (nomeCampo, payload) => {
  return payload.length ? !validationRegex[nomeCampo].test(payload) : false;
};

const useFormReducer = () => {
  const [state, dispatch] = useReducer(formReducer, {});
  return { state, dispatch };
};

const passwordIsInvalid = (pass, confirmPass) => {
  if (!pass && !confirmPass) return false;
  if (!pass && confirmPass) return true;
  if (pass !== confirmPass) return true;
  if (pass === confirmPass) return false;
};

export default useFormReducer;
