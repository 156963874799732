export const EXERCISE_LIST_REQUEST = 'EXERCISE_LIST_REQUEST'
export const EXERCISE_LIST_SUCCESS = 'EXERCISE_LIST_SUCCESS'
export const EXERCISE_LIST_FAIL = 'EXERCISE_LIST_FAIL'

export const MUSCLE_LIST_REQUEST = 'MUSCLE_LIST_REQUEST'
export const MUSCLE_LIST_SUCCESS = 'MUSCLE_LIST_SUCCESS'
export const MUSCLE_LIST_FAIL = 'MUSCLE_LIST_FAIL'

export const EXERCISE_DETAILS_REQUEST = 'EXERCISE_DETAILS_REQUEST'
export const EXERCISE_DETAILS_SUCCESS = 'EXERCISE_DETAILS_SUCCESS'
export const EXERCISE_DETAILS_FAIL = 'EXERCISE_DETAILS_FAIL'

export const EXERCISE_DELETE_REQUEST = 'EXERCISE_DELETE_REQUEST'
export const EXERCISE_DELETE_SUCCESS = 'EXERCISE_DELETE_SUCCESS'
export const EXERCISE_DELETE_FAIL = 'EXERCISE_DELETE_FAIL'
export const EXERCISE_DELETE_RESET = 'EXERCISE_DELETE_RESET'

export const EXERCISE_CREATE_REQUEST = 'EXERCISE_CREATE_REQUEST'
export const EXERCISE_CREATE_SUCCESS = 'EXERCISE_CREATE_SUCCESS'
export const EXERCISE_CREATE_FAIL = 'EXERCISE_CREATE_FAIL'
export const EXERCISE_CREATE_RESET = 'EXERCISE_CREATE_RESET'

export const EXERCISE_UPDATE_REQUEST = 'EXERCISE_UPDATE_REQUEST'
export const EXERCISE_UPDATE_SUCCESS = 'EXERCISE_UPDATE_SUCCESS'
export const EXERCISE_UPDATE_FAIL = 'EXERCISE_UPDATE_FAIL'
export const EXERCISE_UPDATE_RESET = 'EXERCISE_UPDATE_RESET'

export const EXERCISE_CREATE_REVIEW_REQUEST = 'EXERCISE_CREATE_REVIEW_REQUEST'
export const EXERCISE_CREATE_REVIEW_SUCCESS = 'EXERCISE_CREATE_REVIEW_SUCCESS'
export const EXERCISE_CREATE_REVIEW_FAIL = 'EXERCISE_CREATE_REVIEW_FAIL'
export const EXERCISE_CREATE_REVIEW_RESET = 'EXERCISE_CREATE_REVIEW_RESET'

export const EXERCISE_TOP_REQUEST = 'EXERCISE_TOP_REQUEST'
export const EXERCISE_TOP_SUCCESS = 'EXERCISE_TOP_SUCCESS'
export const EXERCISE_TOP_FAIL = 'EXERCISE_TOP_FAIL'

export const EXERCISE_SAVE_DATA = 'EXERCISE_SAVE_DATA'

export const EXERCISE_SAVE_INDICATIONS = 'EXERCISE_SAVE_INDICATIONS'

export const EXERCISE_SAVE_RESULTS = 'EXERCISE_SAVE_RESULTS'

export const EXERCISE_IMAGE_CREATE_REQUEST = 'EXERCISE_IMAGE_CREATE_REQUEST'
export const EXERCISE_IMAGE_CREATE_SUCCESS = 'EXERCISE_IMAGE_CREATE_SUCCESS'
export const EXERCISE_IMAGE_CREATE_FAIL = 'EXERCISE_IMAGE_CREATE_FAIL'

export const EXERCISE_VIDEO_CREATE_REQUEST = 'EXERCISE_VIDEO_CREATE_REQUEST'
export const EXERCISE_VIDEO_CREATE_SUCCESS = 'EXERCISE_VIDEO_CREATE_SUCCESS'
export const EXERCISE_VIDEO_CREATE_FAIL = 'EXERCISE_VIDEO_CREATE_FAIL'

export const EXERCISE_PATTER_LIST_REQUEST = 'EXERCISE_PATTER_LIST_REQUEST'
export const EXERCISE_PATTER_SUCCESS = 'EXERCISE_PATTER_SUCCESS'
export const EXERCISE_PATTER_FAIL = 'EXERCISE_PATTER_FAIL'

export const EXERCISE_RESET = 'EXERCISE_RESET'




