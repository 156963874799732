import axios from "../services/axios";

import { toast } from "react-toastify";

import {
  PACIENT_REGISTER_REQUEST,
  PACIENT_REGISTER_SUCCESS,
  PACIENT_REGISTER_FAIL,
  PACIENT_LIST_REQUEST,
  PACIENT_LIST_SUCCESS,
  PACIENT_LIST_FAIL,
  PACIENT_DETAILS_REQUEST,
  PACIENT_DETAILS_SUCCESS,
  PACIENT_DETAILS_FAIL,
  PACIENT_DELETE_REQUEST,
  PACIENT_DELETE_SUCCESS,
  PACIENT_DELETE_FAIL,
  PACIENT_UPDATE_PROFILE_REQUEST,
  PACIENT_UPDATE_PROFILE_SUCCESS,
  PACIENT_UPDATE_PROFILE_FAIL,
  PACIENT_UPDATE_PROFILE_RESET,
} from "../constants/pacientConstants";

export const listPacients =
  (page, search = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PACIENT_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        params: {
          page,
          search,
        },
      };

      const { data } = await axios.get(`/api/profiles/pacient/readall`, config);

      dispatch({
        type: PACIENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PACIENT_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const register = (pacient, history) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PACIENT_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      "/api/profiles/pacient/create/",
      pacient,
      config
    );

    dispatch({
      type: PACIENT_REGISTER_SUCCESS,
      payload: data,
    });

    localStorage.setItem("pacientInfo", JSON.stringify(data));

    history.push(`/home/personal/pacients/${data.id}`);
    dispatch(listPacients(1));
  } catch (error) {
    dispatch({
      type: PACIENT_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updatePacient = (pacient) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PACIENT_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/profiles/pacient/edit/${pacient.id}/`,
      pacient,
      config
    );

    dispatch({
      type: PACIENT_UPDATE_PROFILE_SUCCESS,
    });

    dispatch(detailsPacient(pacient.id));
  } catch (error) {
    dispatch({
      type: PACIENT_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const detailsPacient = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PACIENT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/profiles/pacient/read/${id}`,
      config
    );

    dispatch({
      type: PACIENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PACIENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deletePacient = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PACIENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `/api/profiles/pacient/delete/${id}/`,
      config
    );

    toast("Usuário deletado com sucesso!", {
      type: "success",
      position: "top-center",
    });

    dispatch({
      type: PACIENT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PACIENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
