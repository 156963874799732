import React, { useState, useRef } from "react";
import play from "../../assets/v2/icons/play.png";
import pause from "../../assets/v2/icons/pause.png";

const VideoPlayer = ({ source }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);

  const togglePlay = () => {
    if (videoRef.current) {
      let status =
        videoRef.current.currentTime > 0 &&
        !videoRef.current.paused &&
        !videoRef.current.ended &&
        videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

      if (!status) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <div
      className={`w-100 h-100 position-relative d-flex align-items-center justify-content-center overflow-hidden`}
      onClick={togglePlay}
    >
      <video
        ref={videoRef}
        src={source}
        type="video/mp4"
        className="w-100 h-100"
        controls
        style={{ maxHeight: "60vh", objectFit: "contain" }}
      >
        Seu navegador não suporta o elemento de vídeo.
      </video>
      {!isPlaying && (
        <div
          className="h-100 w-100 position-absolute bg-black bg-opacity-75 d-flex justify-content-center align-items-center"
          style={{ zIndex: 100 }}
        >
          <img className={`player-control`} src={!isPlaying ? play : pause} />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
