import React from "react";
import { toast } from "react-toastify";
import { cpf } from "cpf-cnpj-validator";
import { useState } from "react";
import { useRef } from "react";
import { STATES } from "../../../constants/countries";
import InputMask from "react-input-mask";
import { CoupomInput, CoupomInputResume } from "./CoupomInput";
import { PaymentMethod } from "./PaymentMethod";
import { Form, Col, Row, Card, Button } from "react-bootstrap";

export function PaymentForm({
  goBack: goBackParam = () => {},
  plan,
  onPayFinish,
  isMonthly,
  planPrice,
  planId,
  onConfirm = () => {},
  renderResume = true,
  renderCupomInput = true,
}) {
  const [paymentMethod, setPaymentMethod] = useState(undefined);
  const [paymentCheckMethod, setPaymentCheckMethod] = useState(undefined);
  const renderInfoInput = paymentMethod === undefined;

  const formRef = useRef();

  const [coupom, setCoupom] = useState();

  const newPrice =
    coupom && coupom.is_active && coupom.newPrice ? coupom.newPrice : null;
  const [errors, setErrors] = useState({ cpf: undefined });

  const [formData, setFormData] = useState({
    cpf: "",
    street_number: "",
    zip_code: "",
    city: "",
    state: "",
  });

  const changePaymentMethod = async (method) => {
    if (paymentCheckMethod === undefined) {
      toast("Escolha uma forma de pagamento!", {
        position: "top-center",
        type: "error",
      });

      return;
    }

    if (!cpf.isValid(formData.cpf)) {
      setErrors({ ...errors, cpf: true });
      return;
    }

    if (formRef.current.reportValidity()) {
      setPaymentMethod(method);
      setErrors({});
      onConfirm();
    }
  };

  const goBack = () => {
    setFormData({
      cpf: "",
      street_number: "",
      zip_code: "",
      city: "",
      state: undefined,
    });
    setErrors({});
    setPaymentMethod(undefined);
    goBackParam();
  };

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <PaymentMethod
        paymentMethod={paymentMethod}
        goBack={goBack}
        newPrice={newPrice}
        plan={plan}
        formData={formData}
        onPayFinish={onPayFinish}
        planId={planId}
        planPrice={planPrice}
        isMonthly={isMonthly}
        paymentCheckMethod={paymentCheckMethod}
        setPaymentCheckMethod={setPaymentCheckMethod}
      ></PaymentMethod>

      {renderInfoInput && (
        <Form ref={formRef} className="mt-3">
          <Row className="border-bottom-1 m-0 p-1">
            <Col className="col-12 p-0">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Endereço</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Número, rua, bairo"
                  name="street_number"
                  required={true}
                  value={formData.street_number}
                  onChange={updateFormData}
                />
              </Form.Group>
            </Col>

            <Col className="col-12 col-md-6 p-0">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Cidade</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex.: São Paulo"
                  name="city"
                  value={formData.city}
                  required={true}
                  onChange={updateFormData}
                />
              </Form.Group>
            </Col>

            <Col className="col-12 col-md-3 p-0 ps-1">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">CEP</Form.Label>
                <Form.Control
                  as={InputMask}
                  type="text"
                  placeholder="00000-000"
                  name="zip_code"
                  mask={"99999-999"}
                  value={formData.zip_code}
                  required={true}
                  onChange={updateFormData}
                />
              </Form.Group>
            </Col>

            <Col className="col-12 col-md-3 p-0 ps-1">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Estado</Form.Label>
                <Form.Control
                  as="select"
                  name="state"
                  required={true}
                  value={formData.state}
                  onChange={updateFormData}
                >
                  <option value=""></option>
                  {STATES.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <div className="col-12 d-flex flex-column flex-md-row p-0">
              <div className="d-flex flex-column col-12 col-md-6 p-0">
                <Form.Group className="col-12 mb-3 p-0">
                  <Form.Label className="fw-bold">CPF</Form.Label>
                  <Form.Control
                    as={InputMask}
                    type="text"
                    placeholder="000.000.000-00"
                    name="cpf"
                    mask={"999.999.999-99"}
                    required={true}
                    value={formData["cpf"]}
                    onChange={updateFormData}
                  />

                  {errors.cpf && (
                    <Form.Control.Feedback type="invalid" className="d-flex">
                      CPF Inválido!
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {renderResume && (
                  <CoupomInput
                    isMonthly={isMonthly}
                    newPrice={newPrice}
                    plan={plan}
                    planId={planId}
                    planPrice={planPrice}
                    onCoupomFetch={setCoupom}
                  ></CoupomInput>
                )}
              </div>

              {!renderResume && renderCupomInput && (
                <div className="w-100 ms-md-1">
                  <CoupomInput
                    isMonthly={isMonthly}
                    newPrice={newPrice}
                    plan={plan}
                    planId={planId}
                    planPrice={planPrice}
                    onCoupomFetch={setCoupom}
                  ></CoupomInput>
                </div>
              )}

              {renderResume && (
                <div className="col-12 col-md-6 mt-md-4 pt-2 ms-md-1 pb-3">
                  <CoupomInputResume
                    coupom={coupom}
                    newPrice={newPrice}
                    planPrice={planPrice}
                    isMonthly={isMonthly}
                    plan={plan}
                  ></CoupomInputResume>
                </div>
              )}
            </div>
          </Row>

          <div className="d-flex justify-content-end mt-2">
            <Button className="me-2" onClick={() => goBack()}>
              Cancelar
            </Button>

            <Button onClick={() => changePaymentMethod(paymentCheckMethod)}>
              Confirmar
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
}
