import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/v2/icons/logo.svg";
import phone from "../../assets/v2/icons/phone.svg";
import printer from "../../assets/v2/icons/printer.svg";
import linkedin from "../../assets/v2/icons/linkedin.svg";
import youtube from "../../assets/v2/icons/youtube.svg";
import instagram from "../../assets/v2/icons/instagram.svg";
import google from "../../assets/v2/icons/Google.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { respStyle } from "../../utils/respStyle";

function FooterV2() {
  const { t } = useTranslation();
  const mediaValues = useMediaQueryValues();

  return (
    <footer className="px-3 p-md-0">
      <div className="container border-primary border-3 border-top">
        <div className="row py-5 d-flex position-relative ">
          <div className="col-md-6">
            <div className="logo d-flex justify-content-center d-md-block mt-0">
              <img src={logo} alt="" className="w-350" />
            </div>
          </div>
          <div
            className={
              "col-auto position-relative" +
              respStyle({ md: "position-absolute" }, mediaValues)
            }
            style={{
              left: "calc(50% - 120px)",
            }}
          >
            <div className="contact-info mt-3 mt-md-0">
              <a
                href="mailto:"
                className="text-primary text-decoration-none fw-bold"
              >
                contato@exercitium.com.br
              </a>
            </div>
            <div className="social-networks">
              <ul className="list-unstyled list-group list-group-horizontal d-flex flex-md-row justify-content-center justify-content-md-start">
                <li className="text-primary me-4">
                  <p className="mb-0 d-none d-md-block">{t("socials")}</p>
                </li>
                <li className="me-4">
                  <a
                    href="https://www.linkedin.com/company/exercitium/"
                    target="_blank"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                {/* <li className="me-4">
                  <a href="#" target="_blank">
                    <img src={youtube} alt="" />
                  </a>
                </li> */}
                <li className="me-4">
                  <a
                    href="https://www.instagram.com/exer.citium?igsh=eGJ0dmdqM3l6N2d1"
                    target="_blank"
                  >
                    <img src={instagram} alt="" />
                  </a>
                </li>
                {/* <li className="me-4">
                  <a href="#" target="_blank">
                    <img src={google} alt="" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <nav>
          <ul className="d-none d-md-flex list-unstyled list-group list-group-horizontal justify-content-center border-top py-4">
            <li className="mx-3">
              <Link
                to={"#banner"}
                className="text-primary text-uppercase text-decoration-none"
              >
                {t("about")}
              </Link>
            </li>
            <li className="mx-3">
              <Link
                to="#benefits"
                className="text-primary text-uppercase text-decoration-none"
              >
                {t("benefits")}
              </Link>
            </li>
            <li className="mx-3">
              <Link
                to="#users"
                className="text-primary text-uppercase text-decoration-none"
              >
                {t("users")}
              </Link>
            </li>
            <li className="mx-3">
              <Link
                to="#plans"
                className="text-primary text-uppercase text-decoration-none"
              >
                {t("plans")}
              </Link>
            </li>
            {/* <li className="mx-3">
              <a
                href="#/#reviews"
                className="text-primary text-uppercase text-decoration-none"
              >
                {t("Reviews")}
              </a>
            </li> */}
          </ul>
        </nav>
        <p className="text-center text-secondary">Copyright © 2023 </p>

        <div style={{ height: 10 }}> </div>
      </div>
    </footer>
  );
}

export default FooterV2;
