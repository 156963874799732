import React, { useState, useEffect } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { usePaymentMutation } from "../../../mutations";
import { useFormInput } from "../../../hooks/useFormInput";
import creditCard from "../../../assets/v2/img/credit-card.png";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { parseBrazilianPhoneNumber } from "../../../utils/phone.utils";
import { useRef } from "react";
import { SpinnerButton } from "../../../components/v2/SpinnerButton";

export function CreditCardPurchase({
  planId,
  description,
  planPrice,
  onPayFinish,
  customer,
  goBack,
  isMonthly,
}) {
  const { createPayment } = usePaymentMutation();

  const isLoading = createPayment.isPending;

  const [returnDataValue, setReturnDataValue] = useState(false);

  const sectionRef = useRef();
  const expDate = {
    date: useFormInput(""),
  };

  //   (1, 'Crédito - Recorrencia mensal'),
  // (2, 'Crédito - Pagamento anual'),
  const data = {
    paymentsTypes_id: isMonthly ? 1 : 2,
    plan_id: planId,
    customer: customer,
    items: {
      price: planPrice,
      description: "plano " + planId,
      quantity: 1,
    },
    payments: {
      payment_method: useFormInput("credit_card", returnDataValue),
      number: useFormInput("", returnDataValue),
      holder_name: useFormInput("", returnDataValue),
      exp_month: parseInt(expDate.date.value.substring(0, 2)),
      exp_year: parseInt(expDate.date.value.substring(3, 6)),
      cvv: useFormInput("", returnDataValue),
    },
  };

  const handlerSubmit = (e) => {
    e.preventDefault();

    setReturnDataValue(true);
  };

  useEffect(() => {
    if (returnDataValue) {
      data.payments.number = data.payments.number.replace(/\D/g, "");

      createPayment
        .mutateAsync(data)
        .then((response) => {
          toast(response.data.detail, {
            type: "success",
            position: "top-center",
          });
        })
        .catch(() => {
          toast(
            "Erro ao realizar pagamento, verifique se as informações de pagamento estão corretas.",
            { type: "error", position: "top-center" }
          );
        })
        .finally(() => {
          setReturnDataValue(false);
          onPayFinish();
        });
    }
  }, [returnDataValue]);

  useEffect(() => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <Row
        ref={sectionRef}
        className="flex-row m-0 bg-body-secondary rounded p-2"
      >
        <p className="fw-500 mt-3 ps-1 d-flex flex-wrap justify-content-between align-items-center">
          Pagamento via Cartão de Crédito
          <img className="credit-card" src={creditCard} />
        </p>
        <Row className=" m-0 p-1">
          <Form.Group className="mb-3 p-0">
            <Form.Label className="fw-bold">Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome do titular"
              name="holder_name"
              {...data.payments.holder_name}
            />
          </Form.Group>

          <Form.Group className="mb-3 p-0" controlId="formEmail">
            <Form.Label className="fw-bold">Número do cartão</Form.Label>
            <Form.Control
              as={InputMask}
              type="text"
              placeholder="Digite o número do cartão"
              name="cardnumber"
              mask="9999 9999 9999 9999"
              {...data.payments.number}
            />
          </Form.Group>

          <Form.Group className="mb-3 p-0 col-6" controlId="formContact">
            <Form.Label className="fw-bold">Exp date</Form.Label>
            <Form.Control
              as={InputMask}
              type="text"
              placeholder="mm/yy"
              name="exp_date"
              mask={"99/99"}
              {...expDate.date}
            />
          </Form.Group>

          <Form.Group className="mb-3 p-0 col-6 ps-1" controlId="formContact">
            <Form.Label className="fw-bold">CVV</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o cvv"
              name="cardnumber"
              as={InputMask}
              mask={"999"}
              {...data.payments.cvv}
            />
          </Form.Group>
        </Row>
      </Row>

      {/* 
      <Row className="m-0 col-12 col-xl-6 mb-2">
       
      </Row> */}

      <div className="d-flex mb-0 justify-content-end mt-2">
        <Button className="me-2" disabled={isLoading} onClick={goBack}>
          Cancelar
        </Button>
        <Button
          type="submit"
          className="fw-bold"
          onClick={handlerSubmit}
          disabled={isLoading}
        >
          <SpinnerButton text="Pagar" isLoading={isLoading}></SpinnerButton>
        </Button>
      </div>
    </>
  );
}
