import React, { useState } from "react";
import { CloseButton, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { useRoutineMutation } from "../../../mutations";

import { SpinnerButton } from "../../../components/v2/SpinnerButton";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function CreateRoutineModal({
  onFinish = () => {},
  initialRoutine,
  intitalWorkouts = [],
}) {
  const { createRoutine, updateRoutine } = useRoutineMutation();

  const isEditMode = !!initialRoutine;

  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    name: isEditMode ? initialRoutine.name : "",
    tag: isEditMode ? initialRoutine.tag : "",
    description: isEditMode ? initialRoutine.description : "",
    workouts: intitalWorkouts,
  });

  const handleOnChange = (e, key) => {
    setFormData((prev) => {
      const value = { ...prev };
      value[key] = e.target.value;

      return value;
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (isEditMode) {
      await updateRoutine.mutateAsync({ id: initialRoutine?._id, ...formData });

      toast("Rotina atualizada com sucesso", {
        type: "success",
        position: "top-center",
      });
    } else {
      await createRoutine.mutateAsync(formData);
    }

    await queryClient.refetchQueries({
      queryKey: ["read-all-routine-query"],
    });

    onFinish();
  };

  return (
    <Modal show={true}>
      <Modal.Header className="flex-between">
        <Modal.Title className="text-primary fw-500 fs-5">
          Nova Rotina
        </Modal.Title>

        <CloseButton
          className="btn text-danger fs-4 my-0 py-0"
          onClick={() => onFinish()}
        ></CloseButton>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Row>
            <Col className="col-12">
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label className="fw-500">Nome da Rotina</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ex.: Rotina de Superiores"
                  value={formData.name}
                  onChange={(e) => handleOnChange(e, "name")}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicTag">
                <Form.Label className="fw-500">Tag</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Selecione"
                  value={formData.tag}
                  onChange={(e) => handleOnChange(e, "tag")}
                  required
                >
                  <option>Selecionar...</option>
                  <option value={"emagrecimento"}>Emagrecimento</option>
                  <option value={"hipertrofia"}>Hipertrofia</option>
                  <option value={"resistência"}>Resistência</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label className="fw-500">Descrição</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Descrição"
                  value={formData.description}
                  onChange={(e) => handleOnChange(e, "description")}
                  required
                />
              </Form.Group>
              <Col className="col-12 mt-3">
                <Button
                  className="fw-500 float-end"
                  variant="primary"
                  type="submit"
                  disabled={createRoutine.isPending}
                >
                  {createRoutine.isPending ? (
                    <SpinnerButton></SpinnerButton>
                  ) : (
                    "Salvar"
                  )}
                </Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
