import React, { useRef, useState } from "react";
import { Button, Image, Modal, Row } from "react-bootstrap";

import userPng from "../../assets/v2/icons/user.png";
import AvatarEditor from "react-avatar-editor";
import maxPng from "../../assets/v2/icons/max-scale.svg";
import { useUserMutation } from "../../mutations";
import { SpinnerButton } from "../../components/v2/SpinnerButton";

export function UserImageProfileV3({
  userImage,
  handleInputImageChange,
  disabled = false,
}) {
  const inputRef = React.useRef(null);

  const imageSrc = userImage ?? userPng;
  const maxHeight = 190;

  const { uploadImage } = useUserMutation();

  const editor = useRef(null);

  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  const handleSaveClick = async () => {
    const canvas = editor.current.getImage();

    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );

    const randomString = generateRandomString(6);

    const file = new File([blob], randomString + ".png");

    const responseImage = await uploadImage.mutateAsync({
      file: file,
      name: randomString + ".png",
    });

    handleInputImageChange({
      id: responseImage.data._id,
      url: responseImage.data.image,
    });

    setInputImage(null);
  };

  const [inputImage, setInputImage] = useState();

  const [scale, setScale] = useState(1.5);

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  return (
    <div
      className="w-100 position-relative"
      style={{ maxHeight, minHeight: maxHeight }}
    >
      <Image
        onClick={() => inputRef.current.click()}
        style={{ maxHeight }}
        className="h-100 w-100 object-fit-contain"
        src={imageSrc}
      ></Image>

      <input
        onChange={() => {
          if (disabled) return;

          const file = inputRef.current.files[0];

          if (file) setInputImage(URL.createObjectURL(file));
        }}
        disabled={disabled}
        ref={inputRef}
        type="file"
        hidden
        multiple={false}
        accept=".png,.jpg,.jpeg"
      ></input>

      {!disabled && (
        <div className="pe-none position-absolute bottom-0 w-100 bg-black bg-opacity-50 d-flex p-3 justify-content-center align-items-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 26 25"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className=""
          >
            <g id="share">
              <path
                id="Vector"
                d="M4.25 12.4999V20.8333C4.25 21.3858 4.46949 21.9157 4.86019 22.3064C5.25089 22.6971 5.7808 22.9166 6.33333 22.9166H18.8333C19.3859 22.9166 19.9158 22.6971 20.3065 22.3064C20.6972 21.9157 20.9167 21.3858 20.9167 20.8333V12.4999M16.75 6.24992L12.5833 2.08325M12.5833 2.08325L8.41667 6.24992M12.5833 2.08325V15.6249"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <div className="ms-2 d-lg-flex text-center text-white pe-none">
            Carregar Foto
          </div>
        </div>
      )}

      <Modal show={inputImage} onHide={() => setInputImage(null)} centered>
        <div className="profile-body mx-auto">
          <Modal.Header className="border-0">
            <Modal.Title className="text-primary">Alterar foto</Modal.Title>
            <div className="title-action" onClick={() => setInputImage(null)}>
              <img alt="close icon" src="/assets/v2/icons/x-square.png" />
            </div>
          </Modal.Header>
          <Modal.Body className="modal-body d-flex flex-column m-0 p-2">
            <Row className="flex-center">
              <AvatarEditor
                ref={editor}
                image={inputImage}
                width={300}
                height={300}
                scale={scale}
                color={[55, 65, 81, 0.6]} // RGBA
                rotate={0}
                className="w-100"
                style={{ objectFit: "contain" }}
              />
              <Row className="d-flex mt-2 m-0 p-0 col-10 mt-4">
                <img src={maxPng} className="min-scale" />
                <input
                  className="custom-range mx-auto"
                  name="scale"
                  type="range"
                  onChange={handleScale}
                  // min={state.allowZoomOut ? '0.1' : '1'}
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
                <img src={maxPng} className="max-scale" />
              </Row>
            </Row>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button
              disabled={uploadImage.isPending}
              className="mx-auto btn-primary fw-bold"
              variant="secondary"
              onClick={handleSaveClick}
            >
              <SpinnerButton isLoading={uploadImage.isPending}></SpinnerButton>
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}
