import { useEffect, useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RegisterExerciseVideoV2 from "../../../components/v2/RegisterExerciseVideoV2";
import VideoPlayer from "../../../components/v2/VideoPlayer";
import {
  listExerciseDetails,
  deleteExercise,
  resetDeleteExercise,
  listMuscles,
  resetExercise,
} from "../../../actions/exerciseActions";
import trash from "../../../assets/v2/icons/trash2.svg";
import penIcon from "../../../assets/v2/icons/pen2.svg";

import { useUserInfo } from "../../../hooks/useUserInfo";
import { PROFESSIONAL_ROLE } from "../../../constants/rolesConstants";
import { toast } from "react-toastify";
import { ResultFinalCard } from "../../../components/ResultFinalCard";
import { useReadExerciseDetailsQuery } from "../../../queries";
import { ModalHeader } from "../../../components/ModalHeader";

function ExerciseDetailsV2({ handleModal, unsetModalBody }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: exerciseId } = useParams();

  const exerciseList = useSelector((state) => state.exerciseList);
  const { errorExerciseList, loadingExerciseList, exercises, page, pages } =
    exerciseList;

  const { exercise, readExerciseQuery } = useReadExerciseDetailsQuery({
    params: { id: exerciseId },
    enabled: !!exerciseId,
  });

  const { userRole } = useUserInfo();

  const exerciseDelete = useSelector((state) => state.exerciseDelete);
  const { deleteExerciseSuccess } = exerciseDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (deleteExerciseSuccess) {
      history.push("/home/personal/programs/");
      dispatch(resetDeleteExercise());

      toast("Exercício deletado com sucesso!", {
        type: "success",
        position: "top-center",
      });
    }
  }, [dispatch, deleteExerciseSuccess]);

  useEffect(() => {
    dispatch(listMuscles());
  }, [dispatch]);

  const deleteHandler = (e) => {
    e.preventDefault();
    dispatch(deleteExercise(exerciseId));
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    dispatch(resetExercise());
    setShowModal(true);
  };

  useEffect(() => {
    if (!showModal) {
      readExerciseQuery.refetch();
    }
  }, [showModal]);

  const [description, link] = (exercise?.description ?? "").split("https");
  const fullLink = link ? `https${link}` : undefined;

  return (
    <div className="h-100" style={{}}>
      <Col className="col-12">
        <div className="row m-0">
          <div className="col-11">
            <h3 className="">
              <button onClick={() => history.goBack()}>{"<"}</button>{" "}
              {exercise?.name}
            </h3>
          </div>
          {userRole === PROFESSIONAL_ROLE && (
            <Col className="col-1 d-flex justify-content-end align-items-center">
              <div
                style={{ cursor: "pointer" }}
                className="bg-primary p-2 rounded"
                onClick={(e) => handleEditClick(e)}
              >
                <img alt="preferências" src={penIcon} />
              </div>

              <div
                style={{ cursor: "pointer" }}
                className="bg-primary p-2 rounded ms-2 cursor-pointer"
                onClick={(e) => deleteHandler(e)}
              >
                <img alt="preferências" src={trash} />
              </div>
            </Col>
          )}
        </div>

        <Row className="position-relative mx-auto mt-2 d-flex">
          <VideoPlayer source={exercise?.exerciseVideo?.video} />
        </Row>

        <div
          className="w-100 mt-4 bg-primary rounded-top rounded-bottom"
          style={{ height: 3 }}
        ></div>

        <p className="mt-2 mb-0 w-100 text-wrap text-break">{description}</p>
        {fullLink && (
          <a href={fullLink} target="_blank" rel="noreferrer">
            {fullLink}
          </a>
        )}

        <div className="mb-2"></div>

        {exercise?.hasFeedback && exercise?.feedback?.socketFeedback && (
          <div className="col-12">
            <ResultFinalCard
              resultFinal={{ v2: { ...exercise?.feedback } }}
            ></ResultFinalCard>
          </div>
        )}

        {exercise && (
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            style={{ zIndex: 1000000 }}
          >
            <ModalHeader
              title={"Editar Exercício"}
              onClose={() => setShowModal(false)}
            ></ModalHeader>
            <div className="p-4 pt-2">
              <RegisterExerciseVideoV2
                exercise={exercise}
                onFinish={() => setShowModal(false)}
              />
            </div>
          </Modal>
        )}
      </Col>
    </div>
  );
}

export default ExerciseDetailsV2;
