import React, { useEffect, useRef, useState } from "react";
import { useUserInfo } from "../hooks/useUserInfo";
import { PATIENT_ROLE } from "../constants/rolesConstants";
import { useFeedbackStreamController } from "./feedback-stream-controller";
import { useExerciseMutation, useUserMutation } from "../mutations";

import { Button, Spinner, Form } from "react-bootstrap";
import { WebcamPlayer } from "../components/v2/WebcamPlayer";
import { getFilenameFromURL, getVideoDuration } from "../utils/file.utils";
import { extractImageFromVideo } from "../utils/image.utils";
import { useComputeExerciseInputVideo } from "../computer/useComputeExerciseInputVideo";
import { toast } from "react-toastify";

export function useExerciseInputVideoController({
  withFeedback,
  muscleName,
  handleSendClick,
  defaultVideo,
  sendButtonText = "Feito",
  formIsValid = true,
  professionalFeedback = {},
}) {
  const isFirstRun = useRef(true);

  // STATES
  const [withFeedbackValue, setWithFeedback] = useState(withFeedback);
  const [sendIsLoading, setIsSendLoading] = useState(false);
  const [videoToUpload, setVideoToUpload] = useState(undefined);
  const [showWebcam, setShowWebcam] = useState(false);
  const [webcamFeedbackResult, setWebcamFeedbackResult] = useState(undefined);

  const { userRole } = useUserInfo();
  const { uploadExerciseVideo, uploadImage } = useExerciseMutation();
  const feedbackStreamController = useFeedbackStreamController({
    forceNewSocket: true,
  });

  // COMPUTED
  const computed = useComputeExerciseInputVideo({
    feedbackStreamController,
    uploadExerciseVideoIsPending: uploadExerciseVideo.isPending,
    videoToUpload,
    webcamFeedbackResult,
    withFeedbackValue,
  });

  const {
    videoIsLoading,
    feedbackResultFinal,
    showUploadButton,
    isWaitingSocket,
  } = computed;

  // FUNCTIONS
  const handleResetFeedback = (e) => {
    e && e.preventDefault();
    setVideoToUpload(undefined);
    feedbackStreamController.resetResult();
    setWebcamFeedbackResult(undefined);
  };

  const onGetVideoHandler = async (event) => {
    let file;
    let src;

    if (event?.target?.files) {
      event.preventDefault();
      file = event.target.files[0];

      const duration = await getVideoDuration(file);

      console.log({ duration });

      if (duration.toFixed(0) > (withFeedback ? 40 : 60)) {
        return toast(
          "O vídeo deve ter até 40 segundos com IA e 60 segundos sem IA.",
          {
            position: "top-center",
            type: "error",
          }
        );
      }

      src = "upload";

      if (!feedbackStreamController.isSocketOpen)
        feedbackStreamController.connect();
    } else {
      file = event;
      src = "webcam";
    }

    if (file)
      setVideoToUpload({
        file: file,
        url: window?.URL?.createObjectURL(file),
        src,
      });

    setShowWebcam(false);
  };

  const processFeedback = async ({ link }) => {
    return new Promise((resolve, reject) => {
      if (userRole === PATIENT_ROLE) {
        console.log("COM FEEDBACK DE PROFISSIONAL");

        feedbackStreamController.uploadPatientVideo(
          {
            link,
            muscle: muscleName,
            ...professionalFeedback,
          },
          (resultFinal) => resolve(resultFinal)
        );
      } else {
        feedbackStreamController.uploadVideo(
          { link, muscle: muscleName },
          (resultFinal) => resolve(resultFinal)
        );
      }
    });
  };

  const handleProcessVideo = async (e) => {
    withFeedbackValue && e.preventDefault();

    console.log({ formIsValid });

    if (!formIsValid) return;

    const videoResponse = await uploadExerciseVideo.mutateAsync({
      file: videoToUpload.file,
    });

    const url = videoResponse?.data?.video;
    const id = videoResponse?.data?._id;

    const imageName = getFilenameFromURL(url)?.split(".")[0] + ".png";

    const imageFile = await extractImageFromVideo(
      videoToUpload.file,
      imageName
    );

    const imageResponse = await uploadImage.mutateAsync({
      file: imageFile,
      name: imageName,
    });

    const imageId = imageResponse?.data?._id;

    let feedback = null;

    if (withFeedbackValue && !feedbackResultFinal) {
      feedback = await processFeedback({ link: url });
    }

    const videoData = {
      url: url,
      _id: id,
      imageId,
      uploaded: true,
    };

    setVideoToUpload((prev) => ({
      ...prev,
      ...videoData,
    }));

    const sendFeedback = feedback ?? feedbackResultFinal;

    await handleButtonSend(
      null,
      { ...videoToUpload, ...videoData },
      sendFeedback
    );
  };

  const handleButtonSend = async (e, videoToUploadParam, feedback) => {
    setIsSendLoading(true);
    try {
      await handleSendClick(e, {
        videoToUpload: videoToUploadParam ?? videoToUpload,
        feedbackResultFinal: feedback ?? feedbackResultFinal,
        withFeedback: withFeedbackValue,
      });
    } catch (error) {
      throw error;
    } finally {
      setIsSendLoading(false);
    }
  };

  const handleFileInputClick = () => {
    handleResetFeedback();
  };

  const handleWithFeedbackChange = () => {
    if (withFeedbackValue) {
      if (defaultVideo && !videoToUpload) {
        processFeedback({ link: defaultVideo?.video });
      } else if (videoToUpload?.uploaded) {
        processFeedback({ link: videoToUpload.url });
      }
    }
  };

  // EFFECTS
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (withFeedbackValue && !feedbackStreamController.isSocketOpen)
      return feedbackStreamController.connect();

    handleWithFeedbackChange();
  }, [withFeedbackValue]);

  useEffect(() => {
    if (!feedbackStreamController.isSocketOpen) return;

    handleWithFeedbackChange();
  }, [feedbackStreamController.isSocketOpen]);

  // RENDERS
  const loader = (
    <div className="" style={{ transform: `translate(${0}px, ${2}px)` }}>
      <Spinner
        animation="border"
        role="status"
        style={{
          height: 20,
          width: 20,
        }}
      ></Spinner>
    </div>
  );

  const renderMainActionsButtons = !showWebcam && !videoToUpload?.url && (
    <div className="w-100 flex-between">
      <Button
        disabled={videoIsLoading || !muscleName}
        onClick={() => {
          handleResetFeedback();
          setShowWebcam(true);
        }}
      >
        Gravar
      </Button>

      <Button className="ms-2 pointer" disabled={videoIsLoading || !muscleName}>
        <Form.File id="formcheck-api-custom" custom>
          <Form.File.Input
            value={videoToUpload}
            onClick={() => handleFileInputClick()}
            type="file"
            onChange={onGetVideoHandler}
            accept=".mov,.mp4"
          />
          <Form.File.Label data-browse="Button text" className="pointer">
            Buscar
          </Form.File.Label>
        </Form.File>
      </Button>
    </div>
  );

  const renderActions = (
    <>
      {isWaitingSocket ? loader : renderMainActionsButtons}

      <div className="d-flex justify-content-between w-100">
        {videoToUpload?.file && (
          <Button
            disabled={videoIsLoading}
            className="align-self-start me-2"
            onClick={handleResetFeedback}
          >
            Cancelar
          </Button>
        )}

        <div>
          {showUploadButton && (
            <Button
              disabled={isWaitingSocket || videoIsLoading || !muscleName}
              onClick={handleProcessVideo}
              className="me-2"
              type="submit"
            >
              {!videoIsLoading &&
                (isWaitingSocket ? "Aguardando conexão..." : "Salvar")}

              {videoIsLoading && loader}
            </Button>
          )}

          {videoToUpload?.file && !showUploadButton && (
            <Button
              disabled={!videoToUpload?.uploaded || sendIsLoading}
              onClick={handleButtonSend}
              type="submit"
            >
              {sendIsLoading ? loader : sendButtonText}
            </Button>
          )}
        </div>
      </div>
    </>
  );

  const renderPlayer = (
    <WebcamPlayer
      muscleName={muscleName}
      onGetVideoHandler={onGetVideoHandler}
      previewUrl={videoToUpload?.url ?? defaultVideo?.video}
      setWebcamFeedbackResult={setWebcamFeedbackResult}
      showWebcam={showWebcam}
      withFeedback={withFeedbackValue}
      professionalFeedback={professionalFeedback}
    ></WebcamPlayer>
  );

  const renderFeedbackInputComp = (
    <Form.Group className="mb-3">
      <Form.Label className="fw-bold m-0">Análise De Movimento IA</Form.Label>
      <Form.Control
        disabled={videoIsLoading || videoToUpload || !muscleName}
        as="select"
        className="h-100 w-100 me-3"
        required
        onChange={(e) => setWithFeedback(e.target.value === "true")}
        value={withFeedbackValue}
      >
        <option value={true}>Sim</option>
        <option value={false}>Não</option>
      </Form.Control>
    </Form.Group>
  );

  const renderWithFeedbackInput = withFeedback && renderFeedbackInputComp;

  return {
    handleProcessVideo,
    setShowWebcam,
    onGetVideoHandler,
    setWebcamFeedbackResult,
    processFeedback,
    renderActions,
    feedbackStreamController,
    renderPlayer,
    videoIsLoading,
    renderWithFeedbackInput,
    renderFeedbackInputComp,
    withFeedbackValue,
    videoToUpload,
    ...computed,
  };
}
