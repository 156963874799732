import React from "react";
import { useReadAllRoutineQuery } from "../../../queries";

import { RoutineCard } from "./RoutineCard";

export function RoutineList({ selectedWorkouts = [] }) {
  const { allRoutines } = useReadAllRoutineQuery({});

  return (
    <div className="row m-0 p-0 col-12 mb-4 pointer">
      {allRoutines.map((routine) => (
        <div
          key={routine?._id}
          className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 p-0 pe-3"
        >
          <RoutineCard
            selectedWorkouts={selectedWorkouts}
            routine={routine}
          ></RoutineCard>
        </div>
      ))}
    </div>
  );
}
