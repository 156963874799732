import React, { useState } from "react";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import { useFormInput } from "../../hooks/useFormInput";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkout,
  updateWorkout,
  listMyWorkouts,
  listMyWorkoutsDetails,
} from "../../actions/workoutActions";

function RegisterWorkoutV2({ exercises, unsetModalBody, defaultWorkout }) {
  const dispatch = useDispatch();
  const workoutsCreate = useSelector((state) => state.workoutsCreate);

  const isEditMode = !!defaultWorkout;

  const workoutsSelector = useSelector((state) => state.workoutsList);

  const { loadingWorkoutList, workoutList, page, pages } = workoutsSelector;
  const { errorWorkoutCreate, loadingWorkoutCreate, workoutCreate } =
    workoutsCreate;

  const workout = {
    name: useFormInput(defaultWorkout?.name ?? ""),
    description: useFormInput(defaultWorkout?.description ?? ""),
    tag: useFormInput(defaultWorkout?.tag ?? ""),
    exercises: exercises ? exercises : [],
  };

  const [formData, setFormData] = useState({
    restTime: defaultWorkout?.restTime ?? 60,
    repetitions: defaultWorkout?.repetitions ?? 10,
    serie: defaultWorkout?.serie ?? 3,
  });

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (isEditMode) {
      dispatch(
        updateWorkout({
          _id: defaultWorkout._id,
          name: workout.name.value,
          description: workout.description.value,
          tag: workout.tag.value,
          isActive: defaultWorkout.isActive,
          isFavorite: defaultWorkout.isFavorite,
          workoutImage: defaultWorkout.workoutImage,
          exercises: [],
          ...formData,
        })
      );

      unsetModalBody();
    } else {
      dispatch(createWorkout({ ...workout, ...formData }));
    }

    if (!loadingWorkoutCreate) {
      unsetModalBody();
      dispatch(listMyWorkouts(1));
    }
  };

  return (
    <Form onSubmit={submitHandler}>
      <Row>
        <Col className="col-12">
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label className="fw-500">Nome do treino</Form.Label>
            <Form.Control
              type="text"
              placeholder="ex.: Treino de Superiores"
              {...workout.name}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTag">
            <Form.Label className="fw-500">Tag</Form.Label>
            <Form.Control
              as="select"
              placeholder="Selecione"
              {...workout.tag}
              required
            >
              <option>Selecionar...</option>
              <option>Emagrecimento</option>
              <option>Hipertrofia</option>
              <option>Resistência</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDescription">
            <Form.Label className="fw-500">Descrição</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Descrição"
              {...workout.description}
              required
            />
          </Form.Group>

          <div
            className="w-100 bg-black mb-3"
            style={{ height: 1, opacity: 0.2 }}
          ></div>

          <p className="mb-0 text-primary fw-500 fs-5 mb-3">Periodicidade</p>

          <div className="d-flex flex-row gap-5">
            <Form.Group className="mb-3 w-100" controlId="formBasicName">
              <Form.Label className="fw-500">Serie</Form.Label>
              <Form.Control
                type="number"
                name="serie"
                value={formData.serie}
                required
                onChange={updateFormData}
              />
            </Form.Group>

            <Form.Group className="mb-3 w-100" controlId="formBasicName">
              <Form.Label className="fw-500">Repetições</Form.Label>
              <Form.Control
                type="number"
                name="repetitions"
                value={formData.repetitions}
                required
                onChange={updateFormData}
              />
            </Form.Group>
          </div>

          <Form.Group className="mb-3 w-100" controlId="formBasicName">
            <Form.Label className="fw-500">Tempo de Descanço</Form.Label>
            <Form.Control
              type="number"
              name="restTime"
              value={formData.restTime}
              required
              onChange={updateFormData}
            />
          </Form.Group>

          <Col className="col-12 mt-3">
            <Button
              className="fw-500 float-end"
              variant="primary"
              type="submit"
            >
              Salvar
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  );
}

export default RegisterWorkoutV2;
