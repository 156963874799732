import { React, useState, forwardRef, useEffect, useMemo } from "react";
import { Form, Button, Row, Col, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SearchBarV2 from "../../components/v2/SearchBarV2";
import PacientProfileV2 from "../../screens/v2/PacientDetailsV2/PacientProfileV2";
import WorkoutSheetV2 from "./WorkoutSheetV2";

import DateField from "./Fields/DateField";

import "react-datepicker/dist/react-datepicker.css";
import { dateFormat } from "../../utils/date";

import {
  createWorksheet,
  updateWorksheet,
  listMyWorkoutSheets,
} from "../../actions/workoutSheetActions";
import { listMyWorkoutsDetails } from "../../actions/workoutActions";

import { SearchPatients } from "./SearchPatients";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { respStyle } from "../../utils/respStyle";
import { useWorkoutMutation } from "../../mutations";
import { toast } from "react-toastify";
import { daysMap } from "../../constants/days.consts";
import { usePrintPage } from "../../hooks/usePrintPage";
import { PrintButton } from "../PrintButton";
import { getWorkoutSheetPrint } from "../print/getWorkoutSheetPrint";
import download from "../../assets/v2/icons/download.png";
import { ModalHeader } from "../ModalHeader";
import { useUserInfo } from "../../hooks/useUserInfo";

function RegisterPacientWorkoutV2({
  row,
  workoutID,
  sheetRows = [],
  unsetModalBody,
  registeredPacientes = [],
  onDone = () => {},
  defaultPacient = null,
}) {
  const dispatch = useDispatch();

  const mediaValues = useMediaQueryValues();

  const { createWorkoutSheetMutation, editWorkoutSheetMutation } =
    useWorkoutMutation();
  const { isMD, isLG } = mediaValues;

  const isEditMode = !!defaultPacient;

  const pacientList = useSelector((state) => state.pacientList);
  const { loading, pacients, page, pages } = pacientList;

  const [pacient, setPacient] = useState(defaultPacient);
  const [days, setDays] = useState(defaultPacient?.sheet?.days ?? []);

  const { userDetails } = useUserInfo();

  const [startDate, setStartDate] = useState(
    defaultPacient?.initialDate ?? new Date()
  );
  const [endDate, setEndDate] = useState(
    defaultPacient?.finalDate ?? new Date()
  );
  const [error, setError] = useState();
  const workoutSheetList = useSelector((state) => state.workoutSheetList);

  const sheetRowsMemo = useMemo(() => {
    if (defaultPacient) {
      if (workoutSheetList.payload.ExerciciosFicha)
        return workoutSheetList.payload.ExerciciosFicha;
      return null;
    }
    return sheetRows;
  }, [defaultPacient, sheetRows, workoutSheetList.payload]);

  const [sheetState, setSheetState] = useState();

  const { document, printDocument } = usePrintPage({
    filename: "ficha-de-treino",
    title: "Treino - " + sheetRows[0]?.workoutSheet?.workout?.name,
    patient: defaultPacient,
    subtitle: "Ficha de Treino",
    bodyContent: getWorkoutSheetPrint({
      workoutSheet: sheetState ?? sheetRowsMemo ?? [],
      initialDate: defaultPacient?.initialDate,
      finalDate: defaultPacient?.finalDate,
    }),
    professional: userDetails,
  });

  const sheet = {
    workoutID: workoutID,
    initialDate: dateFormat(startDate, "yyyy-mm-dd"),
    finalDate: dateFormat(endDate, "yyyy-mm-dd"),
    days: days,
  };

  const validateForm = () => {
    if (startDate.getTime() > endDate.getTime()) {
      setError("Data de início não pode ser maior que a data de término");
      return false;
    }

    if (days.length === 0) {
      setError("Selecione pelo menos um dia da semana");
      return false;
    }

    return true;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    let response = null;

    if (isEditMode) {
      response = await editWorkoutSheetMutation.mutateAsync({
        patientSheetId: pacient.sheet._id,
        workoutSheetId: pacient.sheet.workoutSheet,
        sheet: {
          days,
          initialDate: dateFormat(startDate, "yyyy-mm-dd"),
          finalDate: dateFormat(endDate, "yyyy-mm-dd"),
          isSheetIndividual: true,
        },
      });
    } else {
      response = await createWorkoutSheetMutation.mutateAsync({
        pacientId: pacient.id,
        sheet,
      });
    }

    toast(response.data?.message?.detail, {
      type: "success",
      position: "top-center",
      autoClose: 9000,
    });

    dispatch(listMyWorkoutsDetails(workoutID));
    unsetModalBody();

    onDone();
  };

  const handleResult = (pacient) => {
    if (pacient) {
      setPacient(pacient);
    }
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Form.Control
      type="text"
      placeholder="00/00/0000"
      value={value}
      onClick={onClick}
      ref={ref}
      required
    />
  ));

  const onCheckboxChange = (value) => {
    if (days.includes(value)) {
      setDays(days.filter((day) => day !== value));
    } else {
      setDays([...days, value]);
    }
  };

  useEffect(() => {
    if (defaultPacient) {
      dispatch(listMyWorkoutSheets(defaultPacient.sheetId));
    }
  }, [defaultPacient]);

  return (
    <Modal show={true} size="lg" onHide={() => unsetModalBody()}>
      <ModalHeader
        onClose={() => unsetModalBody()}
        title={"Adicionar aluno / personalizar ficha de aluno"}
      >
        {isEditMode && (
          <div className="me-2">
            <PrintButton
              icon={download}
              onClick={() => printDocument()}
            ></PrintButton>
          </div>
        )}
      </ModalHeader>

      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Row>
            <Col className="col-12 mb-4">
              {!isEditMode && (
                <SearchPatients
                  excludeIds={registeredPacientes.map((p) => p.id)}
                  handleResult={handleResult}
                ></SearchPatients>
              )}

              {pacient && (
                <div className="m-md-3">
                  <Row>
                    <PacientProfileV2 withActions={false} pacient={pacient} />
                  </Row>
                  <Row className="mt-2">
                    <Col className="col-12">
                      <Form.Group className="mb-3" controlId="formBasicDay">
                        <Form.Label className="fw-500">
                          Dia do Treino
                        </Form.Label>
                        <Form.Control
                          required
                          as="fieldset"
                          onChange={(e) =>
                            onCheckboxChange(Number(e.target.value))
                          }
                          className="h-100"
                        >
                          <div className="d-flex flex-row flex-wrap mt-2">
                            {Object.keys(daysMap).map((k) => (
                              <div
                                key={k}
                                className={
                                  "w-50 mb-2" +
                                  respStyle({ lg: "w-auto" }, mediaValues)
                                }
                              >
                                <input
                                  id={k}
                                  type="checkbox"
                                  value={Number(k)}
                                  checked={days.includes(Number(k))}
                                  className="ms-3 me-1"
                                  name="days"
                                  readOnly
                                />
                                {daysMap[k]}
                              </div>
                            ))}
                          </div>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicAddress">
                        <Form.Label className="fw-500">Início</Form.Label>
                        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} customInput={<CustomInput />} /> */}
                        <DateField
                          currentDate={startDate}
                          setDate={setStartDate}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <Form.Group className="mb-3" controlId="formBasicGender">
                        <Form.Label className="fw-500">Fim</Form.Label>
                        {/* <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} customInput={<CustomInput />} /> */}
                        <DateField currentDate={endDate} setDate={setEndDate} />
                      </Form.Group>
                    </Col>
                  </Row>
                  {sheetRowsMemo && (
                    <WorkoutSheetV2
                      isPatientSheet={isEditMode}
                      sheetRows={sheetState ?? sheetRowsMemo}
                      onUpdate={(s) => setSheetState(s)}
                    />
                  )}

                  {error && (
                    <div class="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <Button
                    className="fw-500 mt-3 float-end"
                    variant="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          {document}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default RegisterPacientWorkoutV2;
