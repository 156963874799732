export const PHYSIO_EVALUATION_REGISTER_REQUEST = 'PHYSIO_EVALUATION_REGISTER_REQUEST'
export const PHYSIO_EVALUATION_REGISTER_SUCCESS = 'PHYSIO_EVALUATION_REGISTER_SUCCESS'
export const PHYSIO_EVALUATION_REGISTER_FAIL = 'PHYSIO_EVALUATION_REGISTER_FAIL'
export const PHYSIO_EVALUATION_REGISTER_RESET = 'PHYSIO_EVALUATION_REGISTER_RESET'

export const PHYSIO_EVALUATION_DETAILS_REQUEST = 'PHYSIO_EVALUATION_DETAILS_REQUEST'
export const PHYSIO_EVALUATION_DETAILS_SUCCESS = 'PHYSIO_EVALUATION_DETAILS_SUCCESS'
export const PHYSIO_EVALUATION_DETAILS_FAIL = 'PHYSIO_EVALUATION_DETAILS_FAIL'
export const PHYSIO_EVALUATION_DETAILS_RESET = 'PHYSIO_EVALUATION_DETAILS_RESET'

export const PHYSIO_EVALUATION_LIST_REQUEST = 'PHYSIO_EVALUATION_LIST_REQUEST'
export const PHYSIO_EVALUATION_LIST_SUCCESS = 'PHYSIO_EVALUATION_LIST_SUCCESS'
export const PHYSIO_EVALUATION_LIST_FAIL = 'PHYSIO_EVALUATION_LIST_FAIL'
export const PHYSIO_EVALUATION_LIST_RESET = 'PHYSIO_EVALUATION_LIST_RESET'

export const PHYSIO_EVALUATION_DELETE_REQUEST = 'PHYSIO_EVALUATION_DELETE_REQUEST'
export const PHYSIO_EVALUATION_DELETE_SUCCESS = 'PHYSIO_EVALUATION_DELETE_SUCCESS'
export const PHYSIO_EVALUATION_DELETE_FAIL = 'PHYSIO_EVALUATION_DELETE_FAIL'
export const PHYSIO_EVALUATION_DELETE_RESET = 'PHYSIO_EVALUATION_DELETE_RESET'

export const PHYSIO_EVALUATION_UPDATE_REQUEST = 'PHYSIO_EVALUATION_UPDATE_REQUEST'
export const PHYSIO_EVALUATION_UPDATE_SUCCESS = 'PHYSIO_EVALUATION_UPDATE_SUCCESS'
export const PHYSIO_EVALUATION_UPDATE_FAIL = 'PHYSIO_EVALUATION_UPDATE_FAIL'
export const PHYSIO_EVALUATION_UPDATE_RESET = 'PHYSIO_EVALUATION_UPDATE_RESET'