import {
  PHYSIO_EVALUATION_REGISTER_REQUEST,
  PHYSIO_EVALUATION_REGISTER_SUCCESS,
  PHYSIO_EVALUATION_REGISTER_FAIL,
  PHYSIO_EVALUATION_REGISTER_RESET,
  PHYSIO_EVALUATION_DETAILS_REQUEST,
  PHYSIO_EVALUATION_DETAILS_SUCCESS,
  PHYSIO_EVALUATION_DETAILS_FAIL,
  PHYSIO_EVALUATION_DETAILS_RESET,
  PHYSIO_EVALUATION_LIST_REQUEST,
  PHYSIO_EVALUATION_LIST_SUCCESS,
  PHYSIO_EVALUATION_LIST_FAIL,
  PHYSIO_EVALUATION_LIST_RESET,
  PHYSIO_EVALUATION_DELETE_REQUEST,
  PHYSIO_EVALUATION_DELETE_SUCCESS,
  PHYSIO_EVALUATION_DELETE_FAIL,
  PHYSIO_EVALUATION_DELETE_RESET,
  PHYSIO_EVALUATION_UPDATE_REQUEST,
  PHYSIO_EVALUATION_UPDATE_SUCCESS,
  PHYSIO_EVALUATION_UPDATE_FAIL,
  PHYSIO_EVALUATION_UPDATE_RESET,
} from "../constants/physioEvaluationConstants";

export const physioEvaluationDetailsReducer = (
  state = { physio_evaluation: null },
  action
) => {
  switch (action.type) {
    case PHYSIO_EVALUATION_DETAILS_REQUEST:
      console.log("reducer", PHYSIO_EVALUATION_DETAILS_REQUEST);
      return { loading: true, physio_evaluation: null };

    case PHYSIO_EVALUATION_DETAILS_SUCCESS:
      return {
        loading: false,
        physio_evaluation: action.payload,
      };

    case PHYSIO_EVALUATION_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const physioEvaluationListReducer = (
  state = { physio_evaluations: [] },
  action
) => {
  switch (action.type) {
    case PHYSIO_EVALUATION_LIST_REQUEST:
      return { loading: true, physio_evaluations: [] };

    case PHYSIO_EVALUATION_LIST_SUCCESS:
      return {
        loading: false,
        physio_evaluations: action.payload.data,
        page: action.payload.page,
        pages: action.payload.pages,
      };

    case PHYSIO_EVALUATION_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const physioEvaluationRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case PHYSIO_EVALUATION_REGISTER_REQUEST:
      return { loading: true };

    case PHYSIO_EVALUATION_REGISTER_SUCCESS:
      return { loading: false, physio_evaluation: action.payload };

    case PHYSIO_EVALUATION_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const physioEvaluationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PHYSIO_EVALUATION_UPDATE_REQUEST:
      return { loading: true };

    case PHYSIO_EVALUATION_UPDATE_SUCCESS:
      return { loading: false, physio_evaluation: action.payload };

    case PHYSIO_EVALUATION_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const physioEvaluationDeleteReducer = (
  state = { loading: false, payload: {} },
  action
) => {
  switch (action.type) {
    case PHYSIO_EVALUATION_DELETE_SUCCESS:
      return { loading: false, payload: action.payload };
    default:
      return state;
  }
};
