import React from "react";
import { Image, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import {
  useReadAllPatientFormModelQuery,
  useReadAllFilledPatientFormQuery,
} from "../../../../queries/patient-form.query";
import { useState } from "react";
import trash from "../../../../assets/v2/icons/trash.svg";

import { CreateFilledPatientForm } from "./CreateFilledPatientForm";
import { formatDate } from "../../../../utils/date";
import { ViewFilledPatientForm } from "./ViewFilledPatientForm";
import { usePatientFormMutation } from "../../../../mutations/patient-form.mutation";
import { useQueryClient } from "@tanstack/react-query";
import { useUserInfo } from "../../../../hooks/useUserInfo";
export function FilledPatientFormList({
  formModel,
  patient,
  onClose = () => {},
}) {
  const { allFilledPatientForms, readAllFilledPatientFormQuery } =
    useReadAllFilledPatientFormQuery({
      enabled: true,
      params: { patientId: patient?.id, formId: formModel.data._id },
    });

  const { isPatient, isProfessional } = useUserInfo();

  const { deleteFilledPatientFormMutation } = usePatientFormMutation();

  const [showCreateFilledEv, setShowCreateFilledEv] = useState(false);

  const [filledPatientForm, setFilledPatientForm] = useState();

  const queryClient = useQueryClient();

  const handleDeleteClick = async (filledEv) => {
    await deleteFilledPatientFormMutation.mutateAsync({
      filledPatientFormId: filledEv._id,
    });

    readAllFilledPatientFormQuery.refetch();

    queryClient.refetchQueries({
      queryKey: [useReadAllPatientFormModelQuery.key],
    });
  };

  return (
    <>
      <Modal size="lg" show={true} onHide={onClose}>
        <ModalHeader
          onClose={onClose}
          title={formModel.data.name}
        ></ModalHeader>

        <Modal.Body>
          <div className="flex-between align-items-center mb-3">
            <h6 className="mb-0">Formulários Preenchidos por este Aluno</h6>

            {isProfessional && (
              <div
                className="bg-primary p-2 rounded pointer"
                onClick={() => setShowCreateFilledEv(true)}
              >
                <img alt="adicionar" src="assets/v2/icons/plus.svg" />
              </div>
            )}
          </div>

          <div className="d-flex flex-column">
            {allFilledPatientForms.map((filledEv) => (
              <div
                key={filledEv._id}
                className="flex-between my-3 align-items-center"
              >
                <p
                  className="m-0 flex-grow-1 pointer"
                  onClick={() => setFilledPatientForm(filledEv)}
                >
                  {filledEv.name ?? "-"}
                </p>
                <p className="m-0 me-3">{formatDate(filledEv.createdAt)}</p>

                {isProfessional && (
                  <div
                    className="d-flex align-items-center pointer"
                    onClick={() => handleDeleteClick(filledEv)}
                  >
                    <Image
                      width={30}
                      className="p-1 pointer"
                      src={trash}
                    ></Image>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      {showCreateFilledEv && (
        <CreateFilledPatientForm
          patientId={patient.id}
          formModel={formModel}
          onClose={() => setShowCreateFilledEv(false)}
        ></CreateFilledPatientForm>
      )}

      {filledPatientForm && (
        <ViewFilledPatientForm
          patient={patient}
          onClose={() => setFilledPatientForm(undefined)}
          filledPatientForm={filledPatientForm}
        ></ViewFilledPatientForm>
      )}
    </>
  );
}
