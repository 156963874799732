import { Col, Image } from "react-bootstrap";
import profile from "../../assets/v2/pacient-profile-component.css";

import userPng from "../../assets/v2/icons/user.png";
import { useEffect, useState } from "react";
import { fetchImageWithHeaders } from "../../utils/image.utils";

export function PacientProfilePrint({
  pacient,
  withStatus = true,
  withBirth = true,
  withGender = true,
  withAddress = true,
  label = "",
}) {
  let url =
    pacient?.profileImage?.image ?? pacient?.userImage?.image ?? userPng;

  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    fetchImageWithHeaders(url)
      .then((value) => setImageUrl(value))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="row m-0">
      <style src={profile}></style>
      <Col className={`col-4`}>
        <div className={"h-100 me-1 d-flex align-items-start"}>
          <Image className="w-100" src={imageUrl}></Image>
        </div>
      </Col>
      <div className={`ppcv2-root col-8`}>
        <div className="d-flex flex-wrap justify-content-between align-items-center ppcv2-header">
          <h2 style={{ fontSize: "30px" }} className="w-100 mb-0">
            {label}
          </h2>
          <h3>{pacient?.name}</h3>
        </div>
        <div className="ppcv2-item-root">
          {withGender && (
            <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
              <img alt="pessoa" src="assets/v2/icons/person.svg" />
              <div>
                {pacient?.gender == "F" ? "Feminino" : ""}
                {pacient?.gender == "M" ? "Masculino" : ""}
                {pacient?.gender == "N" ? "Não declarado" : ""}
              </div>
            </div>
          )}
          {withBirth && (
            <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
              <img alt="pessoa" src="assets/v2/icons/cake.svg" />
              <div>{pacient?.birthDate?.split("-").reverse().join("/")}</div>
            </div>
          )}
          {withAddress && (
            <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
              <img alt="pessoa" src="assets/v2/icons/location.svg" />
              <div>{pacient?.address}</div>
            </div>
          )}
          <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
            <img alt="pessoa" src="assets/v2/icons/message.svg" />
            <div>{pacient?.email}</div>
          </div>
          <div className="ppcv2-item d-flex flex-row justify-content-start align-item-center">
            <img alt="pessoa" src="assets/v2/icons/phone.svg" />
            <div>{pacient?.phone}</div>
          </div>

          {withStatus && (
            <div className="ppcv2-item d-flex flex-row justify-content-start align-items-center">
              <img alt="pessoa" src="assets/v2/icons/person.svg" />
              <div>
                {pacient?.user ? (
                  "Ativo"
                ) : (
                  <span className="text-secondary">
                    Não possui conta na plataforma
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
