import { useQuery } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";
import { useMemo } from "react";

export function useReadExercisePostQuery({ enabled, params }) {
  const config = useApiConfig();

  const readExercisePostQuery = useQuery({
    queryKey: ["read-post-query", enabled, params],
    enabled,
    queryFn: () => {
      const { postId, exerciseId } = params;
      return api.get(
        `api/exercises_done/post/read/${postId}/${exerciseId}/`,
        config
      );
    },
  });

  const exercisePost = useMemo(() => {
    return readExercisePostQuery.data?.data;
  }, [readExercisePostQuery.data]);

  return { exercisePost, readExercisePostQuery };
}

export function useReadPendentQuery({ enabled, params }) {
  const config = useApiConfig();

  const readPendentQuery = useQuery({
    queryKey: ["read-pendent-query", enabled, params],
    enabled: enabled,
    refetchOnWindowFocus: true,
    queryFn: () => {
      return api.get(`api/exercises_done/post/pendent/read/`, config);
    },
  });

  const pendentPost = useMemo(() => {
    if (Array.isArray(readPendentQuery.data?.data))
      return readPendentQuery.data?.data[0];

    return readPendentQuery.data?.data?.data;
  }, [readPendentQuery.data]);

  const sheetPatient = useMemo(() => {
    return readPendentQuery.data?.data?.sheetPatient;
  }, [readPendentQuery.data]);

  return { readPendentQuery, pendentPost, sheetPatient };
}

export function useReadPostResumeQuery({ enabled, params }) {
  const config = useApiConfig();

  const readPostResumeQuery = useQuery({
    queryKey: ["read-post-resume", enabled, params],
    enabled,
    queryFn: () => {
      const { postId } = params;

      return api.get(`api/exercises_done/post/resume/read/${postId}/`, config);
    },
  });

  const [postResume, postQueue] = useMemo(() => {
    return [
      readPostResumeQuery.data?.data,
      readPostResumeQuery.data?.data.postExerciseDone ?? [],
    ];
  }, [readPostResumeQuery.data]);

  return { postResume, readPostResumeQuery, postQueue };
}
