import React, { useState } from "react";
import { FinanceLogItem } from "./FinanceLogItem";
import { CreateFinanceModal } from "./CreateFinanceModal";
import { useReadAllFinancesQuery } from "../../../queries";
import { Form, Image } from "react-bootstrap";

import financePng from "../../../assets/v2/icons/finance.png";

import { monthsMap } from "../../../constants/days.consts";
import "../../../assets/v2/financePlanSection.css";

export function FinanceLog({ className = "", style = {} }) {
  const [finance, setFinance] = useState();

  const [currentMonth, setCurrentMonth] = useState(undefined);

  const { allFinances, readAllFinanceQuery } = useReadAllFinancesQuery({
    params: { month: currentMonth },
  });

  const isLoading = readAllFinanceQuery.isFetching;

  return (
    <div
      style={{ ...style }}
      className={
        "d-flex flex-column col-12 p-0 bg-white p-3 ps-lg-5 rounded " +
        className
      }
    >
      <div className="d-flex">
        <Image src={financePng} width={55}></Image>

        <div className="ms-3">
          <p className="text-small mb-0">Valor Total</p>
          <p className="h4">
            R$ {allFinances.total.toFixed(2).toString().replace(".", ",")}
          </p>
        </div>
      </div>

      <div className="flex-between  my-3 align-items-center">
        <h4 className="text-primary mb-0">Extrato</h4>

        <Form.Group className="d-flex align-items-center">
          <Form.Label className="mb-0 me-2 text-primary fw-500">
            Mês:
          </Form.Label>
          <Form.Control
            as={"select"}
            value={currentMonth}
            disabled={isLoading}
            onChange={(e) => setCurrentMonth(e.target.value)}
          >
            <option key={-1} value={undefined} label={"Todos"}></option>
            {Object.keys(monthsMap).map((key) => (
              <option key={key} value={key} label={monthsMap[key]}></option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>

      <div
        style={{ overflowY: "scroll", flex: 1 }}
        className="custom-scrollbar"
      >
        {allFinances.data.map((finance) => (
          <div key={finance?._id} className="me-1">
            <FinanceLogItem
              finance={finance}
              onClick={() => setFinance(finance)}
            ></FinanceLogItem>
          </div>
        ))}
      </div>

      {finance && (
        <CreateFinanceModal
          onClose={() => setFinance(undefined)}
          initialFinance={finance}
          initialVinculed={!!finance?.plansPatient}
        ></CreateFinanceModal>
      )}
    </div>
  );
}
