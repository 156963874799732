import React, { useEffect } from "react";
import { CloseButton, Form, Image } from "react-bootstrap";

import { readFileAsDataURL } from "../../../../utils/file.utils";

import imageJpg from "../../../../assets/v2/icons/image.jpg";
import xSquarePng from "../../../../assets/v2/icons/x-square.png";

export const QuestionInput = ({
  question,
  name,
  onChange = (e, value) => {},
  value,
  className = "",
  readOnly = false,
  answer,
}) => {
  const handleOnChange = (e) => {
    e.question = question;

    onChange(e, e.target.value);
  };

  const handleCheckChange = (e, id) => {
    e.question = question;

    if (e.target.checked) {
      onChange(e, id);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const imageUrl = await readFileAsDataURL(file);

    e.question = question;

    const images = value ?? answer?.images?.map((i) => i.image) ?? [];

    onChange(e, [...images, imageUrl]);
  };

  if (question.type === "0") {
    return (
      <Form.Group className={className}>
        <Form.Label className="fw-bold">{question.question_name}</Form.Label>
        <Form.Control
          disabled={readOnly}
          name={name}
          value={value ?? answer?.data?.answerText ?? ""}
          onChange={handleOnChange}
        ></Form.Control>
      </Form.Group>
    );
  }

  if (question.type === "1") {
    const checkedValue = value ?? answer?.data?.answerChoice;

    return (
      <Form.Group className={className}>
        <Form.Label className="fw-bold">{question.question_name}</Form.Label>

        <div className="d-flex flex-wrap">
          {question.choices.map((c) => (
            <Form.Check
              name={name}
              checked={checkedValue === c.id && checkedValue !== undefined}
              disabled={readOnly}
              className="me-3  mt-3"
              label={c.choice_name}
              key={c.id}
              value={value}
              onChange={(e) => handleCheckChange(e, c.id)}
            ></Form.Check>
          ))}
        </div>
      </Form.Group>
    );
  }

  if (question.type === "2") {
    const images = value ?? answer?.images?.map((i) => i.image) ?? [];

    return (
      <Form.Group className={className}>
        <Form.Label className="fw-bold">{question.question_name}</Form.Label>
        <div className="input-group mt-1 d-flex align-items-center">
          <div className="custom-file w-100">
            <input
              name={name}
              disabled={readOnly || images.length >= 5}
              type="file"
              className=""
              id={name}
              accept=".jpg, .png, .jpeg, .webp"
              onChange={handleFileChange}
            />

            {images.length > 0 ? (
              <div
                style={{ overflowX: "auto" }}
                className="d-flex flex-row gap-3 pb-3"
              >
                {images.map((i, index) => (
                  <div key={index} className="d-flex position-relative">
                    <Image
                      style={{
                        maxWidth: 250,
                        maxHeight: 300,
                        objectFit: "contain",
                      }}
                      src={i}
                    ></Image>

                    <div
                      className="position-absolute bg-white rounded end-0 me-1 mt-1 pointer"
                      onClick={() => {
                        onChange(
                          { question: question, target: { name } },
                          images.filter((img) => img !== i)
                        );
                      }}
                    >
                      <img src={xSquarePng}></img>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Image
                className="w-100"
                style={{ maxHeight: 200, objectFit: "contain" }}
                src={imageJpg}
              ></Image>
            )}

            {!readOnly && images.length < 5 && (
              <label className="pointer mt-2" htmlFor={name}>
                <span className="input-group-text">Escolher Imagem</span>
              </label>
            )}

            {images.length >= 5 && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Máximo de 5 imagens
              </Form.Control.Feedback>
            )}
          </div>
        </div>
      </Form.Group>
    );
  }

  return <p>Sem render</p>;
};
