import { useMutation } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import api from "../services/api";

export function usePatientMutation() {
  const config = useApiConfig();

  const unlikeProfessional = useMutation({
    mutationFn: async (params) => {
      return api.put(`/api/profiles/pacient/unlike/edit/`, {}, config);
    },
  });

  return { unlikeProfessional };
}
