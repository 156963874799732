import React, { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";
import { useHistory, useParams } from "react-router-dom";
import trash from "../../../assets/v2/icons/trash2.svg";
import penIcon from "../../../assets/v2/icons/pen2.svg";
import { useReadRoutineDetailsQuery } from "../../../queries";
import { RoutineSheet } from "./RoutineSheet";
import { useRoutineMutation } from "../../../mutations";
import { toast } from "react-toastify";
import PacientCardV2 from "../../../components/v2/PacientCardV2";
import { CreatePatientRoutine } from "./CreatePatientRoutine";
import { stringToDate } from "../../../utils/date";
import { CreateRoutineModal } from "./CreateRoutineModal";
import { useQueryClient } from "@tanstack/react-query";
import WorkoutCard from "../../../components/v2/Cards/CardFolderV2";

export function RoutineDetails() {
  const mediaValues = useMediaQueryValues();
  const { isMD } = mediaValues;
  const { id } = useParams();

  const { routine, routineWorkouts, patientsRoutine, readRoutineDetailsQuery } =
    useReadRoutineDetailsQuery({
      params: { id },
    });

  const registredPatientsIds = patientsRoutine.map((i) => i?.patient?.id);

  const { deleteRoutine, deleteRoutinePatient } = useRoutineMutation();
  const queryClient = useQueryClient();
  const [editRoutinePatient, setEditRoutinePatient] = useState();
  const [editRoutine, setEditRoutine] = useState();

  const history = useHistory();
  const handlePrintClick = () => {};

  const [showModalState, setShowModal] = useState({
    createPatient: false,
  });

  const handleClosePatientModal = () => {
    setShowModal({ ...showModalState, createPatient: false });
    setEditRoutinePatient(undefined);
  };

  const deleteHandler = async () => {
    await deleteRoutine.mutateAsync({ id: routine?._id });

    toast("Rotina deletada com sucesso!", {
      type: "success",
      position: "top-center",
    });

    history.replace("/home/personal/programs");
  };

  const handleRoutinePatientDelete = async (item) => {
    await deleteRoutinePatient.mutateAsync({ id: item?._id });
    await queryClient.refetchQueries({
      queryKey: ["read-routine-details-query", true, { id }],
    });
  };

  const renderActions = (
    <Col className="col-12 col-md-2 d-flex justify-content-end ps-2 align-items-center">
      <p
        className="btn btn-primary me-2 fw-bold d-flex justify-content-center align-items-center"
        onClick={(e) => setEditRoutine(routine)}
      >
        <img className="p-1" src={penIcon} />
      </p>

      <p
        className="btn btn-primary fw-bold d-flex justify-content-center align-items-center"
        onClick={(e) => deleteHandler(e)}
      >
        <img className="p-1" src={trash} />
      </p>
    </Col>
  );

  if (readRoutineDetailsQuery.isLoading)
    return (
      <div className="h-50 w-100 flex-center">
        <Spinner animation="border" role="status" variant="primary"></Spinner>
      </div>
    );

  return (
    <div>
      <Row className="m-0">
        {!isMD && renderActions}
        <Col className="col-12 col-md-10">
          <h2 className="text-primary text-capitalize h1">
            <button onClick={() => history.goBack()}>{"<"}</button>{" "}
            {routine?.name}
          </h2>
        </Col>

        {isMD && renderActions}
      </Row>

      <Row className="m-0">
        <p className="fw-bold m-0">Informações</p>
        <p className="text-break word-break">{routine?.description}</p>
      </Row>

      <RoutineSheet
        isInitialLoading={readRoutineDetailsQuery.isFetching}
        sheetRows={routineWorkouts}
      ></RoutineSheet>

      <Row className="m-0 mt-4">
        <Col className="col-6 p-1">
          <h3 className="mt-2">Alunos</h3>
        </Col>
        <Col className="col-6 p-1 d-flex justify-content-end align-items-center">
          <Button
            className="btn btn-primary"
            onClick={(e) =>
              setShowModal((prev) => ({ ...prev, createPatient: true }))
            }
          >
            Novo aluno
          </Button>
        </Col>
      </Row>

      <Row className="m-0">
        {patientsRoutine.length === 0 && (
          <p className="text-center col-12 mt-5">Não há alunos cadastrados.</p>
        )}

        {patientsRoutine?.map((item, i) => {
          item.patient.initialDate = stringToDate(item.initialDate);
          item.patient.finalDate = stringToDate(item.finalDate);

          return (
            <div
              key={i}
              className="col-12 col-md-6 col-xl-4 col-xxl-3 p-0 px-2 py-2 m-0"
            >
              <PacientCardV2
                hasDate
                user={item?.patient}
                defaultDelete={false}
                onDelete={() => handleRoutinePatientDelete(item)}
                onClick={(e) => setEditRoutinePatient(item)}
                hasDelete
                isLoading={
                  deleteRoutinePatient.isPending &&
                  deleteRoutinePatient.variables?.id === item?._id
                }
                variant="secondary"
              />
            </div>
          );
        })}
      </Row>

      <Row className="m-0 mt-4">
        <Col className="col-6 p-1">
          <h3 className="mt-2">Treinos</h3>
        </Col>
      </Row>

      <Row className="m-0">
        {routineWorkouts.length === 0 && (
          <p className="text-center col-12 mt-5">Não há treinos cadastrados.</p>
        )}

        {routineWorkouts?.map((item, i) => {
          return (
            <div
              key={i}
              className="col-12 col-md-6 col-xl-4 col-xxl-3 p-0 px-2 py-2 m-0"
            >
              <WorkoutCard
                canSelect={false}
                workout={item?.workout}
              ></WorkoutCard>
            </div>
          );
        })}
      </Row>

      {(showModalState.createPatient || editRoutinePatient) && (
        <CreatePatientRoutine
          registeredPatients={registredPatientsIds}
          routine={routine}
          routineSheet={routineWorkouts}
          routineWorkouts={routineWorkouts}
          initialRoutinePatient={editRoutinePatient}
          onFinish={() => handleClosePatientModal()}
        ></CreatePatientRoutine>
      )}

      {editRoutine && (
        <CreateRoutineModal
          onFinish={() => {
            setEditRoutine(undefined);
            readRoutineDetailsQuery.refetch();
          }}
          initialRoutine={editRoutine}
        ></CreateRoutineModal>
      )}
    </div>
  );
}
