import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ModalHeader } from "../../../../components/ModalHeader";
import {
  useReadAllFilledPatientFormQuery,
  useReadAllQuestionsPatientFormQuery,
} from "../../../../queries/patient-form.query";
import { useQueryClient } from "@tanstack/react-query";
import { SpinnerButton } from "../../../../components/v2/SpinnerButton";
import { usePatientFormMutation } from "../../../../mutations/patient-form.mutation";
import { getQuestionsAnswers } from "../../../../utils/question.utils";
import { QuestionInput } from "../Evaluations/QuestionInput";
import { useUserInfo } from "../../../../hooks/useUserInfo";

export function CreateFilledPatientForm({
  formModel,
  patientId,
  onClose = () => {},
}) {
  const { allQuestions, readAllQuestionsPatientFormQuery } =
    useReadAllQuestionsPatientFormQuery({
      enabled: true,
      params: { formModelId: formModel.data._id },
    });

  const {
    createFilledPatientFormMutation,
    createAnswerPatientFormMutation,
    uploadPatientFormImage,
  } = usePatientFormMutation();

  const { isPatient, isProfessional } = useUserInfo();

  const [formData, setFormData] = useState({ name: "" });

  const updateFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateFormQuestion = (e, value) => {
    setFormData({
      ...formData,
      [e.target.name]: { value, type: e.question.type, id: e.question.id },
    });
  };

  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, ...questions } = formData;

    const response = await createFilledPatientFormMutation.mutateAsync({
      patientId,
      formModelId: formModel.data._id,
      name,
    });

    queryClient.refetchQueries({
      queryKey: [useReadAllFilledPatientFormQuery.key],
    });

    onClose();
  };

  const isLoading =
    readAllQuestionsPatientFormQuery.isFetching ||
    createFilledPatientFormMutation.isPending ||
    createAnswerPatientFormMutation.isPending ||
    uploadPatientFormImage.isPending;

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <ModalHeader
        title={"Adicionar Formulário"}
        onClose={onClose}
      ></ModalHeader>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {isProfessional && (
            <Form.Group>
              <Form.Label>Título</Form.Label>
              <Form.Control
                name="name"
                onChange={updateFormData}
                type="text"
                placeholder="Digite o Título"
                required
                value={formData["name"]}
              />
            </Form.Group>
          )}

          {isPatient &&
            allQuestions.map((q) => {
              const key = `question${q.id}`;
              return (
                <QuestionInput
                  className="my-3"
                  key={key}
                  name={key}
                  question={q}
                  value={formData[key]?.value}
                  onChange={updateFormQuestion}
                ></QuestionInput>
              );
            })}
        </Modal.Body>

        <Modal.Footer>
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary"
          >
            <SpinnerButton isLoading={isLoading}></SpinnerButton>
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
