import React, { useState } from "react";
import { Col, Image, Row, Spinner, Card } from "react-bootstrap";

import trash from "../../../assets/v2/icons/trash.svg";
import { formatDate } from "../../../utils/date";
import { CreateFinanceModal } from "./CreateFinanceModal";
import { useFinanceMutation } from "../../../mutations/finance.mutation";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useMediaQueryValues } from "../../../hooks/useMediaQueryValues";

export function FinanceSheet({
  sheetRows,
  isLoading = false,
  canEdit = false,
  vinculed = true,
}) {
  const [sheetItem, setSheetItem] = useState();
  const { deleteFinance } = useFinanceMutation();
  const queryClient = useQueryClient();

  const { isMD, isLG, isSM } = useMediaQueryValues();

  const [openSheetItem, setOpenSheetItem] = useState();

  const handleDelete = async (sheetItem) => {
    await deleteFinance.mutateAsync({ id: sheetItem?._id });
    await queryClient.refetchQueries({
      queryKey: ["read-patient-finances-details-query"],
    });

    toast("Pagamento deletado com sucesso!", {
      type: "success",
      position: "top-center",
    });
  };

  const renderCanEditContent = (sheetItem, enabled = true) =>
    canEdit && (
      <div className="flex-center">
        <div className={"text-center mx-0" + (enabled && " pointer ")}>
          <Image
            variant="top"
            src="assets/v2/icons/pen.svg"
            onClick={() => {
              if (enabled) setSheetItem(sheetItem);
            }}
          />
        </div>
        <div className={"text-center ms-2 " + (enabled && " pointer ")}>
          <Image
            variant="top"
            src={trash}
            onClick={() => enabled && handleDelete(sheetItem)}
          />
        </div>
      </div>
    );

  return (
    <div
      className="flex-center"
      style={!isSM ? { marginLeft: "-13px", marginRight: "-13px" } : {}}
    >
      <Row className="bg-body-secondary mt-4 mx-2 rounded-3 p-3 col-12 align-self-center">
        <h2 className="h3 fw-bold ps-0 text-primary ps-md-3 pt-md-3">
          Histórico de Pagamentos
        </h2>

        {isLoading && (
          <div className="w-100 flex-center mb-3">
            <Spinner
              animation="border"
              role="status"
              variant="primary"
            ></Spinner>
          </div>
        )}

        {!isLoading && (
          <Row className="m-0 p-0 p-md-3 mt-2">
            <Col className="col-12 px-0">
              <div className="d-none d-lg-flex w-100 fw-500 ps-3 mb-1">
                <p
                  className={"ms-1 mb-0 text-capitalize text-small "}
                  style={{ width: "21.5%", flex: "0 0 auto" }}
                >
                  Plano
                </p>

                <div
                  className="text-small col-2 flex-center"
                  style={{ width: "14%", flex: "0 0 auto" }}
                >
                  <p className="mb-0 text-nowrap">Pagamento</p>
                </div>

                <div
                  className="text-small col-2 flex-center"
                  style={{ width: "20%", flex: "0 0 auto" }}
                >
                  <p className="mb-0">Data Inicial</p>
                </div>

                <div
                  className="text-small col-2 flex-center"
                  style={{ width: "16.5%", flex: "0 0 auto" }}
                >
                  <p className="mb-0">Data Final</p>
                </div>

                <div
                  className="me-3 mb-0 text-small flex-center col-2"
                  style={{ width: "15%", flex: "0 0 auto" }}
                >
                  <p className="mb-0">Valor</p>
                </div>

                <div
                  className="mb-0 text-small flex-center col-2"
                  style={{ width: "12%", flex: "0 0 auto" }}
                >
                  <p className="mb-0">Ações</p>
                </div>
              </div>

              <div className="d-flex d-lg-none w-100 fw-500 ps-3 mb-1">
                <p
                  className={"ms-1 mb-0 text-capitalize text-small "}
                  style={{ width: "27%", flex: "0 0 auto" }}
                >
                  Nome
                </p>

                <div
                  className="text-small col-2 flex-center"
                  style={{ width: "33%", flex: "0 0 auto" }}
                >
                  <p className="mb-0 ms-3 ms-md-0 text-nowrap"> Pagamento</p>
                </div>

                <div
                  className="me-3 mb-0 ms-3 ms-md-0 text-small flex-center col-2"
                  style={{ width: "30%", flex: "0 0 auto" }}
                >
                  <p className="mb-0">Valor</p>
                </div>
              </div>

              <div
                className="d-flex  w-100 bg-primary mb-3 mb-lg-4"
                style={{ height: 2 }}
              ></div>

              {sheetRows?.map((sheetItem, i) => {
                const { plansPatient } = sheetItem;

                return (
                  sheetItem && (
                    <div
                      key={i}
                      className="d-flex flex-direction-row fw-bold w-100"
                    >
                      <p className="pe-1 mb-0 border-end border-dark border-1">
                        {i + 1}
                      </p>
                      <div className="d-flex flex-column w-100 mb-3">
                        <div
                          className="d-flex justify-content-between w-100 pointer"
                          onClick={() => setOpenSheetItem(sheetItem)}
                        >
                          <p
                            className={
                              "ms-2 mb-0 text-capitalize col-3 col-lg-2 text-small "
                            }
                          >
                            {plansPatient?.name_type}
                          </p>

                          <div
                            style={{ flex: isLG ? 0 : 1 }}
                            className="text-small flex-center justify-content-start ms-3 ms-md-0"
                          >
                            <p className="mb-0">
                              {formatDate(sheetItem?.date)}
                            </p>
                          </div>

                          <div className="d-none d-lg-flex text-small">
                            <p className="mb-0">
                              {formatDate(sheetItem?.dateInitial)}
                            </p>
                          </div>

                          <div className="d-none d-lg-flex text-small">
                            <p className="mb-0">
                              {formatDate(sheetItem?.dateFinal)}
                            </p>
                          </div>

                          <div className="me-3 mb-0 text-small flex-center">
                            <p className="mb-0">
                              R$ {sheetItem?.value.toFixed(2)}
                            </p>
                          </div>

                          <div className="d-none d-lg-flex">
                            {renderCanEditContent(sheetItem)}
                          </div>
                        </div>

                        {openSheetItem?._id === sheetItem?._id && (
                          <div className="d-flex justify-content-between w-100 d-lg-none">
                            <Card className="w-100 mx-2 p-2 mt-2 bg-transparent">
                              <div className="flex-between w-100 mb-2">
                                <div style={{}} className=" ms-2 text-small">
                                  <p className="mb-0">Data de Inicio</p>
                                  <p className="mb-0">
                                    {formatDate(sheetItem?.dateInitial)}
                                  </p>
                                </div>

                                <div className="mb-0 text-small d-flex flex-column me-3">
                                  <p className="mb-0">Data de Fim</p>
                                  <p className="mb-0">
                                    {formatDate(sheetItem?.dateFinal)}
                                  </p>
                                </div>
                              </div>
                              {renderCanEditContent(sheetItem)}
                            </Card>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
            </Col>
          </Row>
        )}

        {sheetItem && (
          <CreateFinanceModal
            onClose={() => setSheetItem(null)}
            initialFinance={sheetItem}
            initialVinculed={vinculed}
          ></CreateFinanceModal>
        )}
      </Row>
    </div>
  );
}
