import React from "react";

export function useComputeExerciseInputVideo({
  feedbackStreamController,
  webcamFeedbackResult,
  videoToUpload,
  withFeedbackValue,
  uploadExerciseVideoIsPending,
}) {
  const { isSocketOpen, resultFinal, loadingState } = feedbackStreamController;
  const feedbackResultFinal = resultFinal ?? webcamFeedbackResult;
  const isWaitingSocket =
    withFeedbackValue && !isSocketOpen && loadingState.socketConnect;
  const isWaitingFeedback = withFeedbackValue && loadingState.resultFinal;
  const videoIsLoading =
    uploadExerciseVideoIsPending || isWaitingFeedback || isWaitingSocket;
  const showUploadButton =
    videoToUpload?.file && (!videoToUpload?.uploaded || isWaitingFeedback);

  return {
    showUploadButton,
    videoIsLoading,
    isWaitingFeedback,
    isWaitingSocket,
    feedbackResultFinal,
  };
}
