import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useExerciseInputVideoController } from "../../controllers";
import { Form, Modal, Col } from "react-bootstrap";
import VideoPlayer from "./VideoPlayer";
import { ResultFinalCard } from "../ResultFinalCard";
import { useExerciseDoneMutation } from "../../mutations";
import WebcamStreamCapture from "./WebcamStreamCapture";
import { useReadExerciseDetailsQuery } from "../../queries";

export const PatientExerciseExecution = ({
  showModal,
  handleClose,
  exercise,
  workoutId,
  data,
}) => {
  const { createExerciseDoneMut } = useExerciseDoneMutation();

  const { exercise: exerciseDetails } = useReadExerciseDetailsQuery({
    enabled: true,
    params: { id: exercise?._id },
  });

  const handleSendClick = async (
    e,
    { videoToUpload, feedbackResultFinal, withFeedback }
  ) => {
    e?.preventDefault();

    const response = await createExerciseDoneMut.mutateAsync({
      exercise_id: exercise._id,
      exerciseVideo_id: videoToUpload._id,
      professional_id: exercise.professional,
      workout_id: workoutId,
      finish_serie: null,
      hasFeedback: withFeedback,
      ...feedbackResultFinal,
    });

    handleClose();
  };

  const exerciseInputController = useExerciseInputVideoController({
    withFeedback: exercise?.hasFeedback,
    muscleName: exercise?.muscle?.name,
    handleSendClick,
    professionalFeedback: exerciseDetails?.feedback?.socketFeedback,
  });

  const {
    renderActions,
    videoToUpload,
    showWebcam,
    renderPlayer,
    feedbackResultFinal,
    videoIsLoading,
    renderWithFeedbackInput,
  } = exerciseInputController;

  return (
    <Modal size="lg" show={showModal} onHide={handleClose} centered>
      <Form>
        <Modal.Header className="border-0">
          <Modal.Title className="text-primary">Detalhes</Modal.Title>
          <div className="title-action" onClick={handleClose}>
            <img alt="close icon" src="/assets/v2/icons/x-square.png" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Col className="col-12 d-flex mb-3">
            <div className="">
              <label className="fw-bold">Descrição</label>
              <p className="fw-bold text-gray">{exercise?.description}</p>
            </div>
          </Col>
          <Col className="col-12 d-flex mb-3">
            <div className="">
              <label className="fw-bold">Músculo</label>
              <p className="fw-bold text-gray">{exercise?.muscle?.name}</p>
            </div>
          </Col>
          <Col className="col-12 d-flex mb-3">
            <div className="me-5">
              <label className="fw-bold">Quantidade de repetições</label>
              <p className="fw-bold text-gray">{data?.repetitions}</p>
            </div>

            <div className="">
              <label className="fw-bold">Séries</label>
              <p className="fw-bold text-gray">{data?.series}</p>
            </div>
          </Col>
          <Col className="col-12 d-flex mb-3">
            <div className="me-5">
              <label className="fw-bold">Tempo de descanso</label>
              <p className="fw-bold text-gray">{data?.restTime}</p>
            </div>
            <div className="">
              <label className="fw-bold">Peso</label>
              <p className="fw-bold text-gray">{data?.weight}</p>
            </div>
          </Col>

          {exercise?.hasFeedback && renderWithFeedbackInput}

          {renderPlayer}

          {feedbackResultFinal && (
            <>
              <div className="mt-2"></div>

              <ResultFinalCard resultFinal={feedbackResultFinal} />
            </>
          )}
        </Modal.Body>

        <Modal.Footer>{renderActions}</Modal.Footer>
      </Form>
    </Modal>
  );
};
