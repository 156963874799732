import { useQuery } from "@tanstack/react-query";
import { useApiConfig } from "../hooks/useApiConfig";
import { useMemo } from "react";
import api from "../services/api";

export const useReadAllScheduleQuery = ({ params, enabled = true }) => {
  const config = useApiConfig();

  const readScheduleQuery = useQuery({
    queryKey: ["read-all-schedules", params, enabled],
    queryFn: async () => {
      config.params = params;
      return api.get(`/api/management/agenda/readall/`, config);
    },
    enabled,
  });

  const schedules = useMemo(
    () => readScheduleQuery.data?.data ?? [],
    [readScheduleQuery.data?.data]
  );

  return { schedules, readScheduleQuery };
};
