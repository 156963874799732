import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  CloseButton,
  Image,
} from "react-bootstrap";
import NavTabsV2 from "../../components/v2/NavTabsV2";
import ExerciseDetailsV2 from "./ProgramsV2/ExerciseDetailsV2";
import WorkoutPacientDetailsV2 from "./ProgramsV2/WorkoutPacientDetailsV2";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import dashboard from "../../assets/v2/dashboard.css";
import { listPatientWorkout } from "../../actions/workoutActions";
import { useSelector, useDispatch } from "react-redux";
import FeedbackScreenV2 from "./Feedback/FeedbackScreenV2";
import UserProfileV2 from "./Configs/UserProfileV2";
import { useMediaQueryValues } from "../../hooks/useMediaQueryValues";
import { PageRouteContainer } from "../PageRouteContainer";
import { WorkoutSheetExecution } from "../../components/WorkoutSheetExecution";
import { formatDate, stringToDate } from "../../utils/date";
import PacientDetailsV2 from "./PacientDetailsV2/PacientDetailsV2";
import { setTodayExercises } from "../../reducers/todayExercisesReducer";
import { PatientPayment } from "./PatientPayment/PatientPayment";

function HomePacientScren({ location, history }) {
  let { path, url } = useRouteMatch();

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalVisible, setVisible] = useState(true);
  const [modalBody, setModalBody] = useState([]);

  const today = new Date().getDay();
  const [currentTabIndex, setCurrentTabIndex] = useState(today);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const weekdays = [
    "domingo",
    "segunda-feira",
    "terça-feira",
    "quarta-feira",
    "quinta-feira",
    "sexta-feira",
    "sábado",
  ];

  const mediaValues = useMediaQueryValues();

  const [tabs, setTabs] = useState([]);

  const getModalBody = (body, title, visible = true) => {
    setModalTitle(title);
    setModalShow(true);
    setVisible(visible);
    setModalBody(body);
  };

  const week = [
    {
      name: weekdays[1],
      exercises: [],
      body: <></>,
      params: "dia=" + weekdays[1],
    },

    {
      name: weekdays[2],
      exercises: [],
      body: <></>,
      params: "dia=" + weekdays[2],
    },

    {
      name: weekdays[3],
      exercises: [],
      body: <></>,
      params: "dia=" + weekdays[3],
    },

    {
      name: weekdays[4],
      exercises: [],
      body: <></>,
      params: "dia=" + weekdays[4],
    },

    {
      name: weekdays[5],
      exercises: [],
      body: <></>,
      params: "dia=" + weekdays[5],
    },

    {
      name: weekdays[6],
      exercises: [],
      body: <></>,
      params: "dia=" + weekdays[6],
    },

    {
      name: weekdays[0],
      exercises: [],
      body: <></>,
      params: "dia=" + weekdays[0],
    },
  ];

  const profileTabs = [
    {
      name: "Meu perfil",
      body: <UserProfileV2 />,
    },
    {
      name: "Planos & Pagamentos",
      body: <PatientPayment></PatientPayment>,
      params: "plans",
    },
  ];

  const changeVisbility = (visible) => {
    setVisible(visible);
    return modalVisible;
  };

  const unsetModalBody = () => {
    setModalBody([]);
    setModalShow(false);
  };

  const workoutsSelector = useSelector((state) => state.workoutPatientList);
  const { loadingWorkoutListDetails, workoutListDetails } = workoutsSelector;

  useEffect(() => {
    dispatch(listPatientWorkout());
  }, []);

  useEffect(() => {
    if (workoutListDetails) {
      workoutListDetails?.map((sheet) => {
        const startDate = stringToDate(sheet["Ficha Paciente"].initialDate);
        const finalDate = stringToDate(sheet["Ficha Paciente"].finalDate);

        finalDate.setHours(23, 59);
        const now = Date.now();

        if (now < startDate.getTime() || now > finalDate.getTime()) {
          return;
        }

        sheet["Ficha Paciente"]["days"].forEach((day) => {
          week[day - 1].exercises.push(sheet["ExerciciosFicha"][0]);
          week[day - 1].startDate = sheet["Ficha Paciente"].initialDate;
          week[day - 1].finalDate = sheet["Ficha Paciente"].finalDate;
          week[day - 1].pace = sheet.pace;
        });
      });

      week.map((day, index) => {
        day.exercises = [].concat(...day.exercises);

        day.exercises = day.exercises.filter((e) => e && !!e.exercise);

        day.body = (
          <WorkoutPacientDetailsV2
            startDate={day.startDate}
            finalDate={day.finalDate}
            exercises={day.exercises}
            pace={day.pace}
            weekDay={index + 1}
          />
        );
      });

      setTabs(week);
    }
  }, [workoutListDetails]);

  useEffect(() => {
    const index = currentTabIndex === -1 ? today - 1 : currentTabIndex;

    const exercises = tabs[index]?.exercises;
    const pace = tabs[index]?.pace;

    dispatch(
      setTodayExercises({
        todayExercises: exercises,
        pace,
        currentTabIndex: index,
      })
    );
  }, [currentTabIndex, tabs, today]);

  const getHomeDefaultTabKey = () => {
    return searchParams.get("dia") ?? weekdays[today];
  };

  const getProfileTabsInitialKey = () => {
    const all = Array.from(searchParams.entries()).map((e) => e[0]);

    if (all.includes("plans")) return profileTabs[1].name;

    return profileTabs[0].name;
  };

  return (
    <Container fluid className="p-0">
      <style src={dashboard}></style>
      <PageRouteContainer
        changeVisbility={changeVisbility}
        getModalBody={getModalBody}
        unsetModalBody={unsetModalBody}
      >
        <Switch>
          <Route exact path={`${path}`}>
            <NavTabsV2
              defaultKey={getHomeDefaultTabKey()}
              tabs={tabs}
              onChangeTab={(i) => setCurrentTabIndex(i)}
              handleModal={getModalBody}
              unsetModalBody={unsetModalBody}
              classNames="d-flex justify-content-between"
            />
          </Route>
          <Route exact path={`${path}/exercises/:id`}>
            <ExerciseDetailsV2
              handleModal={getModalBody}
              unsetModalBody={unsetModalBody}
            />
          </Route>

          <Route exact path={`${path}/feedback/`}>
            <div className="d-flex flex-column col-12">
              <p className="h2 text-primary mb-3">Feedbacks</p>
              <FeedbackScreenV2 />
            </div>
          </Route>
          <Route exact path={`${path}/perfil/`}>
            <PacientDetailsV2
              handleModal={getModalBody}
              unsetModalBody={unsetModalBody}
            />
          </Route>
          <Route
            exact
            path={`${path}/workoutsheet/execution/:exerciseId/:postId`}
          >
            <WorkoutSheetExecution></WorkoutSheetExecution>
          </Route>
          <Route exact path={`${path}/profile`}>
            <NavTabsV2
              defaultKey={getProfileTabsInitialKey()}
              tabs={profileTabs}
            />
          </Route>
        </Switch>
      </PageRouteContainer>

      <Modal
        show={modalShow}
        className={`${modalVisible ? "visible" : "invisible overflow-hidden"}`}
        onHide={() => unsetModalBody()}
        size="lg"
        aria-labelledby="contained-modal-title-center"
        centered
      >
        <Modal.Header className="justify-content-between align-items-center">
          <p className="mb-0 text-primary fw-500 fs-5">{modalTitle}</p>
          <CloseButton
            className="btn text-danger fs-4"
            onClick={unsetModalBody}
          />
        </Modal.Header>
        <Modal.Body children={modalBody} />
      </Modal>
    </Container>
  );
}

export default HomePacientScren;
